export default {
  GET_REVIEW_ORDERS_SUCCESS: 'GET_REVIEW_ORDERS_SUCCESS',
  SET_COUNT_REVIEW: 'SET_COUNT_REVIEW',

  GET_REVIEWED_ORDERS: 'GET_REVIEWED_ORDERS',
  IS_LOADING_GET_REVIEWED_ORDERS: 'IS_LOADING_GET_REVIEWED_ORDERS',

  GET_NOT_YET_REVIEWED_ORDER: 'GET_NOT_YET_REVIEWED_ORDER',
  IS_LOADING_GET_NOT_YET_REVIEWED_ORDER: 'IS_LOADING_GET_NOT_YET_REVIEWED_ORDER',

  GET_DETAIL_REVIEW: 'GET_DETAIL_REVIEW',
  GET_DETAIL_REVIEW_SUCCESS: 'GET_DETAIL_REVIEW_SUCCESS',
  IS_LOADING_GET_DETAIL_REVIEW: 'IS_LOADING_GET_DETAIL_REVIEW',

  CREATE_REVIEW: 'CREATE_REVIEW',
  CREATE_REVIEW_SUCCESS: 'CREATE_REVIEW_SUCCESS',
  IS_LOADING_CREATE_REVIEW: 'IS_LOADING_CREATE_REVIEW',

  UPDATE_REVIEW: 'UPDATE_REVIEW',
  UPDATE_REVIEW_SUCCESS: 'UPDATE_REVIEW_SUCCESS',
  IS_LOADING_UPDATE_REVIEW: 'IS_LOADING_UPDATE_REVIEW',

  RESET_REVIEW: 'RESET_REVIEW'
};
