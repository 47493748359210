import types from "./constant";

const initialState = {
  orders: [],
  payment_method: "",
  payment_details: {},
  receiver: {},
  cost: {
    store_id: null,
    data: [],
    loading: false,
  },
  created: {
    payment: {},
    success: false,
    loading: false,
  },
  payment: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CHECKOUT_PAYMENT:
      return {
        ...state,
        payment_method: action.payload.payment_method,
        payment_details: action.payload.payment_details,
      };
    case types.SET_CHECKOUT_RECEIVER:
      return {
        ...state,
        receiver: action.payload,
      };
    case types.SET_CHECKOUT_SHIPPING:
      return {
        ...state,
        orders: state.orders.map((item) =>
          item.store_id === action.payload.store_id
            ? {
                ...item,
                shipping_service: action.payload.shipping_service,
                shipping_service_type: action.payload.shipping_service_type,
                shipping_cost: action.payload.shipping_cost,
                service_type_id: action.payload.service_type_id,
                drop: action.payload.drop,
              }
            : item
        ),
      };
    case types.SET_CHECKOUT_INSURANCE:
      return {
        ...state,
        orders: state.orders.map((item) =>
          item.store_id === action.payload.store_id
            ? {
                ...item,
                insurance: action.payload.checked,
                shipping_insurance_fee: action.payload.shipping_insurance_fee,
                insurance_setting: action.payload.insurance_setting,
              }
            : item
        ),
      };
    case types.SET_CHECKOUT_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    case types.SET_CHECKOUT_SELECT_SENDER:
      return {
        ...state,
        orders: state.orders.map((item) =>
          item.store_id === action.payload.store_id
            ? {
                ...item,
                sender: action.payload,
                warehouse_id: action.payload.id,
              }
            : item
        ),
      };
    case types.RESET_CHECKOUT_SHIPPING:
      return {
        ...state,
        orders: state.orders.map((item) => {
          const newObj = { ...item };
          delete newObj["Costs"];
          delete newObj["shipping_service"];
          delete newObj["shipping_service_type"];
          delete newObj["shipping_cost"];
          delete newObj["service_type_id"];
          delete newObj["drop"];
          delete newObj["insurance"];
          delete newObj["shipping_insurance_fee"];
          delete newObj["insurance_setting"];
          return newObj;
        }),
      };

    case types.GET_COSTS_SUCCESS:
      return {
        ...state,
        orders: state.orders.map((item) =>
          item.store_id === action.payload.store_id
            ? {
                ...item,
                Costs: action.payload.data,
              }
            : item
        ),
        cost: {
          ...state.cost,
          store_id: action.payload.store_id,
          data: action.payload.data,
        },
      };
    case types.IS_LOADING_GET_COSTS:
      return {
        ...state,
        cost: {
          ...state.cost,
          loading: action.payload,
        },
      };
    case types.RESET_COSTS:
      return {
        ...state,
        cost: initialState.cost,
      };

    case types.CREATE_ORDERS_SUCCESS:
      return {
        ...state,
        created: {
          ...state.created,
          data: action.payload,
        },
      };
    case types.IS_LOADING_CREATE_ORDERS:
      return {
        ...state,
        created: {
          ...state.created,
          loading: action.payload,
        },
      };
    case types.SET_CREATED_ORDERS_SUCCESS:
      return {
        ...state,
        created: {
          ...state.created,
          success: action.payload,
        },
      };
    case types.RESET_CREATE_ORDERS:
      return {
        ...state,
        created: initialState.created,
      };

    case types.SET_CHECKOUT_PAYMENT_SUCCESS:
      return {
        ...state,
        payment: action.payload,
      };

    case types.RESET_CHECKOUT:
      return initialState;

    default:
      return state;
  }
};

export const setCheckoutOrders = (payload) => ({
  type: types.SET_CHECKOUT_ORDERS,
  payload,
});
export const setCheckoutSelectSender = (payload) => ({
  type: types.SET_CHECKOUT_SELECT_SENDER,
  payload,
});
export const setCheckoutShipping = (payload) => ({
  type: types.SET_CHECKOUT_SHIPPING,
  payload,
});
export const setCheckoutInsurance = (payload) => ({
  type: types.SET_CHECKOUT_INSURANCE,
  payload,
});
export const resetShipping = (payload) => ({
  type: types.RESET_CHECKOUT_SHIPPING,
  payload,
});

export const setCheckoutPayment = (payload) => ({
  type: types.SET_CHECKOUT_PAYMENT,
  payload,
});
export const setCheckoutReceiver = (payload) => ({
  type: types.SET_CHECKOUT_RECEIVER,
  payload,
});

export const getCosts = (payload) => ({
  type: types.GET_COSTS,
  payload,
});
export const getCostsSuccess = (payload) => ({
  type: types.GET_COSTS_SUCCESS,
  payload,
});
export const setIsLoadingGetCosts = (payload) => ({
  type: types.IS_LOADING_GET_COSTS,
  payload,
});
export const resetCosts = (payload) => ({
  type: types.RESET_COSTS,
  payload,
});

export const createOrders = (payload) => ({
  type: types.CREATE_ORDERS,
  payload,
});
export const createOrdersSuccess = (payload) => ({
  type: types.CREATE_ORDERS_SUCCESS,
  payload,
});
export const setIsLoadingCreateOrders = (payload) => ({
  type: types.IS_LOADING_CREATE_ORDERS,
  payload,
});
export const resetCreateOrders = (payload) => ({
  type: types.RESET_CREATE_ORDERS,
  payload,
});
export const setCreatedSuccessOrders = (payload) => ({
  type: types.SET_CREATED_ORDERS_SUCCESS,
  payload,
});

export const setCheckoutPaymentSuccess = (payload) => ({
  type: types.SET_CHECKOUT_PAYMENT_SUCCESS,
  payload,
});

export const resetCheckout = (payload) => ({
  type: types.RESET_CHECKOUT,
  payload,
});
