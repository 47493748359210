import { put, call, takeLatest } from "redux-saga/effects";
import Api from "../../services";
import * as actions from "./reducer";
import types from "./constant";

function* watchGet(values) {
  yield put(actions.setLoadingGetCategories(true));
  try {
    const response = yield call(Api.category.get);
    const { data } = response;
    yield put(actions.getCategoriesSuccess(data.data));
  } catch (error) {
    yield put(actions.setLoadingGetCategories(false));
  } finally {
    yield put(actions.setLoadingGetCategories(false));
  }
}

function* watchGetProducts(values) {
  yield put(actions.setLoadingGetProducts(true));
  const { payload } = values;
  try {
    const response = yield call(Api.product.getByCategoryId, payload);
    const { data } = response;
    yield put(actions.getProductsSuccess(data));
  } catch (error) {
    yield put(actions.setLoadingGetProducts(false));
  } finally {
    yield put(actions.setLoadingGetProducts(false));
  }
}
const sagas = [
  takeLatest(types.GET_CATEGORIES, watchGet),
  takeLatest(types.GET_PRODUCTS_BY_CATEGORY_ID, watchGetProducts),
];

export default sagas;
