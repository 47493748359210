import types from './constants';

const initialState={
  origin:{},
  destination:{},
  warehouse:{
    data:[],
    loading:false
  },
  payment_method: "",
  payment_details: {},
  orders:[],
  insurance:{
    checked: false,
    fee: 0,
    setting: {}
  },
  cod:{
    checked: false,
    fee: 0,
    setting: {}
  },
  cost: {
    data:[],
    loading:false
  },
  created: {
    payment: {},
    loading: false,
    success: false
  },
  courier: {},
  payment:null,
}

export default (state = initialState, action) => {
  switch(action.type){
    case types.SET_ORIGIN:
      return {...state, origin: action.payload}
    case types.SET_DESTINATION:
      return {...state, destination: action.payload}
    case types.GET_WAREHOUSE_SUCCESS:
      return {
        ...state,
        warehouse: {
          ...state.warehouse,
          data: action.payload
        }
      }
    case types.IS_LOADING_GET_WAREHOUSE:
      return {
        ...state,
        warehouse: {
          ...state.warehouse,
          loading: action.payload
        }
      }

    case types.CHANGE_ORDERS:
      return { ...state, orders: action.payload }
    case types.SET_QUANTITY:
      return Object.assign({}, state, {
        orders: state.orders.map(item => {
          return item.store_id === action.payload.store_id ? {
            ...item,
            products: item.products.map(data=>{
              return data.product_id === action.payload.product_id ? {
                ...data,
                quantity: action.payload.quantity,
                weight: action.payload.weight,
                price: action.payload.price,
              } : data
            })
          } : item;
        })
      });
    case types.SET_DESCRIPTION:
      return Object.assign({}, state, {
        orders: state.orders.map(item => {
          return item.store_id === action.payload.store_id ? {
            ...item,
            products: item.products.map(data=>{
              return data.product_id === action.payload.product_id ? {
                ...data,
                description: action.payload.description,
              } : data
            })
          } : item;
        })
      });

    case types.CHECK_SHIPPING_COST_SUCCESS:
      return {
        ...state,
        cost: {
          ...state.cost,
          data: action.payload
        }
      }
    case types.IS_LOADING_CHECK_SHIPPING_COST:
      return {
        ...state,
        cost: {
          ...state.cost,
          loading: action.payload
        }
      }

    case types.SET_COURIER:
      return {
        ...state,
        orders: state.orders.map(item => ({
          ...item,
          shipping_service: action.payload.shipping_service,
          shipping_service_type: action.payload.shipping_service_type,
          shipping_cost: action.payload.shipping_cost,
          service_type_id: action.payload.service_type_id,
          drop: action.payload.drop,
        })),
        courier: action.payload.courier
      }
    case types.RESET_COURIER:
      return {
        ...state,
        cost: initialState.cost,
        orders: state.orders.map(item => ({
          ...item,
          shipping_service: "",
          shipping_service_type: "",
          shipping_cost: 0,
          service_type_id: null,
        })),
        courier: {}
      }
    case types.SELECT_PAYMENT_METHOD:
      return {
        ...state,
        payment_method: action.payload.payment_method,
        payment_details: action.payload.payment_details,
      }

    case types.SET_INSURANCE:
      return {
        ...state,
        insurance: {
          checked: action.payload.checked,
          fee: action.payload.shipping_insurance_fee
        },
        orders: state.orders.map(item => ({
          ...item,
          insurance: action.payload.checked,
          shipping_insurance_fee: action.payload.shipping_insurance_fee
        }))
      }
    case types.SET_COD:
      return {
        ...state,
        cod: {
          checked: action.payload.cod,
          fee: action.payload.cod_fee,
          setting: action.payload.cod_setting
        },
        orders: state.orders.map(item =>  ({
          ...item,
          cod: action.payload.cod,
          cod_fee: action.payload.cod_fee,
          cod_setting: action.payload.cod_setting
        }))
      }
    case types.RESET_INSURANCE:
      return {
        ...state,
        insurance: initialState.insurance,
        orders: state.orders.map(item => ({
          ...item,
          insurance: false,
          shipping_insurance_fee: 0
        }))
      }
    case types.RESET_COD:
      return {
        cod: initialState.cod,
        orders: state.orders.map(item => ({
          ...item,
          cod: false,
          cod_fee: 0,
          cod_setting: {}
        }))
      };
    case types.SET_PAYMENT:
      return {
        ...state,
        payment: action.payload
      }
    case types.RESET_PAYMENT:
      return {...state, payment:null}

    case types.CREATE_ORDERS_SUCCESS:
      return {...state, created: {...state.created, payment: action.payload, success: action.payload.success}}
    case types.IS_LOADING_CREATE_ORDERS:
      return {
        ...state,
        created: {
          ...state.created,
          loading: action.payload
        }
      }

    case types.RESET_BUY:
      return initialState;
    default:
      return state
  }
}

export const setOrders = payload => ({
  type:types.CHANGE_ORDERS,
  payload
})
export const setOrigin = payload => ({
  type:types.SET_ORIGIN,
  payload
})
export const setDestination = payload => ({
  type:types.SET_DESTINATION,
  payload
})
export const getWarehouse = payload => ({
  type:types.GET_WAREHOUSE,
  payload
})
export const getWarehouseSuccess = payload => ({
  type:types.GET_WAREHOUSE_SUCCESS,
  payload
})
export const setIsLoadingGetWarehouse = payload => ({
  type:types.IS_LOADING_GET_WAREHOUSE,
  payload
})

export const setQty = payload => ({
  type: types.SET_QUANTITY,
  payload
})
export const setDescription = payload => ({
  type: types.SET_DESCRIPTION,
  payload
})

export const checkSippingCost = payload => ({
  type: types.CHECK_SHIPPING_COST,
  payload
})
export const checkSippingCostSuccess = payload => ({
  type: types.CHECK_SHIPPING_COST_SUCCESS,
  payload
})
export const setIsLoadingCheckSippingCost = payload => ({
  type: types.IS_LOADING_CHECK_SHIPPING_COST,
  payload
})

export const setCourier = payload => ({
  type: types.SET_COURIER,
  payload
})
export const resetCourier = payload => ({
  type:types.RESET_COURIER,
  payload
})
export const resetBuy = payload => ({
  type: types.RESET_BUY
})
export const selectPaymentMethod = payload => ({
  type: types.SELECT_PAYMENT_METHOD,
  payload
})
export const setPayment = payload => ({
  type: types.SET_PAYMENT,
  payload
})
export const resetPayment = payload => ({
  type: types.RESET_PAYMENT
})

export const createOrders = payload => ({
  type:types.CREATE_ORDERS,
  payload
})
export const createOrdersSuccess = payload => ({
  type:types.CREATE_ORDERS_SUCCESS,
  payload
})
export const setIsLoadingCreateOrders = payload => ({
  type:types.IS_LOADING_CREATE_ORDERS,
  payload
})
export const setCOD = payload => ({
  type: types.SET_COD,
  payload
})
export const setInsurance = payload => ({
  type: types.SET_INSURANCE,
  payload
})
export const resetInsurance = payload => ({
  type: types.RESET_INSURANCE,
  payload
})
export const resetOrders = payload => ({
  type: types.RESET_ORDERS,
  payload
})
export const resetCOD = payload => ({
  type: types.RESET_COD,
  payload
})
