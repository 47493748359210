import types from './constant';

const initialState = {
  coin:0,
  loading:false,
  count: 0,
  history:[],
  loading_history: false,

  destination_bank_id: null,
  request_otp_success: false,
  loading_request_otp: false,
  verify_otp_success: false,
  loading_verify_otp: false,
  message: ''
};

export default (state = initialState, action) => {
  switch(action.type){
    case types.SELECTED_DESTINATION_BANK_ACCOUNT_COIN:
      return { ...state, destination_bank_id: action.payload }
    case types.GET_COIN_BALANCE_SUCCESS:
      return { ...state, coin: action.payload }
    case types.IS_LOADING_GET_COIN_BALANCE:
      return { ...state, loading: action.payload }
    case types.GET_COIN_BALANCE_HISTORY_SUCCESS:
      return { ...state, history: action.payload }
    case types.IS_LOADING_GET_COIN_BALANCE_HISTORY:
      return { ...state, loading_history: action.payload }
    case types.REQUEST_WITHDRAWAL_COIN_SUCCESS:
      return { ...state, request_otp_success:action.payload }
    case types.IS_LOADING_REQUEST_WITHDRAWAL_COIN:
      return { ...state, loading_request_otp:action.payload }
    case types.CONFIRM_OTP_WITHDRAWAL_COIN_SUCCESS:
      return { ...state, verify_otp_success:action.payload }
    case types.IS_LOADING_CONFIRM_OTP_WITHDRAWAL_COIN:
      return { ...state, loading_verify_otp:action.payload }
    case types.SET_MESSAGE_COIN:
      return { ...state, message: action.payload }
    default:
      return state
  }
}
export const selectDestinationBankAccount = payload => ({
  type: types.SELECTED_DESTINATION_BANK_ACCOUNT_COIN,
  payload
})

export const requestOTP = payload => ({
  type: types.REQUEST_WITHDRAWAL_COIN,
  payload
})
export const requestOTPSuccess = payload => ({
  type: types.REQUEST_WITHDRAWAL_COIN_SUCCESS,
  payload
})
export const setLoadingRequestOtp = payload => ({
  type: types.IS_LOADING_REQUEST_WITHDRAWAL_COIN,
  payload
})

export const verifyOTP = payload => ({
  type: types.CONFIRM_OTP_WITHDRAWAL_COIN,
  payload
})
export const verifyOTPSuccess = payload => ({
  type: types.CONFIRM_OTP_WITHDRAWAL_COIN_SUCCESS,
  payload
})
export const setLoadingVerifyOtp = payload => ({
  type: types.IS_LOADING_CONFIRM_OTP_WITHDRAWAL_COIN,
  payload
})

export const setMessage = payload => ({
  type: types.SET_MESSAGE_COIN,
  payload
})

export const getCoin = payload => ({
  type: types.GET_COIN_BALANCE,
  payload
})
export const getCoinSuccess = payload => ({
  type: types.GET_COIN_BALANCE_SUCCESS,
  payload
})
export const setLoading = payload => ({
  type: types.IS_LOADING_GET_COIN_BALANCE,
  payload
})

export const getHistory = payload => ({
  type: types.GET_COIN_BALANCE_HISTORY,
  payload
})
export const getHistorySuccess = payload => ({
  type: types.GET_COIN_BALANCE_HISTORY_SUCCESS,
  payload
})
export const setLoadingHistory = payload => ({
  type: types.IS_LOADING_GET_COIN_BALANCE_HISTORY,
  payload
})
