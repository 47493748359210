import types from './constants';

const initialState = {
  countRfq:0,
  dataRfq:[],
  countRequest:0,
  dataRequest: [],
  statusCreateRequest:false,
  countOffer: 0,
  dataOffer: [],
  offer: null,
  statusCreateOffer: false,
  detailRequest: {},
  comment:null,
  commentSuccess:false,
  isLoadingCreateComment:false,
  isLoadingDetailRequest: false,
  isLoadingGetRfq: false,
  isLoadingGetRequest: false,
  isLoadingCreateRequest: false,
  isLoadingGetOffer: false,
  isLoadingCreateOffer:false
};

export default (state = initialState, action) => {
  switch(action.type){
    case types.GET_REQUEST_SUCCESS:
      return { ...state, dataRequest:action.payload}
    case types.IS_LOADING_GET_REQUEST:
      return { ...state, isLoadingGetRequest:action.payload}
    case types.GET_COUNT_REQUEST:
      return { ...state, countRequest:action.payload}
    case types.RESET_REQUEST:
      return { ...state, dataRequest:initialState.dataRequest}
    //
    case types.GET_OFFER_SUCCESS:
      return {...state, dataOffer:state.dataOffer.concat(action.payload)}
    case types.IS_LOADING_GET_OFFER:
      return {...state, isLoadingGetOffer:action.payload}
    case types.GET_COUNT_OFFER:
      return {...state, countOffer:action.payload}
    case types.RESET_OFFER:
      return {...state, dataOffer:initialState.dataOffer}
    //
    case types.GET_RFQ_SUCCESS:
      return { ...state, dataRfq:action.payload}
    case types.IS_LOADING_GET_RFQ:
      return {...state, isLoadingGetRfq:action.payload}
    case types.GET_COUNT_RFQ:
      return { ...state, countRfq:action.payload}
    case types.RESET_RFQ:
      return { ...state, dataRfq:initialState.dataRfq}
    //
    case types.GET_DETAIL_REQUEST_SUCCESS:
      return {...state, detailRequest:action.payload}
    case types.IS_LOADING_GET_DETAIL_REQUEST:
      return {...state, isLoadingDetailRequest:action.payload}
    //
    case types.CREATE_REQUEST_SUCCESS:
      return {...state, dataRequest: [action.payload, ...state.dataRequest], countRequest:state.countRequest + 1};
    case types.STATUS_CREATE_REQUEST_SUCCESS:
      return {...state, statusCreateRequest:action.payload}
    case types.IS_LOADING_CREATE_REQUEST:
      return {...state, isLoadingCreateRequest:action.payload}

    case types.CREATE_OFFER_SUCCESS:
      return Object.assign({}, state, {
        offer:action.payload,
        detailRequest: {
          ...state.detailRequest,
          response_request: [action.payload, ...state.detailRequest.response_request]
        },
      });
    case types.STATUS_CREATE_OFFER_SUCCESS:
      return {...state, statusCreateOffer:action.payload}
    case types.IS_LOADING_CREATE_OFFER:
      return {...state, isLoadingCreateOffer:action.payload}
    case types.RESET_CREATE_OFFER:
      return {...state, offer:initialState.offer}
    case types.CREATE_COMMENT_OFFER_SUCCESS:
      return Object.assign({}, state, {
        comment: action.payload,
        detailRequest: {
          ...state.detailRequest,
          response_request: state.detailRequest.response_request.map(dt=> dt._id === action.payload.ResponseRFQ ? {
              ...dt,
              comments: [...dt.comments, action.payload]
            } : dt
          )
        },
      });
    case types.IS_LOADING_CREATE_COMMENT:
      return {...state, isLoadingCreateComment:action.payload}
    case types.IS_COMMENT_SUCCESS:
      return {...state, commentSuccess:action.payload}
    case types.RESET_COMMENT_OFFER:
      return {...state, comment:initialState.comment}
    default:
      return state;
  }
}

//get request
export const getRequest = payload => ({
  type: types.GET_REQUEST,
  payload
});
export const getRequestSuccess = payload => ({
  type: types.GET_REQUEST_SUCCESS,
  payload
})
export const isLoadingGetRequest = payload => ({
  type: types.IS_LOADING_GET_REQUEST,
  payload
})
export const getCountRequest = payload => ({
  type: types.GET_COUNT_REQUEST,
  payload
})
export const resetRequest = payload => ({
  type: types.RESET_REQUEST,
  payload
})
//get offer
export const getOffer = payload => ({
  type: types.GET_OFFER,
  payload
});
export const getOfferSuccess = payload => ({
  type: types.GET_OFFER_SUCCESS,
  payload
})
export const isLoadingGetOffer = payload => ({
  type: types.IS_LOADING_GET_OFFER,
  payload
})
export const getCountOffer = payload => ({
  type: types.GET_COUNT_OFFER,
  payload
})
export const resetOffer = payload => ({
  type: types.RESET_OFFER,
  payload
})
//get rfq
export const getRFQ = payload => ({
  type: types.GET_RFQ,
  payload
});
export const getRFQSuccess = payload => ({
  type: types.GET_RFQ_SUCCESS,
  payload
})
export const isLoadingGetRFQ = payload => ({
  type: types.IS_LOADING_GET_RFQ,
  payload
})
export const getCountRFQ = payload => ({
  type: types.GET_COUNT_RFQ,
  payload
})
export const resetRFQ = payload => ({
  type: types.RESET_RFQ,
  payload
})
//get detail rfq
export const getDetailRequest = payload => ({
  type: types.GET_DETAIL_REQUEST,
  payload
});
export const getDetailRequestSuccess = payload => ({
  type: types.GET_DETAIL_REQUEST_SUCCESS,
  payload
})
export const isLoadingGetDetailRequest = payload => ({
  type: types.IS_LOADING_GET_DETAIL_REQUEST,
  payload
})

//create request

export const createRequest = payload => ({
  type: types.CREATE_REQUEST,
  payload
})
export const createRequestSuccess = payload => ({
  type: types.CREATE_REQUEST_SUCCESS,
  payload
})
export const setIsLoadingCreateRequest = payload => ({
  type: types.IS_LOADING_CREATE_REQUEST,
  payload
})
export const setStatusCreateRequestSuccess = payload => ({
  type: types.STATUS_CREATE_REQUEST_SUCCESS,
  payload
})
//create offer
export const createOffer = payload => ({
  type: types.CREATE_OFFER,
  payload
})
export const createOfferSuccess = payload => ({
  type: types.CREATE_OFFER_SUCCESS,
  payload
})
export const setIsLoadingCreateOffer = payload => ({
  type: types.IS_LOADING_CREATE_OFFER,
  payload
})
export const setStatusCreateOfferSuccess = payload => ({
  type: types.STATUS_CREATE_OFFER_SUCCESS,
  payload
})
export const resetCreateOffer = payload => ({
  type: types.RESET_CREATE_OFFER
})
//create comment success
export const createComment = payload => ({
  type: types.CREATE_COMMENT_OFFER,
  payload
})
export const createCommentSuccess = payload => ({
  type: types.CREATE_COMMENT_OFFER_SUCCESS,
  payload
})
export const setIsLoadingCreateComment = payload => ({
  type: types.IS_LOADING_CREATE_COMMENT,
  payload
})
export const setIsCommentSuccess = payload => ({
  type: types.IS_COMMENT_SUCCESS,
  payload
})
export const resetCommentOffer = payload => ({
  type: types.RESET_COMMENT_OFFER
})
