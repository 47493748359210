import { Api } from './api';

export default {
  getChatUser: payload => Api.get('/v1/notifications/message/user',{
    headers: {token:payload.token}
  }),
  getChatStore: payload => Api.get('/v1/notifications/message/store',{
    headers: {token:payload.token}
  }),
  updateReadChat: payload => Api.put(`/v1/notifications/message/${payload.subjectMessage_id}`,{}, {
    headers: {token:payload.token}
  }),
  getComplaint: payload => Api.get('/v1/notifications/complaint',{
    headers: {token:payload.token}
  }),
  getReview: payload => Api.get('/v1/notifications/review',{
    headers: {token:payload.token}
  }),
  getPayment: payload => Api.get('/v2/payments/notification',{
    headers: {token:payload.token}
  }),
  getBuying: payload => Api.get('/v1/notifications/buying',{
    headers: {token:payload.token}
  }),
  getSelling: payload => Api.get('/v1/notifications/selling',{
    headers: {token:payload.token}
  }),
  getHelpChat: payload => Api.get('/v2/help/notification',{
    headers: {token:payload.token}
  }),

};
