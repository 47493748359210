import React from "react";
import { CModal, CModalBody, CSpinner } from "@coreui/react";

const ModalLoading = ({ show }) => {
  return (
    <>
      <CModal
        centered
        closeOnBackdrop={false}
        show={show}
        style={{ background: "transparent", border: 0 }}
        className="fade"
        size="sm"
      >
        <CModalBody className="bg-transparent p-3 d-flex justify-content-center align-items-center">
          <div className="bg-white rounded p-2">
            <CSpinner color="primary" />
          </div>
        </CModalBody>
      </CModal>
    </>
  );
};

export default ModalLoading;
