import { Api, apiUrl } from "./api";
import axios from "axios";

export default {
  getPaymentStatus: (payload) =>
    Api.get("/v1/payments/user", {
      headers: { token: payload.token },
    }),
  getPaymentPending: (payload) =>
    Api.get("/v1/payments/user", {
      headers: { token: payload.token },
    }),
  uploadFotoPayment: (payload) => {
    const formData = new FormData();
    formData.append("image", payload.data);
    return axios
      .put(
        `${apiUrl}/v2/payments/buyer/upload/proof-payment/${payload.payment_id}`,
        formData,
        {
          headers: {
            token: payload.token,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => response)
      .catch((error) => {
        throw Error(error);
      });
  },
  updatePaymentCOD: (payload) =>
    Api.put(
      `/v2/payments/buyer/cod/${payload.id}`,
      {},
      {
        headers: { token: payload.token },
      }
    ),
  cancelPayment: (payload) =>
    Api.put(
      `/v2/payments/buyer/cancel/${payload.payment_id}`,
      {},
      {
        headers: { token: payload.token },
      }
    ),

  requestOrder: (payload) =>
    Api.post(`/v2/orders/buyer/create`, payload.data, {
      headers: { token: payload.token },
    }),

  getNotification: (payload) =>
    Api.get("/v2/orders/buyer/notification", {
      headers: { token: payload.token },
    }),
  getAllOrder: (payload) =>
    Api.get("/v2/orders/buyer/all", {
      headers: { token: payload.token },
      params: {
        filter: payload.filter,
        limit: payload.limit,
        page: payload.page,
        start: payload.start,
        end: payload.end,
        search: payload.search,
        sort: payload.sort,
      },
    }),
  getOrderWaitingConfirmation: (payload) =>
    Api.get("/v2/orders/buyer/waiting", {
      headers: { token: payload.token },
      params: {
        filter: payload.filter,
        limit: payload.limit,
        page: payload.page,
        start: payload.start,
        end: payload.end,
        search: payload.search,
        sort: payload.sort,
      },
    }),
  getOrderProcessed: (payload) =>
    Api.get("/v2/orders/buyer/processed", {
      headers: { token: payload.token },
      params: {
        filter: payload.filter,
        limit: payload.limit,
        page: payload.page,
        start: payload.start,
        end: payload.end,
        search: payload.search,
        sort: payload.sort,
      },
    }),
  getOrderShipping: (payload) =>
    Api.get("/v2/orders/buyer/shipping", {
      headers: { token: payload.token },
      params: {
        filter: payload.filter,
        limit: payload.limit,
        page: payload.page,
        start: payload.start,
        end: payload.end,
        search: payload.search,
        sort: payload.sort,
      },
    }),
  getOrderArrived: (payload) =>
    Api.get("/v2/orders/buyer/arrived", {
      headers: { token: payload.token },
      params: {
        filter: payload.filter,
        limit: payload.limit,
        page: payload.page,
        start: payload.start,
        end: payload.end,
        search: payload.search,
        sort: payload.sort,
      },
    }),
  getOrderDone: (payload) =>
    Api.get("/v2/orders/buyer/done", {
      headers: { token: payload.token },
      params: {
        filter: payload.filter,
        limit: payload.limit,
        page: payload.page,
        start: payload.start,
        end: payload.end,
        search: payload.search,
        sort: payload.sort,
      },
    }),
  getOrderAborted: (payload) =>
    Api.get("/v2/orders/buyer/aborted", {
      headers: { token: payload.token },
      params: {
        filter: payload.filter,
        limit: payload.limit,
        page: payload.page,
        start: payload.start,
        end: payload.end,
        search: payload.search,
        sort: payload.sort,
      },
    }),
  getOrderComplaint: (payload) =>
    Api.get("/v2/orders/buyer/complaint", {
      headers: { token: payload.token },
      params: {
        filter: payload.filter,
        limit: payload.limit,
        page: payload.page,
        start: payload.start,
        end: payload.end,
        search: payload.search,
        sort: payload.sort,
      },
    }),
  getDetailOrder: (payload) =>
    Api.get(`/v2/orders/buyer/id/${payload.order_id}`, {
      headers: { token: payload.token },
    }),

  updateToShipped: (payload) =>
    Api.put(
      `/v2/orders/buyer/update/shipped/${payload.order_id}`,
      {},
      {
        headers: { token: payload.token },
      }
    ),
  updateToArrived: (payload) =>
    Api.put(
      `/v2/orders/buyer/update/arrived/${payload.order_id}`,
      {},
      {
        headers: { token: payload.token },
      }
    ),
  updateToDone: (payload) =>
    Api.put(
      `/v2/orders/buyer/update/done/${payload.order_id}`,
      {},
      {
        headers: { token: payload.token },
      }
    ),

  checkShippingReceipt: (payload) =>
    Api.get(`/v1/waybill/${payload.order_id}`, {
      headers: { token: payload.token },
    }),
  changeOrderToDelivered: (payload) =>
    Api.put(
      `/v1/orders/buyer/update_delivered/${payload.order_id}`,
      {},
      {
        headers: { token: payload.token },
      }
    ),
  finishOrder: (payload) =>
    Api.put(`/v1/orders/confirm_accept/${payload.order_id}`, payload.data, {
      headers: { token: payload.token },
    }),
  buyerAcceptRejectOrder: (payload) =>
    Api.put(
      `/v1/orders/aborted_user/${payload.orderId}`,
      {},
      {
        headers: { token: payload.token },
      }
    ),

  getNotYetReviewed: (token) =>
    Api.get("/v1/points/not-review", {
      headers: { token },
    }),
  getDoneReviewed: (token) =>
    Api.get("/v1/points/done-review", {
      headers: { token },
    }),
  giveReview: (payload) =>
    Api.post(`/v1/points/${payload.pointId}`, payload.data, {
      headers: { token: payload.token },
    }),
};
