export default {
  GET_LAST_LOGIN: 'get-last-login',
  GET_LAST_LOGIN_SUCCESS: 'get-last-login-success',
  IS_LOADING_LAST_LOGIN: 'is-loading-last-login',

  GET_PAYMENT_NOTIFICATION: 'get-payment-notification',
  GET_PAYMENT_NOTIFICATION_SUCCESS: 'get-payment-notification-success',
  IS_LOADING_PAYMENT_NOTIFICATION: 'is-loading-payment-notification',

  GET_ORDER_NOTIFICATION: 'get-order-notification',
  GET_ORDER_NOTIFICATION_SUCCESS: 'get-order-notification-success',
  IS_LOADING_ORDER_NOTIFICATION: 'is-loading-order-notification',

  GET_COMPLAINT_NOTIFICATION: 'get-complaint-notification',
  GET_COMPLAINT_NOTIFICATION_SUCCESS: 'get-complaint-notification-success',
  IS_LOADING_COMPLAINT_NOTIFICATION: 'is-loading-complaint-notification',

  GET_CHAT_NOTIFICATION: 'get-chat-notification',
  GET_CHAT_NOTIFICATION_SUCCESS: 'get-chat-notification-success',
  IS_LOADING_CHAT_NOTIFICATION: 'is-loading-chat-notification',

  GET_REVIEW_NOTIFICATION: 'get-review-notification',
  GET_REVIEW_NOTIFICATION_SUCCESS: 'get-review-notification-success',
  IS_LOADING_REVIEW_NOTIFICATION: 'is-loading-review-notification',

  SET_CHAT_NOTIFICATION: 'set-chat-notification',

  GET_HELP_CHAT_NOTIFICATION: 'get-help-chat-notification',
  GET_HELP_CHAT_NOTIFICATION_SUCCESS: 'get-help-chat-notification-success',
  IS_LOADING_HELP_CHAT_NOTIFICATION: 'is-loading-help-chat-notification',
};
