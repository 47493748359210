import React from "react";
//on other
// import PrivacyPolicy from './components/pages/privacypolicy';
import About from "./components/pages/about";

const Privacy = React.lazy(() => import("./components/pages/privacy"));
const Terms = React.lazy(() => import("./components/pages/terms"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Login = React.lazy(() => import("./components/loginpage"));
const Logout = React.lazy(() => import("./components/loginpage/logout"));
const Register = React.lazy(() => import("./components/registerpage"));

const RFQPage = React.lazy(() => import("./components/rfqpage"));
const AddRFQPage = React.lazy(() =>
  import("./components/rfqpage/pages/request_item")
);
const MyRFQPage = React.lazy(() =>
  import("./components/rfqpage/pages/my_request")
);
const DetailRFQPage = React.lazy(() =>
  import("./components/rfqpage/pages/detail_request")
);
const CreateShop = React.lazy(() =>
  import("./components/sellercenter/pages/createshop")
);
const DonatePage = React.lazy(() => import("./components/donatepage"));
const CheckoutDonatePage = React.lazy(() =>
  import("./components/donatepage/pages/checkout")
);
const PaymentDonatePage = React.lazy(() =>
  import("./components/donatepage/pages/payment")
);

const ResetPasswordPage = React.lazy(() =>
  import("./components/account/pages/resetpassword")
);
const ForgotPasswordPage = React.lazy(() =>
  import("./components/account/pages/forgotpassword")
);

//Home Layout Content
const Homepage = React.lazy(() => import("./components/homepage"));
const Account = React.lazy(() => import("./components/account"));
const Profile = React.lazy(() => import("./components/account/pages/profile"));
const LoginAndSecurity = React.lazy(() =>
  import("./components/account/pages/loginsecurity")
);
const Address = React.lazy(() => import("./components/account/pages/address"));
const Bank = React.lazy(() => import("./components/account/pages/bank"));
const Payment = React.lazy(() => import("./components/account/pages/payment"));
const Order = React.lazy(() => import("./components/account/pages/order"));
const List = React.lazy(() => import("./components/account/pages/list"));
const Deposit = React.lazy(() => import("./components/account/pages/deposit"));
const Review = React.lazy(() => import("./components/account/pages/review"));

const HelpMessage = React.lazy(() =>
  import("./components/account/pages/helpmessage")
);
const Coin = React.lazy(() => import("./components/account/pages/coin"));

const Cart = React.lazy(() => import("./components/cartpage"));

const Search = React.lazy(() => import("./components/searchpage"));
// const Product = React.lazy(() => import("./components/productpage"));

const Buy = React.lazy(() => import("./components/buypage"));
const BuyPaymentSuccess = React.lazy(() =>
  import("./components/buypage/pages/payment")
);

const Shop = React.lazy(() => import("./components/shoppage"));

const Category = React.lazy(() => import("./components/categorypage"));
const ProductsByCategory = React.lazy(() =>
  import("./components/categorypage/pages/product")
);

const CreateComplaint = React.lazy(() =>
  import("./components/account/pages/complaint/pages/create")
);
const ListComplaint = React.lazy(() =>
  import("./components/account/pages/complaint/pages/list")
);
const DetailComplaint = React.lazy(() =>
  import("./components/account/pages/complaint/pages/detail")
);

const Help = React.lazy(() => import("./views/help"));
const Checkout = React.lazy(() => import("./components/checkout"));
const CheckoutSuccess = React.lazy(() =>
  import("./components/checkout/pages/success")
);

const Withdraw = React.lazy(() =>
  import("./components/account/pages/withdraw")
);
const RobotsTxt = React.lazy(() => import("./robots.txt"));
const Chat = React.lazy(() => import("./views/chat"));
const Discussion = React.lazy(() => import("./views/discussion"));
const AssetLinks = React.lazy(() => import("./.well-known/assetlinks.json"));
const Invoice = React.lazy(() => import("./views/invoice"));
const Product = React.lazy(() => import("./views/product"));

export const routes = [
  { path: "/.well-known/assetlinks.json", component: AssetLinks },
  { path: "/robots.txt", component: RobotsTxt },
  { path: "/help", exact: true, name: "Help", component: Help },
  {
    path: "/forgot-password",
    exact: true,
    name: "Lupa Kata Sandi",
    component: ForgotPasswordPage,
  },
  {
    path: "/reset-password",
    exact: true,
    name: "Reset Kata Sandi",
    component: ResetPasswordPage,
  },
  {
    path: "/privacy",
    exact: true,
    name: "Kebijakan Privasi Plasgos",
    component: Privacy,
  },
  { path: "/about", exact: true, name: "Tentang Plasgos", component: About },
  {
    path: "/terms",
    exact: true,
    name: "Syarat & Ketentuan Plasgos",
    component: Terms,
  },
  { path: "/login", exact: true, name: "Login", component: Login },
  { path: "/register", exact: true, name: "Register", component: Register },
  { path: "/logout", exact: true, name: "Logout", component: Logout },
  { path: "/404", exact: true, name: "Page 404", component: Page404 },
  { path: "/500", exact: true, name: "Page 500", component: Page500 },
  {
    path: "/cart/checkout",
    exact: true,
    name: "Checkout",
    component: Checkout,
  },
  {
    path: "/order/invoice/:id",
    exact: true,
    name: "Invoice",
    component: Invoice,
  },
  { path: "/invoice/:id", exact: true, name: "Invoice", component: Invoice },
  {
    path: "/permintaan-penawaran",
    exact: true,
    name: "RFQPage",
    component: RFQPage,
  },
  {
    path: "/permintaan-penawaran/form",
    exact: true,
    name: "AddRFQPage",
    component: AddRFQPage,
  },
  { path: "/my_request", exact: true, name: "MyRequest", component: MyRFQPage },
  {
    path: "/my_request/:id",
    exact: true,
    name: "DetailRFQ",
    component: DetailRFQPage,
  },
  { path: "/open-shop", exact: true, name: "Buka Toko", component: CreateShop },
  {
    path: "/donate",
    exact: true,
    name: "Donasi Plasgos",
    component: DonatePage,
  },
  {
    path: "/donate/checkout",
    exact: true,
    name: "Checkout Donasi Plasgos",
    component: CheckoutDonatePage,
  },
  {
    path: "/donate/checkout/success",
    exact: true,
    name: "Checkout Donasi Sukses",
    component: PaymentDonatePage,
  },
];

export default [
  {
    path: "/buy/success",
    exact: true,
    name: "Pembelian",
    component: BuyPaymentSuccess,
  },
  {
    path: "/checkout/success",
    exact: true,
    name: "Checkout",
    component: CheckoutSuccess,
  },
  { path: "/search", exact: true, name: "Search", component: Search },
  { path: "/cart", exact: true, name: "Keranjang", component: Cart },
  {
    path: "/security",
    exact: true,
    name: "Login dan Keamanan",
    component: LoginAndSecurity,
  },
  { path: "/setting/profile", exact: true, name: "Profil", component: Profile },
  { path: "/your-lists", exact: true, name: "Daftar Suka", component: List },
  { path: "/payment", exact: true, name: "Pembayaran", component: Payment },
  { path: "/order", exact: true, name: "Pesanan", component: Order },
  { path: "/account", exact: true, name: "Akun", component: Account },
  { path: "/address", exact: true, name: "Alamat", component: Address },
  { path: "/bank", exact: true, name: "Bank", component: Bank },
  {
    path: "/help-inbox",
    exact: true,
    name: "Pesan Bantuan",
    component: HelpMessage,
  },
  { path: "/chat", exact: true, name: "Pesan", component: Chat },
  { path: "/buy", exact: true, name: "Beli", component: Buy },
  { path: "/deposit", exact: true, name: "Saldo", component: Deposit },
  {
    path: "/deposit/withdraw",
    exact: true,
    name: "Penarikan",
    component: Withdraw,
  },
  { path: "/coin", exact: true, name: "Koin", component: Coin },
  { path: "/review", exact: true, name: "Ulasan", component: Review },
  {
    path: "/discussion",
    exact: true,
    name: "Diskusi",
    component: Discussion,
  },

  {
    path: "/complaint",
    exact: true,
    name: "Daftar Komplain",
    component: ListComplaint,
  },
  {
    path: "/complaint/:id",
    exact: true,
    name: "Detail Komplain",
    component: DetailComplaint,
  },
  {
    path: "/create-complaint/:id",
    exact: true,
    name: "Buat Komplain",
    component: CreateComplaint,
  },

  {
    path: "/c/:category/:subcategory1/:subcategory2",
    exact: true,
    name: "Produk",
    component: ProductsByCategory,
  },
  {
    path: "/c/:category/:subcategory1",
    exact: true,
    name: "Produk",
    component: ProductsByCategory,
  },
  {
    path: "/c/:category",
    exact: true,
    name: "Produk",
    component: ProductsByCategory,
  },
  { path: "/c", exact: true, name: "Kategori", component: Category },
  {
    path: "/:subdomain_shop/:subdomain_product",
    exact: true,
    name: "Produk",
    component: Product,
  },
  { path: "/:subdomain_shop", exact: true, name: "Toko", component: Shop },
  { path: "/", name: "Home", component: Homepage, exact: true },
];
