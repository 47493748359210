import { put, call, takeLatest, select } from 'redux-saga/effects';

import Api from '../../../../services';
import types from './constants';
import * as actions from './reducer';

function* watchGetPaymentPending(values) {
  yield put(actions.setIsLoadingGetPaymentPending(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.buyertransaction.getPaymentPending, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getPaymentPendingSuccess(data.data));
    }
  } catch (error) {
    yield put(actions.getPaymentPendingSuccess(state.payment.data));
  } finally {
    yield put(actions.setIsLoadingGetPaymentPending(false));
  }
}
function* watchUploadImage(values) {
  yield put(actions.setIsLoadingUploadProofOfPayment(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.buyertransaction.uploadFotoPayment, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.uploadProofOfPaymentSuccess(data));
    }
  } catch (e) {
    yield put(actions.getPaymentPendingSuccess(state.payment.data));
  } finally {
    yield put(actions.setIsLoadingUploadProofOfPayment(false));
  }
}
function* watchCancelPayment(values) {
  yield put(actions.setIsLoadingCancelPayment(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.buyertransaction.cancelPayment, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.cancelPaymentSuccess(data));
    }
  } catch (e) {
    yield put(actions.getPaymentPendingSuccess(state.payment.data));
  } finally {
    yield put(actions.setIsLoadingCancelPayment(false));
  }
}
function* watchUpdatePaymentCOD(values) {
  yield put(actions.setIsLoadingUpdatePaymentCOD(true));
  const { payload } = values;
  try {
    const response = yield call(Api.buyertransaction.updatePaymentCOD, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.updatePaymentCODSuccess(data));
    }
  } catch (e) {
    yield put(actions.updatePaymentCODSuccess({}));
  } finally {
    yield put(actions.setIsLoadingUpdatePaymentCOD(false));
  }
}
const saga = [
  takeLatest(types.GET_PAYMENT_PENDING, watchGetPaymentPending),
  takeLatest(types.UPLOAD_PROOF_OF_PAYMENT, watchUploadImage),
  takeLatest(types.CANCEL_PAYMENT, watchCancelPayment),
  takeLatest(types.UPDATE_PAYMENT_COD, watchUpdatePaymentCOD),

];

export default saga;
