import types from "./constants";

const initialState = {
  count: 0,
  data: [],
  isLoadingGetAddress: false,
  isLoadingAddAddress: false,
  isLoadingEditAddress: false,
  isLoadingDeleteAddress: false,
  isLoadingSetDefaultAddress: false,
  subdistricts: [],
  isLoadingGetSubdistricts: false,
  cities: [],
  isLoadingGetCities: false,
  success: false,
  error: false,
  message: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    //GET ALL
    case types.GET_ALL_ADDRESS_SUCCESS:
      return {
        ...state,
        count: action.payload.count,
        data: action.payload.data,
      };
    case types.IS_LOADING_GET_ADDRESS:
      return { ...state, isLoadingGetAddress: action.payload };
    //CREATE
    case types.CREATE_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        data: [...state.data.concat(action.payload)],
      });
    case types.IS_LOADING_CREATE_ADDRESS:
      return { ...state, isLoadingAddAddress: action.payload };
    //EDIT
    case types.EDIT_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        data: state.data.map((item) => {
          return item.id === action.payload.id
            ? { ...item, ...action.payload.data, id: action.payload.id }
            : item;
        }),
      });
    case types.IS_LOADING_EDIT_ADDRESS:
      return { ...state, isLoadingEditAddress: action.payload };
    //SET DEFAULT
    case types.SET_DEFAULT_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        data: state.data.map((item) => {
          return item.id === action.payload.id
            ? {
                ...action.payload.data,
                is_default: true,
              }
            : {
                ...item,
                is_default: false,
              };
        }),
      });
    case types.IS_LOADING_SET_DEFAULT_ADDRESS:
      return { ...state, isLoadingSetDefaultAddress: action.payload };
    //DELETE
    case types.DELETE_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        data: state.data.filter(
          (item) => item.id !== Number(action.payload.id)
        ),
      });
    case types.IS_LOADING_DELETE_ADDRESS:
      return { ...state, isLoadingDeleteAddress: action.payload };
    case types.GET_SUBDISTRICT_SUCCESS:
      return { ...state, subdistricts: action.payload };
    case types.IS_LOADING_GET_SUBDISTRICT:
      return { ...state, isLoadingGetSubdistricts: action.payload };
    case types.GET_CITY_SUCCESS:
      return { ...state, cities: action.payload };
    case types.IS_LOADING_GET_CITY:
      return { ...state, isLoadingGetCities: action.payload };
    case types.RESPONSE_SUCCESS:
      return { ...state, success: action.payload };
    case types.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case types.SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    default:
      return state;
  }
};

//GET
export const getAllAddress = (payload) => ({
  type: types.GET_ALL_ADDRESS,
  payload,
});
export const getAllAddressSuccess = (payload) => ({
  type: types.GET_ALL_ADDRESS_SUCCESS,
  payload,
});
export const setIsLoadingGetAddress = (payload) => ({
  type: types.IS_LOADING_GET_ADDRESS,
  payload,
});
//CREATE
export const createNewAddress = (payload) => ({
  type: types.CREATE_ADDRESS,
  payload,
});
export const createNewAddressSuccess = (payload) => ({
  type: types.CREATE_ADDRESS_SUCCESS,
  payload,
});
export const setIsLoadingCreateAddress = (payload) => ({
  type: types.IS_LOADING_CREATE_ADDRESS,
  payload,
});
//EDIT
export const editAddressById = (payload) => ({
  type: types.EDIT_ADDRESS,
  payload,
});
export const editAddressByIdSuccess = (payload) => ({
  type: types.EDIT_ADDRESS_SUCCESS,
  payload,
});
export const setIsLoadingEditAddress = (payload) => ({
  type: types.IS_LOADING_EDIT_ADDRESS,
  payload,
});
//SET DEFAULT
export const setDefaultAddressById = (payload) => ({
  type: types.SET_DEFAULT_ADDRESS,
  payload,
});
export const setDefaultAddressByIdSuccess = (payload) => ({
  type: types.SET_DEFAULT_ADDRESS_SUCCESS,
  payload,
});
export const setIsLoadingSetDefaultAddress = (payload) => ({
  type: types.IS_LOADING_SET_DEFAULT_ADDRESS,
  payload,
});
//DELETE
export const deleteAddressById = (payload) => ({
  type: types.DELETE_ADDRESS,
  payload,
});
export const deleteAnddressByIdSuccess = (payload) => ({
  type: types.DELETE_ADDRESS_SUCCESS,
  payload,
});
export const setIsLoadingDeleteAddress = (payload) => ({
  type: types.IS_LOADING_DELETE_ADDRESS,
  payload,
});
//GET SUBDISTRICTS
export const getSubdistricts = (payload) => ({
  type: types.GET_SUBDISTRICT,
  payload,
});
export const getSubdistrictSuccess = (payload) => ({
  type: types.GET_SUBDISTRICT_SUCCESS,
  payload,
});
export const setIsLoadingGetSubdistrict = (payload) => ({
  type: types.IS_LOADING_GET_SUBDISTRICT,
  payload,
});
//GET CITY
export const getCities = (payload) => ({
  type: types.GET_CITY,
  payload,
});
export const getCitiesSuccess = (payload) => ({
  type: types.GET_CITY_SUCCESS,
  payload,
});
export const setIsLoadingGetCities = (payload) => ({
  type: types.IS_LOADING_GET_CITY,
  payload,
});
//respond
export const setIsResponseSuccess = (payload) => ({
  type: types.RESPONSE_SUCCESS,
  payload,
});
export const setError = (payload) => ({
  type: types.SET_ERROR,
  payload,
});
export const setMessage = (payload) => ({
  type: types.SET_MESSAGE,
  payload,
});
