import types from './constants';

const initialState = {
  cod: {
    data:{},
    loading:false
  },
  data:[],
  isLoadingGetPaymentPending:false,
  isLoadingUploadProofOfPayment:false,
  isLoadingCancelPayment: false
}

export default (state=initialState, action) => {
  switch(action.type){
    case types.GET_PAYMENT_PENDING_SUCCESS:
      return {...state, data:action.payload}
    case types.IS_LOADING_GET_PAYMENT_PENDING:
      return {...state, isLoadingGetPaymentPending:action.payload}
    case types.UPLOAD_PROOF_OF_PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        data: state.data.map(item => {
          return item.id === action.payload.id ? {
            ...item,
            image_url:action.payload.image_url
          } : item;
        })
      });
    case types.IS_LOADING_UPLOAD_PROOF_OF_PAYMENT:
      return {...state, isLoadingUploadProofOfPayment:action.payload}
    case types.CANCEL_PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        data: state.data.filter(item => item.id !== action.payload.id)
      });
    case types.IS_LOADING_CANCEL_PAYMENT:
      return {...state, isLoadingCancelPayment:action.payload}
    case types.UPDATE_PAYMENT_COD_SUCCESS:
      return {
        ...state,
        cod: {
          ...state.cod,
          data: action.payload
        }
      }
    case types.IS_LOADING_UPDATE_PAYMENT_COD:
      return {
        ...state,
        cod: {
          ...state.cod,
          loading: action.payload
        }
      }
    case types.RESET_PAYMENT_COD:
      return {
        ...state,
        cod: initialState.cod
      }
    default:
      return state;
  }
}

export const getPaymentPending = payload => ({
  type: types.GET_PAYMENT_PENDING,
  payload
})
export const getPaymentPendingSuccess = payload => ({
  type: types.GET_PAYMENT_PENDING_SUCCESS,
  payload
})
export const setIsLoadingGetPaymentPending = payload => ({
  type: types.IS_LOADING_GET_PAYMENT_PENDING,
  payload
})

export const uploadProofOfPayment = payload => ({
  type: types.UPLOAD_PROOF_OF_PAYMENT,
  payload
})
export const uploadProofOfPaymentSuccess = payload => ({
  type: types.UPLOAD_PROOF_OF_PAYMENT_SUCCESS,
  payload
})
export const setIsLoadingUploadProofOfPayment = payload => ({
  type: types.IS_LOADING_UPLOAD_PROOF_OF_PAYMENT,
  payload
})

export const cancelPayment = payload => ({
  type: types.CANCEL_PAYMENT,
  payload
})
export const cancelPaymentSuccess = payload => ({
  type: types.CANCEL_PAYMENT_SUCCESS,
  payload
})
export const setIsLoadingCancelPayment = payload => ({
  type: types.IS_LOADING_CANCEL_PAYMENT,
  payload
})

export const updatePaymentCOD = payload => ({
  type: types.UPDATE_PAYMENT_COD,
  payload
})
export const updatePaymentCODSuccess = payload => ({
  type: types.UPDATE_PAYMENT_COD_SUCCESS,
  payload
})
export const setIsLoadingUpdatePaymentCOD = payload => ({
  type: types.IS_LOADING_UPDATE_PAYMENT_COD,
  payload
})
export const resetPaymentCOD = payload => ({
  type: types.RESET_PAYMENT_COD,
  payload
})
