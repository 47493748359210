import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";

import styles from "../../../../assets/jss/material-kit-react/views/aboutPageSections/informationStyle.js";

const useStyles = makeStyles(styles);

export default function InformationSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>MEMBERDAYAKAN PRODUK DALAM NEGERI</h2>
          <h5 className={classes.description} style={{fontSize: 18}}>
            Plasgos merupakan Perusahaan teknologi yang mempertemukan antara Produsen (Manufacture, Pabrikasi) dan Distributor yang memungkinkan pemilik bisnis untuk mengembangkan dan mengelola bisnis mereka secara online dengan mudah dan gratis, sekaligus Aman dan Nyaman.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
