import { Api } from './api';

export default {
  check: payload => Api.get(`/v1/favorite/${payload.store_id}`, {headers: {token: payload.token}}),
  get: payload => Api.get('/v1/favorite/user', {
    headers: {
      token: payload.token
    },
    params: {
      limit: payload.limit,
      page: payload.page
    }
  }),
  add: payload => Api.post('/v1/favorite', {store_id:payload.store_id}, {headers: {token: payload.token}}),
  delete: payload => Api.delete(`/v1/favorite/${payload.id}`, {headers: {token: payload.token}})
};
