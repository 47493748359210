import { put, call, takeLatest, select } from 'redux-saga/effects';

import Api from '../../services';
import types from './constants';
import * as actions from './reducer';

function* watchGetSearchResultProduct(values) {
  const { payload } = values;
  // const state = yield select();
  yield put(actions.isLoadingSearchProduct(true));
  try {
    const response = yield call(Api.product.getBySearchName, payload);
    const { data } = response;
    if (data.success) {
      // if(data.data.length > 0){
        yield put(actions.getSearchProductSuccess(data.data));
        yield put(actions.getCountProduct(data.count));
        yield put(actions.allLoadedProduct(false));
      // }
    }
  } catch (error) {
  } finally {
    yield put(actions.isLoadingSearchProduct(false));
  }
}

function* watchGetSearchResultSupplier(values) {
  const { payload } = values;
  const state = yield select();
  yield put(actions.isLoadingSearchStore(true));
  try {
    const {search} = state;
    if(search.allLoadStore === false){
      const response = yield call(Api.store.getBySearchName, payload);
      const { data } = response;
      if (data.success) {
        // if(data.data.length > 0){
          yield put(actions.getSearchStoreSuccess(data.data));
          yield put(actions.getCountStore(data.count));
          yield put(actions.allLoadedStore(false));
        // }
      }
    }else{
      yield put(actions.isLoadingSearchStore(false));
    }
  } catch (error) {
  } finally {
    yield put(actions.isLoadingSearchStore(false));
  }
}

const searchResultSagas = [
  takeLatest(types.GET_SEARCH_PRODUCT, watchGetSearchResultProduct),
  takeLatest(types.GET_SEARCH_STORE, watchGetSearchResultSupplier),
];

export default searchResultSagas;
