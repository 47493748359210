import { Api } from './api';

export default {
  getSubjectMessage: payload => Api.get('/v1/messages', {
    headers: {
      token: payload.token
    },
    params:{
      limit:payload.limit,
      page:payload.page,
    }
  }),
  getSubjectMessageByUser: payload => Api.get('/v1/messages/user',{
    headers: {
      token: payload.token
    },
    params:{
      limit: payload.limit,
      page: payload.page
    }
  }),
  getSubjectMessageByStore: payload => Api.get('/v1/messages/store',{
    headers: {
      token: payload.token
    },
    params:{
      limit: payload.limit,
      page: payload.page
    }
  }),
  getChatDetail: payload => Api.get(`/v1/messages/${payload.subjectMessage_id}`,{
    headers: {
      token: payload.token
    },
    params:{
      limit:payload.limit,
      page:payload.page,
    }
  }),
  getChatByStoreIdAndUserId: payload => Api.get(`/v1/messages/detail`,{
    headers:{
      token:payload.token
    },
    params:{
      store_id:payload.store_id,
      user_id:payload.user_id
    }
  }),
  chatToBuyer: payload => Api.post('/v1/messages/new-to-buyer', {
    text: payload.data.text,
    user_id: payload.data.buyerId,
    store_id: payload.data.store_id
  },{
    headers: { token: payload.token },
  }),
  chatToSeller: payload => Api.post('/v1/messages/new', {
    text: payload.data.text,
    store_id: payload.data.store_id
  }, {
    headers: { token: payload.token },
  }),
  reply: payload => Api.post('/v1/messages', payload.data, {
    headers: { token: payload.token },
  }),
  updateReadMessage: payload => Api.put(`/v1/messages/update-read/${payload.subjectMessage_id}`, {}, {
    headers: {
      token: payload.token
    }
  }),
};
