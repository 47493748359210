import { put, call, takeLatest, select } from "redux-saga/effects";
import Api from "../../services";
import types from "./constants";
import * as actions from "./reducer";
import { notification } from "../../helpers/notification";

function* watchGetCart(value) {
  yield put(actions.setIsLoadingGetCart(true));
  const state = yield select();
  const { payload } = value;
  try {
    const response = yield call(Api.cart.get, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getCartSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getCartSuccess(state.cart.carts));
  } finally {
    yield put(actions.setIsLoadingGetCart(false));
  }
}

function* watchEditCartById(values) {
  yield put(actions.setIsLoadingEditCart(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.cart.editById, payload);
    if (response.status === 200) {
      // const responseGet = yield call(Api.cart.get, payload);
      yield put(actions.editCartByIdSuccess(payload));
      yield put(actions.setEditedCart(true));
      // yield put(actions.getCartSuccess(responseGet.data.data));
      // yield put(actions.getCountCartsSuccess(responseGet.data.total));
    }
  } catch (e) {
    yield put(actions.getCartSuccess(state.cart.carts));
  } finally {
    yield put(actions.setEditedCart(false));
    yield put(actions.setIsLoadingEditCart(false));
  }
}

function* watchGetCountCart(value) {
  yield put(actions.setIsLoadingGetCountCarts(true));
  const state = yield select();
  const { payload } = value;
  try {
    const response = yield call(Api.cart.get, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getCountCartsSuccess(data.total));
    }
  } catch (e) {
    yield put(actions.getCountCartsSuccess(state.cart.count));
  } finally {
    yield put(actions.setIsLoadingGetCountCarts(false));
  }
}

function* watchAddToCart(value) {
  yield put(actions.setIsLoadingCreateCart(true));
  const state = yield select();
  const { payload } = value;
  try {
    const response = yield call(Api.cart.create, payload);
    const { data } = response;
    if (data.success) {
      yield put(
        actions.getCountCartsSuccess(state.cart.count + payload.data.quantity)
      );
      yield put(actions.setAddedCart(true));
      yield notification.default(
        `${payload.data.quantity} barang berhasil ditambahkan ke keranjang`
      );
    }
  } catch (e) {
    yield put(actions.getCountCartsSuccess(state.cart.count));
  } finally {
    yield put(actions.setIsLoadingCreateCart(false));
    yield put(actions.setAddedCart(false));
  }
}

function* watchDeleteCart(value) {
  yield put(actions.setIsLoadingDeleteCart(true));
  const state = yield select();
  const { payload } = value;
  try {
    const response = yield call(Api.cart.deleteById, payload);
    const { data } = response;
    if (data.success) {
      const responseGet = yield call(Api.cart.get, payload);
      const getData = responseGet.data;
      if (getData.success) {
        yield put(actions.getCartSuccess(getData.data));
        yield put(actions.getCountCartsSuccess(getData.total));
        yield put(actions.deletedCart(true));
        yield notification.default(`1 barang telah dihapus dari keranjang`);
      }
    }
  } catch (e) {
    yield put(actions.deletedCart(false));
    yield put(actions.getCountCartsSuccess(state.cart.count));
  } finally {
    yield put(actions.deletedCart(false));
    yield put(actions.setIsLoadingDeleteCart(false));
  }
}

function* watchDeleteMultipleCart(value) {
  yield put(actions.setIsLoadingDeleteCart(true));
  const state = yield select();
  const { payload } = value;
  try {
    const response = yield call(Api.cart.deleteByMultipleId, payload);
    const { data } = response;
    if (data.success) {
      const result = {
        multipleId: payload.multipleId,
        qty: state.cart.count - payload.qty,
      };
      yield put(actions.deleteMultipleCartSuccess(result));
      yield put(actions.getCountCartsSuccess(result.qty));
      yield put(actions.deletedCart(true));
      yield notification.default(
        `${payload.multipleId.length} barang telah dihapus dari keranjang`
      );
    }
  } catch (e) {
    yield put(actions.deletedCart(false));
    yield put(actions.getCountCartsSuccess(state.cart.count));
  } finally {
    yield put(actions.deletedCart(false));
    yield put(actions.setIsLoadingDeleteCart(false));
  }
}

const cartSagas = [
  takeLatest(types.GET_CART, watchGetCart),
  takeLatest(types.GET_COUNT_CART, watchGetCountCart),
  takeLatest(types.CREATE_CART, watchAddToCart),
  takeLatest(types.EDIT_CART, watchEditCartById),
  takeLatest(types.DELETE_CART, watchDeleteCart),
  takeLatest(types.DELETE_MULTIPLE_CART_ID, watchDeleteMultipleCart),
];

export default cartSagas;
