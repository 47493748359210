import { Api } from "./api";

export default {
  tracking: {
    order: (payload) =>
      Api.post(
        `/v2/orders/tracking/express`,
        {
          order_id: payload.order_id,
        },
        {
          headers: {
            token: payload.token,
          },
        }
      ),
  },
};
