export default {
  CREATE_DONATION: 'CREATE_DONATION',
  CREATE_DONATION_SUCCESS: 'CREATE_DONATION_SUCCESS',
  IS_LOADING_CREATE_DONATION: 'IS_LOADING_CREATE_DONATION',
  RESET_DONATION: 'RESET_DONATION',
  SELECT_PAYMENT_METHOD: 'SELECT_PAYMENT_METHOD',

  SET_CHECKOUT: 'set-checkout-donation',
  SET_PAYMENT: 'set-payment-donation'
};
