import { Api, apiUrl } from './api';

export default {
  postComplaint: payload => Api.post('/v1/complaints',{
    description:payload.description,
    complaint_solution_id:Number(payload.complaint_solution_id),
    order_id:payload.order_id,
    items:payload.items
  },{
    headers:{token:payload.token}
  }),
  postImgComplaint: payload => {
    const formData = new FormData();
    payload.fileList.forEach((each, idx) => (formData.append(`image_${idx}`, each.originFileObj)));
    return fetch(`${apiUrl}/v1/complaints/images/${payload.id}`, {
      method: 'POST',
      body: formData,
      headers: {token: payload.token},
    })
    .then(response => response.json())
    .then(data => ({data}))
    .catch((error) => {throw Error(error)});
  },
  getDetailComplaint: payload => Api.get(`/v1/complaints/buyer/${payload.complaint_id}`,{
    headers:{token:payload.token}
  }),
  getDetailComplaintOrderId: payload => Api.get(`/v1/complaints/order/${payload.order_id}`,{
    headers:{token:payload.token}
  }),
  getComplaintByBuyer: payload => Api.get('/v1/complaints/buyer',{
    headers:{token:payload.token}
  }),
  getComplaintBySeller: payload => Api.get('/v1/complaints/seller',{
    headers:{token:payload.token}
  }),
  getHistoryComplaint: payload => Api.get(`/v1/complaints/histories/${payload.complaint_id}`, {
    headers:{token:payload.token}
  }),
  getDiscussionComplaint: payload => Api.get(`/v1/complaints/discussions/${payload.complaint_id}`, {
    headers:{token:payload.token}
  }),
  postDiscussion: payload => Api.post(`/v1/complaints/discussions/${payload.complaint_id}`, {
    text:payload.text
  },{
    headers:{token:payload.token}
  }),
  postBid: payload => Api.post(`/v1/complaints/histories/price-bid/${payload.complaint_id}`, {
    refund:payload.refund
  },{
    headers:{token:payload.token}
  }),
  getServices: payload => Api.get(`/v1/services`),
  postDeliveryReceipt: payload => Api.post(`/v1/complaints/histories/delivery-receipt/${payload.complaint_id}`, payload.data,{
    headers:{token:payload.token}
  }),
  postReturnReceipt: payload => Api.post(`/v1/complaints/histories/return-receipt/${payload.complaint_id}`, payload.data,{
    headers:{token:payload.token}
  }),
  confirmApproveBySeller: payload => Api.put(`/v1/complaints/approved-by-seller/${payload.complaint_id}`, {},{
    headers:{token:payload.token}
  }),
  confirmApproveByBuyer: payload => Api.put(`/v1/complaints/approved-by-buyer/${payload.complaint_id}`, payload.data,{
    headers:{token:payload.token}
  }),
  confirmAbortedByBuyer: payload => Api.put(`/v1/complaints/rejected-approved-by-seller/${payload.complaint_id}`, payload.data,{
    headers:{token:payload.token}
  }),
  askForHelp: payload => Api.put(`/v1/complaints/ask-for-help/${payload.complaint_id}`, payload.data,{
    headers:{token:payload.token}
  }),
  // isReadSeller: payload => Api.put(`/v1/complaints/ask-for-help/${payload.complaint_id}`, payload.data,{
  //   headers:{token:payload.token}
  // }),
}
