export default {
  AUTHENTICATION: "authentication",
  AUTHENTICATION_SUCCESS: "authentication-success",
  IS_LOADING_AUTHENTICATION: "set-is-loading-authentication",

  LOGIN: "login",
  LOGIN_SUCCESS: "login-success",
  IS_LOADING_LOGIN: "is-loading-login",
  SET_MESSAGE_LOGIN: "set-message-login",

  LOGOUT: "logout",
  LOGOUT_SUCCESS: "logout-success",
  IS_LOADING_LOGOUT: "is-loading-logout",
  //GOOGLE LOGIN
  GOOGLE_SIGN_IN: "GOOGLE_SIGN_IN",
  FACEBOOK_SIGN_IN: "FACEBOOK_SIGN_IN",

  CHECK_LOGIN_EMAIL: "check-login-email",
  CHECK_LOGIN_EMAIL_SUCCESS: "check-login-email-success",
  CHECK_LOGIN_PHONE: "check-login-phone",
  CHECK_LOGIN_PHONE_SUCCESS: "check-login-phone-success",
  IS_LOADING_CHECK_LOGIN: "is-loading-check-login",
  RESET_CHECK_LOGIN: "reset-check-login",

  GET_OTP_LOGIN_BY_PHONE: "get-otp-login-by-phone",
  GET_OTP_LOGIN_BY_PHONE_SUCCESS: "get-otp-login-by-phone-success",
  IS_LOADING_GET_OTP_LOGIN_BY_PHONE: "is-loading-get-otp-login-by-phone",
  RESET_OTP_LOGIN: "reset-otp-login",

  GET_ADDRESS_FOR_SENT_TO: "get-address-for-sent-to",
  GET_ADDRESS_FOR_SENT_TO_SUCCESS: "get-address-for-sent-to-success",
  SET_ID_ADDRESS_FOR_SENT_TO: "set-address-selected-for-sent-to",
  IS_LOADING_GET_ADDRESS_FOR_SENT_TO: "is-loading-get-address-for-sent-to",

  RESET_LOGIN: "reset-login",

  LAST_LOGIN: "last-login",
  LAST_LOGIN_SUCCESS: "last-login-success",
  IS_LOADING_LAST_LOGIN: "is-loading-last-login",
};
