import { Api } from './api';

export default {
  get: payload => Api.get('/v1/feeds', {
    params: {
      limit: payload.limit,
      page: payload.page
    },
    headers: {
      token: payload.token
    }
  }),
};
