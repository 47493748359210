import { put, call, takeLatest, select } from "redux-saga/effects";
import Api from "../../services";
import * as actions from "./reducer";
import * as actionsCart from "../cartpage/reducer";
import types from "./constant";
// import { notification } from "../../helpers";

function* watchGetCosts(values) {
  const { payload } = values;
  yield put(actions.setIsLoadingGetCosts(true));
  try {
    const response = yield call(Api.checkcost.multiple, payload);
    const { data } = response;
    let result = {
      store_id: payload.data.store_id,
      data: data.data,
    };
    yield put(actions.getCostsSuccess(result));
  } catch (e) {
    yield put(actions.setIsLoadingGetCosts(false));
  } finally {
    yield put(actions.setIsLoadingGetCosts(false));
  }
}

function* watchRequestOrder(values) {
  const { payload } = values;
  yield put(actions.setIsLoadingCreateOrders(true));
  const state = yield select();
  try {
    const response = yield call(Api.buyertransaction.requestOrder, payload);
    const { data } = response;
    if (response.status === 200) {
      const resCart = yield call(Api.cart.deleteByMultipleId, {
        token: payload.token,
        multipleId: payload.multipleId,
      });
      if (resCart.status === 200) {
        let result = {
          multipleId: payload.multipleId,
          qty: state.cart.count - payload.qty,
        };
        yield put(actionsCart.deleteMultipleCartSuccess(result));
        yield put(actionsCart.getCountCartsSuccess(result.qty));
      }
      yield put(actions.createOrdersSuccess(data));
      yield put(actions.setCheckoutPaymentSuccess(data.payment));
      yield put(actions.setCreatedSuccessOrders(true));
    }
  } catch (e) {
    // yield notification.error("Gagal mengajukan pesanan");
    yield put(actions.setIsLoadingCreateOrders(false));
  } finally {
    yield put(actions.setIsLoadingCreateOrders(false));
  }
}

const saga = [
  takeLatest(types.CREATE_ORDERS, watchRequestOrder),
  takeLatest(types.GET_COSTS, watchGetCosts),
];
export default saga;
