import types from './constants';

const initialState = {
  createComplaint:{},
  loadingCreateComplaint:false,
  data:[],
  detail:{},
  isLoadingGetComplaint:false,
  history:[],
  isLoadingGetHistoryComplaint:false,
  discussion:[],
  isLoadingGetDiscussionComplaint:false,
  isLoadingCreateDiscussion: false,
  bidSuccess:false,
  isLoadingCreateBid:false,
  services:[],
  isLoadingGetServices:false,
  receiptCreated:false,
  isLoadingCreateReceipt:false,
  approveBySellerSuccess:false,
  isLoadingConfirmApproveBySeller:false,
  abortedByBuyerSuccess: false,
  isLoadingConfirmAbortedByBuyer: false,
  approveByBuyerSuccess: false,
  isLoadingConfirmApproveByBuyer: false,
  askForHelpSuccess: false,
  isLoadingAskForHelp: false
}

export default (state=initialState, action) => {
  switch(action.type){
    case types.CREATE_COMPLAINT_SUCCESS:
      return{
        ...state,
        createComplaint: action.payload
      }
    case types.IS_LOADING_CREATE_COMPLAINT:
      return{
        ...state,
        loadingCreateComplaint: action.payload
      }
    case types.GET_COMPLAINT_SUCCESS:
      return {...state, data:action.payload}
    case types.GET_DETAIL_COMPLAINT_SUCCESS:
      return {...state, detail:action.payload}
    case types.IS_LOADING_GET_COMPLAINT:
      return {...state, isLoadingGetComplaint:action.payload}
    case types.RESET_GET_COMPLAINT:
      return {...state, data:initialState.data}
    case types.GET_HISTORY_COMPLAINT_SUCCESS:
      return {...state, history:action.payload}
    case types.RESET_GET_HISTORY_COMPLAINT:
      return {...state, history:initialState.history}
    case types.IS_LOADING_GET_HISTORY_COMPLAINT:
      return {...state, isLoadingGetHistoryComplaint:action.payload}
    case types.GET_DISCUSSION_COMPLAINT_SUCCESS:
      return {...state, discussion:action.payload}
    case types.RESET_GET_DISCUSSION_COMPLAINT:
      return {...state, discussion:initialState.history}
    case types.IS_LOADING_GET_DISCUSSION_COMPLAINT:
      return {...state, isLoadingGetDiscussionComplaint:action.payload}
    case types.CREATE_DISCUSSION_COMPLAINT_SUCCESS:
      return {...state, discussion: [...state.discussion.concat(action.payload)].sort((a, b)=> new Date(b.createdAt) - new Date(a.createdAt))}
    case types.IS_LOADING_CREATE_DISCUSSION_COMPLAINT:
      return {...state, isLoadingCreateDiscussion:action.payload}
    case types.CREATE_BID_COMPLAINT_SUCCESS:
      return {
        ...state,
        discussion: [...state.discussion.concat(action.payload.discussion)].sort((a, b)=> new Date(b.createdAt) - new Date(a.createdAt)),
        history: [...state.history.concat(action.payload.history)].sort((a, b)=> new Date(b.createdAt) - new Date(a.createdAt))
      }
    case types.BID_SUCCESS:
      return {...state, bidSuccess:action.payload}
    case types.IS_LOADING_CREATE_BID_COMPLAINT:
      return {...state, isLoadingCreateBid:action.payload}
    case types.GET_SERVICES_COMPLAINT_SUCCESS:
      return {...state, services:action.payload.sort((a,b)=> a.id - b.id)}
    case types.IS_LOADING_GET_SERVICES_COMPLAINT:
      return {...state, isLoadingGetServices:action.payload}
    case types.CREATE_RECEIPT_SUCCESS:
      return {
        ...state,
        discussion: [...state.discussion.concat(action.payload.discussion)].sort((a, b)=> new Date(b.createdAt) - new Date(a.createdAt)),
        history: [...state.history.concat(action.payload.history)].sort((a, b)=> new Date(b.createdAt) - new Date(a.createdAt)),
        receiptCreated: action.payload.success
      }
    case types.SET_RETURN_RECEIPT:
      return {
        ...state,
        receiptCreated: action.payload
      }
    case types.IS_LOADING_CREATE_RECEIPT:
      return {...state, isLoadingCreateReceipt:action.payload}
    case types.CONFIRM_APPROVE_BY_SELLER_SUCCESS:
      return {
        ...state,
        data: state.data.map(dt=> dt.id === state.detail.id ? {
          ...dt,
          approved_by_seller: action.payload.approved_by_seller,
          approved_by_sellerAt: action.payload.approved_by_sellerAt
        }: dt ),
        detail: {
          ...state.detail,
          approved_by_seller: action.payload.approved_by_seller,
          approved_by_sellerAt: action.payload.approved_by_sellerAt
        },
        history: [...state.history.concat(action.payload.history)].sort((a, b)=> new Date(b.createdAt) - new Date(a.createdAt)),
        approveBySellerSuccess:action.payload.success
      }
    case types.SET_SUCCESS_APPROVE_BY_SELLER:
      return {...state, approveBySellerSuccess:action.payload}
    case types.IS_LOADING_CONFIRM_APPROVE_BY_SELLER:
      return {...state, isLoadingConfirmApproveBySeller:action.payload}
    case types.CONFIRM_ABORTED_BY_BUYER_SUCCESS:
      return {
        ...state,
        data: state.data.map(dt=> dt.id === state.detail.id ? {
          ...dt,
          approved_by_seller: action.payload.approved_by_seller,
          approved_by_sellerAt: action.payload.approved_by_sellerAt
        }: dt ),
        detail: {
          ...state.detail,
          approved_by_seller: action.payload.approved_by_seller,
          approved_by_sellerAt: action.payload.approved_by_sellerAt
        },
        history: [...state.history.concat(action.payload.history)].sort((a, b)=> new Date(b.createdAt) - new Date(a.createdAt)),
        approveBySellerSuccess:action.payload.success
      }
    case types.SET_SUCCESS_ABORTED_BY_BUYER:
      return {...state, abortedByBuyerSuccess:action.payload}
    case types.IS_LOADING_CONFIRM_ABORTED_BY_BUYER:
      return {...state, isLoadingConfirmAbortedByBuyer:action.payload}
    case types.CONFIRM_APPROVE_BY_BUYER_SUCCESS:
      return {
        ...state,
        data: state.data.filter(dt=> dt.id !== state.detail.id),
        detail: {
          ...state.detail,
          approved_by_buyer: action.payload.approved_by_buyer,
          approved_by_buyerAt: action.payload.approved_by_buyerAt
        },
        history: [...state.history.concat(action.payload.history)].sort((a, b)=> new Date(b.createdAt) - new Date(a.createdAt)),
        approveByBuyerSuccess:action.payload.success
      }
    case types.SET_SUCCESS_APPROVE_BY_BUYER:
      return {...state, approveByBuyerSuccess:action.payload}
    case types.IS_LOADING_CONFIRM_APPROVE_BY_BUYER:
      return {...state, isLoadingConfirmApproveByBuyer:action.payload}
    case types.ASK_FOR_HELP_SUCCESS:
      return {
        ...state,
        data: state.data.map(dt=> dt.id === state.detail.id ? {
          ...dt,
          ask_for_help: action.payload.ask_for_help,
          ask_for_helpAt: action.payload.ask_for_helpAt
        }: dt ),
        detail: {
          ...state.detail,
          ask_for_help: action.payload.ask_for_help,
          ask_for_helpAt: action.payload.ask_for_helpAt
        },
        history: [...state.history.concat(action.payload.history)].sort((a, b)=> new Date(b.createdAt) - new Date(a.createdAt)),
        discussion: [...state.discussion.concat(action.payload.discussion)].sort((a, b)=> new Date(b.createdAt) - new Date(a.createdAt)),
        askForHelpSuccess:action.payload.success
      }
    case types.SET_SUCCESS_ASK_FOR_HELP:
      return {...state, askForHelpSuccess:action.payload}
    case types.IS_LOADING_ASK_FOR_HELP:
      return{...state, isLoadingAskForHelp:action.payload}
    case types.SET_IS_READ_SELLER_SUCCESS:
      return {
        ...state,
        data: state.data.map(dt=> dt.id === state.detail.id ? {
          ...dt,
          is_read: action.payload.is_read,
          is_readAt: action.payload.is_readAt
        }: dt ),
        detail: {
          ...state.detail,
          is_read: action.payload.is_read,
          is_readAt: action.payload.is_readAt
        },
      }
    default:
      return state
  }
}

export const getComplaintByBuyer = payload => ({
  type: types.GET_COMPLAINT_BY_BUYER,
  payload
})
export const getComplaintBySeller = payload => ({
  type: types.GET_COMPLAINT_BY_SELLER,
  payload
})
export const getComplaintSuccess = payload => ({
  type: types.GET_COMPLAINT_SUCCESS,
  payload
})

export const createComplaint = payload => ({
  type: types.CREATE_COMPLAINT,
  payload
})
export const createComplaintSuccess = payload => ({
  type: types.CREATE_COMPLAINT_SUCCESS,
  payload
})
export const setIsLoadingCreateComplaintSuccess = payload => ({
  type: types.IS_LOADING_CREATE_COMPLAINT,
  payload
})

export const getDetailComplaint = payload => ({
  type: types.GET_DETAIL_COMPLAINT,
  payload
})
export const getDetailComplaintByOrderId = payload => ({
  type: types.GET_DETAIL_COMPLAINT_BY_ORDER_ID,
  payload
})
export const getDetailComplaintSuccess = payload => ({
  type: types.GET_DETAIL_COMPLAINT_SUCCESS,
  payload
})
export const setIsLoadingGetComplaint = payload => ({
  type: types.IS_LOADING_GET_COMPLAINT,
  payload
})
export const resetGetComplaint = payload => ({
  type: types.RESET_GET_COMPLAINT
})

export const getHistoryComplaint = payload => ({
  type: types.GET_HISTORY_COMPLAINT,
  payload
})
export const getHistoryComplaintSuccess = payload => ({
  type: types.GET_HISTORY_COMPLAINT_SUCCESS,
  payload
})
export const setIsLoadingGetHistoryComplaint = payload => ({
  type: types.IS_LOADING_GET_HISTORY_COMPLAINT,
  payload
})
export const resetGetHistoryComplaint = payload => ({
  type: types.RESET_GET_HISTORY_COMPLAINT
})

export const getDiscussionComplaint = payload => ({
  type: types.GET_DISCUSSION_COMPLAINT,
  payload
})
export const getDiscussionComplaintSuccess = payload => ({
  type: types.GET_DISCUSSION_COMPLAINT_SUCCESS,
  payload
})
export const setIsLoadingGetDiscussionComplaint = payload => ({
  type: types.IS_LOADING_GET_DISCUSSION_COMPLAINT,
  payload
})
export const resetGetDiscussionComplaint = payload => ({
  type: types.RESET_GET_DISCUSSION_COMPLAINT
})

export const getServices = payload => ({
  type: types.GET_SERVICES_COMPLAINT,
  payload
})
export const getServicesSuccess = payload => ({
  type: types.GET_SERVICES_COMPLAINT_SUCCESS,
  payload
})
export const setIsLoadingGetServices = payload => ({
  type: types.IS_LOADING_GET_SERVICES_COMPLAINT,
  payload
})

export const createDiscussionComplaint = payload => ({
  type: types.CREATE_DISCUSSION_COMPLAINT,
  payload
})
export const createDiscussionComplaintSuccess = payload => ({
  type: types.CREATE_DISCUSSION_COMPLAINT_SUCCESS,
  payload
})
export const setIsLoadingCreateDiscussionComplaint = payload => ({
  type: types.IS_LOADING_CREATE_DISCUSSION_COMPLAINT,
  payload
})

export const createBidComplaint = payload => ({
  type: types.CREATE_BID_COMPLAINT,
  payload
})
export const createBidComplaintSuccess = payload => ({
  type: types.CREATE_BID_COMPLAINT_SUCCESS,
  payload
})
export const setBidSuccess = payload => ({
  type: types.BID_SUCCESS,
  payload
})
export const setIsLoadingCreateBidComplaint = payload => ({
  type: types.IS_LOADING_CREATE_BID_COMPLAINT,
  payload
})

export const createReturnReceiptFromBuyer = payload => ({
  type: types.CREATE_RETURN_RECEIPT_FROM_BUYER,
  payload
})
export const createDeliveryReceiptFromSeller = payload => ({
  type: types.CREATE_DELIVERY_RECEIPT_FROM_SELLER,
  payload
})
export const createReceiptSuccess = payload => ({
  type: types.CREATE_RECEIPT_SUCCESS,
  payload
})
export const setIsLoadingCreateReceipt = payload => ({
  type: types.IS_LOADING_CREATE_RECEIPT,
  payload
})
export const setReturnReceipt = payload => ({
  type: types.SET_RETURN_RECEIPT,
  payload
})

export const confirmApproveBySeller = payload => ({
  type: types.CONFIRM_APPROVE_BY_SELLER,
  payload
})
export const confirmApproveBySellerSuccess = payload => ({
  type: types.CONFIRM_APPROVE_BY_SELLER_SUCCESS,
  payload
})
export const setSuccessApproveBySeller = payload => ({
  type: types.SET_SUCCESS_APPROVE_BY_SELLER,
  payload
})
export const setIsLoadingConfirmApproveBySeller = payload => ({
  type: types.IS_LOADING_CONFIRM_APPROVE_BY_SELLER,
  payload
})

export const confirmAbortedByBuyer = payload => ({
  type: types.CONFIRM_ABORTED_BY_BUYER,
  payload
})
export const confirmAbortedByBuyerSuccess = payload => ({
  type: types.CONFIRM_ABORTED_BY_BUYER_SUCCESS,
  payload
})
export const setSuccessAbortedByBuyer = payload => ({
  type: types.SET_SUCCESS_ABORTED_BY_BUYER,
  payload
})
export const setIsLoadingConfirmAbortedByBuyer = payload => ({
  type: types.IS_LOADING_CONFIRM_ABORTED_BY_BUYER,
  payload
})

export const confirmApproveByBuyer = payload => ({
  type: types.CONFIRM_APPROVE_BY_BUYER,
  payload
})
export const confirmApproveByBuyerSuccess = payload => ({
  type: types.CONFIRM_APPROVE_BY_BUYER_SUCCESS,
  payload
})
export const setSuccessApproveByBuyer = payload => ({
  type: types.SET_SUCCESS_APPROVE_BY_BUYER,
  payload
})
export const setIsLoadingConfirmApproveByBuyer = payload => ({
  type: types.IS_LOADING_CONFIRM_APPROVE_BY_BUYER,
  payload
})

export const askForHelp = payload => ({
  type: types.ASK_FOR_HELP,
  payload
})
export const askForHelpSuccess = payload => ({
  type: types.ASK_FOR_HELP_SUCCESS,
  payload
})
export const setSuccessAskForHelp = payload => ({
  type: types.SET_SUCCESS_ASK_FOR_HELP,
  payload
})
export const setIsLoadingAskForHelp = payload => ({
  type: types.IS_LOADING_ASK_FOR_HELP,
  payload
})

export const setIsReadSeller = payload => ({
  type: types.SET_IS_READ_SELLER,
  payload
})
export const setIsReadSellerSuccess = payload => ({
  type: types.SET_IS_READ_SELLER_SUCCESS,
  payload
})
