import { Api } from './api';

export default {
  getCoin: payload => Api.get('/v2/coin',{
    headers: {token:payload.token}
  }),
  getHistory: payload => Api.get('/v2/coin/history',{
    headers: {token:payload.token}
  }),
  requestOTPWithdraw: payload => Api.post('/v2/coin/withdraw/request-otp', payload.data,{
    headers: {token:payload.token}
  }),
  verifyOTPWithdraw: payload => Api.post('/v2/coin/withdraw/confirm-otp', {otp_code:payload.otp_code}, {
    headers: {token:payload.token}
  }),

};
