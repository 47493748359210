import { put, call, takeLatest, select, delay } from "redux-saga/effects";
import Api from "../../services";
import types from "./types";
import * as actions from "./reducer";
import { notification } from "../../helpers/notification";

function* watchGetProfileAccount(values) {
  yield put(actions.setIsLoadingGetProfileAccount(true));
  const { payload } = values;
  try {
    const response = yield call(Api.user.getDetailProfile, payload);
    const { data } = response;
    yield put(actions.getProfileAccountSuccess(data.data));
  } catch (error) {
    if (error.response.data) {
      yield notification.error(error.response.data.message);
    }
  } finally {
    yield put(actions.setIsLoadingGetProfileAccount(false));
  }
}
function* watchCheckPasswordAccount(values) {
  yield put(actions.setIsLoadingCheckPasswordAccount(true));
  const { payload } = values;
  try {
    yield delay(500);
    const response = yield call(Api.user.checkPassword, payload);
    const { data } = response;
    yield put(actions.checkPasswordAccountSuccess(data));
  } catch (error) {
    if (error.response.data) {
      yield notification.error(error.response.data.message);
    }
  } finally {
    yield put(actions.setIsLoadingCheckPasswordAccount(false));
  }
}
//edit email
function* watchRequestOTPWithEmailToChangeEmailAccount(values) {
  yield put(actions.setIsLoadingRequestOTPToChangeEmailAccount(true));
  const { payload } = values;
  try {
    yield delay(500);
    const response = yield call(
      Api.user.change_email.request_otp.check.email,
      payload
    );
    const { data } = response;
    let result = {
      ...data,
      is_email: payload.is_email,
      is_phone_number: payload.is_phone_number,
    };
    yield put(actions.requestOTPToChangeEmailAccountSuccess(result));
  } catch (error) {
    if (error.response.data) {
      yield notification.error(error.response.data.message);
    }
  } finally {
    yield put(actions.setIsLoadingRequestOTPToChangeEmailAccount(false));
  }
}
function* watchRequestOTPWithPhoneNumberToChangeEmailAccount(values) {
  yield put(actions.setIsLoadingRequestOTPToChangeEmailAccount(true));
  const { payload } = values;
  try {
    yield delay(500);
    const response = yield call(
      Api.user.change_email.request_otp.check.phone_number,
      payload
    );
    const { data } = response;
    let result = {
      ...data,
      is_email: payload.is_email,
      is_phone_number: payload.is_phone_number,
    };
    yield put(actions.requestOTPToChangeEmailAccountSuccess(result));
  } catch (error) {
    if (error.response.data) {
      yield notification.error(error.response.data.message);
    }
  } finally {
    yield put(actions.setIsLoadingRequestOTPToChangeEmailAccount(false));
  }
}
function* watchVerifyOTPToChangeEmailAccount(values) {
  yield put(actions.setIsLoadingVerifyOTPToChangeEmailAccount(true));
  const { payload } = values;
  try {
    yield delay(500);
    const response = yield call(
      Api.user.change_email.verify_otp.check,
      payload
    );
    const { data } = response;
    yield put(actions.verifyOTPToChangeEmailAccountSuccess(data));
  } catch (error) {
    if (error.response.data) {
      yield put(
        actions.verifyOTPToChangeEmailAccountSuccess({
          error: true,
          message: error.response.data.message,
        })
      );
      yield notification.error(error.response.data.message);
    }
  } finally {
    yield put(actions.setIsLoadingVerifyOTPToChangeEmailAccount(false));
  }
}
function* watchChangeNewEmailAccount(values) {
  yield put(actions.setIsLoadingChangeNewEmail(true));
  const { payload } = values;
  try {
    yield delay(500);
    const response = yield call(
      Api.user.change_email.request_otp.change,
      payload
    );
    const { data } = response;
    yield put(
      actions.changeNewEmailSuccess({
        email: payload.new_email,
        ...data,
      })
    );
  } catch (error) {
    if (error.response.data) {
      yield put(
        actions.changeNewEmailSuccess({
          ...error.response.data,
          error: true,
          email: payload.new_email,
        })
      );
    }
  } finally {
    yield put(actions.setIsLoadingChangeNewEmail(false));
  }
}
function* watchVerifyOTPNewEmail(values) {
  yield put(actions.setIsLoadingVerifyOTPNewEmail(true));
  const { payload } = values;
  try {
    yield delay(500);
    const response = yield call(
      Api.user.change_email.verify_otp.change,
      payload
    );
    const { data } = response;
    const state = yield select();
    yield put(actions.verifyOTPNewEmailSuccess(data));
    yield put(
      actions.getProfileAccountSuccess({
        ...state.accountprofile.detail,
        email: payload.email,
      })
    );
    yield notification.success(data.message);
  } catch (error) {
    if (error.response.data) {
      yield put(
        actions.verifyOTPNewEmailSuccess({
          ...error.response.data,
          error: true,
        })
      );
      yield notification.error(error.response.data.message);
    }
  } finally {
    yield put(actions.setIsLoadingVerifyOTPNewEmail(false));
  }
}
//verify email
function* watchRequestOTPVerifyEmail(values) {
  yield put(actions.setIsLoadingRequestOTPVerifyEmail(true));
  const { payload } = values;
  try {
    // yield delay(500);
    const response = yield call(Api.user.verify_email.request_otp, payload);
    const { data } = response;
    yield put(actions.requestOTPVerifyEmailSuccess(data));
  } catch (error) {
    if (error.response.data) {
      yield notification.error(error.response.data.message);
    }
  } finally {
    yield put(actions.setIsLoadingRequestOTPVerifyEmail(false));
  }
}
function* watchVerifyOTPVerifyEmail(values) {
  yield put(actions.setIsLoadingVerifyOTPVerifyEmail(true));
  const { payload } = values;
  try {
    yield delay(500);
    const response = yield call(Api.user.verify_email.verify_otp, payload);
    const { data } = response;
    yield put(actions.verifyOTPVerifyEmailSuccess(data));
  } catch (error) {
    if (error.response.data) {
      yield notification.error(error.response.data.message);
    }
  } finally {
    yield put(actions.setIsLoadingVerifyOTPVerifyEmail(false));
  }
}
// edit phone number
function* watchRequestOTPWithEmailToChangePhoneNumberAccount(values) {
  yield put(actions.setIsLoadingRequestOTPToChangePhoneNumberAccount(true));
  const { payload } = values;
  try {
    yield delay(500);
    const response = yield call(
      Api.user.change_phone.request_otp.check.email,
      payload
    );
    const { data } = response;
    let result = {
      ...data,
      is_email: payload.is_email,
      is_phone_number: payload.is_phone_number,
    };
    yield put(actions.requestOTPToChangePhoneNumberAccountSuccess(result));
  } catch (error) {
    if (error.response.data) {
      yield notification.error(error.response.data.message);
    }
  } finally {
    yield put(actions.setIsLoadingRequestOTPToChangePhoneNumberAccount(false));
  }
}
function* watchRequestOTPWithPhoneNumberToChangePhoneNumberAccount(values) {
  yield put(actions.setIsLoadingRequestOTPToChangePhoneNumberAccount(true));
  const { payload } = values;
  try {
    yield delay(500);
    const response = yield call(
      Api.user.change_phone.request_otp.check.phone_number,
      payload
    );
    const { data } = response;
    let result = {
      ...data,
      is_email: payload.is_email,
      is_phone_number: payload.is_phone_number,
    };
    yield put(actions.requestOTPToChangePhoneNumberAccountSuccess(result));
  } catch (error) {
    if (error.response.data) {
      yield notification.error(error.response.data.message);
    }
  } finally {
    yield put(actions.setIsLoadingRequestOTPToChangePhoneNumberAccount(false));
  }
}
function* watchVerifyOTPToChangePhoneNumberAccount(values) {
  yield put(actions.setIsLoadingVerifyOTPToChangePhoneNumberAccount(true));
  const { payload } = values;
  try {
    yield delay(500);
    const response = yield call(
      Api.user.change_phone.verify_otp.check,
      payload
    );
    const { data } = response;
    yield put(actions.verifyOTPToChangePhoneNumberAccountSuccess(data));
  } catch (error) {
    if (error.response.data) {
      yield put(
        actions.verifyOTPToChangePhoneNumberAccountSuccess({
          error: true,
          message: error.response.data.message,
        })
      );
      yield notification.error(error.response.data.message);
    }
  } finally {
    yield put(actions.setIsLoadingVerifyOTPToChangePhoneNumberAccount(false));
  }
}
function* watchChangeNewPhoneNumberAccount(values) {
  yield put(actions.setIsLoadingChangeNewPhoneNumber(true));
  const { payload } = values;
  try {
    yield delay(500);
    const response = yield call(
      Api.user.change_phone.request_otp.change,
      payload
    );
    const { data } = response;
    yield put(
      actions.changeNewPhoneNumberAccountSuccess({
        phone_number: payload.new_phone_number,
        ...data,
      })
    );
  } catch (error) {
    if (error.response.data) {
      yield put(
        actions.changeNewPhoneNumberAccountSuccess({
          ...error.response.data,
          error: true,
          phone_number: payload.new_phone_number,
        })
      );
    }
  } finally {
    yield put(actions.setIsLoadingChangeNewPhoneNumber(false));
  }
}
function* watchVerifyOTPNewPhoneNumber(values) {
  yield put(actions.setIsLoadingVerifyOTPNewPhoneNumber(true));
  const { payload } = values;
  try {
    yield delay(500);
    const response = yield call(
      Api.user.change_phone.verify_otp.change,
      payload
    );
    const { data } = response;
    const state = yield select();
    yield put(actions.verifyOTPNewPhoneNumberSuccess(data));
    yield put(
      actions.getProfileAccountSuccess({
        ...state.accountprofile.detail,
        phone_number: payload.phone_number,
      })
    );
    yield notification.success(data.message);
  } catch (error) {
    if (error.response.data) {
      yield put(
        actions.verifyOTPNewPhoneNumberSuccess({
          ...error.response.data,
          error: true,
        })
      );
      yield notification.error(error.response.data.message);
    }
  } finally {
    yield put(actions.setIsLoadingVerifyOTPNewPhoneNumber(false));
  }
}
//verify phone number
function* watchRequestOTPVerifyPhoneNumber(values) {
  yield put(actions.setIsLoadingRequestOTPVerifyPhoneNumber(true));
  const { payload } = values;
  try {
    // yield delay(500);
    const response = yield call(Api.user.verify_phone.request_otp, payload);
    const { data } = response;
    yield put(actions.requestOTPVerifyPhoneNumberSuccess(data));
  } catch (error) {
    if (error.response.data) {
      yield notification.error(error.response.data.message);
    }
  } finally {
    yield put(actions.setIsLoadingRequestOTPVerifyPhoneNumber(false));
  }
}
function* watchVerifyOTPVerifyPhoneNumber(values) {
  yield put(actions.setIsLoadingVerifyOTPVerifyPhoneNumber(true));
  const { payload } = values;
  try {
    yield delay(500);
    const response = yield call(Api.user.verify_phone.verify_otp, payload);
    const { data } = response;
    yield put(actions.verifyOTPVerifyPhoneNumberSuccess(data));
  } catch (error) {
    if (error.response.data) {
      yield notification.error(error.response.data.message);
    }
  } finally {
    yield put(actions.setIsLoadingVerifyOTPVerifyPhoneNumber(false));
  }
}
const saga = [
  takeLatest(types.REQUEST_OTP_VERIFY_EMAIL, watchRequestOTPVerifyEmail),
  takeLatest(types.VERIFY_OTP_VERIFY_EMAIL, watchVerifyOTPVerifyEmail),
  takeLatest(
    types.REQUEST_OTP_VERIFY_PHONE_NUMBER,
    watchRequestOTPVerifyPhoneNumber
  ),
  takeLatest(
    types.VERIFY_OTP_VERIFY_PHONE_NUMBER,
    watchVerifyOTPVerifyPhoneNumber
  ),
  takeLatest(types.GET_PROFILE_ACCOUNT, watchGetProfileAccount),
  takeLatest(types.CHECK_PASSWORD_ACCOUNT, watchCheckPasswordAccount),
  takeLatest(
    types.REQUEST_OTP_WITH_EMAIL_TO_CHANGE_EMAIL_ACCOUNT,
    watchRequestOTPWithEmailToChangeEmailAccount
  ),
  takeLatest(
    types.REQUEST_OTP_WITH_PHONE_NUMBER_TO_CHANGE_EMAIL_ACCOUNT,
    watchRequestOTPWithPhoneNumberToChangeEmailAccount
  ),
  takeLatest(
    types.VERIFY_OTP_TO_CHANGE_EMAIL_ACCOUNT,
    watchVerifyOTPToChangeEmailAccount
  ),
  takeLatest(types.CHANGE_NEW_EMAIL_ACCOUNT, watchChangeNewEmailAccount),
  takeLatest(types.VERIFY_OTP_CHANGE_NEW_EMAIL, watchVerifyOTPNewEmail),
  takeLatest(
    types.REQUEST_OTP_WITH_EMAIL_TO_CHANGE_PHONE_NUMBER_ACCOUNT,
    watchRequestOTPWithEmailToChangePhoneNumberAccount
  ),
  takeLatest(
    types.REQUEST_OTP_WITH_PHONE_NUMBER_TO_CHANGE_PHONE_NUMBER_ACCOUNT,
    watchRequestOTPWithPhoneNumberToChangePhoneNumberAccount
  ),
  takeLatest(
    types.VERIFY_OTP_TO_CHANGE_PHONE_NUMBER_ACCOUNT,
    watchVerifyOTPToChangePhoneNumberAccount
  ),
  takeLatest(
    types.CHANGE_NEW_PHONE_NUMBER_ACCOUNT,
    watchChangeNewPhoneNumberAccount
  ),
  takeLatest(
    types.VERIFY_OTP_CHANGE_NEW_PHONE_NUMBER,
    watchVerifyOTPNewPhoneNumber
  ),
];

export default saga;
