import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import InfoArea from "../../components/InfoArea/InfoArea.js";

import styles from "../../../../assets/jss/material-kit-react/views/aboutPageSections/informationStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <div>
            <img alt="hand_shake" src={require('../../../../assets/img/hand_shake_head_animation.png')} style={{maxWidth:400, maxHeight:400}}/>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <InfoArea
            title=""
            descriptionStyle={{fontSize:22, lineHeight: '1.6'}}
            description="Jika Anda ingin sukses maka Buatlah Orang Menjadi Sukses Sebanyak-banyaknya. Menjadikan Anda Lebih Sukses adalah Tujuan Utama Kami."
            icon={ThumbUpIcon}
            iconColor="danger"
            vertical
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
