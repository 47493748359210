import types from "./types";

const initialState = {
  detail: {},
  loading: false,
  check_password: {
    loading: false,
    success: false,
    message: "",
  },
  change_email: {
    request: {
      is_email: false,
      is_phone_number: false,
      success: false,
      loading: false,
      message: "",
    },
    verify: {
      error: false,
      success: false,
      message: "",
      loading: false,
    },
    new: {
      request: {
        message: "",
        success: false,
        loading: false,
        error: false,
      },
      verify: {
        success: false,
        loading: false,
      },
      email: "",
    },
  },
  change_phone_number: {
    request: {
      is_email: false,
      is_phone_number: false,
      success: false,
      loading: false,
      message: "",
    },
    verify: {
      error: false,
      success: false,
      message: "",
      loading: false,
    },
    new: {
      request: {
        message: "",
        success: false,
        loading: false,
        error: false,
      },
      verify: {
        success: false,
        loading: false,
      },
      phone_number: "",
    },
  },
  change_password: {
    request: {
      success: false,
      loading: false,
    },
    verify: {
      success: false,
      loading: false,
    },
    success: false,
    loading: false,
  },
  verify_email: {
    request: {
      error: false,
      success: false,
      message: "",
      loading: false,
    },
    verify: {
      error: false,
      success: false,
      message: "",
      loading: false,
    },
  },
  verify_phone_number: {
    request: {
      error: false,
      success: false,
      message: "",
      loading: false,
    },
    verify: {
      error: false,
      success: false,
      message: "",
      loading: false,
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PROFILE_ACCOUNT_SUCCESS:
      return {
        ...state,
        detail: action.payload,
      };
    case types.IS_LOADING_GET_PROFILE_ACCOUNT:
      return {
        ...state,
        loading: action.payload,
      };
    case types.RESET_GET_PROFILE_ACCOUNT:
      return initialState;
    //check password
    case types.CHECK_PASSWORD_ACCOUNT_SUCCESS:
      return {
        ...state,
        check_password: {
          ...state.check_password,
          ...action.payload,
        },
      };
    case types.IS_LOADING_CHECK_PASSWORD_ACCOUNT:
      return {
        ...state,
        check_password: {
          ...state.check_password,
          loading: action.payload,
        },
      };
    case types.RESET_CHECK_PASSWORD_ACCOUNT:
      return {
        ...state,
        check_password: initialState.check_password,
      };
    //change email
    case types.REQUEST_OTP_TO_CHANGE_EMAIL_ACCOUNT_SUCCESS:
      return {
        ...state,
        change_email: {
          ...state.change_email,
          request: {
            ...state.change_email.request,
            ...action.payload,
          },
        },
      };
    case types.IS_LOADING_REQUEST_OTP_TO_CHANGE_EMAIL_ACCOUNT:
      return {
        ...state,
        change_email: {
          ...state.change_email,
          request: {
            ...state.change_email.request,
            loading: action.payload,
          },
        },
      };
    case types.RESET_REQUEST_OTP_TO_CHANGE_EMAIL_ACCOUNT:
      return {
        ...state,
        change_email: {
          ...state.change_email,
          request: initialState.change_email.request,
        },
      };
    case types.VERIFY_OTP_TO_CHANGE_EMAIL_ACCOUNT_SUCCESS:
      return {
        ...state,
        change_email: {
          ...state.change_email,
          verify: {
            ...state.change_email.verify,
            ...action.payload,
          },
        },
      };
    case types.IS_LOADING_VERIFY_OTP_TO_CHANGE_EMAIL_ACCOUNT:
      return {
        ...state,
        change_email: {
          ...state.change_email,
          verify: {
            ...state.change_email.verify,
            loading: action.payload,
          },
        },
      };
    case types.RESET_VERIFY_OTP_TO_CHANGE_EMAIL_ACCOUNT:
      return {
        ...state,
        change_email: {
          ...state.change_email,
          verify: initialState.change_email.verify,
        },
      };
    case types.CHANGE_NEW_EMAIL_ACCOUNT_SUCCESS:
      return {
        ...state,
        change_email: {
          ...state.change_email,
          new: {
            ...state.change_email.new,
            email: action.payload.email,
            request: {
              ...state.change_email.new.request,
              ...action.payload,
            },
          },
        },
      };
    case types.IS_LOADING_CHANGE_NEW_EMAIL_ACCOUNT:
      return {
        ...state,
        change_email: {
          ...state.change_email,
          new: {
            ...state.change_email.new,
            request: {
              ...state.change_email.new.request,
              loading: action.payload,
            },
          },
        },
      };
    case types.RESET_CHANGE_NEW_EMAIL_ACCOUNT:
      return {
        ...state,
        change_email: {
          ...state.change_email,
          new: initialState.change_email.new,
        },
      };
    case types.VERIFY_OTP_CHANGE_NEW_EMAIL_SUCCESS:
      return {
        ...state,
        change_email: {
          ...state.change_email,
          new: {
            ...state.change_email.new,
            verify: {
              ...state.change_email.new.verify,
              ...action.payload,
            },
          },
        },
      };
    case types.IS_LOADING_VERIFY_OTP_CHANGE_NEW_EMAIL:
      return {
        ...state,
        change_email: {
          ...state.change_email,
          new: {
            ...state.change_email.new,
            verify: {
              ...state.change_email.new.verify,
              loading: action.payload,
            },
          },
        },
      };
    case types.RESET_VERIFY_OTP_CHANGE_NEW_EMAIL:
      return {
        ...state,
        change_email: {
          ...state.change_email,
          new: {
            ...state.change_email.new,
            verify: state.change_email.new.verify,
          },
        },
      };
    case types.RESET_CHANGE_NEW_EMAIL:
      return {
        ...state,
        change_email: initialState.change_email,
      };
    //verify email
    case types.REQUEST_OTP_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        verify_email: {
          ...state.verify_email,
          request: {
            ...state.verify_email.request,
            ...action.payload,
          },
        },
      };
    case types.IS_LOADING_REQUEST_OTP_VERIFY_EMAIL:
      return {
        ...state,
        verify_email: {
          ...state.verify_email,
          request: {
            ...state.verify_email.request,
            loading: action.payload,
          },
        },
      };
    case types.RESET_REQUEST_OTP_VERIFY_EMAIL:
      return {
        ...state.verify_email,
        verify_email: {
          ...state.verify_email,
          request: state.verify_email.request,
        },
      };

    case types.VERIFY_OTP_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        verify_email: {
          ...state.verify_email,
          verify: {
            ...state.verify_email.verify,
            ...action.payload,
          },
        },
        detail: {
          ...state.detail,
          email_verified: action.payload.success,
        },
      };
    case types.IS_LOADING_VERIFY_OTP_VERIFY_EMAIL:
      return {
        ...state,
        verify_email: {
          ...state.verify_email,
          verify: {
            ...state.verify_email.verify,
            loading: action.payload,
          },
        },
      };
    case types.RESET_VERIFY_OTP_VERIFY_EMAIL:
      return {
        ...state,
        verify_email: {
          ...state.verify_email,
          verify: state.verify_email.verify,
        },
      };
    //change phone number
    case types.REQUEST_OTP_TO_CHANGE_PHONE_NUMBER_ACCOUNT_SUCCESS:
      return {
        ...state,
        change_phone_number: {
          ...state.change_phone_number,
          request: {
            ...state.change_phone_number.request,
            ...action.payload,
          },
        },
      };
    case types.IS_LOADING_REQUEST_OTP_TO_CHANGE_PHONE_NUMBER_ACCOUNT:
      return {
        ...state,
        change_phone_number: {
          ...state.change_phone_number,
          request: {
            ...state.change_phone_number.request,
            loading: action.payload,
          },
        },
      };
    case types.RESET_REQUEST_OTP_TO_CHANGE_PHONE_NUMBER_ACCOUNT:
      return {
        ...state,
        change_phone_number: {
          ...state.change_phone_number,
          request: initialState.change_phone_number.request,
        },
      };
    case types.VERIFY_OTP_TO_CHANGE_PHONE_NUMBER_ACCOUNT_SUCCESS:
      return {
        ...state,
        change_phone_number: {
          ...state.change_phone_number,
          verify: {
            ...state.change_phone_number.verify,
            ...action.payload,
          },
        },
      };
    case types.IS_LOADING_VERIFY_OTP_TO_CHANGE_PHONE_NUMBER_ACCOUNT:
      return {
        ...state,
        change_phone_number: {
          ...state.change_phone_number,
          verify: {
            ...state.change_phone_number.verify,
            loading: action.payload,
          },
        },
      };
    case types.RESET_VERIFY_OTP_TO_CHANGE_PHONE_NUMBER_ACCOUNT:
      return {
        ...state,
        change_phone_number: {
          ...state.change_phone_number,
          verify: initialState.change_phone_number.verify,
        },
      };
    case types.CHANGE_NEW_PHONE_NUMBER_ACCOUNT_SUCCESS:
      return {
        ...state,
        change_phone_number: {
          ...state.change_phone_number,
          new: {
            ...state.change_phone_number.new,
            phone_number: action.payload.phone_number,
            request: {
              ...state.change_phone_number.new.request,
              ...action.payload,
            },
          },
        },
      };
    case types.IS_LOADING_CHANGE_NEW_PHONE_NUMBER_ACCOUNT:
      return {
        ...state,
        change_phone_number: {
          ...state.change_phone_number,
          new: {
            ...state.change_phone_number.new,
            request: {
              ...state.change_phone_number.new.request,
              loading: action.payload,
            },
          },
        },
      };
    case types.RESET_CHANGE_NEW_PHONE_NUMBER_ACCOUNT:
      return {
        ...state,
        change_phone_number: {
          ...state.change_phone_number,
          new: initialState.change_phone_number.new,
        },
      };
    case types.VERIFY_OTP_CHANGE_NEW_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        change_phone_number: {
          ...state.change_phone_number,
          new: {
            ...state.change_phone_number.new,
            verify: {
              ...state.change_phone_number.new.verify,
              ...action.payload,
            },
          },
        },
      };
    case types.IS_LOADING_VERIFY_OTP_CHANGE_NEW_PHONE_NUMBER:
      return {
        ...state,
        change_phone_number: {
          ...state.change_phone_number,
          new: {
            ...state.change_phone_number.new,
            verify: {
              ...state.change_phone_number.new.verify,
              loading: action.payload,
            },
          },
        },
      };
    case types.RESET_VERIFY_OTP_CHANGE_NEW_PHONE_NUMBER:
      return {
        ...state,
        change_phone_number: {
          ...state.change_phone_number,
          new: {
            ...state.change_phone_number.new,
            verify: state.change_phone_number.new.verify,
          },
        },
      };
    case types.RESET_CHANGE_NEW_PHONE_NUMBER:
      return {
        ...state,
        change_phone_number: initialState.change_phone_number,
      };
    //verify phone number
    case types.REQUEST_OTP_VERIFY_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        verify_phone_number: {
          ...state.verify_phone_number,
          request: {
            ...state.verify_phone_number.request,
            ...action.payload,
          },
        },
      };
    case types.IS_LOADING_REQUEST_OTP_VERIFY_PHONE_NUMBER:
      return {
        ...state,
        verify_phone_number: {
          ...state.verify_phone_number,
          request: {
            ...state.verify_phone_number.request,
            loading: action.payload,
          },
        },
      };
    case types.RESET_REQUEST_OTP_VERIFY_PHONE_NUMBER:
      return {
        ...state.verify_phone_number,
        verify_phone_number: {
          ...state.verify_phone_number,
          request: state.verify_phone_number.request,
        },
      };

    case types.VERIFY_OTP_VERIFY_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        verify_phone_number: {
          ...state.verify_phone_number,
          verify: {
            ...state.verify_phone_number.verify,
            ...action.payload,
          },
        },
        detail: {
          ...state.detail,
          phone_number_verified: action.payload.success,
        },
      };
    case types.IS_LOADING_VERIFY_OTP_VERIFY_PHONE_NUMBER:
      return {
        ...state,
        verify_phone_number: {
          ...state.verify_phone_number,
          verify: {
            ...state.verify_phone_number.verify,
            loading: action.payload,
          },
        },
      };
    case types.RESET_VERIFY_OTP_VERIFY_PHONE_NUMBER:
      return {
        ...state,
        verify_phone_number: {
          ...state.verify_phone_number,
          verify: state.verify_phone_number.verify,
        },
      };

    default:
      return state;
  }
};

export const getProfileAccount = (payload) => ({
  type: types.GET_PROFILE_ACCOUNT,
  payload,
});
export const getProfileAccountSuccess = (payload) => ({
  type: types.GET_PROFILE_ACCOUNT_SUCCESS,
  payload,
});
export const setIsLoadingGetProfileAccount = (payload) => ({
  type: types.IS_LOADING_GET_PROFILE_ACCOUNT,
  payload,
});
export const resetGetProfileAccount = (payload) => ({
  type: types.RESET_GET_PROFILE_ACCOUNT,
  payload,
});

export const checkPasswordAccount = (payload) => ({
  type: types.CHECK_PASSWORD_ACCOUNT,
  payload,
});
export const checkPasswordAccountSuccess = (payload) => ({
  type: types.CHECK_PASSWORD_ACCOUNT_SUCCESS,
  payload,
});
export const setIsLoadingCheckPasswordAccount = (payload) => ({
  type: types.IS_LOADING_CHECK_PASSWORD_ACCOUNT,
  payload,
});
export const resetCheckPasswordAccount = (payload) => ({
  type: types.RESET_CHECK_PASSWORD_ACCOUNT,
  payload,
});
//edit email
export const requestOTPWithEmailToChangeEmailAccount = (payload) => ({
  type: types.REQUEST_OTP_WITH_EMAIL_TO_CHANGE_EMAIL_ACCOUNT,
  payload,
});
export const requestOTPWithPhoneNumberToChangeEmailAccount = (payload) => ({
  type: types.REQUEST_OTP_WITH_PHONE_NUMBER_TO_CHANGE_EMAIL_ACCOUNT,
  payload,
});
export const requestOTPToChangeEmailAccountSuccess = (payload) => ({
  type: types.REQUEST_OTP_TO_CHANGE_EMAIL_ACCOUNT_SUCCESS,
  payload,
});
export const setIsLoadingRequestOTPToChangeEmailAccount = (payload) => ({
  type: types.IS_LOADING_REQUEST_OTP_TO_CHANGE_EMAIL_ACCOUNT,
  payload,
});
export const resetRequestOTPToChangeEmailAccount = (payload) => ({
  type: types.RESET_REQUEST_OTP_TO_CHANGE_EMAIL_ACCOUNT,
  payload,
});
export const verifyOTPToChangeEmailAccount = (payload) => ({
  type: types.VERIFY_OTP_TO_CHANGE_EMAIL_ACCOUNT,
  payload,
});
export const verifyOTPToChangeEmailAccountSuccess = (payload) => ({
  type: types.VERIFY_OTP_TO_CHANGE_EMAIL_ACCOUNT_SUCCESS,
  payload,
});
export const setIsLoadingVerifyOTPToChangeEmailAccount = (payload) => ({
  type: types.IS_LOADING_VERIFY_OTP_TO_CHANGE_EMAIL_ACCOUNT,
  payload,
});
export const resetVerifyOTPToChangeEmailAccount = (payload) => ({
  type: types.RESET_VERIFY_OTP_TO_CHANGE_EMAIL_ACCOUNT,
  payload,
});

export const changeNewEmail = (payload) => ({
  type: types.CHANGE_NEW_EMAIL_ACCOUNT,
  payload,
});
export const changeNewEmailSuccess = (payload) => ({
  type: types.CHANGE_NEW_EMAIL_ACCOUNT_SUCCESS,
  payload,
});
export const setIsLoadingChangeNewEmail = (payload) => ({
  type: types.IS_LOADING_CHANGE_NEW_EMAIL_ACCOUNT,
  payload,
});
export const resetChangeNewEmailAccount = (payload) => ({
  type: types.RESET_CHANGE_NEW_EMAIL_ACCOUNT,
  payload,
});

export const verifyOTPNewEmail = (payload) => ({
  type: types.VERIFY_OTP_CHANGE_NEW_EMAIL,
  payload,
});
export const verifyOTPNewEmailSuccess = (payload) => ({
  type: types.VERIFY_OTP_CHANGE_NEW_EMAIL_SUCCESS,
  payload,
});
export const setIsLoadingVerifyOTPNewEmail = (payload) => ({
  type: types.IS_LOADING_VERIFY_OTP_CHANGE_NEW_EMAIL,
  payload,
});
export const resetVerifyOTPNewEmailAccount = (payload) => ({
  type: types.RESET_VERIFY_OTP_CHANGE_NEW_EMAIL,
  payload,
});

export const resetChangeNewEmail = (payload) => ({
  type: types.RESET_CHANGE_NEW_EMAIL,
});
//verify email
export const requestOTPVerifyEmail = (payload) => ({
  type: types.REQUEST_OTP_VERIFY_EMAIL,
  payload,
});
export const requestOTPVerifyEmailSuccess = (payload) => ({
  type: types.REQUEST_OTP_VERIFY_EMAIL_SUCCESS,
  payload,
});
export const setIsLoadingRequestOTPVerifyEmail = (payload) => ({
  type: types.IS_LOADING_REQUEST_OTP_VERIFY_EMAIL,
  payload,
});
export const resetRequestOTPVerifyEmail = (payload) => ({
  type: types.RESET_REQUEST_OTP_VERIFY_EMAIL,
  payload,
});
export const verifyOTPVerifyEmail = (payload) => ({
  type: types.VERIFY_OTP_VERIFY_EMAIL,
  payload,
});
export const verifyOTPVerifyEmailSuccess = (payload) => ({
  type: types.VERIFY_OTP_VERIFY_EMAIL_SUCCESS,
  payload,
});
export const setIsLoadingVerifyOTPVerifyEmail = (payload) => ({
  type: types.IS_LOADING_VERIFY_OTP_VERIFY_EMAIL,
  payload,
});
export const resetVerifyOTPVerifyEmail = (payload) => ({
  type: types.RESET_VERIFY_OTP_VERIFY_EMAIL,
  payload,
});
//edit phone number
export const requestOTPWithEmailToChangePhoneNumberAccount = (payload) => ({
  type: types.REQUEST_OTP_WITH_EMAIL_TO_CHANGE_PHONE_NUMBER_ACCOUNT,
  payload,
});
export const requestOTPWithPhoneNumberToChangePhoneNumberAccount = (
  payload
) => ({
  type: types.REQUEST_OTP_WITH_PHONE_NUMBER_TO_CHANGE_PHONE_NUMBER_ACCOUNT,
  payload,
});
export const requestOTPToChangePhoneNumberAccountSuccess = (payload) => ({
  type: types.REQUEST_OTP_TO_CHANGE_PHONE_NUMBER_ACCOUNT_SUCCESS,
  payload,
});
export const setIsLoadingRequestOTPToChangePhoneNumberAccount = (payload) => ({
  type: types.IS_LOADING_REQUEST_OTP_TO_CHANGE_PHONE_NUMBER_ACCOUNT,
  payload,
});
export const resetRequestOTPToChangePhoneNumberAccount = (payload) => ({
  type: types.RESET_REQUEST_OTP_TO_CHANGE_PHONE_NUMBER_ACCOUNT,
  payload,
});
export const verifyOTPToChangePhoneNumberAccount = (payload) => ({
  type: types.VERIFY_OTP_TO_CHANGE_PHONE_NUMBER_ACCOUNT,
  payload,
});
export const verifyOTPToChangePhoneNumberAccountSuccess = (payload) => ({
  type: types.VERIFY_OTP_TO_CHANGE_PHONE_NUMBER_ACCOUNT_SUCCESS,
  payload,
});
export const setIsLoadingVerifyOTPToChangePhoneNumberAccount = (payload) => ({
  type: types.IS_LOADING_VERIFY_OTP_TO_CHANGE_PHONE_NUMBER_ACCOUNT,
  payload,
});
export const resetVerifyOTPToChangePhoneNumberAccount = (payload) => ({
  type: types.RESET_VERIFY_OTP_TO_CHANGE_PHONE_NUMBER_ACCOUNT,
  payload,
});

export const changeNewPhoneNumberAccount = (payload) => ({
  type: types.CHANGE_NEW_PHONE_NUMBER_ACCOUNT,
  payload,
});
export const changeNewPhoneNumberAccountSuccess = (payload) => ({
  type: types.CHANGE_NEW_PHONE_NUMBER_ACCOUNT_SUCCESS,
  payload,
});
export const setIsLoadingChangeNewPhoneNumber = (payload) => ({
  type: types.IS_LOADING_CHANGE_NEW_PHONE_NUMBER_ACCOUNT,
  payload,
});
export const resetChangeNewPhoneNumberAccount = (payload) => ({
  type: types.RESET_CHANGE_NEW_PHONE_NUMBER_ACCOUNT,
  payload,
});

export const verifyOTPNewPhoneNumber = (payload) => ({
  type: types.VERIFY_OTP_CHANGE_NEW_PHONE_NUMBER,
  payload,
});
export const verifyOTPNewPhoneNumberSuccess = (payload) => ({
  type: types.VERIFY_OTP_CHANGE_NEW_PHONE_NUMBER_SUCCESS,
  payload,
});
export const setIsLoadingVerifyOTPNewPhoneNumber = (payload) => ({
  type: types.IS_LOADING_VERIFY_OTP_CHANGE_NEW_PHONE_NUMBER,
  payload,
});
export const resetVerifyOTPNewPhoneNumber = (payload) => ({
  type: types.RESET_VERIFY_OTP_CHANGE_NEW_PHONE_NUMBER,
  payload,
});

export const resetChangeNewPhoneNumber = (payload) => ({
  type: types.RESET_CHANGE_NEW_PHONE_NUMBER,
});
//verify phone number
export const requestOTPVerifyPhoneNumber = (payload) => ({
  type: types.REQUEST_OTP_VERIFY_PHONE_NUMBER,
  payload,
});
export const requestOTPVerifyPhoneNumberSuccess = (payload) => ({
  type: types.REQUEST_OTP_VERIFY_PHONE_NUMBER_SUCCESS,
  payload,
});
export const setIsLoadingRequestOTPVerifyPhoneNumber = (payload) => ({
  type: types.IS_LOADING_REQUEST_OTP_VERIFY_PHONE_NUMBER,
  payload,
});
export const resetRequestOTPVerifyPhoneNumber = (payload) => ({
  type: types.RESET_REQUEST_OTP_VERIFY_PHONE_NUMBER,
  payload,
});
export const verifyOTPVerifyPhoneNumber = (payload) => ({
  type: types.VERIFY_OTP_VERIFY_PHONE_NUMBER,
  payload,
});
export const verifyOTPVerifyPhoneNumberSuccess = (payload) => ({
  type: types.VERIFY_OTP_VERIFY_PHONE_NUMBER_SUCCESS,
  payload,
});
export const setIsLoadingVerifyOTPVerifyPhoneNumber = (payload) => ({
  type: types.IS_LOADING_VERIFY_OTP_VERIFY_PHONE_NUMBER,
  payload,
});
export const resetVerifyOTPVerifyPhoneNumber = (payload) => ({
  type: types.RESET_VERIFY_OTP_VERIFY_PHONE_NUMBER,
  payload,
});
