import { put, call, takeLatest, select } from 'redux-saga/effects';
import Api from '../../services';
import types from './constants';
import * as actions from './reducer';

function* watchGetRequest(values){
  yield put(actions.isLoadingGetRequest(true))
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.rfq.getRequest, payload);
    const {data} = response;
    if(data.success){
      yield put(actions.getCountRequest(data.count))
      yield put(actions.getRequestSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getCountRequest(0))
    yield put(actions.getRequestSuccess(state.rfq.dataRequest));
  } finally {
    yield put(actions.isLoadingGetRequest(false))
  }
}

function* watchGetOffer(values){
  yield put(actions.isLoadingGetOffer(true))
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.rfq.getOffer, payload);
    const {data} = response;
    if(data.success){
      yield put(actions.getCountOffer(data.count))
      yield put(actions.getOfferSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getCountOffer(0))
    yield put(actions.getOfferSuccess(state.rfq.dataOffer));
  } finally {
    yield put(actions.isLoadingGetOffer(false))
  }
}

function* watchGetRFQ(values){
  yield put(actions.isLoadingGetRFQ(true))
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.rfq.getRFQ, payload);
    const {data} = response;
    if(data.success){
      yield put(actions.getCountRFQ(data.count))
      yield put(actions.getRFQSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getCountRFQ(0))
    yield put(actions.getRFQSuccess(state.rfq.dataRfq));
  } finally {
    yield put(actions.isLoadingGetRFQ(false))
  }
}

function* watchGetDetailRequest(values){
  yield put(actions.isLoadingGetDetailRequest(true))
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.rfq.getDetailRfq, payload);
    const {data} = response;
    if(data.success){
      yield put(actions.getDetailRequestSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getDetailRequestSuccess(state.rfq.detailRequest));
  } finally {
    yield put(actions.isLoadingGetDetailRequest(false))
  }
}

function* watchCreateRequest(values){
  yield put(actions.setIsLoadingCreateRequest(true))
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.rfq.createRequest, payload);
    const {data} = response;
    if(data.success){
      yield put(actions.createRequestSuccess(data));
      yield put(actions.setStatusCreateRequestSuccess(true));
    }
  } catch (e) {
    yield put(actions.getCountRequest(0))
    yield put(actions.getRequestSuccess(state.rfq.dataRequest));
  } finally {
    yield put(actions.setIsLoadingCreateRequest(false))
  }
}

function* watchCreateOffer(values){
  yield put(actions.setIsLoadingCreateOffer(true))
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.rfq.createOffer, payload);
    const {data} = response;
    if(data.success){
      yield put(actions.createOfferSuccess(data));
      yield put(actions.setStatusCreateOfferSuccess(true))
    }
  } catch (e) {
    yield put(actions.getCountOffer(0))
    yield put(actions.getOfferSuccess(state.rfq.dataOffer));
  } finally {
    yield put(actions.setIsLoadingCreateOffer(false))
  }
}

function* watchCreateComment(values){
  yield put(actions.setIsLoadingCreateComment(true))
  const { payload } = values;
  try {
    const response = yield call(Api.rfq.createComment, payload);
    const {data} = response;
    if(data.success){
      yield put(actions.createCommentSuccess(data));
      yield put(actions.setIsCommentSuccess(true));
    }
  } catch (e) {
    yield put(actions.setIsCommentSuccess(false));
  } finally {
    yield put(actions.setIsLoadingCreateComment(false))
  }
}

const rfqSagas = [
  takeLatest(types.GET_REQUEST, watchGetRequest),
  takeLatest(types.GET_OFFER, watchGetOffer),
  takeLatest(types.GET_RFQ, watchGetRFQ),
  takeLatest(types.GET_DETAIL_REQUEST, watchGetDetailRequest),
  takeLatest(types.CREATE_REQUEST, watchCreateRequest),
  takeLatest(types.CREATE_OFFER, watchCreateOffer),
  takeLatest(types.CREATE_COMMENT_OFFER, watchCreateComment)
]

export default rfqSagas;
