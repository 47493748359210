import { Api } from "./api";
// import config from '../config';

export default {
  getNotYetReviewed: (payload) =>
    Api.get("/v1/points/user/not-reviewed", {
      headers: { token: payload.token },
    }),
  getDoneReviewed: (payload) =>
    Api.get("/v1/points/user/reviewed", {
      headers: { token: payload.token },
    }),
  getDetailReviewed: (payload) =>
    Api.get(`/v1/points/${payload.point_id}`, {
      headers: { token: payload.token },
    }),
  getAllByStoreId: (payload) =>
    Api.get(`/v2/reviews/store/${payload.store_id}`, {
      params: payload.data,
    }),
  createReview: (payload) =>
    Api.post(`/v1/points/${payload.point_id}`, payload.data, {
      headers: { token: payload.token },
    }),
  updateReview: (payload) =>
    Api.put(`/v1/points/${payload.point_id}`, payload.data, {
      headers: { token: payload.token },
    }),
};
