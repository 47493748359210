import React, { useState, useEffect } from "react";
import {
  CCol,
  CRow,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CButton,
  CSpinner,
  CModal,
  CModalBody,
} from "@coreui/react";
import { useSelector, useDispatch } from "react-redux";

import * as actions from "./reducer";
import * as actionsCheckout from "../checkout/reducer";
import * as actionsList from "../account/pages/list/reducer";
import Card from "./common/card";
import CardCant from "./common/cardcant";
import { Checkbox } from "../common";

import { priceStyle } from "../../utils/modifiedText";
import { hostWeb } from "../../services/api";

export function _priceValue(product, qty) {
  if (product.GrocierPrices) {
    if (product.GrocierPrices.length > 0) {
      let grosirPrice = product.GrocierPrices.filter(
        (each) => each.min_order <= qty
      ).sort((a, b) => b.min_order - a.min_order);
      if (grosirPrice.length > 0) {
        return grosirPrice[0].price;
      }
      return product.price;
    } else {
      return product.price;
    }
  } else {
    return product.price;
  }
}
export function _cashbackValue(product, qty) {
  if (product.GrocierPrices) {
    if (product.GrocierPrices.length > 0) {
      let grosirPrice = product.GrocierPrices.filter(
        (each) => each.min_order <= qty
      ).sort((a, b) => b.min_order - a.min_order);
      if (grosirPrice.length > 0) {
        return grosirPrice[0].cashback;
      }
      return 0;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}
export function pushProductEmptyAndNotActive(carts, store_id, sender) {
  return carts
    .filter((dt) => dt.Product.store_id === store_id)
    .filter(
      (item) =>
        item.Product.status !== "active" ||
        (item.Product.Stocks.length > 0 &&
          item.Product.Stocks.find((val) => val.warehouse_id === sender.id)
            ?.qty <= 0)
    )
    .map((data) => {
      return {
        id: data.id,
        product: data.Product,
        product_id: data.Product.id,
        price: _priceValue(data.Product, data.quantity),
        cashback: _cashbackValue(data.Product, data.quantity),
        weight: data.Product.weight * data.quantity,
        quantity: data.quantity,
        description: data.description,
        stock: sender
          ? data.Product.Stocks.find((val) => val.warehouse_id === sender.id)
          : null,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
      };
    });
}
export function pushProduct(carts, store_id, sender) {
  return carts
    .filter((item) => item.Product.store_id === store_id)
    .filter((item) => item.Product.status === "active")
    .map((data) => {
      return {
        id: data.id,
        product: data.Product,
        product_id: data.Product.id,
        price: _priceValue(data.Product, data.quantity),
        cashback: _cashbackValue(data.Product, data.quantity),
        weight: data.Product.weight * data.quantity,
        quantity: data.quantity,
        description: data.description,
        stock: sender
          ? data.Product.Stocks.find((val) => val.warehouse_id === sender?.id)
          : null,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
      };
    });
}
export function formatCart(carts) {
  return carts
    .filter(
      (item) =>
        item.Product.status === "active" && item.Product.Store.status === "Open"
    )
    .filter(
      (
        (set) => (f) =>
          !set.has(f.Product.Store.id) && set.add(f.Product.Store.id)
      )(new Set())
    )
    .map((dt) => {
      let sender =
        dt.Product.Store.Warehouses.length > 0
          ? dt.Product.Store.Warehouses.find((item) => item.is_default)
          : {
              name: dt.Product.Store.name,
              phone_number: dt.Product.Store.User.phone_number,
              address: dt.Product.Store.address,
              subdistrict_id: dt.Product.Store.subdistrict_id,
              latitude: dt.Product.Store.latitude,
              longitude: dt.Product.Store.longitude,
              postal_code: dt.Product.Store.postal_code,
              Subdistrict: dt.Product.Store.Subdistrict,
            };
      return {
        store: dt.Product.Store.id,
        store_id: dt.Product.Store.id,
        Store: dt.Product.Store,
        name_store: dt.Product.Store.name,
        avatar_store: dt.Product.Store.avatar_img,
        subdomain_store: dt.Product.Store.subdomain_store,
        province_id: dt.Product.Store.province_id,
        city_id: dt.Product.Store.city_id,
        subdistrict_id: dt.Product.Store.subdistrict_id,
        is_premium: dt.Product.Store.is_premium,
        ServiceTypes: dt.Product.Store.ServiceTypes,
        City: dt.Product.Store.City,
        Province: dt.Product.Store.Province,
        Services: dt.Product.Store.Services,
        status: dt.Product.Store.status,
        Warehouses: dt.Product.Store.Warehouses,
        sender,
        products: pushProduct(carts, dt.Product.Store.id, sender),
      };
    });
}
export function formatCartCantProcess(carts) {
  return carts
    .filter(
      (item) =>
        (item.Product.Store.status === "Open" &&
          item.Product.status !== "active") ||
        item.Product.Store.status === "Close"
    )
    .filter(
      (
        (set) => (f) =>
          !set.has(f.Product.Store.id) && set.add(f.Product.Store.id)
      )(new Set())
    )
    .map((dt) => {
      let sender =
        dt.Product.Store.Warehouses.length > 0
          ? dt.Product.Store.Warehouses[0]
          : {
              name: dt.Product.Store.name,
              phone_number: dt.Product.Store.User.phone_number,
              address: dt.Product.Store.address,
              subdistrict_id: dt.Product.Store.subdistrict_id,
              latitude: dt.Product.Store.latitude,
              longitude: dt.Product.Store.longitude,
              postal_code: dt.Product.Store.postal_code,
              Subdistrict: dt.Product.Store.Subdistrict,
            };
      return {
        store: dt.Product.Store.id,
        store_id: dt.Product.Store.id,
        Store: dt.Product.Store,
        name_store: dt.Product.Store.name,
        avatar_store: dt.Product.Store.avatar_img,
        subdomain_store: dt.Product.Store.subdomain_store,
        province_id: dt.Product.Store.province_id,
        city_id: dt.Product.Store.city_id,
        subdistrict_id: dt.Product.Store.subdistrict_id,
        is_premium: dt.Product.Store.is_premium,
        City: dt.Product.Store.City,
        Province: dt.Product.Store.Province,
        Services: dt.Product.Store.Services,
        status: dt.Product.Store.status,
        Warehouses: dt.Product.Store.Warehouses,
        sender,
        products:
          dt.Product.Store.status === "Open"
            ? pushProductEmptyAndNotActive(carts, dt.Product.Store.id, sender)
            : pushProduct(carts, dt.Product.Store.id, sender),
      };
    });
}

const CartPage = ({ history, location }) => {
  const dispatch = useDispatch();
  const { logged_in, token } = useSelector((state) => state.login);
  const {
    carts,
    data,
    isLoadingGetCarts,
    isLoadingDeleteCart,
    deleted,
    edited,
  } = useSelector((state) => state.cart);

  // const [page] = useState(1);
  // const [limit] = useState(20);
  const [selected, setSelected] = useState([]);
  const [showDeleteMultiple, setShowDeleteMultiple] = useState(false);
  const [tmpOrder, setTmpOrder] = useState([]);

  const onChangedSelected = () => {
    let formated = formatCart(
      data.filter((item) => selected.includes(item.id))
    );
    let arrStore = formated.map((item) => item.store_id);
    let result = carts
      .filter((item) => arrStore.includes(item.store_id))
      .map((item) => ({
        ...item,
        products: item.products.filter((val) => selected.includes(val.id)),
      }));
    setTmpOrder(result);
  };
  const cartsCanProcess = carts
    .map((cart) => {
      const filteredProducts = cart.products.filter((product) => {
        return (
          product.product.status === "active" &&
          product.quantity >= product.product.min_order &&
          product.stock &&
          product.stock?.qty >= product.quantity &&
          product.quantity > 0
        );
      });
      return {
        ...cart,
        products: filteredProducts,
      };
    })
    .filter((item) => item.products.length > 0);
  const cartsCantProcess = carts.map((cart) => {
    const filteredProducts = cart.products.filter((product) => {
      return (
        product.product.status !== "active" ||
        product.quantity < product.product.min_order ||
        !product.stock ||
        (product.stock && product.stock?.qty < product.quantity) ||
        product.quantity <= 0
      );
    });
    return {
      ...cart,
      products: filteredProducts,
    };
  });
  const onRemoveMultiple = (multipleId) => {
    const qty = data
      .filter((item) => multipleId.includes(item.id))
      .reduce((acc, curr) => acc + curr.quantity, 0);
    if (logged_in) {
      let payload = { multipleId, qty, token };
      dispatch(actions.deleteMultipleCart(payload));
    }
  };
  const onRemoveAllCantProcess = () => {
    const arr = cartsCantProcess.map((item) =>
      item.products.map((dt) => dt.id)
    );
    const multipleId = [].concat.apply([], arr);
    onRemoveMultiple(multipleId);
  };
  const getData = () => {
    if (logged_in) {
      // let payload = { limit, page, token }
      dispatch(actionsCheckout.resetCheckout());
      dispatch(actions.getCart({ token }));
      dispatch(actionsList.getWishlist({ token }));
    } else {
      history.replace({
        pathname: "/login",
        search: `?p=${encodeURIComponent(hostWeb + location.pathname)}`,
      });
    }
  };

  const onChangeSelected = (value) => {
    let filtered = selected.filter((item) => item === value);
    let result =
      filtered.length > 0
        ? selected.filter((item) => item !== value)
        : [...selected, value];
    setSelected(result);
  };
  const onChangeMultipleSelected = (values, checked) => {
    let result = !checked
      ? selected.filter((item) => !values.includes(item))
      : [...selected, ...values].filter(
          (value, index, self) => self.indexOf(value) === index
        );
    setSelected(result);
  };
  const checkValuesCanCheckout = () => {
    let arr = cartsCanProcess.map((item) => item.products.map((val) => val));
    let con = [].concat(...arr);
    let result = con.filter(
      (item) =>
        item.quantity >= item.product.min_order &&
        item.stock &&
        item.quantity <= item.stock.qty &&
        item.quantity > 0
    );
    return result.map((item) => item.id);
  };
  const onSubmit = () => {
    if (logged_in) {
      let result = tmpOrder.filter((item) =>
        item.products.some((val) => val.quantity > 0)
      );
      dispatch(actionsCheckout.setCheckoutOrders(result));
      history.push("/cart/checkout");
    }
  };
  useEffect(() => {
    if (edited) {
      onChangedSelected();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited]);
  useEffect(() => {
    if (deleted) {
      setShowDeleteMultiple(false);
      setSelected([]);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleted]);
  useEffect(() => {
    onChangedSelected();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);
  useEffect(() => {
    getData();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (data.length === 0 && isLoadingGetCarts) {
    return (
      <div className="d-flex align-items-center justify-content-end p-2">
        <CSpinner color="primary" />
      </div>
    );
  }
  if (data.length === 0 && !isLoadingGetCarts) {
    return (
      <div className="d-flex flex-row align-items-center justify-content-center p-2">
        <div>
          <b>Keranjang Kosong</b>
        </div>
      </div>
    );
  }
  return (
    <div className="d-flex justify-content-center">
      <div style={{ width: 1024 }}>
        <div style={{ fontSize: 20 }}>
          <b>Keranjang</b>
        </div>
        <CRow className="mt-2">
          <CCol md="8">
            {cartsCanProcess.length > 0 && (
              <CCard className="my-1 mx-0">
                <CCardBody
                  className="p-2 d-flex align-items-center justify-content-between"
                  onClick={() => {}}
                >
                  <div
                    className="d-flex align-items-center justify-content-start"
                    style={{ cursor: "pointer" }}
                    onClick={(e) =>
                      onChangeMultipleSelected(
                        checkValuesCanCheckout(),
                        !(selected.length === checkValuesCanCheckout().length)
                      )
                    }
                  >
                    <Checkbox
                      onClick={(e) =>
                        onChangeMultipleSelected(
                          checkValuesCanCheckout(),
                          !(selected.length === checkValuesCanCheckout().length)
                        )
                      }
                      checked={
                        selected.length === checkValuesCanCheckout().length
                      }
                      style={{ fontSize: 18 }}
                    />
                    <div>
                      Pilih Semua{" "}
                      {selected.length > 0 && `(${selected.length})`}
                    </div>
                  </div>
                  {selected.length > 0 && (
                    <div
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowDeleteMultiple(true)}
                    >
                      <b>Hapus</b>
                    </div>
                  )}
                </CCardBody>
              </CCard>
            )}
            {cartsCanProcess.length > 0 &&
              cartsCanProcess.map((item, i) => (
                <Card
                  key={i}
                  detail={item}
                  selected={selected}
                  onChangeSelected={(value) => onChangeSelected(value)}
                  onChangeMultipleSelected={(arr, checked) =>
                    onChangeMultipleSelected(arr, checked)
                  }
                  sender={item.sender}
                />
              ))}
            {cartsCantProcess.length > 0 &&
              cartsCantProcess.reduce(
                (acc, curr) => acc + curr.products.length,
                0
              ) > 0 && (
                <>
                  <CCard className="my-1 mx-0">
                    <CCardBody
                      className="p-2 d-flex align-items-center justify-content-between"
                      onClick={() => {}}
                    >
                      <div>
                        <b>
                          Tidak dapat diproses (
                          {cartsCantProcess.reduce(
                            (acc, curr) => acc + curr.products.length,
                            0
                          )}
                          )
                        </b>
                      </div>
                      <div
                        style={{ cursor: "pointer", color: "#fa541c" }}
                        onClick={onRemoveAllCantProcess}
                      >
                        <b>Hapus</b>
                      </div>
                    </CCardBody>
                  </CCard>
                  {cartsCantProcess.map((item, i) => (
                    <div key={i + "empty"}>
                      <CardCant detail={item} sender={item.sender} />
                    </div>
                  ))}
                </>
              )}
          </CCol>
          <CCol md="4">
            <div style={{ position: "sticky", top: 140 }}>
              <CCard className="my-1">
                <CCardHeader className="py-2">
                  <div style={{ fontSize: 14 }}>
                    <b>Ringkasan Belanja</b>
                  </div>
                </CCardHeader>
                <CCardBody>
                  <div className="d-flex justify-content-between">
                    <div>
                      Total Harga{" "}
                      {selected.length > 0 &&
                        `(${data
                          .filter((item) => selected.includes(item.id))
                          .reduce(
                            (acc, item) => acc + item.quantity,
                            0
                          )} barang)`}
                    </div>
                    <div style={{ color: "#FA541C", fontSize: 14 }}>
                      <b>
                        Rp{" "}
                        {priceStyle(
                          data
                            .filter((item) => selected.includes(item.id))
                            .reduce(
                              (acc, item) =>
                                acc +
                                _priceValue(item.Product, item.quantity) *
                                  item.quantity,
                              0
                            )
                        )}
                      </b>
                    </div>
                  </div>
                </CCardBody>
                <CCardFooter
                  style={{
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                  }}
                >
                  <CButton
                    color="primary"
                    className="w-100"
                    onClick={onSubmit}
                    disabled={
                      selected.length === 0 ||
                      data
                        .filter((item) => selected.includes(item.id))
                        .reduce((acc, item) => acc + item.quantity, 0) === 0
                    }
                  >
                    <b>Beli</b>
                  </CButton>
                </CCardFooter>
              </CCard>
            </div>
          </CCol>
        </CRow>
      </div>
      <CModal
        show={showDeleteMultiple}
        size="sm"
        onClose={() => setShowDeleteMultiple(false)}
        centered
        backdrop
      >
        <CModalBody className="text-center">
          <div style={{ fontSize: 16 }} className="mb-2">
            <b>Hapus {selected.length} barang ?</b>
          </div>
          <div className="mb-3">
            Barang yang dipilih akan dihapus dari keranjang.
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <CButton
              onClick={() => setShowDeleteMultiple(false)}
              className="mr-2 px-3"
              size="sm"
            >
              <b>Batal</b>
            </CButton>
            <CButton
              color="primary"
              onClick={() => onRemoveMultiple(selected)}
              className="px-3"
              size="sm"
              disabled={isLoadingDeleteCart}
            >
              {isLoadingDeleteCart && <CSpinner size="sm" />} <b>Hapus</b>
            </CButton>
          </div>
        </CModalBody>
      </CModal>
    </div>
  );
};

export default CartPage;
