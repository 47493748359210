import types from './constants';

const initialState = {
  check: {
    data:{},
    loading: false,
    success:false,
    done: false,
    message:''
  },
  request: {
    verify_selected: "",
    success: false,
    loading: false
  },
  verify: {
    verified:false,
    token:"",
    success:false,
    message:'',
    loading: false
  },
  change: {
    data:{},
    success: false,
    message: '',
    loading: false
  },
  detail:{
    data:{},
    success: false,
    loading: false
  }
}

export default (state=initialState, action) => {
  switch(action.type){
    case types.GET_DETAIL_PROFILE_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action.payload
        }
      }
    case types.IS_LOADING_GET_DETAIL_PROFILE_RESET_PASSWORD:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: action.payload
        }
      }
    case types.RESET_DETAIL_PROFILE_RESET_PASSWORD:
      return {...state, detail: initialState.detail}

    case types.CHECK_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        check: {
          ...state.check,
          ...action.payload
        }
      }
    case types.LOADING_CHECK_RESET_PASSWORD:
      return {
        ...state,
        check: {
          ...state.check,
          loading: action.payload
        }
      }
    case types.DONE_CHECK_RESET_PASSWORD:
      return {
        ...state,
        check: {
          ...state.check,
          done: action.payload
        }
      }
    case types.RESET_CHECK_RESET_PASSWORD:
      return {...state, check: initialState.check}

    case types.REQUEST_OTP_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        request: {
          ...state.request,
          ...action.payload
        }
      }
    case types.IS_LOADING_REQUEST_OTP_RESET_PASSWORD:
      return {
        ...state,
        request:{
          ...state.request,
          loading: action.payload
        }
      }
    case types.RESET_REQUEST_OTP_RESET_PASSWORD:
      return {...state, request: initialState.request}

    case types.VERIFY_OTP_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        verify: {
          ...state.verify,
          ...action.payload
        }
      }
    case types.LOADING_VERIFY_OTP_RESET_PASSWORD:
      return {
        ...state,
        verify: {
          ...state.verify,
          loading: action.payload
        }
      }
    case types.RESET_VERIFY_OTP_RESET_PASSWORD:
      return {...state, verify: initialState.verify}

    case types.CHANGE_PASSWORD_ON_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        change: {
          ...state.change,
          ...action.payload,
        }
      }
    case types.LOADING_CHANGE_PASSWORD_ON_RESET_PASSWORD:
      return {
        ...state,
        change:{
          ...state.change,
          loading: action.payload
        }
      }
    case types.RESET_CHANGE_PASSWORD_ON_RESET_PASSWORD:
      return {...state, change: initialState.change}

    case types.DEFAULT_RESET_PASSWORD:
      return initialState;
    default:
      return state;
  }
}

export const getDetail = payload => ({
  type: types.GET_DETAIL_PROFILE_RESET_PASSWORD,
  payload
})
export const getDetailSuccess = payload => ({
  type: types.GET_DETAIL_PROFILE_RESET_PASSWORD_SUCCESS,
  payload
})
export const setIsLoadingGetDetail = payload => ({
  type: types.IS_LOADING_GET_DETAIL_PROFILE_RESET_PASSWORD,
  payload
})
export const resetDetailProfileResetPassword = payload => ({
  type: types.RESET_DETAIL_PROFILE_RESET_PASSWORD,
  payload
})


export const checkAccountResetPassword = payload => ({
  type: types.CHECK_ACCOUNT_RESET_PASSWORD,
  payload
})
export const checkAccountResetPasswordSuccess = payload => ({
  type: types.CHECK_RESET_PASSWORD_SUCCESS,
  payload
})
export const setIsLoadingCheckResetPassword = payload => ({
  type: types.LOADING_CHECK_RESET_PASSWORD,
  payload
})
export const setDoneCheckResetPassword = payload => ({
  type: types.DONE_CHECK_RESET_PASSWORD,
  payload
})
export const resetCheckResetPassword = payload => ({
  type: types.RESET_CHECK_RESET_PASSWORD,
  payload
})

export const requestOTPResetPasswordEmail = payload => ({
  type: types.REQUEST_OTP_RESET_PASSWORD_EMAIL,
  payload
})
export const requestOTPResetPasswordPhoneNumber = payload => ({
  type: types.REQUEST_OTP_RESET_PASSWORD_PHONE_NUMBER,
  payload
})
export const requestOTPResetPasswordSuccess = payload => ({
  type: types.REQUEST_OTP_RESET_PASSWORD_SUCCESS,
  payload
})
export const setIsLoadingRequestOTPResetPassword = payload => ({
  type: types.IS_LOADING_REQUEST_OTP_RESET_PASSWORD,
  payload
})
export const resetRequestOTPResetPassword = payload => ({
  type: types.RESET_REQUEST_OTP_RESET_PASSWORD,
  payload
})

export const verifyOTPResetPassword = payload => ({
  type: types.VERIFY_OTP_RESET_PASSWORD,
  payload
})
export const verifyOTPResetPasswordSuccess = payload => ({
  type: types.VERIFY_OTP_RESET_PASSWORD_SUCCESS,
  payload
})
export const setIsLoadingVerifyOTPResetPassword = payload => ({
  type: types.LOADING_VERIFY_OTP_RESET_PASSWORD,
  payload
})
export const resetVerifyOTPResetPassword = payload => ({
  type: types.RESET_VERIFY_OTP_RESET_PASSWORD,
  payload
})

export const changePassword = payload => ({
  type: types.CHANGE_PASSWORD_ON_RESET_PASSWORD,
  payload
})
export const changePasswordSuccess = payload => ({
  type: types.CHANGE_PASSWORD_ON_RESET_PASSWORD_SUCCESS,
  payload
})
export const setIsLoadingChangePassword = payload => ({
  type: types.LOADING_CHANGE_PASSWORD_ON_RESET_PASSWORD,
  payload
})
export const resetChangePassword = payload => ({
  type: types.RESET_CHANGE_PASSWORD_ON_RESET_PASSWORD,
  payload
})

export const defaultResetPassword = payload => ({
  type: types.DEFAULT_RESET_PASSWORD
})
