import { put, call, takeLatest } from 'redux-saga/effects';

import Api from '../../services';
import types from './constants';
import * as actions from './reducer';

function* watchCreateDonate(values){
  const { payload } = values;
  yield put(actions.setIsLoadingCreateDonate(true));
  try {
    const response = yield call(Api.donate.create, payload);
    const { data } = response;
    yield put(actions.createDonateSuccess(data));
  } catch (error) {
    yield put(actions.setIsLoadingCreateDonate(false));
  } finally {
    yield put(actions.setIsLoadingCreateDonate(false));
  }
}


const donateSagas = [
  takeLatest(types.CREATE_DONATION,watchCreateDonate),
];

export default donateSagas;
