import { put, call, takeLatest, select } from "redux-saga/effects";

import Api from "../../services";
import types from "./constants";
import * as actions from "./reducer";
import * as actionsCart from "../cartpage/reducer";

function* watchCheckLoginEmail(values) {
  const { payload } = values;
  let result = {
    email: "",
    success: false,
    registered: false,
  };
  yield put(actions.setIsLoadingCheckLogin(true));
  try {
    const response = yield call(Api.login.check.email, payload);
    const { data } = response;
    if (data.success) {
      result.email = payload.email;
      result.registered = data.success;
      result.success = data.success;
      yield put(actions.checkLoginEmailSuccess(result));
    }
  } catch (error) {
    result.email = payload.email;
    yield put(actions.checkLoginEmailSuccess(result));
    yield put(actions.setIsLoadingCheckLogin(false));
  } finally {
    yield put(actions.setIsLoadingCheckLogin(false));
  }
}
function* watchCheckLoginPhone(values) {
  const { payload } = values;
  let result = {
    number: "",
    success: false,
    registered: false,
  };
  yield put(actions.setIsLoadingCheckLogin(true));
  try {
    const response = yield call(Api.login.check.phone, payload);
    const { data } = response;
    if (data.success) {
      result.number = data.data.phone_number;
      result.registered = data.success;
      result.success = data.success;
      yield put(actions.checkLoginPhoneSuccess(result));
    }
  } catch (error) {
    result.number = payload.phone;
    yield put(actions.checkLoginPhoneSuccess(result));
    yield put(actions.setIsLoadingCheckLogin(false));
  } finally {
    yield put(actions.setIsLoadingCheckLogin(false));
  }
}
function* watchAuthenticatonUser(values) {
  const { payload } = values;
  yield put(actions.setIsLoadingLogin(true));
  try {
    const response = yield call(Api.authentication.sessions, payload);
    const { data } = response;
    if (data.success) {
      let result = {
        user: data,
        token: data.token,
        logged_in: true,
      };
      yield put(actions.getLoginSuccess(result));
      yield localStorage.setItem("key_x-log", data.token);
      yield localStorage.setItem(
        "lstlgn",
        JSON.stringify({ nm: data.name, pic: data.avatar_img })
      );
    }
  } catch (error) {
    yield put(actions.setMessageLogin(error.message));
  } finally {
    yield put(actions.setMessageLogin(""));
    yield put(actions.setIsLoadingLogin(false));
  }
}
function* watchLoginUser(values) {
  const { payload } = values;
  yield put(actions.setIsLoadingLogin(true));
  let result = {
    user: {},
    token: "",
    logged_in: false,
  };
  try {
    const response = yield call(Api.login.account, payload);
    const { data } = response;
    if (data.success) {
      result.user = data;
      result.token = data.token;
      result.logged_in = true;
      yield put(actions.getLoginSuccess(result));
      yield localStorage.setItem("key_x-log", data.token);
      yield localStorage.setItem(
        "lstlgn",
        JSON.stringify({ nm: data.name, pic: data.avatar_img })
      );
    }
  } catch (error) {
    if (error.response) {
      const response = error.response;
      yield put(actions.setMessageLogin(response.data.message));
    }
  } finally {
    yield put(actions.setIsLoadingLogin(false));
  }
}
function* watchGoogleSignIn(values) {
  yield put(actions.setIsLoadingLogin(true));
  const { payload } = values;
  let result = {
    user: {},
    token: "",
    logged_in: false,
  };
  try {
    const response = yield call(Api.login.google, payload);
    const { data } = response;
    if (data.success) {
      result.user = data.data;
      result.token = data.data.token;
      result.logged_in = true;
      yield localStorage.setItem("key_x-log", data.data.token);
      yield localStorage.setItem(
        "lstlgn",
        JSON.stringify({ nm: data.data.name, pic: data.data.avatar_img })
      );
      yield put(actions.getLoginSuccess(result));
    } else {
      yield put(actions.getLoginSuccess(result));
      yield put(actions.setMessageLogin(data.message));
    }
  } catch (error) {
    yield put(actions.setMessageLogin(error.message));
  } finally {
    yield put(actions.setMessageLogin(""));
    yield put(actions.setIsLoadingLogin(false));
  }
}
function* watchFacebookSignIn(values) {
  yield put(actions.setIsLoadingLogin(true));
  const { payload } = values;
  let result = {
    user: {},
    token: "",
    logged_in: false,
  };
  try {
    const response = yield call(Api.login.facebook, payload);
    const { data } = response;
    if (data.success) {
      result.user = data.data;
      result.token = data.data.token;
      result.logged_in = true;
      yield localStorage.setItem("key_x-log", data.data.token);
      yield localStorage.setItem(
        "lstlgn",
        JSON.stringify({ nm: data.data.name, pic: data.data.avatar_img })
      );
      yield put(actions.getLoginSuccess(result));
    } else {
      yield put(actions.getLoginSuccess(result));
      yield put(actions.setMessageLogin(data.message));
    }
  } catch (error) {
    yield put(actions.setMessageLogin(error.message));
  } finally {
    yield put(actions.setMessageLogin(""));
    yield put(actions.setIsLoadingLogin(false));
  }
}
function* watchLogout(values) {
  try {
    yield localStorage.clear();
    yield put(actions.getLogoutSuccess());
    yield put(actionsCart.getCountCartsSuccess(0));
  } catch (e) {}
}
function* watchGetAddress(values) {
  yield put(actions.setIsLoadingGetAddressForSentTo(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.address.getAll, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getAddressForSentToSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getAddressForSentToSuccess(state.login.address));
  } finally {
    yield put(actions.setIsLoadingGetAddressForSentTo(false));
  }
}
function* watchLastLogin(value) {
  const { payload } = value;
  yield put(actions.getLastLoginSuccess({}));
  yield put(actions.setIsLoadingLastLogin(true));
  try {
    const response = yield call(Api.login.last_in, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getLastLoginSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.setIsLoadingLastLogin(false));
    yield put(actions.getLastLoginSuccess({}));
  } finally {
    yield put(actions.setIsLoadingLastLogin(false));
  }
}
const saga = [
  takeLatest(types.AUTHENTICATION, watchAuthenticatonUser),
  takeLatest(types.LOGIN, watchLoginUser),
  takeLatest(types.GOOGLE_SIGN_IN, watchGoogleSignIn),
  takeLatest(types.FACEBOOK_SIGN_IN, watchFacebookSignIn),
  takeLatest(types.LOGOUT, watchLogout),
  takeLatest(types.CHECK_LOGIN_EMAIL, watchCheckLoginEmail),
  takeLatest(types.CHECK_LOGIN_PHONE, watchCheckLoginPhone),
  takeLatest(types.GET_ADDRESS_FOR_SENT_TO, watchGetAddress),
  takeLatest(types.LAST_LOGIN, watchLastLogin),
];

export default saga;
