export default {
  //create address
  CREATE_SHOP : 'CREATE_SHOP',
  CREATE_SHOP_SUCCESS : 'CREATE_SHOP_SUCCESS',
  IS_LOADING_CREATE_SHOP : 'IS_LOADING_CREATE_SHOP',
  SET_IS_SHOP_CREATED_SUCCESS : 'SET_IS_SHOP_CREATED_SUCCESS',

  CHECK_SUBDOMAIN_SHOP: 'CHECK_SUBDOMAIN_SHOP',
  CHECK_SUBDOMAIN_SHOP_SUCCESS: 'CHECK_SUBDOMAIN_SHOP_SUCCESS',
  IS_LOADING_CHECK_SUBDOMAIN_SHOP: 'IS_LOADING_CHECK_SUBDOMAIN_SHOP',

  CHECK_NAME_SHOP: 'CHECK_NAME_SHOP',
  CHECK_NAME_SHOP_SUCCESS: 'CHECK_NAME_SHOP_SUCCESS',
  IS_LOADING_CHECK_NAME_SHOP: 'IS_LOADING_CHECK_NAME_SHOP',
}
