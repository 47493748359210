import { Api } from "./api";

export default {
  check: (payload) => Api.post(`/v2/forgot-password/check`, payload),
  request: {
    email: (payload) =>
      Api.get(`/v2/forgot-password/otp/request/email`, {
        headers: {
          token: payload.token,
        },
      }),
    phone: (payload) =>
      Api.get(`/v2/forgot-password/otp/request/phone-number`, {
        headers: {
          token: payload.token,
        },
      }),
  },
  verify: (payload) =>
    Api.post(`/v2/forgot-password/otp/verify`, payload, {
      headers: {
        token: payload.token,
      },
    }),
  change: (payload) =>
    Api.put("/v2/forgot-password/change-password", payload, {
      headers: {
        token: payload.token,
      },
    }),
};
