export default {
  GET_SHOP_DETAILS:'GET_SHOP_DETAILS',
  GET_SHOP_DETAILS_SUCCESS:'GET_SHOP_DETAILS_SUCCESS',
  IS_LOADING_GET_SHOP_DETAILS:'IS_LOADING_GET_SHOP_DETAILS',

  GET_SHOP_DECORATION_HOME:'GET_SHOP_DECORATION_HOME',
  GET_SHOP_DECORATION_HOME_SUCCESS:'GET_SHOP_DECORATION_HOME_SUCCESS',
  IS_LOADING_GET_SHOP_DECORATION_HOME:'IS_LOADING_GET_SHOP_DECORATION_HOME',

  GET_MULTIPLE_PRODUCTS:'GET_MULTIPLE_PRODUCTS_FROM_SHOP_DETAIL',
  GET_MULTIPLE_PRODUCTS_SUCCESS:'GET_MULTIPLE_PRODUCTS_FROM_SHOP_DETAIL_SUCCESS',
  IS_LOADING_MULTIPLE_PRODUCTS:'IS_LOADING_GET_MULTIPLE_PRODUCTS_FROM_SHOP_DETAIL',
  RESET_MULTIPLE_PRODUCTS:'RESET_GET_MULTIPLE_PRODUCTS_FROM_SHOP_DETAIL',

  GET_SHOP_BANNER:'GET_SHOP_BANNER',
  GET_SHOP_BANNER_SUCCESS:'GET_SHOP_BANNER_SUCCESS',
  IS_LOADING_GET_SHOP_BANNER:'IS_LOADING_GET_SHOP_BANNER',

  GET_COUNT_FAVORITES:'GET_COUNT_FAVORITES',
  GET_COUNT_FAVORITES_SUCCESS:'GET_COUNT_FAVORITES_SUCCESS',
  IS_LOADING_GET_COUNT_FAVORITES:'IS_LOADING_GET_COUNT_FAVORITES',

  GET_COUNT_PRODUCTS_SOLD:'GET_COUNT_PRODUCTS_SOLD',
  GET_COUNT_PRODUCTS_SOLD_SUCCESS:'GET_COUNT_PRODUCTS_SOLD_SUCCESS',
  IS_LOADING_GET_COUNT_PRODUCTS_SOLD:'IS_LOADING_GET_COUNT_PRODUCTS_SOLD',

  GET_SHOWCASES:'GET_SHOWCASES',
  GET_SHOWCASES_SUCCESS:'GET_SHOWCASES_SUCCESS',
  IS_LOADING_SHOWCASES:'IS_LOADING_SHOWCASES',

  GET_PRODUCTS:'GET_PRODUCTS_SHOP',
  GET_PRODUCTS_SUCCESS:'GET_PRODUCTS_SHOP_SUCCESS',
  IS_LOADING_PRODUCTS:'IS_LOADING_PRODUCTS_SHOP',
  RESET_PRODUCTS:'RESET_PRODUCTS_SHOP',

  GET_DISCUSSIONS:'GET_DISCUSSIONS',
  GET_DISCUSSIONS_SUCCESS:'GET_DISCUSSIONS_SUCCESS',
  GET_COUNT_DISCUSSION:'GET_COUNT_DISCUSSION',
  IS_LOADING_GET_DISCUSSIONS:'IS_LOADING_GET_DISCUSSIONS',
  RESET_DISCUSSIONS:'RESET_DISCUSSIONS',

  GET_REVIEWS:'GET_REVIEWS',
  GET_REVIEWS_SUCCESS:'GET_REVIEWS_SUCCESS',
  GET_COUNT_REVIEW:'GET_COUNT_REVIEW',
  IS_LOADING_GET_REVIEWS:'IS_LOADING_GET_REVIEWS',
  RESET_REVIEWS:'RESET_REVIEWS',

  CREATE_FOLLOWERS : 'CREATE_FOLLOWERS',
  CREATE_FOLLOWERS_SUCCESS : 'CREATE_FOLLOWERS_SUCCESS',
  DELETE_FOLLOWERS : 'DELETE_FOLLOWERS',
  DELETE_FOLLOWERS_SUCCESS : 'DELETE_FOLLOWERS_SUCCESS',
  IS_LOADING_ACTION_FOLLOWERS : 'IS_LOADING_ACTION_FOLLOWERS',

  RESET_SHOP_DETAILS:'RESET_SHOP_DETAILS',
  SET_SHOW_CHATS_FROM_SHOP:'SET_SHOW_CHATS_FROM_SHOP',

  GET_RATINGS_REVIEW: 'GET_RATINGS_REVIEW',
  GET_RATINGS_REVIEW_SUCCESS: 'GET_RATINGS_REVIEW_SUCCESS',
  IS_LOADING_GET_RATINGS_REVIEW: 'IS_LOADING_GET_RATINGS_REVIEW',
}
