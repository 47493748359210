import { Api } from "./api";

export default {
  get: (payload) =>
    Api.get("/v2/carts", {
      headers: { token: payload.token },
    }),
  create: (payload) =>
    Api.post(`/v1/carts`, payload.data, {
      headers: { token: payload.token },
    }),
  editById: (payload) =>
    Api.put(
      `/v1/carts/${payload.id}`,
      {
        quantity: payload.quantity,
        description: payload.description,
      },
      {
        headers: { token: payload.token },
      }
    ),
  deleteById: (payload) =>
    Api.delete(`/v1/carts/id/${payload.id}`, {
      headers: { token: payload.token },
    }),
  deleteByUserId: (payload) =>
    Api.delete(`/v1/carts/user`, {
      headers: { token: payload.token },
    }),
  deleteByMultipleId: (payload) =>
    Api.delete(`/v2/carts/multiple-id`, {
      headers: { token: payload.token },
      params: {
        multiple_id: payload.multipleId,
      },
    }),
  checkCost: (payload) =>
    Api.post(`/v1/check-shipping-cost`, payload.data, {
      headers: {
        "content-type": "application/json",
        token: payload.token,
      },
    }),
};
