import React, { useEffect, useState } from "react";
import {
  CCol,
  CRow,
  CCard,
  CCardHeader,
  CCardBody,
  CInput,
  CImg,
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CModal,
  CModalBody,
  CButton,
  CBadge,
} from "@coreui/react";
import {
  HeartSharp,
  HeartOutline,
  AddCircleOutline,
  RemoveCircleOutline,
  TrashOutline,
  EllipsisHorizontal,
} from "react-ionicons";
import { IoClose } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";

import { formatPrice } from "../../../helpers/price";
import { defaultImg } from "../../../helpers/product";
import * as actionsList from "../../account/pages/list/reducer";
import * as actions from "../reducer";
import { Checkbox } from "../../common";

export const Action = ({ item }) => {
  const dispatch = useDispatch();
  const { logged_in, token } = useSelector((state) => state.login);
  const { wishlist } = useSelector((state) => state.list);

  const [addedWishlist, setAddedWishlist] = useState(false);
  const moveToWislist = () => {
    if (logged_in) {
      const payload = {
        product_id: item.product.id,
        product: item.product,
        id: item.id,
        qty: item.quantity,
        token,
      };
      dispatch(actionsList.createWishlist(payload));
      dispatch(actions.deleteCart(payload));
    }
  };
  const removeItem = (value) => {
    const payload = {
      id: item.id,
      qty: item.quantity,
      token,
    };
    dispatch(actions.deleteCart(payload));
  };
  useEffect(() => {
    setAddedWishlist(
      wishlist.some((val) => val.product_id === item.product_id)
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishlist]);
  return (
    <CDropdown className="m-0">
      <CDropdownToggle caret={false} size="sm">
        <EllipsisHorizontal height="20px" width="20px" />
      </CDropdownToggle>
      <CDropdownMenu>
        {addedWishlist ? (
          <CDropdownItem className="py-1 px-2">
            <div className="d-flex align-items-center justify-content-start">
              <HeartSharp
                style={{ cursor: "pointer" }}
                height="16px"
                width="16px"
                color="#4F5D73"
              />
              <div className="ml-2">Sudah ada di Wishlist</div>
            </div>
          </CDropdownItem>
        ) : (
          <CDropdownItem className="py-1 px-2" onClick={moveToWislist}>
            <div className="d-flex align-items-center justify-content-start">
              <HeartOutline
                style={{ cursor: "pointer" }}
                height="16px"
                width="16px"
                color="#4F5D73"
              />
              <div className="ml-2">Pindah ke Wishlist</div>
            </div>
          </CDropdownItem>
        )}

        <CDropdownDivider className="my-1" />
        <CDropdownItem onClick={removeItem} className="py-1 px-2">
          <div className="d-flex align-items-center justify-content-start">
            <TrashOutline height="16px" width="16px" color="#4F5D73" />
            <div className="ml-2">Hapus</div>
          </div>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};
export const ActionQuantity = ({ item, disabled, sender }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.login);
  const [qty, setQty] = useState(0);

  const onChangeQty = (text) => {
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if ((!Number.isNaN(text) && reg.test(text)) || text === "") {
      setQty(text);
    }
  };
  const substractQty = async () => {
    try {
      if (qty > item.product.min_order) {
        const payload = {
          store: item.product.store_id,
          id: item.id,
          quantity: item.quantity - 1,
          description: item.description,
          token,
        };
        await setQty(payload.quantity);
        await dispatch(actions.editCartById(payload));
      } else {
        const payload = {
          store: item.product.store_id,
          id: item.id,
          quantity: item.product.min_order,
          description: item.description,
          token,
        };
        await setQty(payload.quantity);
        await dispatch(actions.editCartById(payload));
      }
    } catch (e) {}
  };
  const addQty = async () => {
    try {
      let check = item.stock
        ? item.stock.qty > qty
        : qty >= item.product.min_order;
      if (check) {
        const payload = {
          store: item.product.store_id,
          id: item.id,
          quantity: item.quantity + 1,
          description: item.description,
          token,
        };
        await setQty(payload.quantity);
        await dispatch(actions.editCartById(payload));
      }
    } catch (e) {}
  };
  const onSubmitBlurQty = () => {
    const payload = {
      store: item.product.store_id,
      id: item.id,
      quantity: item.product.min_order,
      description: item.description,
      token,
    };
    if (item.stock) {
      if (item.stock.qty >= qty) {
        payload.quantity =
          qty < item.product.min_order ? item.product.min_order : qty;
      } else if (item.stock.qty === 0) {
        payload.quantity =
          qty < item.product.min_order ? item.product.min_order : qty;
      } else {
        payload.quantity = item.stock.qty;
      }
    } else {
      payload.quantity =
        qty < item.product.min_order ? item.product.min_order : qty;
    }
    dispatch(actions.editCartById(payload));
    setQty(payload.quantity);
  };
  const onSubmitQty = (e) => {
    if (e.key === "Enter") {
      onSubmitBlurQty();
    }
  };

  const onCheckQuantity = async () => {
    const payload = {
      store: item.product.store_id,
      id: item.id,
      quantity: item.quantity,
      description: item.description,
      token,
    };
    if (item.stock) {
      if (item.stock.qty <= 0) {
        payload.quantity = 0;
        await setQty(0);
      }
      if (
        item.stock.qty >= item.product.min_order &&
        item.stock.qty <= item.product.min_order
      ) {
        payload.quantity = item.product.min_order;
        await setQty(item.product.min_order);
      }

      if (item.quantity > item.stock.qty) {
        payload.quantity = item.stock.qty;
        await setQty(item.stock.qty);
      }
      if (item.quantity < item.product.min_order) {
        payload.quantity = item.product.min_order;
        await setQty(item.product.min_order);
      }
    }
    await dispatch(actions.editCartById(payload));
  };
  useEffect(() => {
    if (item.stock && item.stock.qty) {
      onCheckQuantity();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.stock]);
  useEffect(() => {
    setQty(item.quantity);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.quantity]);
  return (
    <div>
      <div
        style={{ width: "100%" }}
        className="d-flex justify-content-start align-items-center"
      >
        <div
          className="mr-2"
          style={{ cursor: "pointer" }}
          onClick={
            qty >= item.product.min_order || !disabled ? substractQty : () => {}
          }
        >
          <RemoveCircleOutline
            width="24px"
            height="24px"
            style={{
              color:
                qty <= item.product.min_order || disabled
                  ? "rgba(0, 0, 0, 0.12)"
                  : "#000",
            }}
          />
        </div>
        <div style={{ marginHorizontal: 5 }}>
          <CInput
            value={qty}
            style={{ textAlign: "center" }}
            onChange={(e) => onChangeQty(e.target.value)}
            onBlur={onSubmitBlurQty}
            onKeyDown={(e) => onSubmitQty(e)}
            readOnly={disabled}
          />
          {qty < item.product.min_order ? (
            <div className="text-danger mt-1" style={{ fontSize: 11 }}>
              <b>Min. Beli {item.product.min_order}</b>
            </div>
          ) : null}
        </div>
        <div
          className="ml-2"
          style={{ cursor: "pointer" }}
          onClick={
            (item.stock && item.stock.qty <= qty) || !disabled
              ? addQty
              : () => {}
          }
        >
          <AddCircleOutline
            width="24px"
            height="24px"
            style={{
              color:
                disabled || (item.stock && item.stock.qty <= qty)
                  ? "rgba(0, 0, 0, 0.12)"
                  : "#000",
            }}
          />
        </div>
      </div>
    </div>
  );
};
export const ModalSender = ({
  detail,
  show,
  setShow,
  onChangeSender,
  sender,
}) => {
  return (
    <>
      <CModal
        centered
        show={show}
        onClose={() => setShow(false)}
        closeOnBackdrop={false}
      >
        <CModalBody>
          <div className="d-flex justify-content-end">
            <IoClose
              style={{ fontSize: 22, cursor: "pointer" }}
              onClick={() => setShow(false)}
            />
          </div>
          <div
            className="d-flex justify-content-center mb-4"
            style={{ fontSize: 18 }}
          >
            <b>Pilih Alamat Gudang Pengiriman</b>
          </div>
          <div
            style={{ width: "100%", overflowY: "scroll", maxHeight: 400 }}
            className="scrollbar-hidden"
          >
            {detail.Warehouses &&
              detail.Warehouses.length > 0 &&
              detail.Warehouses.sort((a, b) =>
                a.is_default === b.is_default ? 1 : !a.is_default ? 0 : -1
              ).map((item) => (
                <CCard
                  key={item.id}
                  className={item.id === sender?.id ? "border-primary" : null}
                >
                  <CCardBody
                    className="py-2 px-3"
                    style={{ fontSize: 14, cursor: "pointer" }}
                    onClick={() => onChangeSender(item, detail.store)}
                  >
                    <div>
                      <span>
                        <b>{item.name}</b>
                      </span>{" "}
                      {item.is_default && (
                        <CBadge color="success" className="px-2">
                          Utama
                        </CBadge>
                      )}
                    </div>
                    <div>{item.address}</div>
                    <div>
                      {item.Subdistrict.name} {item.Subdistrict.City.type}{" "}
                      {item.Subdistrict.City.name}
                    </div>
                    <div>
                      {item.Subdistrict.City.Province.name} {item.postal_code}
                    </div>
                    <div>{item.phone_number}</div>
                  </CCardBody>
                </CCard>
              ))}
          </div>
        </CModalBody>
      </CModal>
    </>
  );
};

const ActionDescription = ({ item }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.login);

  const [note, setNote] = useState("");
  const [show, setShow] = useState(false);

  const updateDescription = (e) => {
    if (e.key === "Enter") {
      const payload = {
        store: item.product.store_id,
        id: item.id,
        quantity: item.quantity,
        description: note,
        token,
      };
      dispatch(actions.editCartById(payload));
      setShow(false);
    }
  };

  useEffect(() => {
    setNote(item.description);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.description]);
  return (
    <>
      {item.product.status === "active" && (
        <div className="my-2 d-flex justify-content-start align-items-center w-100">
          {show ? (
            <div className="w-100">
              <CInput
                size="sm"
                onKeyDown={(e) => updateDescription(e)}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              <div className="mb-1">
                <div style={{ fontSize: 10 }}>Tekan "Enter" untuk submit</div>
              </div>
            </div>
          ) : (
            <div>
              {item.description && item.description.length > 0 ? (
                <div className="d-flex">
                  <div className="mr-2" style={{ fontSize: 12 }}>
                    {note}
                  </div>
                  <div onClick={() => setShow(true)}>
                    <div
                      style={{
                        color: "#fa541c",
                        fontSize: 12,
                        cursor: "pointer",
                      }}
                    >
                      Ubah Catatan
                    </div>
                  </div>
                </div>
              ) : (
                <div onClick={() => setShow(true)}>
                  <div
                    style={{
                      color: "#fa541c",
                      fontSize: 12,
                      cursor: "pointer",
                    }}
                  >
                    Tulis Catatan
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};
const Card = ({
  detail,
  selected,
  onChangeSelected,
  onChangeMultipleSelected,
  sender,
}) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const onChangeSender = (obj, store_id) => {
    setShow(false);
    dispatch(
      actions.changeSenderStoreForCart({ sender: obj, store: store_id })
    );
  };
  useEffect(() => {
    if (sender) {
      onChangeMultipleSelected(
        detail.products.map((item) => item.id),
        false
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sender]);
  // if (detail.products.length === 0) return null;
  return (
    <>
      <CCard>
        <CCardHeader className="p-2 d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div className="d-flex justify-content-start align-items-center">
              <Checkbox
                disabled={
                  detail.products.filter(
                    (item) => (item.stock && item.stock.qty > 0) || !item.stock
                  ).length === 0 ||
                  detail.products.filter((item) => item.quantity === 0).length >
                    0
                }
                onClick={(e) =>
                  onChangeMultipleSelected(
                    detail.products
                      .filter(
                        (item) =>
                          (item.stock && item.stock.qty > 0) ||
                          !item.stock ||
                          item.quantity > 0
                      )
                      .map((item) => item.id),
                    !detail.products
                      .filter(
                        (item) =>
                          (item.stock && item.stock.qty > 0) ||
                          !item.stock ||
                          item.quantity > 0
                      )
                      .map((item) => item.id)
                      .every((a) => selected.includes(a))
                  )
                }
                checked={detail.products
                  .filter(
                    (item) =>
                      (item.stock && item.stock.qty > 0) ||
                      !item.stock ||
                      item.quantity > 0
                  )
                  .map((item) => item.id)
                  .every((a) => selected.includes(a))}
                style={{ fontSize: 18 }}
              />
              <div>
                <div>
                  <b>
                    <a
                      href={`/${detail.subdomain_store}`}
                      style={{ color: "#000" }}
                    >
                      {detail.name_store}
                    </a>
                  </b>
                </div>
                <div style={{ fontSize: 12 }} className="text-dark">
                  Dikirim dari: <b>{sender.name}</b>
                </div>
                <div style={{ fontSize: 12 }} className="text-dark">
                  {sender.Subdistrict.City.type} {sender.Subdistrict.City.name}{" "}
                  - {sender.Subdistrict.City.Province.name}
                </div>
              </div>
            </div>
          </div>
          {detail.Warehouses.length > 0 && (
            <CButton onClick={() => setShow(true)} className="border px-3">
              <b>Pilih Asal Pengiriman</b>
            </CButton>
          )}
        </CCardHeader>
        <CCardBody className="p-2">
          {detail.products
            .filter((item) => (item.stock && item.stock.qty > 0) || !item.stock)
            .map((item, i) => (
              <CRow key={i} className="my-2">
                <CCol md="8">
                  <div className="d-flex justify-content-start align-items-start w-100">
                    <Checkbox
                      disabled={
                        detail.Store.status === "Close" ||
                        item.product.is_empty === true ||
                        (item.stock && item.stock.qty <= 0) ||
                        item.quantity === 0
                      }
                      onClick={(e) => onChangeSelected(item.id)}
                      checked={
                        selected.filter((dt) => dt === item.id).length > 0
                      }
                      style={{ fontSize: 18 }}
                    />
                    <div className="w-100">
                      <div className="d-flex justify-content-start align-items-start">
                        <div className="mr-2">
                          <a
                            href={`/${detail.subdomain_store}/${item.product.subdomain_product}`}
                            className="text-dark"
                          >
                            <CImg
                              src={defaultImg(item.product)}
                              className="rounded"
                              style={{ width: 45, height: 45 }}
                            />
                          </a>
                        </div>
                        <div className="d-flex flex-column justify-content-between">
                          <div>
                            <a
                              href={`/${detail.subdomain_store}/${item.product.subdomain_product}`}
                              className="text-dark"
                            >
                              {item.product.name}
                            </a>
                          </div>
                          <div className="d-flex">
                            {item.product.price > item.price && (
                              <div
                                style={{
                                  textDecorationLine: "line-through",
                                  marginRight: 5,
                                }}
                              >
                                Rp {formatPrice(item.product.price)}
                              </div>
                            )}
                            <div style={{ fontWeight: "bold" }}>
                              Rp {formatPrice(item.price)}
                            </div>
                          </div>
                          {item.product.Stocks &&
                            item.product.Stocks.length > 0 &&
                            sender.id &&
                            item.product.Stocks.find(
                              (val) => val.warehouse_id === sender.id
                            ).qty < 50 && (
                              <div className="mt-1">
                                <div
                                  className="text-danger"
                                  style={{ fontSize: 11 }}
                                >
                                  <b>
                                    Sisa{" "}
                                    {
                                      item.product.Stocks.find(
                                        (val) => val.warehouse_id === sender.id
                                      ).qty
                                    }
                                  </b>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                      <ActionDescription item={item} />
                    </div>
                  </div>
                </CCol>
                <CCol md="3" className="d-flex justify-content-center">
                  <ActionQuantity
                    item={item}
                    sender={sender}
                    disabled={false}
                  />
                </CCol>
                <CCol md="1" className="d-flex flex-row justify-content-end">
                  <div
                    style={{ height: 35 }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Action item={item} />
                  </div>
                </CCol>
              </CRow>
            ))}
        </CCardBody>
      </CCard>
      <ModalSender
        detail={detail}
        show={show}
        setShow={setShow}
        onChangeSender={onChangeSender}
        sender={sender}
      />
    </>
  );
};

export default Card;
