import { Api } from './api';

export default {
  check: {
    email: payload => Api.post(`/v2/login/check-email`, payload),
    phone: payload => Api.post(`/v2/login/check-phone`, payload),
  },
  account: payload => Api.post('/v2/login', payload),
  facebook: payload => Api.post('/v2/login/facebook', payload),
  google: payload => Api.post('/v2/login/google', payload),
  last_in: payload => Api.get(`/v1/log-history/last/${payload.id}`),
}
