import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { PersistGate } from "redux-persist/integration/react";
import { HelmetProvider } from "react-helmet-async";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer } from "react-toastify";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { icons } from "./assets/icons";

import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { clientIdGoogle } from "./services/api";
import packageJson from "../package.json";

import "react-toastify/dist/ReactToastify.css";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";
import "animate.css/animate.compat.css";

console.disableYellowBox = true;
React.icons = icons;
ReactDOM.hydrate(
  <HelmetProvider>
    <GoogleOAuthProvider clientId={clientIdGoogle}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
          <ToastContainer hideProgressBar={true} />
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  </HelmetProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.checkAppVersion(packageJson.name, packageJson.version);
serviceWorker.unregister();
