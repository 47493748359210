import types from "./types";

const initialState = {
  topics: {
    data: [],
    detail: {},
    loading: false,
    l_detail: false,
  },
  chats: {
    data: [],
    loading: false,
  },
  contactus: {
    sent: false,
    loading: false,
  },
  created: {
    success: false,
    loading: false,
  },
  loading_create_new: false,
  loading_reply_chat: false,
  loading_update: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_HELP_DETAIL_TOPIC_SUCCESS:
      return {
        ...state,
        topics: {
          ...state.topics,
          detail: action.payload,
        },
      };
    case types.IS_LOADING_GET_HELP_DETAIL_TOPIC:
      return {
        ...state,
        topics: {
          ...state.topics,
          l_detail: action.payload,
        },
      };
    case types.GET_HELP_TOPICS_SUCCESS:
      return {
        ...state,
        topics: {
          ...state.topics,
          data: action.payload,
        },
      };
    case types.IS_LOADING_GET_HELP_TOPICS:
      return {
        ...state,
        topics: {
          ...state.topics,
          loading: action.payload,
        },
      };

    case types.GET_HELP_CHAT_TALK_SUCCESS:
      return {
        ...state,
        chats: {
          ...state.chats,
          data: action.payload,
        },
      };
    case types.IS_LOADING_GET_HELP_CHAT_TALK:
      return {
        ...state,
        chats: {
          ...state.chats,
          loading: action.payload,
        },
      };

    case types.CREATE_NEW_HELP_CHAT_TALK_SUCCESS:
      return {
        ...state,
        topics: {
          ...state.topics,
          data: [...state.topics.data, action.payload],
        },
      };
    case types.IS_LOADING_CREATE_NEW_HELP_CHAT_TALK:
      return {
        ...state,
        loading_create_new: action.payload,
        created: {
          ...state.create,
          loading: action.payload,
        },
      };

    case types.SET_CREATED_NEW_HELP_CHAT:
      return {
        ...state,
        created: {
          ...state.created,
          success: action.payload,
        },
      };
    case types.REPLY_HELP_CHAT_TALK_SUCCESS:
      return {
        ...state,
        chats: {
          ...state.chats,
          data: [...state.chats.data, action.payload],
        },
      };
    case types.IS_LOADING_REPLY_HELP_CHAT_TALK:
      return { ...state, loading_reply_chat: action.payload };

    case types.SET_IS_READ_CHAT_HELP_SUCCESS:
      return Object.assign({}, state, {
        topics: {
          ...state.topics,
          detail:
            state.topics.detail && state.topics.detail._id
              ? {
                  ...state.topics.detail,
                  help_chat_talks: state.topics.detail.help_chat_talks.map(
                    (item) =>
                      item.admin
                        ? {
                            ...item,
                            is_read: true,
                          }
                        : item
                  ),
                }
              : state.topics.detail,
          data: state.topics.data.map((item) =>
            item._id === action.payload.topic_id
              ? {
                  ...item,
                  help_chat_talks: item.help_chat_talks.map((chat) =>
                    chat.admin
                      ? {
                          ...chat,
                          is_read: true,
                        }
                      : chat
                  ),
                  notification: 0,
                }
              : item
          ),
        },
      });
    case types.IS_LOADING_SET_IS_READ_CHAT_HELP:
      return { ...state, loading_update: action.payload };
    case types.SEND_CONTACT_US_SUCCESS:
      return {
        ...state,
        contactus: {
          ...state.contactus,
          sent: action.payload,
        },
      };
    case types.IS_LOADING_SEND_CONTACT_US:
      return {
        ...state,
        contactus: {
          ...state.contactus,
          loading: action.payload,
        },
      };
    default:
      return state;
  }
};
export const sendContactUs = (payload) => ({
  type: types.SEND_CONTACT_US,
  payload,
});
export const sendContactUsSuccess = (payload) => ({
  type: types.SEND_CONTACT_US_SUCCESS,
  payload,
});
export const setIsLoadingSendContactUs = (payload) => ({
  type: types.IS_LOADING_SEND_CONTACT_US,
  payload,
});

export const getHelpDetailTopic = (payload) => ({
  type: types.GET_HELP_DETAIL_TOPIC,
  payload,
});
export const getHelpDetailTopicSuccess = (payload) => ({
  type: types.GET_HELP_DETAIL_TOPIC_SUCCESS,
  payload,
});
export const setIsLoadingGetHelpDetailTopic = (payload) => ({
  type: types.IS_LOADING_GET_HELP_DETAIL_TOPIC,
  payload,
});

export const getHelpTopics = (payload) => ({
  type: types.GET_HELP_TOPICS,
  payload,
});
export const getHelpTopicsSuccess = (payload) => ({
  type: types.GET_HELP_TOPICS_SUCCESS,
  payload,
});
export const setIsLoadingGetHelpTopics = (payload) => ({
  type: types.IS_LOADING_GET_HELP_TOPICS,
  payload,
});

export const getHelpChatTalk = (payload) => ({
  type: types.GET_HELP_CHAT_TALK,
  payload,
});
export const getHelpChatTalkSuccess = (payload) => ({
  type: types.GET_HELP_CHAT_TALK_SUCCESS,
  payload,
});
export const setIsLoadingGetHelpChatTalk = (payload) => ({
  type: types.IS_LOADING_GET_HELP_CHAT_TALK,
  payload,
});

export const createNewHelpChatTalk = (payload) => ({
  type: types.CREATE_NEW_HELP_CHAT_TALK,
  payload,
});
export const createNewHelpChatTalkSuccess = (payload) => ({
  type: types.CREATE_NEW_HELP_CHAT_TALK_SUCCESS,
  payload,
});
export const setIsLoadingCreateNewHelpChatTalk = (payload) => ({
  type: types.IS_LOADING_CREATE_NEW_HELP_CHAT_TALK,
  payload,
});
export const setCreatedSuccess = (payload) => ({
  type: types.SET_CREATED_NEW_HELP_CHAT,
  payload,
});
export const replyHelpChatTalk = (payload) => ({
  type: types.REPLY_HELP_CHAT_TALK,
  payload,
});
export const replyHelpChatTalkSuccess = (payload) => ({
  type: types.REPLY_HELP_CHAT_TALK_SUCCESS,
  payload,
});
export const setIsLoadingReplyHelpChatTalk = (payload) => ({
  type: types.IS_LOADING_REPLY_HELP_CHAT_TALK,
  payload,
});

export const setIsReadChatHelp = (payload) => ({
  type: types.SET_IS_READ_CHAT_HELP,
  payload,
});
export const setIsReadChatHelpSuccess = (payload) => ({
  type: types.SET_IS_READ_CHAT_HELP_SUCCESS,
  payload,
});
export const setIsLoadingSetIsReadChatHelp = (payload) => ({
  type: types.IS_LOADING_SET_IS_READ_CHAT_HELP,
  payload,
});
