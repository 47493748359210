const imageEmpty =
  "https://image-plasgos.s3-ap-southeast-1.amazonaws.com/no-image-thumb.png";

const defaultImg = (detail) => {
  let find = detail.ImageProducts.find((dt) => dt.is_default);
  let uri = find ? find.url : imageEmpty;
  return uri;
};
const defaultImg200 = (detail) => {
  let find = detail.ImageProducts.find((dt) => dt.is_default);
  let uri = find ? find.square_200_url : imageEmpty;
  return uri;
};
const defaultImg300 = (detail) => {
  let find = detail.ImageProducts.find((dt) => dt.is_default);
  let uri = find ? find.square_300_url : imageEmpty;
  return uri;
};
const checkCashback = (detail) => {
  let result = detail.GrocierPrices.reduce((acc, val) => acc + val.cashback, 0);
  return result > 0;
};
const totalPoint = (detail) => {
  let dataFilter = detail.Points.filter((dt) => dt.point !== null);
  let total = dataFilter.reduce(
    (prevValue, currentValue) => prevValue + currentValue.point,
    0
  );
  return dataFilter.length === 0 || total === 0
    ? 0
    : (Math.round((total / dataFilter.length) * 10) / 10).toFixed(1);
};
const totalReview = (detail) => {
  let dataFilter = detail.Points.filter((dt) => dt.point !== null);
  return dataFilter.length;
};

export {
  defaultImg,
  defaultImg200,
  defaultImg300,
  totalPoint,
  totalReview,
  checkCashback,
};
