import types from './constants';

const initialState = {
  products:[],
  countProducts:0,
  stores:[],
  countStores:0,
  isLoadingProducts:false,
  isLoadingStores:false,
  allLoadProduct:false,
  allLoadStore:false,
  options: 1,
  searchText: '',
  recent:[]
}

export default (state=initialState, action)=>{
  switch(action.type){
    case types.GET_SEARCH_PRODUCT_SUCCESS:
      return {...state, products:action.payload}
    case types.GET_COUNT_PRODUCT:
      return {...state, countProducts:action.payload}
    case types.GET_SEARCH_STORE_SUCCESS:
      return {...state, stores:action.payload}
    case types.GET_COUNT_STORE:
      return {...state, countStores:action.payload}
    case types.IS_LOADING_GET_SEARCH_PRODUCT:
      return {...state, isLoadingProducts:action.payload}
    case types.IS_LOADING_GET_SEARCH_STORE:
      return {...state, isLoadingStores:action.payload}
    case types.ALL_LOAD_PRODUCT:
      return {...state, allLoadProduct:action.payload}
    case types.ALL_LOAD_STORE:
      return {...state, allLoadStore:action.payload}
    case types.SET_OPTION:
      return {...state, options:action.payload}
    case types.CHANGE_SEARCH_NAME:
      return {...state, searchText:action.payload}
    case types.RESET_SEARCH_DATA:
      return {...state, searchText:initialState.searchText}
    case types.CLEAR_SEARCH_RESULT_STATE_AT_UNMOUNT:
      return { ...initialState };
    default:
      return {...state}
  }
}

//payload {search, page, limit}
export const getSearchProduct = payload => ({
  type:types.GET_SEARCH_PRODUCT,
  payload
})
export const getCountProduct = payload => ({
  type:types.GET_COUNT_PRODUCT,
  payload
})
export const getSearchProductSuccess = payload => ({
  type: types.GET_SEARCH_PRODUCT_SUCCESS,
  payload
})
export const isLoadingSearchProduct = payload => ({
  type: types.IS_LOADING_GET_SEARCH_PRODUCT,
  payload
})
export const allLoadedProduct = payload => ({
  type: types.ALL_LOAD_PRODUCT,
  payload
})

export const getSearchStore = payload => ({
  type: types.GET_SEARCH_STORE,
  payload
})
export const getCountStore = payload => ({
  type: types.GET_COUNT_STORE,
  payload
})
export const getSearchStoreSuccess = payload => ({
  type: types.GET_SEARCH_STORE_SUCCESS,
  payload
})
export const isLoadingSearchStore = payload => ({
  type: types.IS_LOADING_GET_SEARCH_STORE,
  payload
})
export const allLoadedStore = payload => ({
  type: types.ALL_LOAD_STORE,
  payload
})
//payload (number)
export const setOption = payload => ({
  type: types.SET_OPTION,
  payload
})
export const changeSearchName = payload => ({
  type: types.CHANGE_SEARCH_NAME,
  payload,
});
export const resetSearchData = () => ({
  type: types.RESET_SEARCH_DATA,
});
export const clearSearchResultStateAtUnmount = () => ({
  type: types.CLEAR_SEARCH_RESULT_STATE_AT_UNMOUNT,
});
