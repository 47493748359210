export default {
  CHECK_WITHDRAW: "check-withdraw",
  CHECK_WITHDRAW_SUCCESS: "check-withdraw-success",
  IS_LOADING_CHECK_WITHDRAW: "is-loading-check-withdraw",
  RESET_CHECK_WITHDRAW: "reset-check-withdraw",

  REQUEST_OTP_WITHDRAW: "request-otp-withdraw",
  REQUEST_OTP_WITHDRAW_SUCCESS: "request-otp-withdraw-success",
  IS_LOADING_REQUEST_OTP_WITHDRAW: "is-loading-request-otp-withdraw",
  RESET_REQUEST_OTP_WITHDRAW: "reset-request-otp-withdraw",

  VERIFY_OTP_WITHDRAW: "verify-otp-withdraw",
  VERIFY_OTP_WITHDRAW_SUCCESS: "verify-otp-withdraw-success",
  IS_LOADING_VERIFY_OTP_WITHDRAW: "is-loading-verify-otp-withdraw",
  RESET_VERIFY_OTP_WITHDRAW: "reset-verify-otp-withdraw",
};
