export default {
  GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',
  IS_LOADING_GET_ORDER: 'IS_LOADING_GET_ORDER',
  //
  GET_ALL_ORDERS: 'GET_ALL_ORDERS',
  IS_LOADING_GET_ALL_ORDERS: 'IS_LOADING_GET_ALL_ORDERS',

  GET_ORDER_WAITING_CONFIRMATION: 'GET_ORDER_WAITING_CONFIRMATION',
  IS_LOADING_GET_ORDER_WAITING_CONFIRMATION: 'IS_LOADING_GET_ORDER_WAITING_CONFIRMATION',

  GET_ORDER_PROCESSED: 'GET_ORDER_PROCESSED',
  IS_LOADING_GET_ORDER_PROCESSED: 'IS_LOADING_GET_ORDER_PROCESSED',

  GET_ORDER_SHIPPED: 'GET_ORDER_SHIPPED',
  IS_LOADING_GET_ORDER_SHIPPED: 'IS_LOADING_GET_ORDER_SHIPPED',

  GET_ORDER_ARRIVED: 'GET_ORDER_ARRIVED',
  IS_LOADING_GET_ORDER_ARRIVED: 'IS_LOADING_GET_ORDER_ARRIVED',

  GET_ORDER_DONE: 'GET_ORDER_DONE',
  IS_LOADING_GET_ORDER_DONE: 'IS_LOADING_GET_ORDER_DONE',

  GET_ORDER_ABORTED: 'GET_ORDER_ABORTED',
  IS_LOADING_GET_ORDER_ABORTED: 'IS_LOADING_GET_ORDER_ABORTED',

  GET_ORDER_COMPLAINT: 'GET_ORDER_COMPLAINT',
  IS_LOADING_GET_ORDER_COMPLAINT: 'IS_LOADING_GET_ORDER_COMPLAINT',
  //
  GET_ORDER_STATUS: 'GET_ORDER_STATUS',
  IS_LOADING_GET_ORDER_STATUS: 'IS_LOADING_GET_ORDER_STATUS',

  GET_ORDER_RECEIPT_CONFIRMATION: 'GET_ORDER_RECEIPT_CONFIRMATION',
  IS_LOADING_GET_ORDER_RECEIPT_CONFIRMATION: 'IS_LOADING_GET_ORDER_RECEIPT_CONFIRMATION',

  GET_NOTIFICATION_ORDER: 'GET_NOTIFICATION_ORDER',
  GET_NOTIFICATION_ORDER_SUCCESS: 'GET_NOTIFICATION_ORDER_SUCCESS',
  IS_LOADING_GET_NOTIFICATION_ORDER: 'IS_LOADING_GET_NOTIFICATION_ORDER',

  GET_DETAIL_ORDER: 'GET_DETAIL_ORDER',
  GET_DETAIL_ORDER_SUCCESS: 'GET_DETAIL_ORDER_SUCCESS',
  IS_LOADING_GET_DETAIL_ORDER: 'IS_LOADING_GET_DETAIL_ORDER',

  RESET_ORDER: 'RESET_ORDER',

  GET_CHECK_SHIPPING_RECEIPT: 'GET_CHECK_SHIPPING_RECEIPT',
  GET_CHECK_SHIPPING_RECEIPT_SUCCESS: 'GET_CHECK_SHIPPING_RECEIPT_SUCCESS',
  IS_LOADING_GET_CHECK_SHIPPING_RECEIPT: 'IS_LOADING_GET_CHECK_SHIPPING_RECEIPT',
  RESET_CHECK_SHIPPING_RECEIPT: 'RESET_CHECK_SHIPPING_RECEIPT',

  CHANGE_ORDER_TO_DELIVERED: 'CHANGE_ORDER_TO_DELIVERED',
  CHANGE_ORDER_TO_DELIVERED_SUCCESS: 'CHANGE_ORDER_TO_DELIVERED_SUCCESS',
  IS_LOADING_CHANGE_ORDER_TO_DELIVERED: 'IS_LOADING_CHANGE_ORDER_TO_DELIVERED',

  CHANGE_ORDER_TO_ACCEPTED: 'CHANGE_ORDER_TO_ACCEPTED',
  CHANGE_ORDER_TO_ACCEPTED_SUCCESS: 'CHANGE_ORDER_TO_ACCEPTED_SUCCESS',
  IS_LOADING_CHANGE_ORDER_TO_ACCEPTED: 'IS_LOADING_CHANGE_ORDER_TO_ACCEPTED',

  SET_COUNT_ORDERS: 'SET_COUNT_ORDERS',
  SET_SHOW_CHATS: 'SET_SHOW_CHATS',
  SET_SHOW_WAYBILL: 'SET_SHOW_WAYBILL',

  GET_WAYBILL: 'GET_WAYBILL',
  GET_WAYBILL_SUCCESS: 'GET_WAYBILL_SUCCESS',
  IS_LOADING_GET_WAYBILL: 'IS_LOADING_GET_WAYBILL',

  UPDATE_ORDER_SHIPPED : 'update-order-shipped',
  UPDATE_ORDER_ARRIVED : 'update-order-arrived',
  UPDATE_ORDER_DONE : 'update-order-done',
  UPDATE_ORDER_SUCCESS : 'update-order-success',
  IS_LOADING_UPDATE_ORDER : 'is-loading-update-order',
  RESET_UPDATE_ORDER : 'reset-update-done',

  ORDER_UPDATED: 'order-updated',

};
