import { Api } from './api';

export default {
  get: payload => Api.get(`/v1/showcases/store/${payload.store_id}`),
  add: payload => Api.post(`/v1/showcases/`, payload.data, {
    headers: { token: payload.token },
  }),
  update: payload => Api.put(`/v1/showcases/${payload.id}`, payload.data, {
    headers: { token: payload.token },
  }),
  delete: payload => Api.delete(`/v1/showcases/${payload.id}`, {
    headers: { token: payload.token },
  })
};
