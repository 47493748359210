import types from './constants';

const initialState = {
  detail:{},
  decoration:{},
  banners:[],
  countFollowers:0,
  dataFollowers:[],
  countProductSold:0,
  products:[],
  countProducts:0,
  showcases:[],
  reviews:[],
  countReview:0,
  productsSelected:[],
  rating: 0,
  isLoadingRating: false,
  isLoadingProductsSelected:false,
  isLoadingDecoration:false,
  isLoadingReviews:false,
  isLoadingShowcases:false,
  isLoadingProducts:false,
  isLoadingCountFollowers:false,
  isLoadingCountProductSold:false,
  isLoadingGetShopDetails:false,
  isLoadingBanner:false,
  chat: {
    show:false,
    store:null,
  },
}

export default(state = initialState, action) => {
  switch(action.type){
    case types.GET_SHOP_DETAILS_SUCCESS:
      return {...state, detail:action.payload}
    case types.IS_LOADING_GET_SHOP_DETAILS:
      return {...state, isLoadingGetShopDetails:action.payload}
    case types.GET_SHOP_DECORATION_HOME_SUCCESS:
      return {...state, decoration:action.payload}
    case types.IS_LOADING_GET_SHOP_DECORATION_HOME:
      return {...state, isLoadingDecoration:action.payload}
    case types.GET_MULTIPLE_PRODUCTS_SUCCESS:
      return {...state, productsSelected:action.payload}
    case types.IS_LOADING_MULTIPLE_PRODUCTS:
      return {...state, isLoadingProductsSelected:action.payload}
    case types.GET_SHOP_BANNER_SUCCESS:
      return {...state, banners:action.payload}
    case types.IS_LOADING_GET_SHOP_BANNER:
      return {...state, isLoadingBanner:action.payload}

    case types.GET_COUNT_FAVORITES_SUCCESS:
      return {...state, countFollowers:action.payload.count, dataFollowers:action.payload.data}
    case types.IS_LOADING_GET_COUNT_FAVORITES:
      return {...state, isLoadingCountFollowers:action.payload}

    case types.GET_COUNT_PRODUCTS_SOLD_SUCCESS:
      return {...state, countProductSold:action.payload}
    case types.IS_LOADING_GET_COUNT_PRODUCTS_SOLD:
      return {...state, isLoadingCountProductSold:action.payload}

    case types.GET_SHOWCASES_SUCCESS:
      return {...state, showcases:action.payload}
    case types.IS_LOADING_SHOWCASES:
      return {...state, isLoadingShowcases:action.payload}

    case types.GET_PRODUCTS_SUCCESS:
      return {...state, products: action.payload.data, countProducts:action.payload.count}
    case types.IS_LOADING_PRODUCTS:
      return {...state, isLoadingProducts:action.payload}
    case types.RESET_PRODUCTS:
      return {...state, products:initialState.products}

    case types.GET_REVIEWS_SUCCESS:
      return {...state, reviews:action.payload}
    case types.GET_COUNT_REVIEW:
      return {...state, countReview:action.payload}
    case types.IS_LOADING_GET_REVIEWS:
      return {...state, isLoadingReviews:action.payload}
    case types.RESET_REVIEWS:
      return {...state, reviews:initialState.reviews}
    case types.SET_SHOW_CHATS_FROM_SHOP:
      return {...state, chat: {show:action.payload.show, store:action.payload.store}}
    case types.GET_RATINGS_REVIEW_SUCCESS:
      return {...state, rating: action.payload}
    case types.IS_LOADING_GET_RATINGS_REVIEW:
      return {...state, isLoadingRating: action.payload}
    case types.RESET_SHOP_DETAILS:
      return initialState;
    default:
      return state
  }
}

export const getRatingsReview = payload => ({
  type: types.GET_RATINGS_REVIEW,
  payload
})
export const getRatingsReviewSuccess = payload => ({
  type: types.GET_RATINGS_REVIEW_SUCCESS,
  payload
})
export const setIsLoadingGetRatingsReview = payload => ({
  type: types.IS_LOADING_GET_RATINGS_REVIEW,
  payload
})

export const getShopDecorationHome = payload => ({
  type: types.GET_SHOP_DECORATION_HOME,
  payload
})
export const getShopDecorationHomeSuccess = payload => ({
  type: types.GET_SHOP_DECORATION_HOME_SUCCESS,
  payload
})
export const setIsLoadingGetShopDecorationHome = payload => ({
  type: types.IS_LOADING_GET_SHOP_DECORATION_HOME,
  payload
})
export const getMultipleProducts = payload => ({
  type: types.GET_MULTIPLE_PRODUCTS,
  payload
})
export const getMultipleProductsSuccess = payload => ({
  type: types.GET_MULTIPLE_PRODUCTS_SUCCESS,
  payload
})
export const setIsLoadingMultipleProducts = payload => ({
  type: types.IS_LOADING_MULTIPLE_PRODUCTS,
  payload
})
export const resetMultipleProducts = payload => ({
  type: types.RESET_MULTIPLE_PRODUCTS
})
export const getShopBanner = payload => ({
  type: types.GET_SHOP_BANNER,
  payload
})
export const getShopBannerSuccess = payload => ({
  type: types.GET_SHOP_BANNER_SUCCESS,
  payload
})
export const setIsLoadingGetShopBanner = payload => ({
  type: types.IS_LOADING_GET_SHOP_BANNER,
  payload
})

export const getShopDetails = payload => ({
  type: types.GET_SHOP_DETAILS,
  payload
})
export const getShopDetailSuccess = payload => ({
  type: types.GET_SHOP_DETAILS_SUCCESS,
  payload
})
export const setIsLoadingGetShopDetails = payload => ({
  type: types.IS_LOADING_GET_SHOP_DETAILS,
  payload
})

export const getShowcases = payload => ({
  type: types.GET_SHOWCASES,
  payload
})
export const getShowcasesSuccess = payload => ({
  type: types.GET_SHOWCASES_SUCCESS,
  payload
})
export const setIsLoadingShowcases = payload => ({
  type: types.IS_LOADING_SHOWCASES,
  payload
})

export const getProducts = payload => ({
  type: types.GET_PRODUCTS,
  payload
})
export const getProductsSuccess = payload => ({
  type: types.GET_PRODUCTS_SUCCESS,
  payload
})
export const setIsLoadingProducts = payload => ({
  type: types.IS_LOADING_PRODUCTS,
  payload
})
export const resetProducts = payload => ({
  type: types.RESET_PRODUCTS
})

export const getReviews = payload => ({
  type: types.GET_REVIEWS,
  payload
})
export const getReviewsSuccess = payload => ({
  type: types.GET_REVIEWS_SUCCESS,
  payload
})
export const getCountReview = payload => ({
  type: types.GET_COUNT_REVIEW,
  payload
})
export const setIsLoadingReviews = payload => ({
  type: types.IS_LOADING_GET_REVIEWS,
  payload
})
export const resetReviews = payload => ({
  type: types.RESET_REVIEWS
})

export const getCountFavorites = payload => ({
  type: types.GET_COUNT_FAVORITES,
  payload
})
export const getCountFavoritesSuccess = payload => ({
  type: types.GET_COUNT_FAVORITES_SUCCESS,
  payload
})
export const setIsLoadingGetCountFavorites = payload => ({
  type: types.IS_LOADING_GET_COUNT_FAVORITES,
  payload
})

export const getCountProductSold = payload => ({
  type: types.GET_COUNT_PRODUCTS_SOLD,
  payload
})
export const getCountProductSoldSuccess = payload => ({
  type: types.GET_COUNT_PRODUCTS_SOLD_SUCCESS,
  payload
})
export const setIsLoadingGetCountProductSold = payload => ({
  type: types.IS_LOADING_GET_COUNT_PRODUCTS_SOLD,
  payload
})

export const resetShopDetails = payload => ({
  type: types.RESET_SHOP_DETAILS
})

export const setShowChat = payload => ({
  type: types.SET_SHOW_CHATS_FROM_SHOP,
  payload
})
