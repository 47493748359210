export default {
  GET_HELP_DETAIL_TOPIC: "get-help-detail-topic",
  GET_HELP_DETAIL_TOPIC_SUCCESS: "get-help-detail-topic-success",
  IS_LOADING_GET_HELP_DETAIL_TOPIC: "is-loading-get-help-detail-topic",

  GET_HELP_TOPICS: "get-help-topics",
  GET_HELP_TOPICS_SUCCESS: "get-help-topics-success",
  IS_LOADING_GET_HELP_TOPICS: "is-loading-get-help-topics",

  GET_HELP_CHAT_TALK: "get-help-chat-topic",
  GET_HELP_CHAT_TALK_SUCCESS: "get-help-chat-topic-success",
  IS_LOADING_GET_HELP_CHAT_TALK: "is-loading-get-help-chat-topic",

  CREATE_NEW_HELP_CHAT_TALK: "create-new-help-chat-talk",
  CREATE_NEW_HELP_CHAT_TALK_SUCCESS: "create-new-help-chat-talk-success",
  IS_LOADING_CREATE_NEW_HELP_CHAT_TALK: "is-loading-create-new-help-chat-talk",

  REPLY_HELP_CHAT_TALK: "reply-help-chat-talk",
  REPLY_HELP_CHAT_TALK_SUCCESS: "reply-help-chat-talk-success",
  IS_LOADING_REPLY_HELP_CHAT_TALK: "is-loading-reply-help-chat-talk",

  SET_IS_READ_CHAT_HELP: "set-is-read-chat-help",
  SET_IS_READ_CHAT_HELP_SUCCESS: "set-is-read-chat-help-success",
  IS_LOADING_SET_IS_READ_CHAT_HELP: "is-loading-set-is-read-chat-help",

  SEND_CONTACT_US: "send-contact-us",
  SEND_CONTACT_US_SUCCESS: "send-contact-us-success",
  IS_LOADING_SEND_CONTACT_US: "is-loading-send-contact-us",

  SET_CREATED_NEW_HELP_CHAT: "set-created-new-help-chat",
};
