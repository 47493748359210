import { Api } from "./api";

export default {
  get: {
    chats: (payload) =>
      Api.get("/v2/chat/buyer", {
        headers: {
          token: payload.token,
        },
        params: {
          limit: payload.limit,
          page: payload.page,
        },
      }),
    message: (payload) =>
      Api.post(
        "/v2/chat/buyer/message",
        {
          store_id: payload.store_id,
        },
        {
          headers: {
            token: payload.token,
          },
          params: {
            limit: payload.limit,
            page: payload.page,
          },
        }
      ),
    notification: (payload) =>
      Api.get("/v2/chat/buyer/notification", {
        headers: {
          token: payload.token,
        },
      }),
  },
  send: (payload) =>
    Api.post("/v2/chat/buyer/send-message", payload.data, {
      headers: {
        token: payload.token,
      },
    }),
};
