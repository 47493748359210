import types from './constant';

const initialState = {
  data:[],
  loading:false,
  products:[],
  countProducts:0,
  isLoadingProducts:false
}

export default (state=initialState, action)=>{
  switch(action.type){
    case types.GET_CATEGORIES_SUCCESS:
      return { ...state, data: action.payload }
    case types.IS_LOADING_GET_CATEGORIES:
      return { ...state, loading: action.payload }
    case types.GET_PRODUCTS_BY_CATEGORY_ID_SUCCESS:
      return { ...state, products: action.payload.data, countProducts:action.payload.count }
    case types.IS_LOADING_GET_PRODUCTS_BY_CATEGORY_ID:
      return { ...state, isLoadingProducts: action.payload }
    default:
      return state;
  }
}

export const getCategories = payload => ({
  type: types.GET_CATEGORIES,
  payload
})
export const getCategoriesSuccess = payload => ({
  type: types.GET_CATEGORIES_SUCCESS,
  payload
})
export const setLoadingGetCategories = payload => ({
  type: types.IS_LOADING_GET_CATEGORIES,
  payload
})

export const getProducts = payload => ({
  type: types.GET_PRODUCTS_BY_CATEGORY_ID,
  payload
})
export const getProductsSuccess = payload => ({
  type: types.GET_PRODUCTS_BY_CATEGORY_ID_SUCCESS,
  payload
})
export const setLoadingGetProducts = payload => ({
  type: types.IS_LOADING_GET_PRODUCTS_BY_CATEGORY_ID,
  payload
})
