export default {
  GET_DETAIL_PROFILE_FORGOT_PASSWORD: 'get-detail-profile-forgot-password',
  GET_DETAIL_PROFILE_FORGOT_PASSWORD_SUCCESS: 'get-detail-profile-forgot-password-success',
  IS_LOADING_GET_DETAIL_PROFILE_FORGOT_PASSWORD: 'is-loading-get-detail-profile-forgot-password',

  CHANGE_PASSWORD_FROM_FORGOT_PASSWORD:'change_password_from_forgot_password',
  CHANGE_PASSWORD_FROM_FORGOT_PASSWORD_SUCCESS:'change_password_from_forgot_password_success',
  IS_LOADING_CHANGE_PASSWORD_FROM_FORGOT_PASSWORD:'is_loading_change_password_from_forgot_password',

  DEFAULT_FORGOT_PASSWORD: 'default-forgot-password'
}
