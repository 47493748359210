export default {
  GET_DETAIL_PROFILE: "get-detail-profile-login-security",
  GET_DETAIL_BY_CHANGE_EMAIL_TOKEN: "get-detail-by-change-email-token-security",
  GET_DETAIL_PROFILE_SUCCESS: "get-detail-profile-login-security-success",
  IS_LOADING_GET_DETAIL_PROFILE: "is-loading-get-detail-profile-login-security",

  CHECK_CHANGE_EMAIL: "change-change-email-security",
  CHECK_CHANGE_EMAIL_SUCCESS: "change-change-email-security-success",
  IS_LOADING_CHECK_CHANGE_EMAIL: "loading-change-change-email-security",
  RESET_CHECK_CHANGE_EMAIL: "reset-check-change-email-security",

  SEND_OTP_UPDATE_EMAIL: "send-otp-change-email-security",
  SEND_OTP_UPDATE_EMAIL_SUCCESS: "send-otp-change-email-security-success",
  IS_LOADING_SEND_OTP_UPDATE_EMAIL:
    "loading-send-otp-change-change-email-security",

  SELECT_VERIFICATION_CHANGE_EMAIL: "select-verification-change-email",
  SELECT_VERIFICATION_CHANGE_EMAIL_SUCCESS:
    "select-verification-change-email-success",
  IS_LOADING_SELECT_VERIFICATION_CHANGE_EMAIL:
    "is-loading-select-verification-change-email",

  GET_LINK_EMAIL_CHANGE_EMAIL: "get-link-email-change-email",
  GET_LINK_EMAIL_CHANGE_EMAIL_SUCCESS: "get-link-email-change-email-success",
  IS_LOADING_GET_LINK_EMAIL_CHANGE_EMAIL:
    "is-loading-get-link-email-change-email",

  GET_SMS_OTP_CHANGE_EMAIL: "get-sms-otp-change-email",
  GET_SMS_OTP_CHANGE_EMAIL_SUCCESS: "get-sms-otp-change-email-success",
  IS_LOADING_GET_SMS_OTP_CHANGE_EMAIL: "is-loading-get-sms-otp-change-email",

  SEND_SMS_OTP_CHANGE_EMAIL: "send-sms-otp-change-email",
  SEND_SMS_OTP_CHANGE_EMAIL_SUCCESS: "send-sms-otp-change-email-success",
  IS_LOADING_SEND_SMS_OTP_CHANGE_EMAIL: "is-loading-send-sms-otp-change-email",

  REQUEST_CHANGE_EMAIL: "request-change-email-security",
  REQUEST_CHANGE_EMAIL_SUCCESS: "request-change-email-security-success",
  IS_LOADING_REQUEST_CHANGE_EMAIL: "is-loading-request-change-email-security",

  CHANGE_EMAIL_OTP: "change-email-otp-security",
  CHANGE_EMAIL_OTP_SUCCESS: "change-email-otp-security-success",
  IS_LOADING_CHANGE_EMAIL_OTP: "loading-change-email-otp-security",

  CHECK_PASSWORD: "check-password-security",
  CHECK_PASSWORD_SUCCESS: "check-password-security-success",
  IS_LOADING_CHECK_PASSWORD: "is-loading-check-password-security",

  CHECK_CHANGE_PHONE_NUMBER: "change-change-phone-number-security",
  CHECK_CHANGE_PHONE_NUMBER_SUCCESS:
    "change-change-phone-number-security-success",
  IS_LOADING_CHECK_CHANGE_PHONE_NUMBER:
    "loading-change-change-phone-number-security",
  RESET_CHECK_CHANGE_PHONE_NUMBER:
    "reset-check-change-phone-number-otp-security",

  CHECK_LAST_PHONE_NUMBER_EMAIL: "check-change-phone-number-by-email-security",
  CHECK_LAST_PHONE_NUMBER_PHONE: "check-change-phone-number-by-phone-security",
  CHECK_LAST_PHONE_NUMBER_SUCCESS: "check-change-phone-number-security-success",
  IS_LOADING_CHECK_LAST_PHONE_NUMBER:
    "loading-check-change-phone-number-security",

  CONFIRM_OTP_LAST_PHONE_NUMBER: "confirm-otp-last-phone-number-security",
  CONFIRM_OTP_LAST_PHONE_NUMBER_SUCCESS:
    "confirm-otp-last-phone-number-security-success",

  CHANGE_PHONE_NUMBER_OTP: "change-phone-number-otp-security",
  CHANGE_PHONE_NUMBER_OTP_SUCCESS: "change-phone-number-otp-security-success",
  IS_LOADING_CHANGE_PHONE_NUMBER_OTP:
    "loading-change-phone-number-otp-security",

  RESET_DEFAULT: "reet-default-security",

  GET_CHANGE_EMAIL_REQUEST_OTP_CHECK_EMAIL:
    "get-change-email-request-otp-check-email",
  GET_CHANGE_EMAIL_REQUEST_OTP_CHECK_EMAIL_SUCCESS:
    "get-change-email-request-otp-check-email-success",
  IS_LOADING_GET_CHANGE_EMAIL_REQUEST_OTP_CHECK_EMAIL:
    "is-loading-get-change-email-request-otp-check-email",
};
