import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../../../services';
import types from './constants';
import * as actions from './reducer';

function* watchPostComplaint(values){
  const {payload} = values;
  yield put(actions.setIsLoadingCreateComplaintSuccess(true))
  try{
    const response = yield call(Api.complaint.postComplaint, payload)
    const {data} = response;
    if(data.success){
      const resImg = yield call(Api.complaint.postImgComplaint, {...payload, id:data.id})
      yield put(actions.createComplaintSuccess({
        ...data,
        ComplaintPictures: resImg.data.ComplaintPictures
      }));
    }
  }catch(e){
    // toast('Gagal mendapatkan data komplain', -20);
  }finally{
    yield put(actions.setIsLoadingCreateComplaintSuccess(false))
  }
}

function* watchGetComplaintDetail(values){
  const {payload} = values;
  yield put(actions.setIsLoadingGetComplaint(true))
  try{
    const response = yield call(Api.complaint.getDetailComplaint, payload)
    const {data} = response;
    yield put(actions.getDetailComplaintSuccess(data.data));
  }catch(e){
    // toast('Gagal mendapatkan data komplain', -20);
  }finally{
    yield put(actions.setIsLoadingGetComplaint(false))
  }
}

function* watchGetComplaintOrderId(values){
  const {payload} = values;
  yield put(actions.setIsLoadingGetComplaint(true))
  try{
    const response = yield call(Api.complaint.getDetailComplaintOrderId, payload)
    const {data} = response;
    yield put(actions.getDetailComplaintSuccess(data.data));
  }catch(e){
    // toast('Gagal mendapatkan data komplain', -20);
  }finally{
    yield put(actions.setIsLoadingGetComplaint(false))
  }
}


function* watchGetComplaintByBuyer(values){
  const {payload} = values;
  yield put(actions.setIsLoadingGetComplaint(true))
  try{
    const response = yield call(Api.complaint.getComplaintByBuyer, payload)
    const {data} = response;
    yield put(actions.getComplaintSuccess(data.data));
  }catch(e){
    // toast('Gagal mendapatkan data komplain', -20);
  }finally{
    yield put(actions.setIsLoadingGetComplaint(false))
  }
}

function* watchGetComplaintBySeller(values){
  const {payload} = values;
  yield put(actions.setIsLoadingGetComplaint(true))
  try{
    const response = yield call(Api.complaint.getComplaintBySeller, payload)
    const {data} = response;
    yield put(actions.getComplaintSuccess(data.data));
  }catch(e){
    // toast('Gagal mendapatkan data komplain', -20);
  }finally{
    yield put(actions.setIsLoadingGetComplaint(false))
  }
}

function* watchGetHistoryComplaint(values){
  const {payload} = values;
  yield put(actions.setIsLoadingGetHistoryComplaint(true))
  try{
    const response = yield call(Api.complaint.getHistoryComplaint, payload)
    const {data} = response;
    yield put(actions.getHistoryComplaintSuccess(data.data));
  }catch(e){
    // toast('Gagal mendapatkan data komplain', -20);
  }finally{
    yield put(actions.setIsLoadingGetHistoryComplaint(false))
  }
}

function* watchGetDiscussionComplaint(values){
  const {payload} = values;
  yield put(actions.setIsLoadingGetDiscussionComplaint(true))
  try{
    const response = yield call(Api.complaint.getDiscussionComplaint, payload)
    const {data} = response;
    yield put(actions.getDiscussionComplaintSuccess(data.data));
  }catch(e){
    // toast('Gagal mendapatkan data komplain', -20);
  }finally{
    yield put(actions.setIsLoadingGetDiscussionComplaint(false))
  }
}

function* watchCreateDiscussionComplaint(values){
  const {payload} = values;
  yield put(actions.setIsLoadingCreateDiscussionComplaint(true))
  try{
    const response = yield call(Api.complaint.postDiscussion, payload)
    const {data} = response;
    yield put(actions.createDiscussionComplaintSuccess(data));
  }catch(e){
    // toast('Gagal mendapatkan data komplain', -20);
  }finally{
    yield put(actions.setIsLoadingCreateDiscussionComplaint(false))
  }
}

function* watchCreateBidComplaint(values){
  const {payload} = values;
  yield put(actions.setIsLoadingCreateBidComplaint(true))
  try{
    const response = yield call(Api.complaint.postBid, payload)
    const {data} = response;
    yield put(actions.createBidComplaintSuccess(data));
    yield put(actions.setBidSuccess(true));
  }catch(e){
    // toast('Gagal membuat tawaran harga', -20);
  }finally{
    yield put(actions.setBidSuccess(false));
    yield put(actions.setIsLoadingCreateBidComplaint(false))
  }
}

function* watchGetAllServices(values){
  yield put(actions.setIsLoadingGetServices(true));
  try{
    const response = yield call(Api.complaint.getServices)
    const {data} = response;
    yield put(actions.getServicesSuccess(data.data));
  }catch(e){
    // toast('Gagal mendapatkan kurir', -20);
  }finally{
    yield put(actions.setIsLoadingGetServices(false))
  }
}

function* watchCreateDeliveryReceipt(values){
  const {payload} = values;
  yield put(actions.setIsLoadingCreateReceipt(true));
  try{
    const response = yield call(Api.complaint.postDeliveryReceipt, payload)
    const {data} = response;
    yield put(actions.createReceiptSuccess(data));
  }catch(e){
    // toast('Gagal mengirimkan resi', -20);
  }finally{
    yield put(actions.setIsLoadingCreateReceipt(false))
  }
}

function* watchCreateReturnReceipt(values){
  const {payload} = values;
  yield put(actions.setIsLoadingCreateReceipt(true));
  try{
    const response = yield call(Api.complaint.postReturnReceipt, payload)
    const {data} = response;
    yield put(actions.createReceiptSuccess(data));
  }catch(e){
    // toast('Gagal mengirimkan resi', -20);
  }finally{
    yield put(actions.setIsLoadingCreateReceipt(false))
  }
}

function* watchConfirmApproveBySeller(values){
  const {payload} = values;
  yield put(actions.setIsLoadingConfirmApproveBySeller(true));
  try{
    const response = yield call(Api.complaint.confirmApproveBySeller, payload)
    const {data} = response;
    yield put(actions.confirmApproveBySellerSuccess(data));
  }catch(e){
    // toast('Gagal menerima solusi', -20);
  }finally{
    yield put(actions.setIsLoadingConfirmApproveBySeller(false))
  }
}

function* watchConfirmAbortedByBuyer(values){
  const {payload} = values;
  yield put(actions.setIsLoadingConfirmAbortedByBuyer(true));
  try{
    const response = yield call(Api.complaint.confirmAbortedByBuyer, payload)
    const {data} = response;
    yield put(actions.confirmAbortedByBuyerSuccess(data));
    yield put(actions.setSuccessAbortedByBuyer(true))
  }catch(e){
    // toast('Gagal menolak solusi', -20);
  }finally{
    yield put(actions.setIsLoadingConfirmAbortedByBuyer(false))
  }
}

function* watchConfirmApproveByBuyer(values){
  const {payload} = values;
  yield put(actions.setIsLoadingConfirmApproveByBuyer(true));
  try{
    const response = yield call(Api.complaint.confirmApproveByBuyer, payload)
    const {data} = response;
    yield put(actions.confirmApproveByBuyerSuccess(data));
  }catch(e){
    // toast('Gagal menerima solusi', -20);
  }finally{
    yield put(actions.setIsLoadingConfirmApproveByBuyer(false))
  }
}

function* watchAskForHelp(values){
  const {payload} = values;
  yield put(actions.setIsLoadingAskForHelp(true));
  try{
    const response = yield call(Api.complaint.askForHelp, payload)
    const {data} = response;
    yield put(actions.askForHelpSuccess(data));
  }catch(e){
    // toast('Gagal menerima solusi', -20);
  }finally{
    yield put(actions.setIsLoadingAskForHelp(false))
  }
}

// function* watchSetIsReadSeller(values){
//   const {payload} = values;
//   try{
//     const response = yield call(Api.complaint.isReadSeller, payload)
//     const {data} = response;
//     yield put(actions.setIsReadSellerSuccess(data));
//   }catch(e){
//     toast('Gagal menerima solusi', -20);
//   }
// }

const complaintSagas = [
  takeLatest(types.GET_DETAIL_COMPLAINT, watchGetComplaintDetail),
  takeLatest(types.GET_DETAIL_COMPLAINT_BY_ORDER_ID, watchGetComplaintOrderId),
  takeLatest(types.CREATE_COMPLAINT, watchPostComplaint),
  takeLatest(types.GET_COMPLAINT_BY_BUYER, watchGetComplaintByBuyer),
  takeLatest(types.GET_COMPLAINT_BY_SELLER, watchGetComplaintBySeller),
  takeLatest(types.GET_HISTORY_COMPLAINT, watchGetHistoryComplaint),
  takeLatest(types.GET_DISCUSSION_COMPLAINT, watchGetDiscussionComplaint),
  takeLatest(types.CREATE_DISCUSSION_COMPLAINT, watchCreateDiscussionComplaint),
  takeLatest(types.CREATE_BID_COMPLAINT, watchCreateBidComplaint),
  takeLatest(types.GET_SERVICES_COMPLAINT, watchGetAllServices),
  takeLatest(types.CREATE_RETURN_RECEIPT_FROM_BUYER, watchCreateReturnReceipt),
  takeLatest(types.CREATE_DELIVERY_RECEIPT_FROM_SELLER, watchCreateDeliveryReceipt),
  takeLatest(types.CONFIRM_APPROVE_BY_SELLER, watchConfirmApproveBySeller),
  takeLatest(types.CONFIRM_ABORTED_BY_BUYER, watchConfirmAbortedByBuyer),
  takeLatest(types.CONFIRM_APPROVE_BY_BUYER, watchConfirmApproveByBuyer),
  takeLatest(types.ASK_FOR_HELP, watchAskForHelp),

  // takeLatest(types.SET_IS_READ_SELLER, watchSetIsReadSeller)
]

export default complaintSagas;
