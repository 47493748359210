export default {
  GET_PAYMENT_PENDING: 'GET_PAYMENT_PENDING',
  GET_PAYMENT_PENDING_SUCCESS: 'GET_PAYMENT_PENDING_SUCCESS',
  IS_LOADING_GET_PAYMENT_PENDING: 'IS_LOADING_GET_PAYMENT_PENDING',

  UPLOAD_PROOF_OF_PAYMENT: 'UPLOAD_PROOF_OF_PAYMENT',
  UPLOAD_PROOF_OF_PAYMENT_SUCCESS: 'UPLOAD_PROOF_OF_PAYMENT_SUCCESS',
  IS_LOADING_UPLOAD_PROOF_OF_PAYMENT: 'IS_LOADING_UPLOAD_PROOF_OF_PAYMENT',

  CANCEL_PAYMENT: 'CANCEL_PAYMENT',
  CANCEL_PAYMENT_SUCCESS: 'CANCEL_PAYMENT_SUCCESS',
  IS_LOADING_CANCEL_PAYMENT: 'IS_LOADING_CANCEL_PAYMENT',

  UPDATE_PAYMENT_COD: 'UPDATE_PAYMENT_COD',
  UPDATE_PAYMENT_COD_SUCCESS: 'UPDATE_PAYMENT_COD_SUCCESS',
  IS_LOADING_UPDATE_PAYMENT_COD: 'IS_LOADING_UPDATE_PAYMENT_COD',
  RESET_PAYMENT_COD: 'RESET_PAYMENT_COD',
}
