import React, { useState } from 'react';

import { IoRadioButtonOff, IoRadioButtonOn } from "react-icons/io5";

const Radio = ({onClick, checked, style, label, labelStyle, disabled}) => {
  const [hover, setHover] = useState(false);
  if(disabled){
    return (
      <div className="d-flex justify-content-left align-items-center mr-2" onClick={() => {}}>
        <IoRadioButtonOff style={style} className="text-dark mr-1" />
        {
          label && (
            <div style={labelStyle} className="text-dark">{label}</div>
          )
        }
      </div>
    )
  }
  if(checked){
    return (
      <div className="d-flex justify-content-left align-items-center mr-2" onClick={onClick} style={{cursor:'pointer'}}>
        <IoRadioButtonOn style={{...style, cursor:'pointer'}} className="text-primary mr-1" />
        {
          label && (
            <div style={labelStyle}>{label}</div>
          )
        }
      </div>
    )
  }
  return (
    <div className="d-flex justify-content-left align-items-center mr-2" onClick={onClick} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{cursor:'pointer'}}>
      <IoRadioButtonOff style={{...style, color: !hover  ? "rgb(118, 118, 118)" : null, cursor:'pointer'}} className={hover ? "text-primary mr-1" : "mr-1"} />
      {
        label  && (
          <div style={labelStyle}>{label}</div>
        )
      }
    </div>
  )
}

export default Radio;
