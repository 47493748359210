import { put, call, takeLatest, select } from "redux-saga/effects";
import Api from "../../services";
import types from "./constants";
import * as actions from "./reducer";

function* watchGetShopDetails(values) {
  yield put(actions.setIsLoadingGetShopDetails(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.store.getShopBySubdomain, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getShopDetailSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getShopDetailSuccess(state.shop.detail));
  } finally {
    yield put(actions.setIsLoadingGetShopDetails(false));
  }
}
function* watchGetShopDecoration(values) {
  yield put(actions.setIsLoadingGetShopDecorationHome(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.store.getDecoration, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getShopDecorationHomeSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getShopDecorationHomeSuccess(state.shop.decoration));
  } finally {
    yield put(actions.setIsLoadingGetShopDecorationHome(false));
  }
}
function* watchGetProductsByMultipleIds(values) {
  yield put(actions.setIsLoadingMultipleProducts(true));
  const { payload } = values;
  try {
    const response = yield call(Api.product.getProductByMultipleIds, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getMultipleProductsSuccess(data.data));
    }
  } catch (e) {
    // yield put(actions.getMultipleProductsSuccess(state.shop.productsSelected));
  } finally {
    yield put(actions.setIsLoadingMultipleProducts(false));
  }
}
function* watchGetShopBanner(values) {
  yield put(actions.setIsLoadingGetShopBanner(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.store.getBanner, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getShopBannerSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getShopBannerSuccess(state.shop.banners));
  } finally {
    yield put(actions.setIsLoadingGetShopBanner(false));
  }
}

function* watchGetCountFavoritesByStoreId(values) {
  yield put(actions.setIsLoadingGetCountFavorites(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.store.getCountFavorites, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getCountFavoritesSuccess(data));
    }
  } catch (e) {
    yield put(
      actions.getCountFavoritesSuccess({
        count: state.shop.countFollowers,
        data: state.shop.dataFollowers,
      })
    );
  } finally {
    yield put(actions.setIsLoadingGetCountFavorites(false));
  }
}

function* watchGetCountProductSoldByStoreId(values) {
  yield put(actions.setIsLoadingGetCountProductSold(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.store.getCountProductsSold, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getCountProductSoldSuccess(data.count));
    }
  } catch (e) {
    yield put(actions.getCountProductSoldSuccess(state.shop.countFollowers));
  } finally {
    yield put(actions.setIsLoadingGetCountProductSold(false));
  }
}

function* watchGetShowcases(values) {
  yield put(actions.setIsLoadingShowcases(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.showcase.get, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getShowcasesSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getShowcasesSuccess(state.shop.products));
  } finally {
    yield put(actions.setIsLoadingShowcases(false));
  }
}

function* watchGetProducts(values) {
  yield put(actions.setIsLoadingProducts(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.product.getProductByStoreId, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getProductsSuccess(data));
    }
  } catch (e) {
    yield put(
      actions.getProductsSuccess({
        data: state.shop.products,
        count: state.shop.countProducts,
      })
    );
  } finally {
    yield put(actions.setIsLoadingProducts(false));
  }
}

function* watchGetReviews(values) {
  yield put(actions.setIsLoadingReviews(true));
  // const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.review.getAllByStoreId, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getCountReview(data.count));
      yield put(actions.getReviewsSuccess(data.data));
    }
  } catch (e) {
    // yield put(actions.getReviewsSuccess(state.shop.discussions));
  } finally {
    yield put(actions.setIsLoadingReviews(false));
  }
}

function* watchGetRatingReview(values) {
  yield put(actions.setIsLoadingGetRatingsReview(true));
  const { payload } = values;
  try {
    const response = yield call(Api.store.getRating, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getRatingsReviewSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getRatingsReviewSuccess(0));
  } finally {
    yield put(actions.setIsLoadingGetRatingsReview(false));
  }
}

const shopSagas = [
  takeLatest(types.GET_SHOP_DETAILS, watchGetShopDetails),
  takeLatest(types.GET_SHOP_BANNER, watchGetShopBanner),
  takeLatest(types.GET_COUNT_FAVORITES, watchGetCountFavoritesByStoreId),
  takeLatest(types.GET_COUNT_PRODUCTS_SOLD, watchGetCountProductSoldByStoreId),
  takeLatest(types.GET_SHOWCASES, watchGetShowcases),
  takeLatest(types.GET_PRODUCTS, watchGetProducts),
  takeLatest(types.GET_REVIEWS, watchGetReviews),
  takeLatest(types.GET_SHOP_DECORATION_HOME, watchGetShopDecoration),
  takeLatest(types.GET_MULTIPLE_PRODUCTS, watchGetProductsByMultipleIds),
  takeLatest(types.GET_RATINGS_REVIEW, watchGetRatingReview),
];

export default shopSagas;
