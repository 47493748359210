import React, { useState, useEffect } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { IoCalendarOutline, IoCaretDown } from "react-icons/io5";

const start2024 = moment("2024-01-01T00:00:00").toDate();
const last2Years = moment().subtract(2, "year").toDate();
//format start dan end DD-MM-YYYY
const AppDateRangePicker = ({ start, end, format, onApply, onCancel }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const setDateRange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };
  const onCancelDateRange = (event, picker) => {
    if (typeof onCancel === "function") {
      onCancel(picker);
    }
  };
  const onApplyDateRange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    onApply(start, end);
  };

  useEffect(() => {
    setStartDate(moment(start, format));
    setEndDate(moment(end, format));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end, format]);
  if (!startDate) {
    return null;
  }
  return (
    <DateRangePicker
      initialSettings={{
        startDate: startDate ? startDate.toDate() : moment().startOf("month"),
        endDate: endDate ? endDate.toDate() : moment(),
        minDate: last2Years > start2024 ? last2Years : start2024,
        maxDate: moment().toDate(),
        showCustomRangeLabel: false,
        alwaysShowCalendars: true,
        ranges: {
          "Bulan Ini": [moment().startOf("month").toDate(), moment().toDate()],
          "7 Hari Terakhir": [
            moment().subtract(7, "days").toDate(),
            moment().toDate(),
          ],
          "30 Hari Terakhir": [
            moment().subtract(30, "days").toDate(),
            moment().toDate(),
          ],
          "90 Hari Terakhir": [
            moment().subtract(90, "days").toDate(),
            moment().toDate(),
          ],
          "Tahun ini": [moment().startOf("year").toDate(), moment().toDate()],
        },
        autoApply: false,
        autoUpdateInput: false,
        locale: {
          format: "DD MMM YYYY", // Format tanggal
          separator: " - ",
          applyLabel: "Terapkan",
          cancelLabel: "Batal",
          fromLabel: "Dari",
          toLabel: "Hingga",
          customRangeLabel: "Pilih Sendiri",
        },
      }}
      onShow={(e) => setDateRange(startDate, endDate)}
      onApply={(e, picker) =>
        onApplyDateRange(picker.startDate, picker.endDate)
      }
      onCancel={onCancelDateRange}
      showMonthPicker={true}
      showYearPicker={true}
    >
      <div
        className="form-control form-control-md d-flex align-items-center justify-content-between border bg-white rounded"
        style={{ cursor: "pointer", width: "100%", borderRadius: 3 }}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="mr-2">
            <IoCalendarOutline />
          </div>
          {startDate ? (
            <div className="d-flex align-items-center justify-content-start">
              {startDate && <span>{startDate.format("DD MMM YYYY")}</span>}
              {startDate && <span className="mx-2"> - </span>}
              {endDate && <span>{endDate.format("DD MMM YYYY")}</span>}
            </div>
          ) : (
            <div>Pilih Rentang Tanggal </div>
          )}
        </div>
        <div>
          <IoCaretDown />
        </div>
      </div>
    </DateRangePicker>
  );
};

export default AppDateRangePicker;
