import { put, call, takeLatest, select } from 'redux-saga/effects';

import Api from '../../../../services';
import types from './constants';
import * as actions from './reducer';
import * as actionsNotifications from '../../reducer';

function* watchGetReviewedOrders(values) {
  const state = yield select();
  const { payload } = values;
  yield put(actions.setIsLoadingGetReviewedOrders(true));
  try {
    const response = yield call(Api.review.getDoneReviewed, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getReviewedOrdersSuccess(data.data));
      yield put(actions.setCountReview(data.count))
    }
  } catch (error) {
    yield put(actions.getReviewedOrdersSuccess(state.review.data));
  } finally {
    yield put(actions.setIsLoadingGetReviewedOrders(false));
  }
}

function* watchGetNotYetRviewedOrder(values) {
  const state = yield select();
  const { payload } = values;
  yield put(actions.setIsLoadingGetNotYetReviewedOrder(true));
  try {
    const response = yield call(Api.review.getNotYetReviewed, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getReviewedOrdersSuccess(data.data));
      yield put(actions.setCountReview(data.count))
    }
  } catch (error) {
    yield put(actions.getReviewedOrdersSuccess(state.review.data));
  } finally {
    yield put(actions.setIsLoadingGetNotYetReviewedOrder(false));
  }
}

function* watchGetDetailReviewed(values) {
  const state = yield select();
  const { payload } = values;
  yield put(actions.setIsLoadingGetDetailReview(true));
  try {
    const response = yield call(Api.review.getDetailReviewed, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getDetailReviewSuccess(data.data));
    }
  } catch (error) {
    yield put(actions.getDetailReviewSuccess(state.review.detail));
  } finally {
    yield put(actions.setIsLoadingGetDetailReview(false));
  }
}

function* watchCreateReview(values) {
  const state = yield select();
  const { payload } = values;
  yield put(actions.setIsLoadingCreateReview(true));
  try {
    const response = yield call(Api.review.createReview, payload);
    const { data } = response;
    if (data.success) {
      let result = {
        point_id:payload.point_id,
        order_id:payload.order_id,
        detailorder_id:payload.detailorder_id,
        Point:data
      }
      yield put(actions.createReviewSuccess(result));
      yield put(actionsNotifications.getReviewNotificationSuccess(state.notification.review - 1))
    }
  } catch (error) {
    yield put(actions.createReviewSuccess(payload.data));
  } finally {
    yield put(actions.setIsLoadingCreateReview(false));
  }
}

function* watchUpdateReview(values) {
  const { payload } = values;
  yield put(actions.setIsLoadingUpdateReview(true));
  try {
    const response = yield call(Api.review.updateReview, payload);
    const { data } = response;
    if (data.success) {
      let result = {
        point_id:payload.point_id,
        order_id:payload.order_id,
        detailorder_id:payload.detailorder_id,
        Point:data
      }
      yield put(actions.updateReviewSuccess(result));
    }
  } catch (error) {
    yield put(actions.updateReviewSuccess(payload.data));
  } finally {
    yield put(actions.setIsLoadingUpdateReview(false));
  }
}


const orderSagas = [
  takeLatest(types.GET_REVIEWED_ORDERS, watchGetReviewedOrders),
  takeLatest(types.GET_NOT_YET_REVIEWED_ORDER, watchGetNotYetRviewedOrder),
  takeLatest(types.GET_DETAIL_REVIEW, watchGetDetailReviewed),
  takeLatest(types.CREATE_REVIEW, watchCreateReview),
  takeLatest(types.UPDATE_REVIEW, watchUpdateReview)
];

export default orderSagas;
