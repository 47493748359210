export default {
  CHECK_TRACKING: "check-tracking",
  CHECK_TRACKING_SUCCESS: "check-tracking-success",
  IS_LOADING_CHECK_TRACKING: "is-loading-check-tracking",
  RESET_CHECK_TRACKING: "check-tracking-reset",

  CHECK_COSTS: "check-costs-shipping",
  CHECK_COSTS_SUCCESS: "check-costs-shipping-success",
  IS_LOADING_CHECK_COSTS: "is-loading-check-costs-shipping",
  RESET_CHECK_COSTS: "check-costs-shipping-reset",
};
