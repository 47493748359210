import { put, call, takeLatest, select, delay } from "redux-saga/effects";
import Api from "../../../../services";
import types from "./constants";
import * as actions from "./reducer";
import * as actionsLoginSecurity from "../loginsecurity/reducer";

function* watchGetDetailProfile(values) {
  yield put(actions.setIsLoadingGetDetailProfile(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.getDetailProfile, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getDetailProfileSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getDetailProfileSuccess(state.profile.detail));
  } finally {
    yield put(actions.setIsLoadingGetDetailProfile(false));
  }
}
function* watchUpdateProfile(values) {
  yield put(actions.setIsLoadingUpdateProfile(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.changeProfile, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.updateProfileSuccess(data));
      yield put(actions.setIsResponseSuccess(true));
      yield put(actions.setMessage(data.message));
    }
  } catch (e) {
    yield put(actions.getDetailProfileSuccess(state.profile.detail));
  } finally {
    yield put(actions.setIsLoadingUpdateProfile(false));
    yield delay(3500);
    yield put(actions.setIsResponseSuccess(false));
  }
}
function* watchUpdateAvatar(values) {
  yield put(actions.setIsLoadingUpdateAvatar(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.changeAvatar, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.updateAvatarSuccess(data.data));
      yield put(actions.setIsResponseSuccess(true));
      yield put(actions.setMessage(data.message));
    }
  } catch (e) {
    yield put(actions.getDetailProfileSuccess(state.profile.detail));
  } finally {
    yield put(actions.setIsLoadingUpdateAvatar(false));
    yield delay(3500);
    yield put(actions.setIsResponseSuccess(false));
  }
}

function* watchGetOtpActivationPhoneNumber(values) {
  yield put(actions.setIsLoadingGetOtpActivationPhoneNumber(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.verify_phone.request_otp, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getOtpActivationPhoneNumberSuccess(data));
    }
  } catch (e) {
    yield put(actions.getDetailProfileSuccess(state.profile.detail));
  } finally {
    yield put(actions.setIsLoadingGetOtpActivationPhoneNumber(false));
  }
}
function* watchConfirmOtpActivationPhoneNumber(values) {
  yield put(actions.setIsLoadingConfirmOtpActivationPhoneNumber(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.verify_phone.verify_otp, payload);
    const { data } = response;
    if (data.success) {
      const result = {
        ...state.loginsecurity.detail,
        phone_number_verified: data.phone_number_verified,
      };
      yield put(
        actions.getOtpActivationPhoneNumberSuccess({
          success: false,
          message: "",
        })
      );
      yield put(actionsLoginSecurity.getDetailProfileSuccess(result));
      yield put(actions.confirmOtpActivationPhoneNumberSuccess(data));
      yield put(
        actions.setError({
          value: false,
          message: "",
        })
      );
    }
  } catch (e) {
    yield put(
      actions.setError({
        value: true,
        message: e.response.data?.message,
      })
    );
    yield put(actions.getDetailProfileSuccess(state.profile.detail));
  } finally {
    yield put(
      actions.confirmOtpActivationPhoneNumberSuccess({
        success: false,
        message: "",
      })
    );
    yield put(actions.setIsLoadingConfirmOtpActivationPhoneNumber(false));
  }
}
function* watchUpdatePhoneNumber(values) {
  yield put(actions.setIsLoadingUpdatePhoneNumberSuccess(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.changePhoneNumber, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.updatePhoneNumberSuccess(data.phone_number));
    }
  } catch (e) {
    yield put(actions.getDetailProfileSuccess(state.profile.detail));
  } finally {
    yield put(actions.setIsLoadingUpdatePhoneNumberSuccess(false));
  }
}

function* watchRequestChangeMail(values) {
  yield put(actions.setLoadingRequestChangeEmail(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.requestChangeEmail, payload);
    const { data } = response;
    if (data.success) {
      let result = {
        email: data.email,
        success: data.success,
        message: data.message,
      };
      yield put(actions.requestChangeEmailSuccess(result));
    }
  } catch (e) {
    yield put(actions.getDetailProfileSuccess(state.profile.detail));
  } finally {
    yield put(actions.setLoadingRequestChangeEmail(false));
  }
}

function* watchCheckPassword(values) {
  yield put(actions.setIsLoadingCheckPassword(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.getDetailProfile, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.checkPasswordSuccess(data));
    }
  } catch (e) {
    yield put(actions.checkPasswordSuccess(state.profile.check_password));
  } finally {
    yield put(actions.setIsLoadingCheckPassword(false));
  }
}
const profileSagas = [
  takeLatest(types.GET_DETAIL_PROFILE, watchGetDetailProfile),
  takeLatest(types.UPDATE_PROFILE, watchUpdateProfile),
  takeLatest(types.UPDATE_AVATAR, watchUpdateAvatar),

  takeLatest(
    types.GET_OTP_ACTIVATION_PHONE_NUMBER,
    watchGetOtpActivationPhoneNumber
  ),
  takeLatest(
    types.CONFIRM_OTP_ACTIVATION_PHONE_NUMBER,
    watchConfirmOtpActivationPhoneNumber
  ),
  takeLatest(types.UPDATE_PHONE_NUMBER, watchUpdatePhoneNumber),

  takeLatest(types.CHECK_PASSWORD, watchCheckPassword),
  takeLatest(types.REQUEST_CHANGE_EMAIL, watchRequestChangeMail),
];

export default profileSagas;
