import { put, call, takeLatest, select, delay } from "redux-saga/effects";
import Api from "../../../../services";
import types from "./constants";
import * as actions from "./reducer";

function* watchGetBankAccount(values) {
  yield put(actions.setIsLoadingGetBankAccount(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.bankaccount.get, payload);
    const { data } = response;
    if (data.success) {
      yield delay(3000);
      yield put(actions.getAllBankAccountSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getAllBankAccountSuccess(state.bankaccount.data));
  } finally {
    yield put(actions.setIsLoadingGetBankAccount(false));
  }
}

function* watchCreateBankAccount(values) {
  yield put(actions.setIsLoadingCreateBankAccount(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.bankaccount.add, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.createNewBankAccountSuccess(true));
    }
  } catch (e) {
    yield put(actions.getAllBankAccountSuccess(state.bankaccount.data));
  } finally {
    yield put(actions.setIsLoadingCreateBankAccount(false));
  }
}

function* watchVerifyOTPBankAccount(values) {
  yield put(actions.setIsLoadingVerifyOTPBankAccount(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.bankaccount.verifyOTPBankAccount, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.verifyOTPBankAccountSuccess(data.data));
      yield put(actions.setIsResponseSuccess(true));
      yield put(actions.createNewBankAccountSuccess(false));
    } else {
    }
  } catch (e) {
    yield put(actions.getAllBankAccountSuccess(state.bankaccount.data));
  } finally {
    yield put(actions.setIsLoadingVerifyOTPBankAccount(false));
    yield put(actions.setIsResponseSuccess(false));
  }
}

function* watchSetDefaultBankAccountById(values) {
  yield put(actions.setIsLoadingSetDefaultBankAccount(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.bankaccount.editDefaultById, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.setDefaultBankAccountByIdSuccess(payload));
    }
  } catch (e) {
    yield put(actions.getAllBankAccountSuccess(state.bankaccount.data));
  } finally {
    yield put(actions.setIsLoadingSetDefaultBankAccount(false));
  }
}

function* watchUpdatedBankAccountById(values) {
  yield put(actions.setIsLoadingEditBankAccount(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.bankaccount.editById, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.editBankAccountByIdSuccess(payload));
      yield put(actions.setIsResponseSuccess(true));
    }
  } catch (e) {
    yield put(actions.getAllBankAccountSuccess(state.bankaccount.data));
  } finally {
    yield put(actions.setIsLoadingEditBankAccount(false));
    yield put(actions.setIsResponseSuccess(false));
  }
}

function* watchDeleteBankAccountByID(values) {
  yield put(actions.setIsLoadingDeleteBankAccount(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.bankaccount.deleteById, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.deleteBankAccountByIdSuccess(payload));
    }
  } catch (e) {
    yield put(actions.getAllBankAccountSuccess(state.bankaccount.data));
  } finally {
    yield put(actions.setIsLoadingDeleteBankAccount(false));
  }
}

function* watchGetBanks(values) {
  yield put(actions.setIsLoadingGetBanks(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.bankaccount.banks, payload);
    const { data } = response;
    yield put(actions.getBanksSuccess(data));
  } catch (e) {
    yield put(actions.getBanksSuccess(state.bankaccount.banks));
  } finally {
    yield put(actions.setIsLoadingGetBanks(false));
  }
}

const bankaccountSagas = [
  takeLatest(types.GET_ALL_BANK_ACCOUNT, watchGetBankAccount),
  takeLatest(types.CREATE_BANK_ACCOUNT, watchCreateBankAccount),
  takeLatest(types.VERIFY_OTP_BANK_ACCOUNT, watchVerifyOTPBankAccount),
  takeLatest(types.EDIT_BANK_ACCOUNT, watchUpdatedBankAccountById),
  takeLatest(types.SET_DEFAULT_BANK_ACCOUNT, watchSetDefaultBankAccountById),
  takeLatest(types.DELETE_BANK_ACCOUNT, watchDeleteBankAccountByID),
  takeLatest(types.GET_BANKS, watchGetBanks),
];

export default bankaccountSagas;
