import types from "./constants";

const initialState = {
  detail: {},
  otp_phone_number: {
    activation: {},
    l_activation: false,
    confirm: {},
    l_confirm: false,
  },
  request_change_email: {
    email: "",
    success: false,
    message: "",
    loading: false,
  },
  check_password: {
    success: false,
    message: "",
    loading: false,
  },
  isLoadingGetDetailProfile: false,
  isLoadingUpdateProfile: false,
  isLoadingUpdateAvatar: false,
  isLoadingUpdatePhoneNumber: false,
  updated: false,
  message: "",
  error: {
    value: false,
    message: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    //GET DETAIL
    case types.GET_DETAIL_PROFILE_SUCCESS:
      return { ...state, detail: action.payload };
    case types.IS_LOADING_GET_DETAIL_PROFILE:
      return { ...state, isLoadingGetDetailProfile: action.payload };
    //UPDATE PROFILE
    case types.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action.payload,
        },
      };
    case types.IS_LOADING_UPDATE_PROFILE:
      return { ...state, isLoadingUpdateProfile: action.payload };
    case types.UPDATE_AVATAR_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action.payload,
        },
      };
    case types.IS_LOADING_UPDATE_AVATAR:
      return { ...state, isLoadingUpdateAvatar: action.payload };
    case types.RESPONSE_SUCCESS:
      return { ...state, updated: action.payload };
    case types.GET_OTP_ACTIVATION_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        otp_phone_number: {
          ...state.otp_phone_number,
          activation: action.payload,
        },
      };
    case types.IS_LOADING_GET_OTP_ACTIVATION_PHONE_NUMBER:
      return {
        ...state,
        otp_phone_number: {
          ...state.otp_phone_number,
          l_activation: action.payload,
        },
      };
    case types.CONFIRM_OTP_ACTIVATION_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        otp_phone_number: {
          ...state.otp_phone_number,
          confirm: action.payload,
        },
        detail: {
          ...state.detail,
          phone_number_verified: true,
        },
      };
    case types.IS_LOADING_CONFIRM_OTP_ACTIVATION_PHONE_NUMBER:
      return {
        ...state,
        otp_phone_number: {
          ...state.otp_phone_number,
          l_confirm: action.payload,
        },
      };
    case types.UPDATE_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          phone_number: action.payload,
        },
      };
    case types.IS_LOADING_UPDATE_PHONE_NUMBER:
      return { ...state, isLoadingUpdatePhoneNumber: action.payload };
    case types.REQUEST_CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        request_change_email: {
          ...state.request_change_email,
          email: action.payload.email,
          success: action.payload.success,
          message: action.payload.message,
        },
      };
    case types.IS_LOADING_REQUEST_CHANGE_EMAIL:
      return {
        ...state,
        request_change_email: {
          ...state.request_change_email,
          loading: action.payload,
        },
      };
    case types.CHECK_PASSWORD_SUCCESS:
      return {
        ...state,
        check_password: {
          ...state.check_password,
          success: action.payload.success,
          message: action.payload.message,
        },
      };
    case types.IS_LOADING_CHECK_PASSWORD:
      return {
        ...state,
        check_password: {
          ...state.check_password,
          loading: action.payload,
        },
      };
    case types.SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case types.SET_ERROR_PROFILE:
      return {
        ...state,
        error: {
          value: action.payload.value,
          message: action.payload.message,
        },
      };
    default:
      return state;
  }
};
export const setError = (payload) => ({
  type: types.SET_ERROR_PROFILE,
  payload,
});
export const requestChangeEmail = (payload) => ({
  type: types.REQUEST_CHANGE_EMAIL,
  payload,
});
export const requestChangeEmailSuccess = (payload) => ({
  type: types.REQUEST_CHANGE_EMAIL_SUCCESS,
  payload,
});
export const setLoadingRequestChangeEmail = (payload) => ({
  type: types.IS_LOADING_REQUEST_CHANGE_EMAIL,
  payload,
});

export const getOtpActivationPhoneNumber = (payload) => ({
  type: types.GET_OTP_ACTIVATION_PHONE_NUMBER,
  payload,
});
export const getOtpActivationPhoneNumberSuccess = (payload) => ({
  type: types.GET_OTP_ACTIVATION_PHONE_NUMBER_SUCCESS,
  payload,
});
export const setIsLoadingGetOtpActivationPhoneNumber = (payload) => ({
  type: types.IS_LOADING_GET_OTP_ACTIVATION_PHONE_NUMBER,
  payload,
});

export const confirmOtpActivationPhoneNumber = (payload) => ({
  type: types.CONFIRM_OTP_ACTIVATION_PHONE_NUMBER,
  payload,
});
export const confirmOtpActivationPhoneNumberSuccess = (payload) => ({
  type: types.CONFIRM_OTP_ACTIVATION_PHONE_NUMBER_SUCCESS,
  payload,
});
export const setIsLoadingConfirmOtpActivationPhoneNumber = (payload) => ({
  type: types.IS_LOADING_CONFIRM_OTP_ACTIVATION_PHONE_NUMBER,
  payload,
});

export const updatePhoneNumber = (payload) => ({
  type: types.UPDATE_PHONE_NUMBER,
  payload,
});
export const updatePhoneNumberSuccess = (payload) => ({
  type: types.UPDATE_PHONE_NUMBER_SUCCESS,
  payload,
});
export const setIsLoadingUpdatePhoneNumberSuccess = (payload) => ({
  type: types.IS_LOADING_UPDATE_PHONE_NUMBER,
  payload,
});

//GET DETAIL
export const getDetailProfile = (payload) => ({
  type: types.GET_DETAIL_PROFILE,
  payload,
});
export const getDetailProfileSuccess = (payload) => ({
  type: types.GET_DETAIL_PROFILE_SUCCESS,
  payload,
});
export const setIsLoadingGetDetailProfile = (payload) => ({
  type: types.IS_LOADING_GET_DETAIL_PROFILE,
  payload,
});
//UPDATE PROFILE
export const updateProfile = (payload) => ({
  type: types.UPDATE_PROFILE,
  payload,
});
export const updateProfileSuccess = (payload) => ({
  type: types.UPDATE_PROFILE_SUCCESS,
  payload,
});
export const setIsLoadingUpdateProfile = (payload) => ({
  type: types.IS_LOADING_UPDATE_PROFILE,
  payload,
});
//UPDATE AVATAR
export const updateAvatar = (payload) => ({
  type: types.UPDATE_AVATAR,
  payload,
});
export const updateAvatarSuccess = (payload) => ({
  type: types.UPDATE_AVATAR_SUCCESS,
  payload,
});
export const setIsLoadingUpdateAvatar = (payload) => ({
  type: types.IS_LOADING_UPDATE_AVATAR,
  payload,
});
//respond
export const setIsResponseSuccess = (payload) => ({
  type: types.RESPONSE_SUCCESS,
  payload,
});

export const checkPassword = (payload) => ({
  type: types.CHECK_PASSWORD,
  payload,
});
export const checkPasswordSuccess = (payload) => ({
  type: types.CHECK_PASSWORD_SUCCESS,
  payload,
});
export const setIsLoadingCheckPassword = (payload) => ({
  type: types.IS_LOADING_CHECK_PASSWORD,
  payload,
});

export const setMessage = (payload) => ({
  type: types.SET_MESSAGE,
  payload,
});
