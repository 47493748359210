export default {
  //detail product
  GET_DETAIL_PRODUCT : 'GET_DETAIL_PRODUCT',
  GET_DETAIL_PRODUCT_SUCCESS : 'GET_DETAIL_PRODUCT_SUCCESS',
  IS_LOADING_GET_DETAIL_PRODUCT : 'IS_LOADING_GET_DETAIL_PRODUCT',
  RESET_DETAIL_PRODUCT : 'RESET_DETAIL_PRODUCT',
  //disscussion by product_id
  SET_COUNT_DISCUSSION_BY_PRODUCT_ID : 'SET_COUNT_DISCUSSION_BY_PRODUCT_ID',
  GET_DISCUSSION_BY_PRODUCT_ID : 'GET_DISCUSSION_BY_PRODUCT_ID',
  GET_DISCUSSION_BY_PRODUCT_ID_SUCCESS : 'GET_DISCUSSION_BY_PRODUCT_ID_SUCCESS',
  CREATE_DISCUSSION_BY_PRODUCT_ID : 'CREATE_DISCUSSION_BY_PRODUCT_ID',
  CREATE_DISCUSSION_BY_PRODUCT_ID_SUCCESS : 'CREATE_DISCUSSION_BY_PRODUCT_ID_SUCCESS',
  IS_LOADING_GET_DISCUSSION_BY_PRODUCT_ID : 'IS_LOADING_GET_DISCUSSION_BY_PRODUCT_ID',
  IS_LOADING_CREATE_DISCUSSION_BY_PRODUCT_ID : 'IS_LOADING_CREATE_DISCUSSION_BY_PRODUCT_ID',
  RESET_DISCUSSION_BY_PRODUCT_ID : 'RESET_DISCUSSION_BY_PRODUCT_ID',
  //create comment
  CREATE_COMMENT_BY_DISCUSSION_ID : 'CREATE_COMMENT_BY_DISCUSSION_ID',
  CREATE_COMMENT_BY_DISCUSSION_ID_SUCCESS : 'CREATE_COMMENT_BY_DISCUSSION_ID_SUCCESS',
  IS_LOADING_CREATE_COMMENT : 'IS_LOADING_CREATE_COMMENT',
  //review by product_id
  SET_COUNT_REVIEW_BY_PRODUCT_ID: 'SET_COUNT_REVIEW_BY_PRODUCT_ID',
  GET_REVIEW_BY_PRODUCT_ID : 'GET_REVIEW_BY_PRODUCT_ID',
  GET_REVIEW_BY_PRODUCT_ID_SUCCESS : 'GET_REVIEW_BY_PRODUCT_ID_SUCCESS',
  IS_LOADING_GET_REVIEW_BY_PRODUCT_ID : 'IS_LOADING_GET_REVIEW_BY_PRODUCT_ID',
  RESET_REVIEW_BY_PRODUCT_ID : 'RESET_REVIEW_BY_PRODUCT_ID',
  //courier by store_id
  GET_COURIER_BY_STORE_ID : 'GET_COURIER_BY_STORE_ID',
  GET_COURIER_BY_STORE_ID_SUCCESS : 'GET_COURIER_BY_STORE_ID_SUCCESS',
  IS_LOADING_GET_COURIER_BY_STORE_ID : 'IS_LOADING_GET_COURIER_BY_STORE_ID',
  RESET_COURIER_BY_STORE_ID : 'RESET_COURIER_BY_STORE_ID',
  //
  GET_VIEWED_PRODUCT: 'GET_VIEWED_PRODUCT',
  UPDATE_VIEWED_PRODUCT: 'UPDATE_VIEWED_PRODUCT',
  GET_VIEWED_PRODUCT_SUCCESS:'GET_VIEWED_PRODUCT_SUCCESS',
  IS_LOADING_GET_VIEWED_PRODUCT:'IS_LOADING_GET_VIEWED_PRODUCT',

  GET_SOLD_PRODUCT: 'GET_SOLD_PRODUCT',
  GET_SOLD_PRODUCT_SUCCESS: 'GET_SOLD_PRODUCT_SUCCESS',
  IS_LOADING_GET_SOLD_PRODUCT: 'IS_LOADING_GET_SOLD_PRODUCT',

  SET_SHOW_CHATS_FROM_PRODUCT: 'SET_SHOW_CHATS_FROM_PRODUCT',

  CHECK_COST_SHIPPING_MULTIPLE : 'check-cost-shipping-multiple-from-product',
  CHECK_COST_SHIPPING_MULTIPLE_SUCCESS : 'check-cost-shipping-multiple-from-product-success',
  IS_LOADING_CHECK_COST_SHIPPING_MULTIPLE : 'is-loading-check-cost-shipping-multiple-from-product',

  GET_PRODUCTS_BY_STORE_ID_ON_DETAIL_PRODUCT: 'GET_PRODUCTS_BY_STORE_ID_ON_DETAIL_PRODUCT',
  GET_PRODUCTS_BY_STORE_ID_ON_DETAIL_PRODUCT_SUCCESS: 'GET_PRODUCTS_BY_STORE_ID_ON_DETAIL_PRODUCT_SUCCESS',
  IS_LOADING_GET_PRODUCTS_BY_STORE_ID_ON_DETAIL_PRODUCT: 'IS_LOADING_GET_PRODUCTS_BY_STORE_ID_ON_DETAIL_PRODUCT',
  SET_COUNT_PRODUCTS_BY_STORE_ID_ON_DETAIL_PRODUCT: 'SET_COUNT_PRODUCTS_BY_STORE_ID_ON_DETAIL_PRODUCT',
}
