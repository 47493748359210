import { Api } from './api';

export default {
  check: payload => Api.get(`/v1/wishlist/${payload.product_id}`, {headers: {token: payload.token}}),
  checkProducts: payload => Api.get(`/v2/wishlist/check/products`, {headers: {token: payload.token}}),
  get: payload => Api.get('/v1/wishlist/user', {
    headers: {
      token: payload.token
    },
    params: {
      limit: payload.limit,
      page: payload.page
    }
  }),
  add: payload => Api.post('/v1/wishlist', {product_id:payload.product_id}, {headers: {token: payload.token}}),
  delete: payload => Api.delete(`/v1/wishlist/${payload.id}`, {headers: {token: payload.token}}),
  removeWithProductId: payload => Api.delete(`/v2/wishlist/product/${payload.product_id}`, {headers: {token: payload.token}}),
};
