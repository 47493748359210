export default {
  GET_PROFILE_ACCOUNT: "get-profile-account",
  GET_PROFILE_ACCOUNT_SUCCESS: "get-profile-account-success",
  IS_LOADING_GET_PROFILE_ACCOUNT: "is-loading-get-profile-account",
  RESET_GET_PROFILE_ACCOUNT: "reset-get-profile-account",

  CHECK_PASSWORD_ACCOUNT: "check-password-account",
  CHECK_PASSWORD_ACCOUNT_SUCCESS: "check-password-account-success",
  IS_LOADING_CHECK_PASSWORD_ACCOUNT: "is-loading-check-password-account",
  RESET_CHECK_PASSWORD_ACCOUNT: "reset-check-password-account",

  // update email account
  REQUEST_OTP_WITH_EMAIL_TO_CHANGE_EMAIL_ACCOUNT:
    "request-otp-with-email-to-change-email-account",
  REQUEST_OTP_WITH_PHONE_NUMBER_TO_CHANGE_EMAIL_ACCOUNT:
    "request-otp-with-phone-number-to-change-email-account",
  REQUEST_OTP_TO_CHANGE_EMAIL_ACCOUNT_SUCCESS:
    "request-otp-to-change-email-account-success",
  IS_LOADING_REQUEST_OTP_TO_CHANGE_EMAIL_ACCOUNT:
    "is-loading-request-otp-to-change-email-account",
  RESET_REQUEST_OTP_TO_CHANGE_EMAIL_ACCOUNT:
    "reset-request-otp-to-change-email-account",

  VERIFY_OTP_TO_CHANGE_EMAIL_ACCOUNT: "verify-otp-to-change-email-account",
  VERIFY_OTP_TO_CHANGE_EMAIL_ACCOUNT_SUCCESS:
    "verify-otp-to-change-email-account-success",
  IS_LOADING_VERIFY_OTP_TO_CHANGE_EMAIL_ACCOUNT:
    "is-loading-verify-otp-to-change-email-account",
  RESET_VERIFY_OTP_TO_CHANGE_EMAIL_ACCOUNT:
    "reset-verify-otp-to-change-email-account",

  CHANGE_NEW_EMAIL_ACCOUNT: "change-new-email-account",
  CHANGE_NEW_EMAIL_ACCOUNT_SUCCESS: "change-new-email-account-success",
  IS_LOADING_CHANGE_NEW_EMAIL_ACCOUNT: "is-loading-change-new-email-account",
  RESET_CHANGE_NEW_EMAIL_ACCOUNT: "reset-change-new-email-account",

  VERIFY_OTP_CHANGE_NEW_EMAIL: "verify-otp-change-new-email",
  VERIFY_OTP_CHANGE_NEW_EMAIL_SUCCESS: "verify-otp-change-new-email-success",
  IS_LOADING_VERIFY_OTP_CHANGE_NEW_EMAIL:
    "is-loading-verify-otp-change-new-email",
  RESET_VERIFY_OTP_CHANGE_NEW_EMAIL: "reset-verify-otp-change-new-email",
  RESET_CHANGE_NEW_EMAIL: "reset-change-new-email",
  //verify email
  REQUEST_OTP_VERIFY_EMAIL: "request-otp-verify-email",
  REQUEST_OTP_VERIFY_EMAIL_SUCCESS: "request-otp-verify-email-success",
  IS_LOADING_REQUEST_OTP_VERIFY_EMAIL: "is-loading-request-otp-verify-email",
  RESET_REQUEST_OTP_VERIFY_EMAIL: "reset-request-otp-verify-email",

  VERIFY_OTP_VERIFY_EMAIL: "verify-otp-verify-email",
  VERIFY_OTP_VERIFY_EMAIL_SUCCESS: "verify-otp-verify-email-success",
  IS_LOADING_VERIFY_OTP_VERIFY_EMAIL: "is-loading-verify-otp-verify-email",
  RESET_VERIFY_OTP_VERIFY_EMAIL: "reset-verify-otp-verify-email",

  //update phone number account
  REQUEST_OTP_WITH_EMAIL_TO_CHANGE_PHONE_NUMBER_ACCOUNT:
    "request-otp-with-email-to-change-phone-number-account",
  REQUEST_OTP_WITH_PHONE_NUMBER_TO_CHANGE_PHONE_NUMBER_ACCOUNT:
    "request-otp-with-phone-number-to-change-phone-number-account",
  REQUEST_OTP_TO_CHANGE_PHONE_NUMBER_ACCOUNT_SUCCESS:
    "request-otp-to-change-phone-number-account-success",
  IS_LOADING_REQUEST_OTP_TO_CHANGE_PHONE_NUMBER_ACCOUNT:
    "is-loading-request-otp-to-change-phone-number-account",
  RESET_REQUEST_OTP_TO_CHANGE_PHONE_NUMBER_ACCOUNT:
    "reset-request-otp-to-change-phone-number-account",

  VERIFY_OTP_TO_CHANGE_PHONE_NUMBER_ACCOUNT:
    "verify-otp-to-change-phone-number-account",
  VERIFY_OTP_TO_CHANGE_PHONE_NUMBER_ACCOUNT_SUCCESS:
    "verify-otp-to-change-phone-number-account-success",
  IS_LOADING_VERIFY_OTP_TO_CHANGE_PHONE_NUMBER_ACCOUNT:
    "is-loading-verify-otp-to-change-phone-number-account",
  RESET_VERIFY_OTP_TO_CHANGE_PHONE_NUMBER_ACCOUNT:
    "reset-verify-otp-to-change-phone-number-account",

  CHANGE_NEW_PHONE_NUMBER_ACCOUNT: "change-new-phone-number-account",
  CHANGE_NEW_PHONE_NUMBER_ACCOUNT_SUCCESS:
    "change-new-phone-number-account-success",
  IS_LOADING_CHANGE_NEW_PHONE_NUMBER_ACCOUNT:
    "is-loading-change-new-phone-number-account",
  RESET_CHANGE_NEW_PHONE_NUMBER_ACCOUNT:
    "reset-change-new-phone-number-account",

  VERIFY_OTP_CHANGE_NEW_PHONE_NUMBER: "verify-otp-change-new-phone-number",
  VERIFY_OTP_CHANGE_NEW_PHONE_NUMBER_SUCCESS:
    "verify-otp-change-new-phone-number-success",
  IS_LOADING_VERIFY_OTP_CHANGE_NEW_PHONE_NUMBER:
    "is-loading-verify-otp-change-new-phone-number",
  RESET_VERIFY_OTP_CHANGE_NEW_PHONE_NUMBER:
    "reset-verify-otp-change-new-phone-number",
  RESET_CHANGE_NEW_PHONE_NUMBER: "reset-change-new-phone-number",
  //verify phone_number
  REQUEST_OTP_VERIFY_PHONE_NUMBER: "request-otp-verify-phone-number",
  REQUEST_OTP_VERIFY_PHONE_NUMBER_SUCCESS:
    "request-otp-verify-phone-number-success",
  IS_LOADING_REQUEST_OTP_VERIFY_PHONE_NUMBER:
    "is-loading-request-otp-verify-phone-number",
  RESET_REQUEST_OTP_VERIFY_PHONE_NUMBER:
    "reset-request-otp-verify-phone-number",

  VERIFY_OTP_VERIFY_PHONE_NUMBER: "verify-otp-verify-phone-number",
  VERIFY_OTP_VERIFY_PHONE_NUMBER_SUCCESS:
    "verify-otp-verify-phone-number-success",
  IS_LOADING_VERIFY_OTP_VERIFY_PHONE_NUMBER:
    "is-loading-verify-otp-verify-phone-number",
  RESET_VERIFY_OTP_VERIFY_PHONE_NUMBER: "reset-verify-otp-verify-phone-number",
};
