import { put, call, takeLatest, select } from 'redux-saga/effects';

import Api from '../../../../services';
import types from './constants';
import * as actions from './reducer';
import * as actionsAccount from '../../reducer';

function* watchGetAllOrders(values) {
  const state = yield select();
  const { payload } = values;
  yield put(actions.setIsLoadingGetOrder(true));
  try {
    const response = yield call(Api.buyertransaction.getAllOrder, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getOrderSuccess(data.data));
      yield put(actions.setCount(data.count));
    }
  } catch (error) {
    yield put(actions.getOrderSuccess(state.order.data));
  } finally {
    yield put(actions.setIsLoadingGetOrder(false));
  }
}
function* watchGetOrderWaitingConfirmation(values) {
  const state = yield select();
  const { payload } = values;
  yield put(actions.setIsLoadingGetOrder(true));
  try {
    const response = yield call(Api.buyertransaction.getOrderWaitingConfirmation, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getOrderSuccess(data.data));
      yield put(actions.setCount(data.count));
    }
  } catch (error) {
    yield put(actions.getOrderSuccess(state.order.data));
  } finally {
    yield put(actions.setIsLoadingGetOrder(false));
  }
}
function* watchGetOrderProcessed(values) {
  const state = yield select();
  const { payload } = values;
  yield put(actions.setIsLoadingGetOrder(true));
  try {
    const response = yield call(Api.buyertransaction.getOrderProcessed, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getOrderSuccess(data.data));
      yield put(actions.setCount(data.count));
    }
  } catch (error) {
    yield put(actions.getOrderSuccess(state.order.data));
  } finally {
    yield put(actions.setIsLoadingGetOrder(false));
  }
}
function* watchGetOrderShipped(values) {
  const state = yield select();
  const { payload } = values;
  yield put(actions.setIsLoadingGetOrder(true));
  try {
    const response = yield call(Api.buyertransaction.getOrderShipping, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getOrderSuccess(data.data));
      yield put(actions.setCount(data.count));
    }
  } catch (error) {
    yield put(actions.getOrderSuccess(state.order.data));
  } finally {
    yield put(actions.setIsLoadingGetOrder(false));
  }
}
function* watchGetOrderArrived(values) {
  const state = yield select();
  const { payload } = values;
  yield put(actions.setIsLoadingGetOrder(true));
  try {
    const response = yield call(Api.buyertransaction.getOrderArrived, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getOrderSuccess(data.data));
      yield put(actions.setCount(data.count));
    }
  } catch (error) {
    yield put(actions.getOrderSuccess(state.order.data));
  } finally {
    yield put(actions.setIsLoadingGetOrder(false));
  }
}
function* watchGetOrderDone(values) {
  const state = yield select();
  const { payload } = values;
  yield put(actions.setIsLoadingGetOrder(true));
  try {
    const response = yield call(Api.buyertransaction.getOrderDone, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getOrderSuccess(data.data));
      yield put(actions.setCount(data.count));
    }
  } catch (error) {
    yield put(actions.getOrderSuccess(state.order.data));
  } finally {
    yield put(actions.setIsLoadingGetOrder(false));
  }
}
function* watchGetOrderAborted(values) {
  const state = yield select();
  const { payload } = values;
  yield put(actions.setIsLoadingGetOrder(true));
  try {
    const response = yield call(Api.buyertransaction.getOrderAborted, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getOrderSuccess(data.data));
      yield put(actions.setCount(data.count));
    }
  } catch (error) {
    yield put(actions.getOrderSuccess(state.order.data));
  } finally {
    yield put(actions.setIsLoadingGetOrder(false));
  }
}
function* watchGetOrderComplaint(values) {
  const state = yield select();
  const { payload } = values;
  yield put(actions.setIsLoadingGetOrder(true));
  try {
    const response = yield call(Api.buyertransaction.getOrderComplaint, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getOrderSuccess(data.data));
      yield put(actions.setCount(data.count));
    }
  } catch (error) {
    yield put(actions.getOrderSuccess(state.order.data));
  } finally {
    yield put(actions.setIsLoadingGetOrder(false));
  }
}
function* watchGetDetailOrder(values) {
  const state = yield select();
  const { payload } = values;
  yield put(actions.setIsLoadingGetDetailOrder(true));
  try {
    const response = yield call(Api.buyertransaction.getDetailOrder, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getDetailOrderSuccess(data.data));
    }
  } catch (error) {
    yield put(actions.getDetailOrderSuccess(state.order.detail));
  } finally {
    yield put(actions.setIsLoadingGetDetailOrder(false));
  }
}

function* watchUpdateToShipped(values) {
  const state = yield select();
  const { payload } = values;
  const { notification } = state.account;
  yield put(actions.setIsLoadingUpdateOrder(true));
  try {
    const response = yield call(Api.buyertransaction.updateToShipped, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.updateOrderSuccess(data));
      yield put(actions.setOrderUpdated(true))
      yield put(actionsAccount.getOrderNotificationSuccess({
        ...notification.order,
        processed: payload.status_id === 4 ? notification.order.processed - 1 : notification.order.processed,
        shipped: notification.order.shipped + 1
      }));
    }
  } catch (error) {
    // yield put(actions.getOrderSuccess(state.order.data));
  } finally {
    yield put(actions.setIsLoadingUpdateOrder(false));
    yield put(actions.setOrderUpdated(false))
  }
}
function* watchUpdateToArrived(values) {
  const state = yield select();
  const { payload } = values;
  const { notification } = state.account;
  yield put(actions.setIsLoadingUpdateOrder(true));
  try {
    const response = yield call(Api.buyertransaction.updateToArrived, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.updateOrderSuccess(data));
      yield put(actions.setOrderUpdated(true));
      yield put(actionsAccount.getOrderNotificationSuccess({
        ...notification.order,
        processed: payload.status_id === 4 ? notification.order.processed - 1 : notification.order.processed,
        shipped: payload.status_id === 5 ||  payload.status_id === 6 ? notification.order.shipped - 1 : notification.order.shipped,
        arrived: notification.order.arrived + 1
      }));
    }
  } catch (error) {
    // yield put(actions.getOrderSuccess(state.order.data));
  } finally {
    yield put(actions.setIsLoadingUpdateOrder(false));
    yield put(actions.setOrderUpdated(false))
  }
}
function* watchUpdateToDone(values) {
  const state = yield select();
  const { payload } = values;
  const { notification } = state.account;
  yield put(actions.setIsLoadingUpdateOrder(true));
  try {
    const response = yield call(Api.buyertransaction.updateToDone, payload);
    const { data } = response;
    if (data.success) {
      let result ={
        id: payload.order_id,
        ...data
      }
      yield put(actions.updateOrderSuccess(result));
      yield put(actions.setOrderUpdated(true))
      yield put(actionsAccount.getOrderNotificationSuccess({
        ...notification.order,
        arrived: notification.order.arrived - 1
      }));
    }
  } catch (error) {
    // yield put(actions.getOrderSuccess(state.order.data));
  } finally {
    yield put(actions.setIsLoadingUpdateOrder(false));
    yield put(actions.setOrderUpdated(false))
  }
}


const sagas = [
  takeLatest(types.GET_ALL_ORDERS, watchGetAllOrders),
  takeLatest(types.GET_ORDER_WAITING_CONFIRMATION, watchGetOrderWaitingConfirmation),
  takeLatest(types.GET_ORDER_PROCESSED, watchGetOrderProcessed),
  takeLatest(types.GET_ORDER_SHIPPED, watchGetOrderShipped),
  takeLatest(types.GET_ORDER_ARRIVED, watchGetOrderArrived),
  takeLatest(types.GET_ORDER_DONE, watchGetOrderDone),
  takeLatest(types.GET_ORDER_ABORTED, watchGetOrderAborted),
  takeLatest(types.GET_ORDER_COMPLAINT, watchGetOrderComplaint),
  takeLatest(types.GET_DETAIL_ORDER, watchGetDetailOrder),

  takeLatest(types.UPDATE_ORDER_SHIPPED, watchUpdateToShipped),
  takeLatest(types.UPDATE_ORDER_ARRIVED, watchUpdateToArrived),
  takeLatest(types.UPDATE_ORDER_DONE, watchUpdateToDone),
];

export default sagas;
