import { put, call, takeLatest, select } from 'redux-saga/effects';
import Api from '../../services';
import types from './constants';
import * as actions from './reducer';

function* watchUpdateViewedProduct(values){
  const { payload } = values;
  const state = yield select();
  try{
    const response = yield call(Api.product.updateViewed,payload);
    const { data } = response;
    if(data.success){
      yield put(actions.getViewedProductSuccess(state.product.viewed + 1))
    }
  }catch(e){
    yield put(actions.getViewedProductSuccess(0))
  }finally{}
}
function* watchGetViewedProduct(values){
  yield put(actions.isLoadingGetViewedProduct(true));
  const { payload } = values;
  try{
    const response = yield call(Api.product.getViewed,payload);
    const { data } = response;
    if(data.success){
      yield put(actions.getViewedProductSuccess(data.count))
    }
  }catch(e){
    yield put(actions.getViewedProductSuccess(0))
  }finally{
    yield put(actions.isLoadingGetViewedProduct(false));
  }
}
function* watchGetSoldProduct(values){
  yield put(actions.isLoadingGetSoldProduct(true));
  const { payload } = values;
  try{
    const response = yield call(Api.product.getSold,payload);
    const { data } = response;
    if(data.success){
      yield put(actions.getSoldProductSuccess(data.count))
    }
  }catch(e){
    yield put(actions.getSoldProductSuccess(0))
  }finally{
    yield put(actions.isLoadingGetSoldProduct(false));
  }
}
function* watchGetDetailProduct(values){
  yield put(actions.isLoadingGetDetailProduct(true))
  const {payload} = values;
  try{
    const response = yield call(Api.product.getProductSubdomain, payload)
    const {data} = response;
    if(response.status === 200){
      yield put(actions.getDetailProductSuccess(data.data))
    }
  }catch(e){
    yield put(actions.getDetailProductSuccess({}))
    yield put(actions.isLoadingGetDetailProduct(false))
  }finally{
    yield put(actions.isLoadingGetDetailProduct(false))
  }
}
function* watchGetDiscussionProduct(values){
  yield put(actions.isLoadingGetDiscussion(true))
  const {payload} = values;
  try{
    const response = yield call(Api.product.getDiscussionByProductId, payload)
    const {data} = response;
    if(response.status === 200){
      yield put(actions.getDiscussionSuccess(data.data))
      yield put(actions.setCountDiscussions(data.count))
    }
  }catch(e){
    yield put(actions.getDiscussionSuccess([]))
    yield put(actions.isLoadingGetDiscussion(false))
  }finally{
    yield put(actions.isLoadingGetDiscussion(false))
  }
}
function* watchCreateDiscussion(values){
  yield put(actions.isLoadingCreateDiscussion(true));
  const {payload} = values;
  const state = yield select();
  try{
    const response = yield call(Api.product.createDiscussion, payload)
    const {data} = response;
    if(response.status === 200){
      const finalResult = {
        ...data,
        User: state.login.user,
        Product: state.product.detail,
        Comments:[]
      }
      yield put(actions.createDiscussionSuccess(finalResult))
    }
  }catch(e){
    yield put(actions.isLoadingCreateDiscussion(false))
  }finally{
    yield put(actions.isLoadingCreateDiscussion(false))
  }
}
function* watchCreateComment(values){
  yield put(actions.isLoadingCreateComment(true))
  const {payload} = values;
  const state = yield select();
  try{
    const response = yield call(Api.product.createComment, payload)
    const {data} = response;
    if(response.status === 200){
      const finalResult = {
        ...data,
        User: state.login.user,
      }
      yield put(actions.createCommentSuccess(finalResult))
    }
  }catch(e){
    yield put(actions.isLoadingCreateComment(false))
  }finally{
    yield put(actions.isLoadingCreateComment(false))
  }
}

function* watchGetReviewProduct(values){
  yield put(actions.isLoadingGetReview(true))
  const {payload} = values;
  try{
    const response = yield call(Api.product.getReviewByProductId, payload)
    const {data} = response;
    if(response.status === 200){
      yield put(actions.getReviewSuccess(data.data))
      yield put(actions.setCountReviews(data.count))
    }
  }catch(e){
    yield put(actions.getReviewSuccess([]))
    yield put(actions.isLoadingGetReview(false))
  }finally{
    yield put(actions.isLoadingGetReview(false))
  }
}

function* watchGetCourierByStore(values){
  yield put(actions.isLoadingGetCourier(true))
  const {payload} = values;
  try{
    const response = yield call(Api.product.getCourierByStoreId, payload)
    const {data} = response;
    if(response.status === 200){
      yield put(actions.getCourierSuccess(data.data))
    }
  }catch(e){
    yield put(actions.getCourierSuccess([]))
    yield put(actions.isLoadingGetCourier(false))
  }finally{
    yield put(actions.isLoadingGetCourier(false))
  }
}

function* watchCheckCostShippingMultiple(values){
  const {payload} = values;
  yield put(actions.isLoadingGetCheckCostShipping(true))
  try{
    const response = yield call(Api.checkcost.multiple, payload)
    const {data} = response;
    if(data.success){
      yield put(actions.getCheckCostShippingSuccess(data.data));
    }
  }catch(e){
    yield put(actions.isLoadingGetCheckCostShipping(false))
  }finally{
    yield put(actions.isLoadingGetCheckCostShipping(false))
  }
}

function* watchGetOtherProducts(values){
  yield put(actions.setIsLoadingGetOtherProducts(true))
  const {payload} = values;
  try{
    const response = yield call(Api.product.getOtherProducts, payload)
    const {data} = response;
    if(response.status === 200){
      yield put(actions.getOtherProductsSuccess(data.data))
      yield put(actions.setCountOtherProducts(data.count))
    }
  }catch(e){
    yield put(actions.getOtherProductsSuccess([]))
    yield put(actions.setCountOtherProducts(0))
    yield put(actions.setIsLoadingGetOtherProducts(false))
  }finally{
    yield put(actions.setIsLoadingGetOtherProducts(false))
  }
}

const saga = [
  takeLatest(types.GET_DETAIL_PRODUCT, watchGetDetailProduct),
  takeLatest(types.GET_DISCUSSION_BY_PRODUCT_ID, watchGetDiscussionProduct),
  takeLatest(types.GET_REVIEW_BY_PRODUCT_ID, watchGetReviewProduct),
  takeLatest(types.GET_COURIER_BY_STORE_ID, watchGetCourierByStore),
  takeLatest(types.CREATE_COMMENT_BY_DISCUSSION_ID, watchCreateComment),
  takeLatest(types.CREATE_DISCUSSION_BY_PRODUCT_ID, watchCreateDiscussion),
  takeLatest(types.GET_VIEWED_PRODUCT, watchGetViewedProduct),
  takeLatest(types.UPDATE_VIEWED_PRODUCT, watchUpdateViewedProduct),
  takeLatest(types.GET_SOLD_PRODUCT, watchGetSoldProduct),
  takeLatest(types.CHECK_COST_SHIPPING_MULTIPLE, watchCheckCostShippingMultiple),
  takeLatest(types.GET_PRODUCTS_BY_STORE_ID_ON_DETAIL_PRODUCT, watchGetOtherProducts)
]

export default saga;
