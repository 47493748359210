import { put, call, takeLatest } from 'redux-saga/effects';

import Api from '../../../../services';
import types from './constant';
import * as actions from './reducer';

function* watchSendContactUs(values){
  const { payload } = values;
  yield put(actions.setIsLoadingSendContactUs(true));
  try {
    const response = yield call(Api.help.contactUs, payload);
    const { data } = response;
    if(data.success){
      yield put(actions.sendContactUsSuccess(true));
    }
  } catch (error) {
    yield put(actions.sendContactUsSuccess(false));
  } finally {
    yield put(actions.setIsLoadingSendContactUs(false));
  }
}
export default [
  takeLatest(types.SEND_CONTACT_US, watchSendContactUs)
]
