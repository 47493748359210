export default {
  GET_SEARCH_PRODUCT : 'GET_SEARCH_PRODUCT',
  GET_SEARCH_PRODUCT_SUCCESS : 'GET_SEARCH_PRODUCT_SUCCESS',
  IS_LOADING_GET_SEARCH_PRODUCT : 'IS_LOADING_GET_SEARCH_PRODUCT',
  ALL_LOAD_PRODUCT : 'ALL_LOAD_PRODUCT',
  GET_COUNT_PRODUCT:'GET_COUNT_PRODUCT',

  GET_SEARCH_STORE : 'GET_SEARCH_STORE',
  GET_SEARCH_STORE_SUCCESS : 'GET_SEARCH_STORE_SUCCESS',
  IS_LOADING_GET_SEARCH_STORE : 'IS_LOADING_GET_SEARCH_STORE',
  ALL_LOAD_STORE : 'ALL_LOAD_STORE',
  GET_COUNT_STORE:'GET_COUNT_STORE',

  SET_OPTION : 'SET_OPTION',
  CHANGE_SEARCH_NAME: 'CHANGE_SEARCH_NAME',
  RESET_SEARCH_DATA: 'RESET_SEARCH_DATA',
  CLEAR_SEARCH_RESULT_STATE_AT_UNMOUNT: 'CLEAR_SEARCH_RESULT_STATE_AT_UNMOUNT',
}
