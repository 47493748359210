export default {
  REGISTER: 'REGISTER',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  IS_REGISTER_LOADING: 'IS_REGISTER_LOADING',
  SET_REGISTER_MESSAGE: 'SET_REGISTER_MESSAGE',
  CLEAR_REGISTER_MESSAGE: 'CLEAR_REGISTER_MESSAGE',

  CHECK_EMAIL_REGISTER: 'check-email-register',
  CHECK_PHONE_NUMBER_REGISTER: 'check-phone-number-register',
  CHECK_REGISTER_SUCCESS: 'check-register-success',
  LOADING_CHECK_REGISTER: 'loading-check-register',
  DONE_CHECK_REGISTER: 'done-check-register',
  RESET_CHECK_REGISTER: 'reset-check-register',

  REQUEST_OTP_EMAIL_REGISTER: 'request-otp-email-register',
  REQUEST_OTP_PHONE_NUMBER_REGISTER: 'request-otp-phone-number-register',
  REQUEST_OTP_REGISTER_SUCCESS: 'request-otp-register-success',
  LOADING_REQUEST_OTP_REGISTER: 'loading-request-otp-register',
  RESET_REQUEST_REGISTER: 'reset-request-register',

  VERIFY_OTP_REGISTER: 'verify-email-register',
  VERIFY_OTP_REGISTER_SUCCESS: 'verify-email-register-success',
  LOADING_VERIFY_OTP_REGISTER: 'loading-verify-email-register',
  RESET_VERIFY_OTP_REGISTER: 'reset-verify-email-register',

};
