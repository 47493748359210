import types from './constants';

const initialState = {
  data: null,
  isLoadingCreateDonate:false,
  checkout: {
    amount: 0,
    _id: '',
    destination_account:'',
    payment_mode: ''
  },
  payment: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_DONATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        payment: action.payload.payment
      };
    case types.IS_LOADING_CREATE_DONATION:
    return {...state, isLoadingCreateDonate:action.payload}
    case types.SET_CHECKOUT:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          _id: action.payload._id,
          amount: action.payload.amount,
        }
      }
    case types.SET_PAYMENT:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          destination_account: action.payload.destination_account,
          payment_mode: action.payload.payment_mode
        }
      }
    case types.RESET_DONATION:
      return initialState;
    default:
      return state;
  }
}

export const setPayment = payload => ({
  type: types.SET_PAYMENT,
  payload
})
export const setCheckout = payload => ({
  type: types.SET_CHECKOUT,
  payload
})
export const createDonate = payload => ({
  type: types.CREATE_DONATION,
  payload
})
export const createDonateSuccess= payload => ({
  type: types.CREATE_DONATION_SUCCESS,
  payload
})
export const setIsLoadingCreateDonate= payload => ({
  type: types.IS_LOADING_CREATE_DONATION,
  payload
})

export const resetDonate = payload => ({
  type: types.RESET_DONATION
})
