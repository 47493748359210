import { combineReducers } from "redux";
import categoryReducer from "./components/categorypage/reducer";
import homepageReducer from "./components/homepage/reducer";
import loginReducer from "./components/loginpage/reducer";
import registerReducer from "./components/registerpage/reducer";
//account
import accountReducer from "./components/account/reducer";
import paymentReducer from "./components/account/pages/payment/reducer";
import profileReducer from "./components/account/pages/profile/reducer";
import addressReducer from "./components/account/pages/address/reducer";
import bankAccountReducer from "./components/account/pages/bank/reducer";
import orderReducer from "./components/account/pages/order/reducer";
// import chatReducer from "./components/account/pages/chat/reducer";
import listReducer from "./components/account/pages/list/reducer";
import depositReducer from "./components/account/pages/deposit/reducer";
import reviewReducer from "./components/account/pages/review/reducer";
import complaintReducer from "./components/account/pages/complaint/reducer";
import loginSecurityReducer from "./components/account/pages/loginsecurity/reducer";

import cartReducer from "./components/cartpage/reducer";
import searchReducer from "./components/searchpage/reducer";
import productReducer from "./components/productpage/reducer";
import buyReducer from "./components/buypage/reducer";
import shopReducer from "./components/shoppage/reducer";
import rfqReducer from "./components/rfqpage/reducer";
import sellerCenterReducer from "./components/sellercenter/reducer";
import donateReducer from "./components/donatepage/reducer";

import resetPasswordReducer from "./components/account/pages/resetpassword/reducer";
import forgotPasswordReducer from "./components/account/pages/forgotpassword/reducer";

import contactusReducer from "./views/help/components/contactus/reducer";
import helpReducer from "./modules/help/reducer";
// import helpReducer from "./components/account/pages/helpmessage/reducer";
import coinReducer from "./components/account/pages/coin/reducer";
import checkoutReducer from "./components/checkout/reducer";
import withdrawReducer from "./modules/withdraw/reducer";
import shippingReducer from "./modules/shipping/reducer";
import chatReducer from "./modules/chat/reducer";
import discussionReducer from "./modules/discussion/reducer";
import accountProfileReducer from "./modules/account/reducer";
import landingpageReducer from "./modules/landingpage/reducer";

const rootReducer = combineReducers({
  home: homepageReducer,
  c: categoryReducer,
  login: loginReducer,
  register: registerReducer,
  //account
  account: accountReducer,
  payment: paymentReducer,
  profile: profileReducer,
  address: addressReducer,
  bankaccount: bankAccountReducer,
  order: orderReducer,
  // chat: chatReducer,
  chat: chatReducer,
  list: listReducer,
  deposit: depositReducer,
  review: reviewReducer,
  discussion: discussionReducer,
  complaint: complaintReducer,
  resetpassword: resetPasswordReducer,
  forgotpassword: forgotPasswordReducer,
  loginsecurity: loginSecurityReducer,

  search: searchReducer,
  cart: cartReducer,
  product: productReducer,
  buy: buyReducer,
  checkout: checkoutReducer,
  shop: shopReducer,
  rfq: rfqReducer,
  sellercenter: sellerCenterReducer,
  donation: donateReducer,

  contactus: contactusReducer,
  help: helpReducer,
  coin: coinReducer,
  shipping: shippingReducer,
  withdraw: withdrawReducer,
  accountprofile: accountProfileReducer,
  landingpage: landingpageReducer,
});

export default rootReducer;
