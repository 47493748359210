import types from './constants';

const initialState = {
  data: [],
  count:0,
  detail:null,
  isLoadingGetReview:false,
  isLoadingGetDetailReview:false,
  isLoadingCreateReview:false,
  isLoadingUpdateReview:false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REVIEW_ORDERS_SUCCESS:
      return {...state, data: action.payload};
    case types.SET_COUNT_REVIEW:
      return {...state, count:action.payload}
    case types.IS_LOADING_GET_REVIEWED_ORDERS:
      return {...state, isLoadingGetReview:action.payload}
    case types.IS_LOADING_GET_NOT_YET_REVIEWED_ORDER:
      return {...state, isLoadingGetReview:action.payload}
    case types.GET_DETAIL_REVIEW_SUCCESS:
      return {...state, detail:action.payload}
    case types.IS_LOADING_GET_DETAIL_REVIEW:
      return {...state, isLoadingGetDetailReview:action.payload}
    case types.CREATE_REVIEW_SUCCESS:
      return Object.assign({}, state, {
        data: state.data.map(item => {
          return item.id === action.payload.order_id ? {
            ...item,
            DetailOrders: item.DetailOrders.map(dt=>{
              return dt.id === action.payload.detailorder_id ? {
                ...dt,
                Point:action.payload.Point
              }: dt
            })
          } : item;
        }),
        detail: {
          ...state.detail,
          ...action.payload.Point
        }
      });
    case types.IS_LOADING_CREATE_REVIEW:
      return {...state, isLoadingCreateReview:action.payload}
    case types.UPDATE_REVIEW_SUCCESS:
      return Object.assign({}, state, {
        data: state.data.map(item => {
          return item.id === action.payload.order_id ? {
            ...item,
            DetailOrders: item.DetailOrders.map(dt=>{
              return dt.id === action.payload.detailorder_id ? {
                ...dt,
                Point:action.payload.Point
              }: dt
            })
          } : item;
        }),
        detail: {
          ...state.detail,
          ...action.payload.Point
        }
      });
    case types.IS_LOADING_UPDATE_REVIEW:
      return {...state, isLoadingUpdateReview:action.payload}
    case types.RESET_REVIEW:
      return {...state, data:[]}
    default:
      return state;
  }
}

export const getReviewedOrdersSuccess = payload => ({
  type: types.GET_REVIEW_ORDERS_SUCCESS,
  payload,
});
export const setCountReview = payload => ({
  type: types.SET_COUNT_REVIEW,
  payload
})
export const getReviewedOrders = payload => ({
  type: types.GET_REVIEWED_ORDERS,
  payload,
});
export const setIsLoadingGetReviewedOrders = payload => ({
  type: types.IS_LOADING_GET_REVIEWED_ORDERS,
  payload
})

export const getNotYetReviewedOrder = payload => ({
  type: types.GET_NOT_YET_REVIEWED_ORDER,
  payload,
});
export const setIsLoadingGetNotYetReviewedOrder = payload => ({
  type: types.IS_LOADING_GET_NOT_YET_REVIEWED_ORDER,
  payload
})

export const getDetailReview = payload => ({
  type: types.GET_DETAIL_REVIEW,
  payload
})
export const getDetailReviewSuccess = payload => ({
  type: types.GET_DETAIL_REVIEW_SUCCESS,
  payload
})
export const setIsLoadingGetDetailReview = payload => ({
  type: types.IS_LOADING_GET_DETAIL_REVIEW,
  payload
})


export const createReview = payload => ({
  type: types.CREATE_REVIEW,
  payload
})
export const createReviewSuccess= payload => ({
  type: types.CREATE_REVIEW_SUCCESS,
  payload
})
export const setIsLoadingCreateReview= payload => ({
  type: types.IS_LOADING_CREATE_REVIEW,
  payload
})

export const updateReview = payload => ({
  type: types.UPDATE_REVIEW,
  payload
})
export const updateReviewSuccess= payload => ({
  type: types.UPDATE_REVIEW_SUCCESS,
  payload
})
export const setIsLoadingUpdateReview= payload => ({
  type: types.IS_LOADING_UPDATE_REVIEW,
  payload
})

export const resetReview = payload => ({
  type: types.RESET_REVIEW
})
