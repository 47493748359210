import React from 'react'
import { CToast, CToastBody, CToastHeader, CToaster } from '@coreui/react'

const Toaster = ({
  position,
  autohide,
  fade,
  closeButton,
  title,
  message,
  show
}) => {
  return (
    <CToaster position={position || 'top-right'} className="w-40" style={{maxHeight: 91}}>
      <CToast show={show} autohide={autohide || 5000} fade={fade}>
        <CToastHeader closeButton={closeButton} >
          {title}
        </CToastHeader>
        <CToastBody>
          {message}
        </CToastBody>
      </CToast>
    </CToaster>
  )
}

export default Toaster
