import { put, call, takeLatest, select } from 'redux-saga/effects';
import Api from '../../services';
import types from './constant';
import * as actions from './reducer';

function* watchGetBanner(value){
  yield put(actions.isLoadingGetBanner(true))
  const state = yield select();
  try {
    const response = yield call(Api.home.getBannerHomepages);
    const {data} = response;
    if(response.status === 200){
      yield put(actions.getAllBannerSuccess(data.data));
    }
    // yield put(actions.getAllBannerSuccess(state.home.banners));
  } catch (e) {
    yield put(actions.getAllBannerSuccess(state.home.banners));
  } finally {
    yield put(actions.isLoadingGetBanner(false))
  }
}

function* watchGetCategories(){
  yield put(actions.isLoadingGetCategories(true))
  const state = yield select();
  try {
    const response = yield call(Api.home.getCategories);
    const {data} = response;
    if(response.status === 200){
      yield put(actions.getCategoriesSuccess(data.data));
    }
    // yield put(actions.getCategoriesSuccess(state.home.categories));

  } catch (e) {
    yield put(actions.getCategoriesSuccess(state.home.categories));
  } finally {
    yield put(actions.isLoadingGetCategories(false))
  }
}

function* watchGetRFQ(){
  yield put(actions.isLoadingGetRFQ(true))
  const state = yield select();
  try {
    const response = yield call(Api.home.getAllRFQ,{limit:3});
    const {data} = response;
    if(response.status === 200){
      yield put(actions.getRFQSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getRFQSuccess(state.home.rfq));
  } finally {
    yield put(actions.isLoadingGetRFQ(false))
  }
}

function* watchGetNewStores(values){
  yield put(actions.isLoadingNewStores(true))
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.home.getNewStore,payload);
    const {data} = response;
    if(response.status === 200){
      yield put(actions.getNewStoresSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getNewStoresSuccess(state.home.newStores));
  } finally {
    yield put(actions.isLoadingNewStores(false))
  }
}

function* watchGetNewProducts(values){
  yield put(actions.isLoadingNewProducts(true))
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.home.getNewProduct,payload);
    const {data} = response;
    if(response.status === 200){
      yield put(actions.getNewProductsSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getNewProductsSuccess(state.home.newProducts));
  } finally {
    yield put(actions.isLoadingNewProducts(false))
  }
}

const homeSagas = [
  takeLatest(types.GET_ALL_BANNER, watchGetBanner),
  takeLatest(types.GET_CATEGORY, watchGetCategories),
  takeLatest(types.GET_RFQ, watchGetRFQ),
  takeLatest(types.GET_NEW_STORES, watchGetNewStores),
  takeLatest(types.GET_NEW_PRODUCTS, watchGetNewProducts)
]

export default homeSagas;
