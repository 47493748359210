export default {
  CHECK_WISHLIST : 'CHECK_WISHLIST',
  CHECK_WISHLIST_SUCCESS : 'CHECK_WISHLIST_SUCCESS',
  IS_LOADING_CHECK_WISHLIST : 'IS_LOADING_CHECK_WISHLIST',

  CHECK_ALL_PRODUCTS_ON_WISHLIST : 'CHECK_ALL_PRODUCTS_ON_WISHLIST',
  CHECK_ALL_PRODUCTS_ON_WISHLIST_SUCCESS : 'CHECK_ALL_PRODUCTS_ON_WISHLIST_SUCCESS',
  IS_LOADING_CHECK_ALL_PRODUCTS_ON_WISHLIST : 'IS_LOADING_CHECK_ALL_PRODUCTS_ON_WISHLIST',

  SET_COUNT_WISHLIST : 'SET_COUNT_WISHLIST',
  GET_WISHLIST : 'GET_WISHLIST',
  GET_WISHLIST_SUCCESS : 'GET_WISHLIST_SUCCESS',
  IS_LOADING_GET_WISHLIST : 'IS_LOADING_GET_WISHLIST',

  CREATE_WISHLIST : 'CREATE_WISHLIST',
  CREATE_WISHLIST_SUCCESS : 'CREATE_WISHLIST_SUCCESS',
  IS_LOADING_CREATE_WISHLIST : 'IS_LOADING_CREATE_WISHLIST',

  DELETE_WISHLIST : 'DELETE_WISHLIST',
  DELETE_WISHLIST_SUCCESS : 'DELETE_WISHLIST_SUCCESS',
  IS_LOADING_DELETE_WISHLIST : 'IS_LOADING_DELETE_WISHLIST',

  DELETE_WISHLIST_BY_PRODUCT_ID : 'DELETE_WISHLIST_BY_PRODUCT_ID',
  DELETE_WISHLIST_BY_PRODUCT_ID_SUCCESS : 'DELETE_WISHLIST_BY_PRODUCT_ID_SUCCESS',
  IS_LOADING_DELETE_WISHLIST_BY_PRODUCT_ID : 'IS_LOADING_DELETE_WISHLIST_BY_PRODUCT_ID',

  SET_ADDED_WISHLIST: 'SET_ADDED_WISHLIST',
  SET_DELETED_WISHLIST: 'SET_DELETED_WISHLIST',

  SET_COUNT_FAVORITE : 'SET_COUNT_FAVORITE',
  CHECK_FAVORITE : 'CHECK_FAVORITE',
  CHECK_FAVORITE_SUCCESS : 'CHECK_FAVORITE_SUCCESS',
  IS_LOADING_CHECK_FAVORITE : 'IS_LOADING_CHECK_FAVORITE',

  GET_FAVORITE : 'GET_FAVORITE',
  GET_FAVORITE_SUCCESS : 'GET_FAVORITE_SUCCESS',
  IS_LOADING_GET_FAVORITE : 'IS_LOADING_GET_FAVORITE',

  CREATE_FAVORITE : 'CREATE_FAVORITE',
  CREATE_FAVORITE_SUCCESS : 'CREATE_FAVORITE_SUCCESS',
  IS_LOADING_CREATE_FAVORITE : 'IS_LOADING_CREATE_FAVORITE',

  DELETE_FAVORITE : 'DELETE_FAVORITE',
  DELETE_FAVORITE_SUCCESS : 'DELETE_FAVORITE_SUCCESS',
  IS_LOADING_DELETE_FAVORITE : 'IS_LOADING_DELETE_FAVORITE',
}
