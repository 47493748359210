import { all } from "redux-saga/effects";
import categorySaga from "./components/categorypage/saga";
import homeSaga from "./components/homepage/sagas";
import loginSaga from "./components/loginpage/sagas";
import registerSaga from "./components/registerpage/sagas";
//account
import accountSaga from "./components/account/sagas";
import paymentSaga from "./components/account/pages/payment/sagas";
import profileSaga from "./components/account/pages/profile/sagas";
import addressSaga from "./components/account/pages/address/sagas";
import bankSaga from "./components/account/pages/bank/sagas";
import orderSaga from "./components/account/pages/order/sagas";
import listSaga from "./components/account/pages/list/sagas";
import depositSaga from "./components/account/pages/deposit/sagas";
import reviewSaga from "./components/account/pages/review/sagas";
import complaintSaga from "./components/account/pages/complaint/sagas";
import resetPasswordSaga from "./components/account/pages/resetpassword/sagas";
import forgotPasswordSaga from "./components/account/pages/forgotpassword/sagas";
import loginSecuritySaga from "./components/account/pages/loginsecurity/sagas";

import cartSaga from "./components/cartpage/sagas";
import searchSaga from "./components/searchpage/sagas";
import productSaga from "./components/productpage/sagas";
import buySaga from "./components/buypage/sagas";
import shopSaga from "./components/shoppage/sagas";
import rfqSaga from "./components/rfqpage/sagas";
import sellerCenterSaga from "./components/sellercenter/sagas";
import donateSaga from "./components/donatepage/sagas";

import contactusSaga from "./views/help/components/contactus/saga";
// import helpSaga from "./components/account/pages/helpmessage/sagas";
import helpSaga from "./modules/help/saga";
import coinSaga from "./components/account/pages/coin/saga";
import checkoutSaga from "./components/checkout/saga";
import withdrawSaga from "./modules/withdraw/saga";
import shippingSaga from "./modules/shipping/saga";
import chatSaga from "./modules/chat/saga";
import discussionSaga from "./modules/discussion/saga";
import accountProfileSaga from "./modules/account/saga";

export default function* rootSaga() {
  yield all([
    all(categorySaga),
    all(homeSaga),
    all(loginSaga),
    all(registerSaga),
    //account
    all(accountSaga),
    all(paymentSaga),
    all(profileSaga),
    all(addressSaga),
    all(bankSaga),
    all(orderSaga),
    all(chatSaga),
    all(listSaga),
    all(depositSaga),
    all(reviewSaga),
    all(discussionSaga),
    all(complaintSaga),
    all(resetPasswordSaga),
    all(forgotPasswordSaga),
    all(loginSecuritySaga),

    all(cartSaga),
    all(searchSaga),
    all(productSaga),
    all(buySaga),
    all(checkoutSaga),
    all(shopSaga),
    all(rfqSaga),
    all(sellerCenterSaga),
    all(donateSaga),

    all(contactusSaga),
    all(helpSaga),
    all(coinSaga),
    all(shippingSaga),
    all(withdrawSaga),
    all(accountProfileSaga),
  ]);
}
