import types from './constants';

const initialState = {
  data: [],
  count: 0,
  detail: {},
  isLoadingGetOrder:false,
  isLoadingGetDetailOrder:false,
  chat: {
    show:false,
    order:null,
    store:null
  },
  isLoadingGetWaybill: false,
  updated: false,
  loading: {
    update: false,
    get: false,
  }
};
export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ORDER_SUCCESS:
      return {...state, data: action.payload};
    case types.SET_COUNT_ORDERS:
      return {...state, count: action.payload}
    case types.IS_LOADING_GET_ORDER:
      return {...state, isLoadingGetOrder: action.payload}
    case types.GET_DETAIL_ORDER_SUCCESS:
      return {...state, detail:action.payload}
    case types.IS_LOADING_GET_DETAIL_ORDER:
      return {...state, isLoadingGetDetailOrder:action.payload}
    case types.RESET_ORDER:
      return {...state, data:[]}

    case types.SET_SHOW_CHATS:
      return {
        ...state,
        chat: {
          show: action.payload.show,
          order: action.payload.order,
          store: action.payload.store
        }
      }


    case types.UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        data: action.payload.id ? state.data.filter(item => item.id !== action.payload.id) : state.data,
        detail: {
          ...state.detail,
          ...action.payload
        }
      }
    case types.IS_LOADING_UPDATE_ORDER:
      return {
        ...state,
        loading: {
          ...state.loading,
          update: action.payload
        }
      }
    case types.RESET_UPDATE_ORDER:
      return { ...state, detail: initialState.detail, updated: initialState.updated, loading: initialState.loading }
    case types.ORDER_UPDATED:
      return { ...state, updated: action.payload }
    default:
      return state;
  }
}

export const setShowWaybill = payload => ({
  type: types.SET_SHOW_WAYBILL,
  payload
})
export const setShowChat = payload => ({
  type: types.SET_SHOW_CHATS,
  payload
})
export const setCount = payload => ({
  type: types.SET_COUNT_ORDERS,
  payload
})

export const getWaybill = payload => ({
  type: types.GET_WAYBILL,
  payload,
});
export const getWaybillSuccess = payload => ({
  type: types.GET_WAYBILL_SUCCESS,
  payload,
});
export const setIsLoadingWaybill = payload => ({
  type: types.IS_LOADING_GET_WAYBILL,
  payload
})

export const getNotificationOrder = payload => ({
  type: types.GET_NOTIFICATION_ORDER,
  payload,
});
export const getNotificationOrderSuccess = payload => ({
  type: types.GET_NOTIFICATION_ORDER_SUCCESS,
  payload,
});
export const setIsLoadingNotificationGetOrder = payload => ({
  type: types.IS_LOADING_GET_NOTIFICATION_ORDER,
  payload
})

export const getAllOrders = payload => ({
  type: types.GET_ALL_ORDERS,
  payload,
});
export const getOrdersWaitingConfirmation = payload => ({
  type: types.GET_ORDER_WAITING_CONFIRMATION,
  payload,
});
export const getOrdersProcessed = payload => ({
  type: types.GET_ORDER_PROCESSED,
  payload,
});
export const getOrdersShipped = payload => ({
  type: types.GET_ORDER_SHIPPED,
  payload,
});
export const getOrdersArrived = payload => ({
  type: types.GET_ORDER_ARRIVED,
  payload,
});
export const getOrderDone = payload => ({
  type: types.GET_ORDER_DONE,
  payload
})
export const getOrderAborted = payload => ({
  type: types.GET_ORDER_ABORTED,
  payload
})
export const getOrderComplaint = payload => ({
  type: types.GET_ORDER_COMPLAINT,
  payload
})
export const getOrderSuccess = payload => ({
  type: types.GET_ORDER_SUCCESS,
  payload,
});
export const setIsLoadingGetOrder = payload => ({
  type: types.IS_LOADING_GET_ORDER,
  payload
})
export const resetOrder = payload => ({
  type: types.RESET_ORDER
});

export const getDetailOrder = payload => ({
  type: types.GET_DETAIL_ORDER,
  payload
})
export const getDetailOrderSuccess = payload => ({
  type: types.GET_DETAIL_ORDER_SUCCESS,
  payload
})
export const setIsLoadingGetDetailOrder = payload => ({
  type: types.IS_LOADING_GET_DETAIL_ORDER,
  payload
})

export const updateOrderShipped = payload => ({
  type: types.UPDATE_ORDER_SHIPPED,
  payload
})
export const updateOrderArrived = payload => ({
  type: types.UPDATE_ORDER_ARRIVED,
  payload
})
export const updateOrderDone = payload => ({
  type: types.UPDATE_ORDER_DONE,
  payload
})
export const updateOrderSuccess = payload => ({
  type: types.UPDATE_ORDER_SUCCESS,
  payload
})
export const setIsLoadingUpdateOrder = payload => ({
  type: types.IS_LOADING_UPDATE_ORDER,
  payload
})
export const resetUpdateOrder = payload => ({
  type: types.RESET_UPDATE_ORDER,
  payload
})
export const setOrderUpdated = payload => ({
  type: types.ORDER_UPDATED,
  payload
})
