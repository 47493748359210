export default {
  //GET_CART
  GET_CART: "GET_CART",
  GET_CART_SUCCESS: "GET_CART_SUCCESS",
  IS_LOADING_GET_CART: "IS_LOADING_GET_CART",
  //CREATE_CART
  CREATE_CART: "CREATE_CART",
  CREATE_CART_SUCCESS: "CREATE_CART_SUCCESS",
  IS_LOADING_CREATE_CART: "IS_LOADING_CREATE_CART",
  ADDED_CART: "ADDED_CART",
  //EDIT_CART
  EDIT_CART: "EDIT_CART",
  EDIT_CART_SUCCESS: "EDIT_CART_SUCCESS",
  IS_LOADING_EDIT_CART: "IS_LOADING_EDIT_CART",
  EDITED_CART: "EDITED_CART",
  //DELETE_CART
  DELETE_CART: "DELETE_CART",
  DELETE_CART_SUCCESS: "DELETE_CART_SUCCESS",
  DELETE_MULTIPLE_CART_ID: "DELETE_MULTIPLE_CART_ID",
  DELETE_MULTIPLE_CART_ID_SUCCESS: "DELETE_MULTIPLE_CART_ID_SUCCESS",
  IS_LOADING_DELETE_CART: "IS_LOADING_DELETE_CART",
  DELETED_CART: "DELETED_CART",

  //GET_COUNT_CART
  GET_COUNT_CART: "GET_COUNT_CART",
  GET_COUNT_CART_SUCCESS: "GET_COUNT_CART_SUCCESS",
  IS_LOADING_GET_COUNT_CART: "IS_LOADING_GET_COUNT_CART",
  //GET_SHIPPING_ADDRESS
  GET_SHIPPING_ADDRESS: "GET_SHIPPING_ADDRESS",
  GET_SHIPPING_ADDRESS_SUCCESS: "GET_SHIPPING_ADDRESS_SUCCESS",
  IS_LOADING_GET_SHIPPING_ADDRESS: "IS_LOADING_GET_SHIPPING_ADDRESS",
  //RESET_CART
  RESET_CART: "RESET-CART",

  CHANGE_SENDER_STORE_FOR_CART: "change-sender-store-for-cart",
};
