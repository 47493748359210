import { put, call, takeLatest, select } from 'redux-saga/effects';
import Api from '../../../../services';
import types from './constants';
import * as actions from './reducer';
import * as actionsLogin from '../../../loginpage/reducer';

function* watchGetDetailProfile(values){
  yield put(actions.setIsLoadingGetDetail(true))
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.getDetailForgotPassword, payload);
    const {data} = response;
    if(data.success){
      yield put(actions.getDetailSuccess(data));
    }
  } catch (e) {
    yield put(actions.getDetailSuccess(state.forgotpassword.detail));
  } finally {
    yield put(actions.setIsLoadingGetDetail(false));
  }
}

function* watchUpdatePassword(values){
  yield put(actions.setIsLoadingUpdatePassword(true));
  const { payload } = values;
  try {
    const response = yield call(Api.authentication.changePassword, payload);
    const {data} = response;
    if(data.success){
      yield put(actions.updatePasswordSuccess(true))
      yield put(actionsLogin.getLogout())
    }
  } catch (e) {
    yield put(actions.updatePasswordSuccess(false))
  } finally {
    yield put(actions.setIsLoadingUpdatePassword(false));
  }
}
const profileSagas = [
  takeLatest(types.GET_DETAIL_PROFILE_FORGOT_PASSWORD, watchGetDetailProfile),
  takeLatest(types.CHANGE_PASSWORD_FROM_FORGOT_PASSWORD, watchUpdatePassword)
]

export default profileSagas;
