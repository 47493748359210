import { put, call, takeLatest } from "redux-saga/effects";
import Api from "../../../../services";
import types from "./constants";
import * as actions from "./reducer";

function* watchGetDetailProfile(values) {
  yield put(actions.setIsLoadingGetDetail(true));
  const { payload } = values;
  try {
    const response = yield call(Api.user.getDetailProfile, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getDetailSuccess(data));
    }
  } catch (e) {
    const { data } = e.response;
    if (data) {
      yield put(actions.getDetailSuccess(data));
    }
  } finally {
    yield put(actions.setIsLoadingGetDetail(false));
  }
}
function* watchCheckAccountResetPassword(values) {
  yield put(actions.setIsLoadingCheckResetPassword(true));
  const { payload } = values;
  try {
    const response = yield call(Api.resetpassword.check, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.checkAccountResetPasswordSuccess(data));
    }
  } catch (e) {
    const { data } = e.response;
    if (data) {
      yield put(actions.checkAccountResetPasswordSuccess(data));
    }
  } finally {
    yield put(actions.setIsLoadingCheckResetPassword(false));
  }
}
function* watchRequestOTPResetPasswordEmail(values) {
  yield put(actions.setIsLoadingRequestOTPResetPassword(true));
  const { payload } = values;
  try {
    const response = yield call(Api.resetpassword.request.email, payload);
    const { data } = response;
    if (data.success) {
      let result = {
        ...data,
        verify_selected: payload.verify_selected,
      };
      yield put(actions.requestOTPResetPasswordSuccess(result));
    }
  } catch (e) {
    const { data } = e.response;
    if (data) {
      yield put(actions.requestOTPResetPasswordSuccess(data));
    }
  } finally {
    yield put(actions.setIsLoadingRequestOTPResetPassword(false));
  }
}
function* watchRequestOTPResetPasswordPhone(values) {
  yield put(actions.setIsLoadingRequestOTPResetPassword(true));
  const { payload } = values;
  try {
    const response = yield call(Api.resetpassword.request.phone, payload);
    const { data } = response;
    if (data.success) {
      let result = {
        ...data,
        verify_selected: payload.verify_selected,
      };
      yield put(actions.requestOTPResetPasswordSuccess(result));
    }
  } catch (e) {
    const { data } = e.response;
    if (data) {
      yield put(actions.requestOTPResetPasswordSuccess(data));
    }
  } finally {
    yield put(actions.setIsLoadingRequestOTPResetPassword(false));
  }
}
function* watchVerifyOTPResetPassword(values) {
  yield put(actions.setIsLoadingVerifyOTPResetPassword(true));
  const { payload } = values;
  try {
    const response = yield call(Api.resetpassword.verify, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.verifyOTPResetPasswordSuccess(data));
    }
  } catch (e) {
    const { data } = e.response;
    if (data) {
      yield put(actions.verifyOTPResetPasswordSuccess(data));
    }
  } finally {
    yield put(actions.setIsLoadingVerifyOTPResetPassword(false));
  }
}
function* watchChangePassword(values) {
  yield put(actions.setIsLoadingChangePassword(true));
  const { payload } = values;
  try {
    const response = yield call(Api.resetpassword.change, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.changePasswordSuccess(data));
    }
  } catch (e) {
    const { data } = e.response;
    if (data) {
      yield put(actions.changePasswordSuccess(data));
    }
  } finally {
    yield put(actions.setIsLoadingChangePassword(false));
  }
}
const saga = [
  takeLatest(types.GET_DETAIL_PROFILE_RESET_PASSWORD, watchGetDetailProfile),
  takeLatest(
    types.CHECK_ACCOUNT_RESET_PASSWORD,
    watchCheckAccountResetPassword
  ),
  takeLatest(
    types.REQUEST_OTP_RESET_PASSWORD_EMAIL,
    watchRequestOTPResetPasswordEmail
  ),
  takeLatest(
    types.REQUEST_OTP_RESET_PASSWORD_PHONE_NUMBER,
    watchRequestOTPResetPasswordPhone
  ),
  takeLatest(types.VERIFY_OTP_RESET_PASSWORD, watchVerifyOTPResetPassword),
  takeLatest(types.CHANGE_PASSWORD_ON_RESET_PASSWORD, watchChangePassword),
];

export default saga;
