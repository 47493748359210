import types from "./types";

const initialState = {
  sections: [],
  options: {
    target: [
      {
        label: "Tidak ada link",
        options: [{ value: undefined, label: "Tidak ada link" }],
      },
      {
        label: "Web",
        options: [
          { value: "url", label: "URL" },
          { value: "scroll-target", label: "Scroll Target" },
        ],
      },
      {
        label: "Chat",
        options: [{ value: "whatsapp", label: "Whatsapp" }],
      },
    ],
    scroll_targets: [
      { id: "hakdjw", value: "back-to-top", label: "Kembali Ke Atas" },
    ],
  },
  popup: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LANDING_PAGE_SECTION:
      return {
        ...state,
        sections: action.payload,
      };
    case types.SET_OPTIONS_SCROLL_TARGET:
      return {
        ...state,
        options: {
          ...state.options,
          scroll_targets: [...state.options.scroll_targets, ...action.payload],
        },
      };
    case types.ADD_OPTIONS_SCROLL_TARGET: {
      const isExisting = state.options.scroll_targets.some(
        (option) => option.id === action.payload.id
      );

      const updatedOptions = isExisting
        ? state.options.scroll_targets.map((option) =>
            option.id === action.payload.id ? action.payload : option
          )
        : [...state.options.scroll_targets, action.payload];
      return {
        ...state,
        options: {
          ...state.options,
          scroll_targets: updatedOptions,
        },
      };
    }
    case types.UPDATE_OPTIONS_SCROLL_TARGET:
      return {
        ...state,
        options: {
          ...state.options,
          scroll_targets: state.options.scroll_targets.map((item) =>
            item.id === action.payload.id
              ? {
                  ...item,
                  ...action.payload,
                }
              : item
          ),
        },
      };
    case types.REMOVE_OPTIONS_SCROLL_TARGET:
      return {
        ...state,
        options: {
          ...state.options,
          scroll_targets: state.options.scroll_targets.filter(
            (option) => option.id !== action.payload
          ),
        },
      };

    case types.SET_POP_UP:
      return {
        ...state,
        popup: action.payload,
      };

    case types.SET_IS_OPEN_POP_UP:
      const isExistingPopUp = state.popup.some(
        (option) => option.id === action.payload.id
      );

      const updatedOptionsPopUpShown = isExistingPopUp
        ? state.popup.map((option) =>
            option.id === action.payload.id ? action.payload : option
          )
        : [...state.popup, action.payload];

      return {
        ...state,
        popup: updatedOptionsPopUpShown,
      };

    case types.SET_CLOSE_POP_UP:
      const updatePopupValue = state.popup.map((arr) =>
        arr.id === action.payload?.id
          ? {
              ...arr,
              ...action.payload,
            }
          : arr
      );

      return {
        ...state,
        popup: updatePopupValue,
      };

    default:
      return state;
  }
};

export const setLandingPageSection = (payload) => ({
  type: types.SET_LANDING_PAGE_SECTION,
  payload,
});
export const setOptionsScrollTarget = (payload) => ({
  type: types.SET_OPTIONS_SCROLL_TARGET,
  payload,
});
export const addOptionsScrollTarget = (payload) => ({
  type: types.ADD_OPTIONS_SCROLL_TARGET,
  payload,
});
export const updateOptionsScrollTarget = (payload) => ({
  type: types.UPDATE_OPTIONS_SCROLL_TARGET,
  payload,
});

export const removeOptionScrollTarget = (id) => ({
  type: types.REMOVE_OPTIONS_SCROLL_TARGET,
  payload: id,
});

export const setPopUp = (payload) => {
  return {
    type: types.SET_POP_UP,
    payload,
  };
};

export const setIsOpenPopup = (payload) => {
  return {
    type: types.SET_IS_OPEN_POP_UP,
    payload,
  };
};

export const setClosePopup = (payload) => {
  return {
    type: types.SET_CLOSE_POP_UP,
    payload,
  };
};

export const setSalesNotification = (payload) => {
  return {
    type: types.SET_SALES_NOTIFICATION,
    payload,
  };
};

export const setIsOpenSalesNotification = (payload) => {
  return {
    type: types.SET_IS_OPEN_SALES_NOTIFICATION,
    payload,
  };
};

export const setCloseSalesNotification = (payload) => {
  return {
    type: types.SET_CLOSE_SALES_NOTIFICATION,
    payload,
  };
};
