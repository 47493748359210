export default {
  SELECTED_DESTINATION_BANK_ACCOUNT_COIN: 'SELECTED_DESTINATION_BANK_ACCOUNT_COIN',

  SET_MESSAGE_COIN: 'SET_MESSAGE_COIN',

  //GET_COIN_BALANCE
  GET_COIN_BALANCE: 'GET_COIN_BALANCE',
  GET_COIN_BALANCE_SUCCESS: 'GET_COIN_BALANCE_SUCCESS',
  IS_LOADING_GET_COIN_BALANCE: 'IS_LOADING_GET_COIN_BALANCE',
  //GET_COIN_BALANCE_HISTORY
  GET_COIN_BALANCE_HISTORY: 'GET_COIN_BALANCE_HISTORY',
  GET_COIN_BALANCE_HISTORY_SUCCESS: 'GET_COIN_BALANCE_HISTORY_SUCCESS',
  IS_LOADING_GET_COIN_BALANCE_HISTORY: 'IS_LOADING_GET_COIN_BALANCE_HISTORY',
  //REQUEST_WITHDRAWAL_COIN
  REQUEST_WITHDRAWAL_COIN: 'REQUEST_WITHDRAWAL_COIN',
  REQUEST_WITHDRAWAL_COIN_SUCCESS: 'REQUEST_WITHDRAWAL_COIN_SUCCESS',
  IS_LOADING_REQUEST_WITHDRAWAL_COIN: 'IS_LOADING_REQUEST_WITHDRAWAL_COIN',

  CONFIRM_OTP_WITHDRAWAL_COIN: 'CONFIRM_OTP_WITHDRAWAL_COIN',
  CONFIRM_OTP_WITHDRAWAL_COIN_SUCCESS: 'CONFIRM_OTP_WITHDRAWAL_COIN_SUCCESS',
  IS_LOADING_CONFIRM_OTP_WITHDRAWAL_COIN: 'IS_LOADING_CONFIRM_OTP_WITHDRAWAL_COIN',

  RESEND_OTP_WITHDRAWAL_COIN: 'RESEND_OTP_WITHDRAWAL_COIN',
  IS_LOADING_RESEND_OTP_WITHDRAWAL_COIN: 'IS_LOADING_RESEND_OTP_WITHDRAWAL_COIN',
  //RESET_COIN_BALANCE
  RESET_COIN_BALANCE: 'RESET-COIN_BALANCE'
}
