import { Api, apiUrl } from "./api";
import axios from "axios";

export default {
  getDetailProfile: (payload) =>
    Api.get("/v2/user", {
      headers: { token: payload.token },
    }),
  checkPassword: (payload) =>
    Api.post("/v1/users/check-password", payload, {
      headers: {
        token: payload.token,
      },
    }),
  change_email: {
    request_otp: {
      check: {
        email: (payload) =>
          Api.get("/v2/user/update-email/otp/request/email", {
            headers: { token: payload.token },
          }),
        phone_number: (payload) =>
          Api.get("/v2/user/update-email/otp/request/phone-number", {
            headers: { token: payload.token },
          }),
      },
      change: (payload) =>
        Api.post("/v2/user/update-email/otp/request/change", payload, {
          headers: { token: payload.token },
        }),
    },
    verify_otp: {
      check: (payload) =>
        Api.post("/v2/user/update-email/otp/confirm/check", payload, {
          headers: { token: payload.token },
        }),
      change: (payload) =>
        Api.post("/v2/user/update-email/otp/confirm/change", payload, {
          headers: { token: payload.token },
        }),
    },
  },
  verify_email: {
    request_otp: (payload) =>
      Api.get("/v2/user/verify-email/otp/request", {
        headers: { token: payload.token },
      }),
    verify_otp: (payload) =>
      Api.post("/v2/user/verify-email/otp/confirm", payload, {
        headers: { token: payload.token },
      }),
  },
  change_phone: {
    request_otp: {
      check: {
        email: (payload) =>
          Api.get("/v2/user/update-phone-number/otp/request/email", {
            headers: { token: payload.token },
          }),
        phone_number: (payload) =>
          Api.get("/v2/user/update-phone-number/otp/request/phone-number", {
            headers: { token: payload.token },
          }),
      },
      change: (payload) =>
        Api.post("/v2/user/update-phone-number/otp/request/change", payload, {
          headers: { token: payload.token },
        }),
    },
    verify_otp: {
      check: (payload) =>
        Api.post("/v2/user/update-phone-number/otp/confirm/check", payload, {
          headers: { token: payload.token },
        }),
      change: (payload) =>
        Api.post("/v2/user/update-phone-number/otp/confirm/change", payload, {
          headers: { token: payload.token },
        }),
    },
  },
  verify_phone: {
    request_otp: (payload) =>
      Api.get("/v2/user/verify-phone-number/otp/request", {
        headers: { token: payload.token },
      }),
    verify_otp: (payload) =>
      Api.post("/v2/user/verify-phone-number/otp/confirm", payload, {
        headers: { token: payload.token },
      }),
  },

  changeProfile: (payload) =>
    Api.put(`/v2/user/change/info`, payload.data, {
      headers: { token: payload.token },
    }),
  changePassword: (payload) =>
    Api.put(`/v1/users/change_password/${payload.id}`, payload.data, {
      headers: { token: payload.token },
    }),
  changeAvatar: (payload) => {
    const formData = new FormData();
    formData.append("image", payload.image);
    return axios
      .put(`${apiUrl}/v2/user/change/photo`, formData, {
        headers: {
          token: payload.token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response)
      .catch((error) => {
        throw Error(error);
      });
  },
};
