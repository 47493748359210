import home from "./home";
import authentication from "./authentication";
import deposit from "./deposit";
import wishlist from "./wishlist";
import favorite from "./favorite";
import feed from "./feed";
import cart from "./cart";
import address from "./address";
import category from "./category";
import bankaccount from "./bankaccount";
import user from "./user";
import showcase from "./showcase";
import product from "./product";
import store from "./store";
import message from "./message";
import buyertransaction from "./buyertransaction";
import review from "./review";
import discussion from "./discussion";
import balance from "./balance";
import notification from "./notification";
import rfq from "./rfq";
import complaint from "./complaint";
import donate from "./donate";
import checkcost from "./checkcost";
import help from "./help";
import coin from "./coin";
import warehouse from "./warehouse";
import register from "./register";
import login from "./login";
import resetpassword from "./resetpassword";
import shipping from "./shipping";
import chat from "./chat";

export default {
  home,
  authentication,
  deposit,
  wishlist,
  favorite,
  feed,
  cart,
  address,
  category,
  bankaccount,
  user,
  showcase,
  product,
  store,
  message,
  buyertransaction,
  review,
  discussion,
  balance,
  notification,
  rfq,
  complaint,
  donate,
  checkcost,
  help,
  coin,
  warehouse,
  register,
  login,
  resetpassword,
  shipping,
  chat,
};
