import { Api } from "./api";

export default {
  getAll: (payload) =>
    Api.get("/v2/address", {
      params: {
        limit: payload.limit,
        page: payload.page,
        search: payload.search,
      },
      headers: { token: payload.token },
    }),
  add: (payload) =>
    Api.post(`/v2/address/add`, payload.data, {
      headers: { token: payload.token },
    }),
  editById: (payload) =>
    Api.put(`/v1/address-lists/${payload.id}`, payload.data, {
      headers: { token: payload.token },
    }),
  editDefaultById: (payload) =>
    Api.put(
      `/v1/address-lists/is_default/${payload.id}`,
      {},
      {
        headers: { token: payload.token },
      }
    ),
  deleteById: (payload) =>
    Api.delete(`/v1/address-lists/is_deleted/${payload.id}`, {
      headers: { token: payload.token },
    }),
  subdistrict: (payload) => Api.get(`/subdistricts`),
  city: (payload) => Api.get(`/cities`),
};
