export default {
  CHECK_ACCOUNT_RESET_PASSWORD: 'check-account-reset-password',
  CHECK_RESET_PASSWORD_SUCCESS: 'check-reset-password-success',
  LOADING_CHECK_RESET_PASSWORD: 'loading-check-reset-password',
  DONE_CHECK_RESET_PASSWORD: 'done-check-reset-password',
  RESET_CHECK_RESET_PASSWORD: 'reset-check-reset-password',

  REQUEST_OTP_RESET_PASSWORD_EMAIL: 'request-otp-reset-password-email',
  REQUEST_OTP_RESET_PASSWORD_PHONE_NUMBER: 'request-otp-reset-password-phone-number',
  REQUEST_OTP_RESET_PASSWORD_SUCCESS: 'request-otp-reset-password-success',
  IS_LOADING_REQUEST_OTP_RESET_PASSWORD: 'is-loading-request-otp-reset-password',
  RESET_REQUEST_OTP_RESET_PASSWORD: 'reset-request-otp-reset-password',

  VERIFY_OTP_RESET_PASSWORD: 'verify-email-reset-password',
  VERIFY_OTP_RESET_PASSWORD_SUCCESS: 'verify-email-reset-password-success',
  LOADING_VERIFY_OTP_RESET_PASSWORD: 'loading-verify-email-reset-password',
  RESET_VERIFY_OTP_RESET_PASSWORD: 'reset-verify-email-reset-password',

  CHANGE_PASSWORD_ON_RESET_PASSWORD: 'change-password-on-reset-password',
  CHANGE_PASSWORD_ON_RESET_PASSWORD_SUCCESS: 'change-password-on-reset-password-success',
  LOADING_CHANGE_PASSWORD_ON_RESET_PASSWORD: 'loading-change-password-on-reset-password',
  RESET_CHANGE_PASSWORD_ON_RESET_PASSWORD: 'reset-change-password-on-reset-password',

  GET_DETAIL_PROFILE_RESET_PASSWORD: 'get-detail-profile-reset-password',
  GET_DETAIL_PROFILE_RESET_PASSWORD_SUCCESS: 'get-detail-profile-reset-password-success',
  IS_LOADING_GET_DETAIL_PROFILE_RESET_PASSWORD: 'is-loading-get-detail-profile-reset-password',
  RESET_DETAIL_PROFILE_RESET_PASSWORD: 'reset-detail-profile-reset-password',

  SELECT_VERIFICATION_RESET_PASSWORD: 'select-verification-reset-password',
  SELECT_VERIFICATION_RESET_PASSWORD_SUCCESS: 'select-verification-reset-password-success',
  IS_LOADING_SELECT_VERIFICATION_RESET_PASSWORD: 'is-loading-select-verification-reset-password',

  GET_LINK_EMAIL_RESET_PASSWORD: 'get-link-email-reset-password',
  GET_LINK_EMAIL_RESET_PASSWORD_SUCCESS: 'get-link-email-reset-password-success',
  IS_LOADING_GET_LINK_EMAIL_RESET_PASSWORD: 'is-loading-get-link-email-reset-password',

  GET_SMS_OTP_RESET_PASSWORD: 'get-sms-otp-reset-password',
  GET_SMS_OTP_RESET_PASSWORD_SUCCESS: 'get-sms-otp-reset-password-success',
  IS_LOADING_GET_SMS_OTP_RESET_PASSWORD: 'is-loading-get-sms-otp-reset-password',

  SEND_SMS_OTP_RESET_PASSWORD: 'send-sms-otp-reset-password',
  SEND_SMS_OTP_RESET_PASSWORD_SUCCESS: 'send-sms-otp-reset-password-success',
  IS_LOADING_SEND_SMS_OTP_RESET_PASSWORD: 'is-loading-send-sms-otp-reset-password',

  DEFAULT_RESET_PASSWORD: 'default-reset-password'
}
