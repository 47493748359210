import types from "./constants";

const initialState = {
  check: {
    email: {},
    phone: {},
    loading: false,
  },
  otp: {
    get: {},
    sent: {},
  },
  user: {},
  token: "",
  logged_in: false,
  last_login: {
    data: {},
    loading: false,
  },
  isLoadingAuthentication: false,
  isLoadingLogin: false,
  isLoadingLogout: false,
  message: "",
  address: [],
  id_address: null,
  address_selected: {},
  isLoadingGetAddress: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHECK_LOGIN_EMAIL_SUCCESS:
      return { ...state, check: { ...state.check, email: action.payload } };
    case types.CHECK_LOGIN_PHONE_SUCCESS:
      return { ...state, check: { ...state.check, phone: action.payload } };
    case types.IS_LOADING_CHECK_LOGIN:
      return { ...state, check: { ...state.check, loading: action.payload } };
    case types.RESET_CHECK_LOGIN:
      return { ...state, check: initialState.check };
    case types.AUTHENTICATION_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        logged_in: action.payload.logged_in,
      };
    case types.IS_LOADING_AUTHENTICATION:
      return { ...state, isLoadingAuthentication: action.payload };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        logged_in: action.payload.logged_in,
      };
    case types.IS_LOADING_LOGIN:
      return { ...state, isLoadingLogin: action.payload };
    case types.LOGOUT_SUCCESS:
      return { ...state, ...initialState };
    case types.IS_LOADING_LOGOUT:
      return { ...state, isLoadingLogout: action.payload };
    case types.SET_MESSAGE_LOGIN:
      return { ...state, message: action.payload };
    case types.GET_ADDRESS_FOR_SENT_TO_SUCCESS: {
      const filtered = action.payload.filter(
        (item) => item.is_default === true
      )[0];
      return {
        ...state,
        address: action.payload,
        id_address: filtered ? filtered.id : state.id_address,
        address_selected: filtered ? filtered : state.address_selected,
      };
    }
    case types.SET_ID_ADDRESS_FOR_SENT_TO:
      return {
        ...state,
        id_address: action.payload,
        address_selected: state.address.filter(
          (item) => item.id === action.payload
        )[0],
      };
    case types.IS_LOADING_GET_ADDRESS_FOR_SENT_TO:
      return { ...state, isLoadingGetAddress: action.payload };
    case types.RESET_LOGIN:
      return initialState;
    case types.LAST_LOGIN_SUCCESS:
      return {
        ...state,
        last_login: {
          ...state.last_login,
          data: action.payload,
        },
      };
    case types.IS_LOADING_LAST_LOGIN:
      return {
        ...state,
        last_login: {
          ...state.last_login,
          loading: action.payload,
        },
      };
    default:
      return state;
  }
};

export const getLastLogin = (payload) => ({
  type: types.LAST_LOGIN,
  payload,
});
export const getLastLoginSuccess = (payload) => ({
  type: types.LAST_LOGIN_SUCCESS,
  payload,
});
export const setIsLoadingLastLogin = (payload) => ({
  type: types.IS_LOADING_LAST_LOGIN,
  payload,
});
export const checkLoginEmail = (payload) => ({
  type: types.CHECK_LOGIN_EMAIL,
  payload,
});
export const checkLoginEmailSuccess = (payload) => ({
  type: types.CHECK_LOGIN_EMAIL_SUCCESS,
  payload,
});
export const checkLoginPhone = (payload) => ({
  type: types.CHECK_LOGIN_PHONE,
  payload,
});
export const checkLoginPhoneSuccess = (payload) => ({
  type: types.CHECK_LOGIN_PHONE_SUCCESS,
  payload,
});
export const setIsLoadingCheckLogin = (payload) => ({
  type: types.IS_LOADING_CHECK_LOGIN,
  payload,
});
export const resetCheckLogin = (payload) => ({
  type: types.RESET_CHECK_LOGIN,
  payload,
});

export const getAddressForSentTo = (payload) => ({
  type: types.GET_ADDRESS_FOR_SENT_TO,
  payload,
});
export const getAddressForSentToSuccess = (payload) => ({
  type: types.GET_ADDRESS_FOR_SENT_TO_SUCCESS,
  payload,
});
export const setIsLoadingGetAddressForSentTo = (payload) => ({
  type: types.IS_LOADING_GET_ADDRESS_FOR_SENT_TO,
  payload,
});
export const setIdAddressForSentTo = (payload) => ({
  type: types.SET_ID_ADDRESS_FOR_SENT_TO,
  payload,
});

export const getAuthentication = (payload) => ({
  type: types.AUTHENTICATION,
  payload,
});
export const getAuthenticationSuccess = (payload) => ({
  type: types.AUTHENTICATION_SUCCESS,
  payload,
});
export const setIsLoadingAuthentication = (payload) => ({
  type: types.IS_LOADING_AUTHENTICATION,
  payload,
});

export const getLogin = (payload) => ({
  type: types.LOGIN,
  payload,
});
export const getLoginSuccess = (payload) => ({
  type: types.LOGIN_SUCCESS,
  payload,
});
export const setIsLoadingLogin = (payload) => ({
  type: types.IS_LOADING_LOGIN,
  payload,
});

export const setMessageLogin = (payload) => ({
  type: types.SET_MESSAGE_LOGIN,
  payload,
});

export const googleSignIn = (payload) => ({
  type: types.GOOGLE_SIGN_IN,
  payload,
});
export const facebookSignIn = (payload) => ({
  type: types.FACEBOOK_SIGN_IN,
  payload,
});

//logout
export const getLogout = (payload) => ({
  type: types.LOGOUT,
  payload,
});
export const getLogoutSuccess = (payload) => ({
  type: types.LOGOUT_SUCCESS,
  payload,
});
export const setIsLoadingLogout = (payload) => ({
  type: types.IS_LOADING_LOGOUT,
  payload,
});

export const resetLogin = (payload) => ({
  type: types.RESET_LOGIN,
});
