import types from "./constants";

const initialState = {
  detail: {},
  request_change_email: {
    email: "",
    success: false,
    message: "",
    loading: false,
  },
  check_password: {
    success: false,
    message: "",
    loading: false,
  },
  sms_otp_change_email: {
    verify: false,
    success: false,
    loading: false,
    message: "",
  },
  email_otp_change_email: {
    verify: false,
    success: false,
    loading: false,
    message: "",
  },
  link_change_email: {
    success: false,
    loading: false,
  },
  select_verify: {
    selected: "",
    email: "",
    phone_number: "",
    loading: false,
  },
  check_change_email: {
    new_email: "",
    is_new: false,
    success: false,
    message: "",
  },
  update_email: {
    email: "",
    name: "",
    success: false,
    message: "",
    loading: false,
  },
  check_phone_number: {
    new_phone_number: "",
    is_new: false,
    success: false,
    message: "",
    loading: false,
  },
  check_last_phone_number: {
    verify: false,
    success: false,
    message: "",
  },
  update_phone_number: {
    phone_number: "",
    name: "",
    success: false,
    message: "",
    loading: false,
  },
  isLoadingGetDetail: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DETAIL_PROFILE_SUCCESS:
      return { ...state, detail: action.payload };
    case types.IS_LOADING_GET_DETAIL_PROFILE:
      return { ...state, isLoadingGetDetailProfile: action.payload };
    case types.CHECK_PASSWORD_SUCCESS:
      return {
        ...state,
        check_password: {
          ...state.check_password,
          success: action.payload.success,
          message: action.payload.message,
        },
      };
    case types.IS_LOADING_CHECK_PASSWORD:
      return {
        ...state,
        check_password: {
          ...state.check_password,
          loading: action.payload,
        },
      };
    case types.REQUEST_CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        request_change_email: {
          ...state.request_change_email,
          email: action.payload.email,
          success: action.payload.success,
          message: action.payload.message,
        },
      };
    case types.IS_LOADING_REQUEST_CHANGE_EMAIL:
      return {
        ...state,
        request_change_email: {
          ...state.request_change_email,
          loading: action.payload,
        },
      };
    case types.SELECT_VERIFICATION_CHANGE_EMAIL:
      return {
        ...state,
        select_verify: {
          ...action.payload,
          selected: action.payload.selected,
          email: action.payload.email,
          phone_number: action.payload.phone_number,
        },
      };
    case types.IS_LOADING_SELECT_VERIFICATION_CHANGE_EMAIL:
      return {
        ...state,
        select_verify: {
          ...state.select_verify,
          loading: action.payload,
        },
      };
    case types.GET_LINK_EMAIL_CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        link_change_email: {
          ...state.link_change_email,
          success: action.payload,
        },
      };
    case types.IS_LOADING_GET_LINK_EMAIL_CHANGE_EMAIL:
      return {
        ...state,
        link_change_email: {
          ...state.link_change_email,
          loading: action.payload,
        },
      };
    case types.GET_SMS_OTP_CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        sms_otp_change_email: {
          ...state.sms_otp_change_email,
          success: action.payload.success,
        },
      };
    case types.IS_LOADING_GET_SMS_OTP_CHANGE_EMAIL:
      return {
        ...state,
        sms_otp_change_email: {
          ...state.sms_otp_change_email,
          loading: action.payload,
        },
      };
    case types.SEND_SMS_OTP_CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        sms_otp_change_email: {
          ...state.sms_otp_change_email,
          verify: action.payload.success,
          message: action.payload.message,
        },
      };
    case types.CHANGE_EMAIL_OTP_SUCCESS:
      return {
        ...state,
        email_otp_change_email: {
          ...state.email_otp_change_email,
          success: action.payload,
        },
      };
    case types.IS_LOADING_CHANGE_EMAIL_OTP_SUCCESS:
      return {
        ...state,
        email_otp_change_email: {
          ...state.email_otp_change_email,
          loading: action.payload,
        },
      };
    case types.CHECK_CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        check_change_email: {
          ...state.check_change_email,
          new_email: action.payload.new_email,
          is_new: action.payload.is_new,
          success: action.payload.success,
          message: action.payload.message,
        },
      };
    case types.IS_LOADING_CHECK_CHANGE_EMAIL:
      return {
        ...state,
        check_change_email: {
          ...state.check_change_email,
          loading: action.payload,
        },
      };
    case types.RESET_CHECK_CHANGE_EMAIL:
      return {
        ...state,
        check_change_email: initialState.check_change_email,
      };
    case types.SEND_OTP_UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        update_email: {
          ...state.update_email,
          ...action.payload,
          success: action.payload.success,
          message: action.payload.message,
        },
      };
    case types.IS_LOADING_SEND_OTP_UPDATE_EMAIL:
      return {
        ...state,
        update_email: {
          ...state.update_email,
          loading: action.payload.loading,
        },
      };
    case types.CHECK_CHANGE_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        check_phone_number: {
          ...state.check_phone_number,
          new_phone_number: action.payload.new_phone_number,
          is_new: action.payload.is_new,
          success: action.payload.success,
          message: action.payload.message,
        },
      };
    case types.IS_LOADING_CHECK_CHANGE_PHONE_NUMBER:
      return {
        ...state,
        check_phone_number: {
          ...state.check_phone_number,
          loading: action.payload,
        },
      };
    case types.RESET_CHECK_CHANGE_PHONE_NUMBER:
      return {
        ...state,
        check_phone_number: initialState.check_phone_number,
      };
    case types.CHECK_LAST_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        check_last_phone_number: {
          ...state.check_last_phone_number,
          success: action.payload.success,
          message: action.payload.message,
        },
      };
    case types.CONFIRM_OTP_LAST_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        check_last_phone_number: {
          ...state.check_last_phone_number,
          verify: action.payload.verify,
          success: action.payload.success,
          message: action.payload.message,
        },
      };
    case types.IS_LOADING_CHECK_LAST_PHONE_NUMBER:
      return {
        ...state,
        check_last_phone_number: {
          ...state.check_last_phone_number,
          loading: action.payload,
        },
      };
    case types.CHANGE_PHONE_NUMBER_OTP_SUCCESS:
      return {
        ...state,
        update_phone_number: {
          ...state.update_phone_number,
          ...action.payload,
          success: action.payload.success,
          message: action.payload.success,
          loading: false,
        },
      };
    case types.IS_LOADING_CHANGE_PHONE_NUMBER_OTP:
      return {
        ...state,
        update_phone_number: {
          ...state.update_phone_number,
          loading: action.payload,
        },
      };
    case types.RESET_DEFAULT:
      return initialState;
    default:
      return state;
  }
};

export const confirmOTPPhoneNumber = (payload) => ({
  type: types.CHANGE_PHONE_NUMBER_OTP,
  payload,
});
export const confirmOTPPhoneNumberSuccess = (payload) => ({
  type: types.CHANGE_PHONE_NUMBER_OTP_SUCCESS,
  payload,
});
export const setLoadingConfirmOTPPhoneNumber = (payload) => ({
  type: types.IS_LOADING_CHANGE_PHONE_NUMBER_OTP,
  payload,
});

export const confirmOtpLastPhoneNumber = (payload) => ({
  type: types.CONFIRM_OTP_LAST_PHONE_NUMBER,
  payload,
});
export const confirmOtpLastPhoneNumberSuccess = (payload) => ({
  type: types.CONFIRM_OTP_LAST_PHONE_NUMBER_SUCCESS,
  payload,
});

export const checkLastPhoneNumberByEmail = (payload) => ({
  type: types.CHECK_LAST_PHONE_NUMBER_EMAIL,
  payload,
});
export const checkLastPhoneNumberBySMS = (payload) => ({
  type: types.CHECK_LAST_PHONE_NUMBER_PHONE,
  payload,
});
export const checkLastPhoneNumberSuccess = (payload) => ({
  type: types.CHECK_LAST_PHONE_NUMBER_SUCCESS,
  payload,
});
export const setLoadingCheckLastPhoneNumber = (payload) => ({
  type: types.IS_LOADING_CHECK_LAST_PHONE_NUMBER,
  payload,
});

export const checkChangePhoneNumber = (payload) => ({
  type: types.CHECK_CHANGE_PHONE_NUMBER,
  payload,
});
export const checkChangePhoneNumberSuccess = (payload) => ({
  type: types.CHECK_CHANGE_PHONE_NUMBER_SUCCESS,
  payload,
});
export const setLoadingCheckChangePhoneNumber = (payload) => ({
  type: types.IS_LOADING_CHECK_CHANGE_PHONE_NUMBER,
  payload,
});
export const resetCheckChangePhoneNumber = (payload) => ({
  type: types.RESET_CHECK_CHANGE_PHONE_NUMBER,
  payload,
});
export const sendOTPChangeEmail = (payload) => ({
  type: types.SEND_OTP_UPDATE_EMAIL,
  payload,
});
export const sendOTPChangeEmailSuccess = (payload) => ({
  type: types.SEND_OTP_UPDATE_EMAIL_SUCCESS,
  payload,
});
export const setLoadingSendOTPChangeEmail = (payload) => ({
  type: types.IS_LOADING_SEND_OTP_UPDATE_EMAIL,
  payload,
});

export const checkChangeEmail = (payload) => ({
  type: types.CHECK_CHANGE_EMAIL,
  payload,
});
export const checkChangeEmailSuccess = (payload) => ({
  type: types.CHECK_CHANGE_EMAIL_SUCCESS,
  payload,
});
export const setLoadingCheckChangeEmail = (payload) => ({
  type: types.IS_LOADING_CHECK_CHANGE_EMAIL,
  payload,
});
export const resetCheckChangeEmail = (payload) => ({
  type: types.RESET_CHECK_CHANGE_EMAIL,
  payload,
});

export const getDetailByChangeEmailToken = (payload) => ({
  type: types.GET_DETAIL_BY_CHANGE_EMAIL_TOKEN,
  payload,
});

export const submitEmailOTPChangeEmail = (payload) => ({
  type: types.CHANGE_EMAIL_OTP,
  payload,
});
export const submitEmailOTPChangeEmailSuccess = (payload) => ({
  type: types.CHANGE_EMAIL_OTP_SUCCESS,
  payload,
});
export const setIsLoadingSubmitEmailOTPChangeEmail = (payload) => ({
  type: types.IS_LOADING_CHANGE_EMAIL_OTP,
  payload,
});

export const requestChangeEmail = (payload) => ({
  type: types.REQUEST_CHANGE_EMAIL,
  payload,
});
export const requestChangeEmailSuccess = (payload) => ({
  type: types.REQUEST_CHANGE_EMAIL_SUCCESS,
  payload,
});
export const setLoadingRequestChangeEmail = (payload) => ({
  type: types.IS_LOADING_REQUEST_CHANGE_EMAIL,
  payload,
});

export const selectVerificationChangeEmail = (payload) => ({
  type: types.SELECT_VERIFICATION_CHANGE_EMAIL,
  payload,
});
export const setIsLoadingSelectVerificationChangeEmail = (payload) => ({
  type: types.IS_LOADING_SELECT_VERIFICATION_CHANGE_EMAIL,
  payload,
});

export const getLinkEmailChangeEmail = (payload) => ({
  type: types.GET_LINK_EMAIL_CHANGE_EMAIL,
  payload,
});
export const getLinkEmailChangeEmailSuccess = (payload) => ({
  type: types.GET_LINK_EMAIL_CHANGE_EMAIL_SUCCESS,
  payload,
});
export const setIsLoadingGetLinkEmailChangeEmail = (payload) => ({
  type: types.IS_LOADING_GET_LINK_EMAIL_CHANGE_EMAIL,
  payload,
});

export const getOTPCheckEmailForChangeEmail = (payload) => ({
  type: types.GET_CHANGE_EMAIL_REQUEST_OTP_CHECK_EMAIL,
  payload,
});
export const getSMSOTPChangeEmail = (payload) => ({
  type: types.GET_SMS_OTP_CHANGE_EMAIL,
  payload,
});
export const getSMSOTPChangeEmailSuccess = (payload) => ({
  type: types.GET_SMS_OTP_CHANGE_EMAIL_SUCCESS,
  payload,
});
export const setIsLoadingGetSMSOTPChangeEmail = (payload) => ({
  type: types.IS_LOADING_GET_SMS_OTP_CHANGE_EMAIL,
  payload,
});

export const sendSMSOTPChangeEmail = (payload) => ({
  type: types.SEND_SMS_OTP_CHANGE_EMAIL,
  payload,
});
export const sendSMSOTPChangeEmailSuccess = (payload) => ({
  type: types.SEND_SMS_OTP_CHANGE_EMAIL_SUCCESS,
  payload,
});
export const setIsLoadingSendSMSOTPChangeEmail = (payload) => ({
  type: types.IS_LOADING_SEND_SMS_OTP_CHANGE_EMAIL,
  payload,
});

export const getDetailProfile = (payload) => ({
  type: types.GET_DETAIL_PROFILE,
  payload,
});
export const getDetailProfileSuccess = (payload) => ({
  type: types.GET_DETAIL_PROFILE_SUCCESS,
  payload,
});
export const setIsLoadingGetDetailProfile = (payload) => ({
  type: types.IS_LOADING_GET_DETAIL_PROFILE,
  payload,
});

export const checkPassword = (payload) => ({
  type: types.CHECK_PASSWORD,
  payload,
});
export const checkPasswordSuccess = (payload) => ({
  type: types.CHECK_PASSWORD_SUCCESS,
  payload,
});
export const setIsLoadingCheckPassword = (payload) => ({
  type: types.IS_LOADING_CHECK_PASSWORD,
  payload,
});

export const resetSecurity = (payload) => ({
  type: types.RESET_DEFAULT,
});
