import { put, call, takeLatest, select } from "redux-saga/effects";
import Api from "../../../../services";
import types from "./constants";
import * as actions from "./reducer";
import * as actionsLogin from "../../../loginpage/reducer";

function* watchGetAddress(values) {
  yield put(actions.setIsLoadingGetAddress(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.address.getAll, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getAllAddressSuccess(data));
      yield put(actions.setIsResponseSuccess(true));
    }
  } catch (e) {
    yield put(actions.getAllAddressSuccess(state.address));
  } finally {
    yield put(actions.setIsLoadingGetAddress(false));
  }
}

function* watchCreateAddress(values) {
  yield put(actions.setIsLoadingCreateAddress(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.address.add, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.createNewAddressSuccess(data));
      yield put(actions.setIsResponseSuccess(true));
    }
  } catch (e) {
    yield put(actions.setError(true));
    yield put(actions.setMessage(e.response.data?.message));
    yield put(actions.getAllAddressSuccess(state.address));
  } finally {
    yield put(actions.setIsLoadingCreateAddress(false));
    yield put(actions.setIsResponseSuccess(false));
  }
}

function* watchSetDefaultAddressById(values) {
  yield put(actions.setIsLoadingSetDefaultAddress(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.address.editDefaultById, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.setDefaultAddressByIdSuccess(payload));
      yield put(
        actionsLogin.getAddressForSentToSuccess(
          state.address.data.map((item) =>
            item.id === payload.id
              ? { ...item, is_default: true }
              : { ...item, is_default: false }
          )
        )
      );
    }
  } catch (e) {
    console.log(e);
    yield put(actions.getAllAddressSuccess(state.address));
  } finally {
    yield put(actions.setIsLoadingSetDefaultAddress(false));
  }
}

function* watchUpdateAddressById(values) {
  yield put(actions.setIsLoadingEditAddress(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.address.editById, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.editAddressByIdSuccess(payload));
      yield put(actions.setIsResponseSuccess(true));
      yield put(
        actionsLogin.getAddressForSentToSuccess(
          state.address.data.map((item) => {
            return item.id === payload.id
              ? { ...item, ...payload.data, id: payload.id }
              : item;
          })
        )
      );
    }
  } catch (e) {
    yield put(actions.getAllAddressSuccess(state.address));
  } finally {
    yield put(actions.setIsLoadingEditAddress(false));
    yield put(actions.setIsResponseSuccess(false));
  }
}

function* watchDeleteAddressByID(values) {
  yield put(actions.setIsLoadingDeleteAddress(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.address.deleteById, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.deleteAnddressByIdSuccess(payload));
      yield put(
        actionsLogin.getAddressForSentToSuccess(
          state.address.data.filter((item) => item.id !== payload.id)
        )
      );
    }
  } catch (e) {
    yield put(actions.getAllAddressSuccess(state.address));
  } finally {
    yield put(actions.setIsLoadingDeleteAddress(false));
  }
}

function* watchGetSubdistrict() {
  yield put(actions.setIsLoadingGetSubdistrict(true));
  const state = yield select();
  try {
    const response = yield call(Api.address.subdistrict);
    const { data } = response;
    if (data.success) {
      yield put(actions.getSubdistrictSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getSubdistrictSuccess(state.address.subdistricts));
  } finally {
    yield put(actions.setIsLoadingGetSubdistrict(false));
  }
}

function* watchGetCities() {
  yield put(actions.setIsLoadingGetCities(true));
  const state = yield select();
  try {
    const response = yield call(Api.address.city);
    const { data } = response;
    if (data.success) {
      yield put(actions.getCitiesSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getCitiesSuccess(state.address.subdistricts));
  } finally {
    yield put(actions.setIsLoadingGetCities(false));
  }
}

const addressSagas = [
  takeLatest(types.GET_ALL_ADDRESS, watchGetAddress),
  takeLatest(types.CREATE_ADDRESS, watchCreateAddress),
  takeLatest(types.EDIT_ADDRESS, watchUpdateAddressById),
  takeLatest(types.SET_DEFAULT_ADDRESS, watchSetDefaultAddressById),
  takeLatest(types.DELETE_ADDRESS, watchDeleteAddressByID),
  takeLatest(types.GET_SUBDISTRICT, watchGetSubdistrict),
  takeLatest(types.GET_CITY, watchGetCities),
];

export default addressSagas;
