import React, { useState } from "react";
import {
  CCol,
  CRow,
  CCard,
  CCardHeader,
  CCardBody,
  CImg,
  CAlert,
  CButton,
} from "@coreui/react";
import { WarningOutline } from "react-ionicons";
import { useDispatch } from "react-redux";

import { formatPrice } from "../../../helpers/price";
import { defaultImg } from "../../../helpers/product";
import * as actions from "../reducer";
import { ActionQuantity, Action, ModalSender } from "./card";
import { Checkbox } from "../../common";

const Card = ({ detail, sender }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const onChangeSender = (obj, store_id) => {
    setShow(false);
    dispatch(
      actions.changeSenderStoreForCart({ sender: obj, store: store_id })
    );
  };
  const data =
    detail.Store.status === "Close"
      ? detail.products
      : detail.products.filter(
          (item) =>
            item.product.status !== "active" ||
            item.quantity < item.product.min_order ||
            !item.stock ||
            (item.stock && item.stock?.qty < item.quantity) ||
            item.quantity <= 0
        );
  if (detail.products.length === 0) return null;
  return (
    <>
      {detail.Store.status === "Close" && (
        <CAlert color="warning" className="mb-0 border-radius-0">
          <div className="d-flex align-items-center justify-content-start p-0">
            <div>
              <WarningOutline width="25px" height="25px" color="orange" />
            </div>
            <div style={{ marginLeft: 10, maxWidth: "85%" }}>
              <div style={{ fontWeight: "bold", fontSize: 12 }}>
                Toko / Penjual tidak aktif
              </div>
              <div style={{ fontSize: 12 }}>{detail.Store.reason_closed}</div>
            </div>
          </div>
        </CAlert>
      )}
      <CCard className={detail.Store.status === "Close" ? "mt-0" : ""}>
        <CCardHeader
          style={{ opacity: 0.8 }}
          className="p-2 d-flex justify-content-between align-items-center"
        >
          <div className="d-flex justify-content-start align-items-center">
            <Checkbox
              disabled={true}
              onClick={(e) => {}}
              checked={false}
              style={{ fontSize: 18 }}
            />
            <div>
              <div>
                <b>
                  <a
                    href={`/${detail.subdomain_store}`}
                    style={{ color: "#000" }}
                  >
                    {detail.name_store}
                  </a>
                </b>
              </div>
              <div style={{ fontSize: 12 }} className="text-dark">
                Dikirim dari: <b>{sender.name}</b>
              </div>
              <div style={{ fontSize: 12 }} className="text-dark">
                {sender.Subdistrict.City.type} {sender.Subdistrict.City.name} -{" "}
                {sender.Subdistrict.City.Province.name}
              </div>
            </div>
          </div>
          {detail.Warehouses.length > 0 && (
            <CButton onClick={() => setShow(true)} className="border px-3">
              <b>Pilih Asal Pengiriman</b>
            </CButton>
          )}
        </CCardHeader>
        <CCardBody className="p-2">
          {data.map((item, i) => (
            <CRow key={i} className="my-2" style={{ opacity: 0.7 }}>
              <CCol md="8">
                <div className="d-flex justify-content-start align-items-start w-100">
                  <Checkbox
                    disabled={true}
                    onClick={(e) => {}}
                    checked={false}
                    style={{ fontSize: 18 }}
                  />
                  <div className="w-100">
                    <div className="d-flex justify-content-start align-items-start">
                      <div className="mr-2">
                        <a
                          href={`/${detail.subdomain_store}/${item.product.subdomain_product}`}
                          className="text-dark"
                        >
                          <CImg
                            src={defaultImg(item.product)}
                            className="rounded"
                            style={{ width: 45, height: 45 }}
                          />
                        </a>
                      </div>
                      <div className="d-flex flex-column justify-content-between">
                        <div>
                          <a
                            href={`/${detail.subdomain_store}/${item.product.subdomain_product}`}
                            className="text-dark"
                          >
                            {item.product.name}
                          </a>
                        </div>
                        <div className="d-flex">
                          {item.product.price > item.price && (
                            <div
                              style={{
                                textDecorationLine: "line-through",
                                marginRight: 5,
                              }}
                            >
                              Rp {formatPrice(item.product.price)}
                            </div>
                          )}
                          <div style={{ fontWeight: "bold" }}>
                            Rp {formatPrice(item.price)}
                          </div>
                        </div>
                        {(item.stock && item.stock.qty === 0) ||
                        item.product.is_empty === true ||
                        item.product.status === "empty" ? (
                          <div style={{ paddingVertical: 5 }}>
                            <div>
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 12,
                                  color: "red",
                                }}
                              >
                                Stok kosong
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol md="3" className="d-flex justify-content-center">
                <ActionQuantity item={item} sender={sender} disabled={true} />
              </CCol>
              <CCol md="1" className="d-flex flex-row justify-content-end">
                <div
                  style={{ height: 35 }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <Action item={item} />
                </div>
              </CCol>
            </CRow>
          ))}
        </CCardBody>
      </CCard>
      <ModalSender
        detail={detail}
        sender={sender}
        show={show}
        setShow={setShow}
        onChangeSender={onChangeSender}
      />
    </>
  );
};

export default Card;
