import types from './constants';

const initialState = {
  wishlist: [],
  countWishlist: 0,
  checkWishlist: {},
  check_products: [],
  w_added:false,
  w_deleted:false,
  isLoadingCheckProducts: false,
  isLoadingCheckWishlist: false,
  isLoadingWishlist: false,
  isLoadingCreateWishlist: false,
  isLoadingDeleteWishlist: false,
  favorite: [],
  countFavorite: 0,
  checkFavorite: {},
  isLoadingCheckFavorite: false,
  isLoadingFavorite: false,
  isLoadingCreateFavorite: false,
  isLoadingDeleteFavorite: false
};

export default (state = initialState, action) => {
  switch(action.type){
    case types.SET_COUNT_WISHLIST:
      return { ...state, countWishlist: action.payload}
    case types.GET_WISHLIST_SUCCESS:
      return { ...state, wishlist:action.payload}
    case types.IS_LOADING_GET_WISHLIST:
      return {...state, isLoadingWishlist:action.payload}
    case types.CHECK_ALL_PRODUCTS_ON_WISHLIST_SUCCESS:
      return {...state, check_products: action.payload}
    case types.IS_LOADING_CHECK_ALL_PRODUCTS_ON_WISHLIST:
      return {...state, isLoadingCheckProducts: action.payload}
    case types.CHECK_WISHLIST_SUCCESS:
      return { ...state, checkWishlist:action.payload}
    case types.IS_LOADING_CHECK_WISHLIST:
      return { ...state, isLoadingCheckWishlist:action.payload}
    case types.CREATE_WISHLIST_SUCCESS:
      return Object.assign({}, state, {
        wishlist: [...(state.wishlist.concat(action.payload))],
        checkWishlist: action.payload
      });
    case types.IS_LOADING_CREATE_WISHLIST:
      return {...state, isLoadingCreateWishlist:action.payload}
    case types.DELETE_WISHLIST_SUCCESS:
      return Object.assign({}, state, {
        wishlist: state.wishlist.filter(item => item.id !== Number(action.payload.id)),
        checkWishlist: {}
      })
    case types.IS_LOADING_DELETE_WISHLIST:
      return {...state, isLoadingDeleteWishlist:action.payload}
    case types.SET_COUNT_FAVORITE:
      return { ...state, countFavorite: action.payload}
    case types.GET_FAVORITE_SUCCESS:
      return {...state, favorite:action.payload}
    case types.IS_LOADING_GET_FAVORITE:
      return {...state, isLoadingFavorite:action.payload}
    case types.CHECK_FAVORITE_SUCCESS:
      return { ...state, checkFavorite:action.payload}
    case types.IS_LOADING_CHECK_FAVORITE:
      return { ...state, isLoadingCheckFavorite:action.payload}
    case types.CREATE_FAVORITE_SUCCESS:
      return Object.assign({}, state, {
        favorite: [...(state.favorite.concat(action.payload))],
        checkFavorite: action.payload
      });
    case types.IS_LOADING_CREATE_FAVORITE:
      return {...state, isLoadingCreateFavorite:action.payload}
    case types.DELETE_FAVORITE_SUCCESS:
      return Object.assign({}, state, {
        favorite: state.favorite.filter(item => item.id !== Number(action.payload.id)),
        checkFavorite: {}
      })
    case types.IS_LOADING_DELETE_FAVORITE:
      return {...state, isLoadingDeleteFavorite:action.payload}
    case types.SET_ADDED_WISHLIST:
      return { ...state, w_added: action.payload }
    case types.SET_DELETED_WISHLIST:
      return { ...state, w_deleted: action.payload }
    case types.DELETE_WISHLIST_BY_PRODUCT_ID_SUCCESS:
      return {...state, check_products: state.check_products.filter(item=> item !== action.payload)}
    case types.IS_LOADING_DELETE_WISHLIST_BY_PRODUCT_ID:
      return {...state}
    default:
      return state;
  }
}


export const checkAllProductsOnWishlist = payload => ({
  type: types.CHECK_ALL_PRODUCTS_ON_WISHLIST,
  payload
});
export const checkAllProductsOnWishlistSuccess = payload => ({
  type: types.CHECK_ALL_PRODUCTS_ON_WISHLIST_SUCCESS,
  payload
})
export const setIsLoadingCheckAllProductsOnWishlist = payload => ({
  type: types.IS_LOADING_CHECK_ALL_PRODUCTS_ON_WISHLIST,
  payload
})

export const setAddedWishlist = payload => ({
  type: types.SET_ADDED_WISHLIST,
  payload
})
export const setDeletedWishlist = payload => ({
  type: types.SET_DELETED_WISHLIST,
  payload
})

export const deleteWishlistByProductId = payload => ({
  type: types.DELETE_WISHLIST_BY_PRODUCT_ID,
  payload
})
export const deleteWishlistByProductIdSuccess = payload => ({
  type: types.DELETE_WISHLIST_BY_PRODUCT_ID_SUCCESS,
  payload
})
export const setIsLoadingDeleteWishlistByProductId = payload => ({
  type: types.IS_LOADING_DELETE_WISHLIST_BY_PRODUCT_ID,
  payload
})
//get wishlist
export const setCountWishlist = payload => ({
  type: types.SET_COUNT_WISHLIST,
  payload
})
export const getWishlist = payload => ({
  type: types.GET_WISHLIST,
  payload
});
export const getWishlistSuccess = payload => ({
  type: types.GET_WISHLIST_SUCCESS,
  payload
})
export const isLoadingGetWishlist = payload => ({
  type: types.IS_LOADING_GET_WISHLIST,
  payload
})
//check wishlist
export const checkWishlist = payload => ({
  type: types.CHECK_WISHLIST,
  payload
});
export const checkWishlistSuccess = payload => ({
  type: types.CHECK_WISHLIST_SUCCESS,
  payload
})
export const isLoadingCheckWishlist = payload => ({
  type: types.IS_LOADING_CHECK_WISHLIST,
  payload
})
//create
export const createWishlist = payload => ({
  type: types.CREATE_WISHLIST,
  payload
})
export const createWishlistSuccess = payload => ({
  type: types.CREATE_WISHLIST_SUCCESS,
  payload
})
export const setIsLoadingCreateWishlist = payload => ({
  type: types.IS_LOADING_CREATE_WISHLIST,
  payload
})

//delete wishlist
export const deleteWishlist = payload => ({
  type: types.DELETE_WISHLIST,
  payload
})
export const deleteWishlistSuccess = payload => ({
  type: types.DELETE_WISHLIST_SUCCESS,
  payload
})
export const setIsLoadingDeleteWishlist = payload => ({
  type: types.IS_LOADING_DELETE_WISHLIST,
  payload
})

//get favorite
export const setCountFavorite = payload => ({
  type: types.SET_COUNT_FAVORITE,
  payload
})
export const getFavorite = payload => ({
  type: types.GET_FAVORITE,
  payload
});
export const getFavoriteSuccess = payload => ({
  type: types.GET_FAVORITE_SUCCESS,
  payload
})
export const setIsLoadingGetFavorite = payload => ({
  type: types.IS_LOADING_GET_FAVORITE,
  payload
})
//check favorite
export const checkFavorite = payload => ({
  type: types.CHECK_FAVORITE,
  payload
});
export const checkFavoriteSuccess = payload => ({
  type: types.CHECK_FAVORITE_SUCCESS,
  payload
})
export const isLoadingCheckFavorite = payload => ({
  type: types.IS_LOADING_CHECK_FAVORITE,
  payload
})
//create
export const createFavorite = payload => ({
  type: types.CREATE_FAVORITE,
  payload
})
export const createFavoriteSuccess = payload => ({
  type: types.CREATE_FAVORITE_SUCCESS,
  payload
})
export const setIsLoadingCreateFavorite = payload => ({
  type: types.IS_LOADING_CREATE_FAVORITE,
  payload
})

//delete favorite
export const deleteFavorite = payload => ({
  type: types.DELETE_FAVORITE,
  payload
});
export const deleteFavoriteSuccess = payload => ({
  type: types.DELETE_FAVORITE_SUCCESS,
  payload
})
export const setIsLoadingDeleteFavorite = payload => ({
  type: types.IS_LOADING_DELETE_FAVORITE,
  payload
})
