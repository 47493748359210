import types from "./constants";
import { formatCart } from "./";
const initialState = {
  data: [],
  count: 0,
  carts: [],
  isLoadingGetCarts: true,
  isLoadingAddCart: false,
  isLoadingEditCart: false,
  isLoadingDeleteCart: false,
  isLoadingGetCountCarts: false,
  edited: false,
  added: false,
  deleted: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_SENDER_STORE_FOR_CART:
      return {
        ...state,
        carts: state.carts.map((item) =>
          item.store === action.payload.store
            ? {
                ...item,
                sender: action.payload.sender,
                products: item.products.map((val) => ({
                  ...val,
                  stock: val.product.Stocks.find(
                    (w) => w.warehouse_id === action.payload.sender?.id
                  ),
                  quantity:
                    Number(val.quantity) >
                    Number(
                      val.product.Stocks.find(
                        (w) => w.warehouse_id === action.payload.sender?.id
                      )?.qty
                    )
                      ? Number(
                          val.product.Stocks.find(
                            (w) => w.warehouse_id === action.payload.sender?.id
                          )?.qty
                        )
                      : Number(val.quantity),
                })),
              }
            : item
        ),
      };
    case types.DELETE_MULTIPLE_CART_ID_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          (item) => !action.payload.multipleId.includes(item.id)
        ),
        carts: state.carts.map((item) => ({
          ...item,
          products: item.products.filter(
            (item) => !action.payload.multipleId.includes(item.id)
          ),
        })),
      };
    case types.GET_CART_SUCCESS:
      return {
        ...state,
        carts: formatCart(action.payload),
        data: action.payload,
      };
    case types.IS_LOADING_GET_CART:
      return { ...state, isLoadingGetCarts: action.payload };
    case types.CREATE_CART_SUCCESS:
      return { ...state };
    case types.IS_LOADING_CREATE_CART:
      return { ...state, isLoadingAddCart: action.payload };
    case types.ADDED_CART:
      return { ...state, added: action.payload };
    case types.GET_COUNT_CART_SUCCESS:
      return { ...state, count: action.payload };
    case types.IS_LOADING_GET_COUNT_CART:
      return { ...state, isLoadingGetCountCarts: action.payload };
    case types.EDIT_CART_SUCCESS:
      return {
        ...state,
        carts: state.carts.map((item) =>
          item.store_id === action.payload.store
            ? {
                ...item,
                products: item.products.map((val) =>
                  val.id === action.payload.id
                    ? {
                        ...val,
                        quantity: Number(action.payload.quantity),
                        description: action.payload.description,
                      }
                    : val
                ),
              }
            : item
        ),
        data: state.data.map((item) =>
          item.id === action.payload.id
            ? {
                ...item,
                quantity: Number(action.payload.quantity),
                description: action.payload.description,
              }
            : item
        ),
      };
    case types.IS_LOADING_EDIT_CART:
      return { ...state, isLoadingEditCart: action.payload };
    case types.IS_LOADING_DELETE_CART:
      return { ...state, isLoadingDeleteCart: action.payload };
    case types.EDITED_CART:
      return { ...state, edited: action.payload };
    case types.DELETED_CART:
      return { ...state, deleted: action.payload };
    case types.RESET_CART:
      return initialState;
    default:
      return state;
  }
};

export const changeSenderStoreForCart = (payload) => ({
  type: types.CHANGE_SENDER_STORE_FOR_CART,
  payload,
});
export const deletedCart = (payload) => ({
  type: types.DELETED_CART,
  payload,
});
//GET_CART
export const getCart = (payload) => ({
  type: types.GET_CART,
  payload,
});
export const getCartSuccess = (payload) => ({
  type: types.GET_CART_SUCCESS,
  payload,
});
export const setIsLoadingGetCart = (payload) => ({
  type: types.IS_LOADING_GET_CART,
  payload,
});

export const editCartById = (payload) => ({
  type: types.EDIT_CART,
  payload,
});
export const editCartByIdSuccess = (payload) => ({
  type: types.EDIT_CART_SUCCESS,
  payload,
});
export const setIsLoadingEditCart = (payload) => ({
  type: types.IS_LOADING_EDIT_CART,
  payload,
});
export const setEditedCart = (payload) => ({
  type: types.EDITED_CART,
  payload,
});

//GET_CART_COUNT
export const getCountCarts = (payload) => ({
  type: types.GET_COUNT_CART,
  payload,
});
export const getCountCartsSuccess = (payload) => ({
  type: types.GET_COUNT_CART_SUCCESS,
  payload,
});
export const setIsLoadingGetCountCarts = (payload) => ({
  type: types.IS_LOADING_GET_COUNT_CART,
  payload,
});

// CREATE_CART
export const createCart = (payload) => ({
  type: types.CREATE_CART,
  payload,
});
export const createCartSuccess = (payload) => ({
  type: types.CREATE_CART_SUCCESS,
  payload,
});
export const setIsLoadingCreateCart = (payload) => ({
  type: types.IS_LOADING_CREATE_CART,
  payload,
});
export const setAddedCart = (payload) => ({
  type: types.ADDED_CART,
  payload,
});

export const deleteCart = (payload) => ({
  type: types.DELETE_CART,
  payload,
});
export const deleteMultipleCart = (payload) => ({
  type: types.DELETE_MULTIPLE_CART_ID,
  payload,
});
export const deleteMultipleCartSuccess = (payload) => ({
  type: types.DELETE_MULTIPLE_CART_ID_SUCCESS,
  payload,
});
export const setIsLoadingDeleteCart = (payload) => ({
  type: types.IS_LOADING_DELETE_CART,
  payload,
});

export const resetCart = (payload) => ({
  type: types.RESET_CART,
});
