import {put, call, takeLatest, select } from 'redux-saga/effects';
import Api from '../../../../services';
import types from './constant';
import * as actions from './reducer';
import { notification } from '../../../../helpers/notification';

function* watchGetCoin(value){
  const { payload } = value;
  yield put(actions.setLoading(true));
  try{
    const response = yield call(Api.coin.getCoin, payload);
    const { data } = response;
    if(data.success){
      yield put(actions.getCoinSuccess(data.data.coin));
    }
  }catch(e){
    yield put(actions.setLoading(false));
  }finally{
    yield put(actions.setLoading(false));
  }
}
function* watchGetHistory(value){
  const { payload } = value;
  yield put(actions.setLoadingHistory(true));
  try{
    const response = yield call(Api.coin.getHistory, payload);
    const { data } = response;
    if(data.success){
      yield put(actions.getHistorySuccess(data.data));
    }
  }catch(e){
    yield put(actions.setLoadingHistory(false));
  }finally{
    yield put(actions.setLoadingHistory(false));
  }
}
function* watchRequestOTP(value){
  const { payload } = value;
  yield put(actions.setLoadingRequestOtp(true));
  try{
    const response = yield call(Api.coin.requestOTPWithdraw, payload);
    const { data } = response;
    if(data.success){
      yield put(actions.requestOTPSuccess(data.success));
    }
  }catch(e){
    yield put(actions.setLoadingRequestOtp(false));
    yield notification.error(e.response.data.message);
  }finally{
    yield put(actions.setLoadingRequestOtp(false));
  }
}
function* watchVerifyOTP(value){
  const { payload } = value;
  yield put(actions.setLoadingVerifyOtp(true));
  const state = yield select();
  try{
    const response = yield call(Api.coin.verifyOTPWithdraw, payload);
    const { data } = response;
    if(data.success){
      yield put(actions.requestOTPSuccess(false));
      yield put(actions.getHistorySuccess([data.history, ...state.coin.history]));
      yield put(actions.verifyOTPSuccess(data.success));
    }
  }catch(e){
    yield put(actions.setLoadingVerifyOtp(false));
    yield notification.error(e.response.data.message);
  }finally{
    yield put(actions.verifyOTPSuccess(false));
    yield put(actions.setLoadingVerifyOtp(false));
  }
};

const sagas = [
  takeLatest(types.GET_COIN_BALANCE, watchGetCoin),
  takeLatest(types.GET_COIN_BALANCE_HISTORY, watchGetHistory),
  takeLatest(types.REQUEST_WITHDRAWAL_COIN, watchRequestOTP),
  takeLatest(types.CONFIRM_OTP_WITHDRAWAL_COIN, watchVerifyOTP),
];

export default sagas;
