import { useState, useEffect } from "react";

export const Timer = ({ minutes, seconds, onTimeUp }) => {
  const [time, setTime] = useState({ minutes, seconds });

  useEffect(() => {
    const timer = setInterval(() => {
      if (time.minutes === 0 && time.seconds === 0) {
        clearInterval(timer);
        // Mengirim nilai true ke komponen induk saat waktu habis
        onTimeUp(true);
      } else {
        setTime((prevTime) => {
          if (prevTime.seconds === 0) {
            return { minutes: prevTime.minutes - 1, seconds: 59 };
          } else {
            return { ...prevTime, seconds: prevTime.seconds - 1 };
          }
        });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [time, onTimeUp]);

  return `${time.minutes.toString().padStart(2, "0")}:${time.seconds
    .toString()
    .padStart(2, "0")}`;
};
