import { Api } from "./api";

export default {
  getByProductId: (payload) =>
    Api.get(`/v1/discussions/product/${payload.product_id}`),
  getByStoreId: (payload) =>
    Api.get(`/v1/discussions/store/${payload.store_id}`, {
      params: payload.data,
    }),
  getDetail: (payload) =>
    Api.get(`/v1/discussions/detail/${payload.discussion_id}`),
  getByUserId: (payload) =>
    Api.get(`/v1/discussions/user`, {
      params: {
        page: payload.page,
        limit: payload.limit,
      },
      headers: { token: payload.token },
    }),
  createDiscussion: (payload) =>
    Api.post(`/v1/discussions`, payload.data, {
      headers: { token: payload.token },
    }),
  updateDiscussion: (payload) =>
    Api.put(`/v1/discussions/edit/${payload.discussion_id}`, payload.data, {
      headers: { token: payload.token },
    }),
  deleteDiscussion: (payload) =>
    Api.put(
      `/v1/discussions/delete/${payload.discussion_id}`,
      {},
      {
        headers: { token: payload.token },
      }
    ),
  createComment: (payload) =>
    Api.post(`/v1/comments`, payload.data, {
      headers: { token: payload.token },
    }),

  notification: (payload) =>
    Api.get(`/v2/discussions/buyer/notification`, {
      headers: {
        token: payload.token,
      },
    }),
  product: (payload) =>
    Api.get(`/v2/discussions/product/${payload.id}`, {
      headers: {
        token: payload.token,
      },
      params: {
        page: payload.page,
        limit: payload.limit,
      },
    }),
  get: (payload) =>
    Api.get(`/v2/discussions/buyer`, {
      headers: {
        token: payload.token,
      },
      params: {
        page: payload.page,
        limit: payload.limit,
      },
    }),
  add: (payload) =>
    Api.post(`/v2/discussions/buyer/add`, payload.data, {
      headers: {
        token: payload.token,
      },
    }),
  delete: (payload) =>
    Api.delete(`/v2/discussions/buyer/${payload.id}`, {
      headers: {
        token: payload.token,
      },
    }),
  comment: {
    add: (payload) =>
      Api.post(`/v2/discussions/buyer/comment`, payload.data, {
        headers: {
          token: payload.token,
        },
      }),
    delete: (payload) =>
      Api.delete(`/v2/discussions/buyer/comment/${payload.id}`, {
        headers: {
          token: payload.token,
        },
      }),
  },
};
