export default {
  SET_CHECKOUT_RECEIVER: "set-checkout-receiver",
  SET_CHECKOUT_PAYMENT: "set-checkout-payment",
  SET_CHECKOUT_SHIPPING: "set-checkout-shipping",
  SET_CHECKOUT_ORDERS: "set-checkout-orders",
  SET_CHECKOUT_SELECT_SENDER: "set-checkout-select-sender",
  SET_CHECKOUT_INSURANCE: "set-checkout-insurance",

  RESET_CHECKOUT_SHIPPING: "reset-checkout-shipping",

  SET_CHECKOUT_PAYMENT_SUCCESS: "set-checkout-payment-success",

  GET_COSTS: "get-costs-checkout",
  GET_COSTS_SUCCESS: "get-costs-checkout-success",
  IS_LOADING_GET_COSTS: "is-loading-get-costs-checkout",
  RESET_COSTS: "reset-costs-checkout",

  CREATE_ORDERS: 'create-orders-checkout',
  CREATE_ORDERS_SUCCESS: 'create-orders-checkout-success',
  IS_LOADING_CREATE_ORDERS: 'is-loading-create-orders-checkout',
  RESET_CREATE_ORDERS: 'reset-create-orders-checkout',
  SET_CREATED_ORDERS_SUCCESS: 'set-created-orders-checkout-success',

  RESET_CHECKOUT: "reset-checkout-shipment",
};
