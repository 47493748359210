import types from "./constants";

const initialState = {
  check: {
    data: {},
    loading: false,
    registered: false,
    success: false,
    done: false,
    google: false,
    message: "",
  },
  request: {
    success: false,
    loading: false,
  },
  verify: {
    email: "",
    phone_number: "",
    token_reg: "",
    verified: false,
    success: false,
    is_error: false,
    message: "",
    loading: false,
  },
  create: {
    data: {},
    success: false,
    message: "",
    loading: false,
  },

  checkEmailRegister: {},
  isLoadingCheckEmailRegister: false,
  createOTPRegister: {},
  isLoadingCreateOTPRegister: false,
  verifiedOTP: {},
  isLoadingVerifiedOTP: false,
  register: {},
  isLoadingRegister: false,
  message: "",
  isRegisterSuccess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHECK_REGISTER_SUCCESS:
      return {
        ...state,
        check: {
          ...state.check,
          ...action.payload,
        },
      };
    case types.LOADING_CHECK_REGISTER:
      return {
        ...state,
        check: {
          ...state.check,
          loading: action.payload,
        },
      };
    case types.DONE_CHECK_REGISTER:
      return {
        ...state,
        check: {
          ...state.check,
          done: action.payload,
        },
      };
    case types.RESET_CHECK_REGISTER:
      return { ...state, check: initialState.check };

    case types.REQUEST_OTP_REGISTER_SUCCESS:
      return {
        ...state,
        request: {
          ...state.request,
          ...action.payload,
        },
      };
    case types.LOADING_REQUEST_OTP_REGISTER:
      return {
        ...state,
        request: {
          ...state.request,
          loading: action.payload,
        },
      };
    case types.RESET_REQUEST_REGISTER:
      return { ...state, request: initialState.request };

    case types.VERIFY_OTP_REGISTER_SUCCESS:
      return {
        ...state,
        verify: {
          ...state.verify,
          ...action.payload,
        },
      };
    case types.LOADING_VERIFY_OTP_REGISTER:
      return {
        ...state,
        verify: {
          ...state.verify,
          loading: action.payload,
        },
      };
    case types.RESET_VERIFY_OTP_REGISTER:
      return { ...state, verify: initialState.verify };

    case types.REGISTER_SUCCESS:
      return {
        ...state,
        register: action.payload,
        create: {
          ...state.create,
          data: action.payload,
          success: action.payload.success,
        },
      };
    case types.IS_REGISTER_LOADING:
      return {
        ...state,
        isLoadingRegister: action.payload,
        create: {
          ...state.create,
          loading: action.payload,
        },
      };
    case types.SET_REGISTER_MESSAGE:
      return { ...state, message: action.payload };
    case types.CLEAR_REGISTER_MESSAGE:
      return { ...state, message: "" };

    default:
      return state;
  }
};

export const checkEmailRegister = (payload) => ({
  type: types.CHECK_EMAIL_REGISTER,
  payload,
});
export const checkPhoneNumberRegister = (payload) => ({
  type: types.CHECK_PHONE_NUMBER_REGISTER,
  payload,
});
export const checkRegisterSuccess = (payload) => ({
  type: types.CHECK_REGISTER_SUCCESS,
  payload,
});
export const setDoneCheckRegister = (payload) => ({
  type: types.DONE_CHECK_REGISTER,
  payload,
});
export const setLoadingCheckRegister = (payload) => ({
  type: types.LOADING_CHECK_REGISTER,
  payload,
});
export const resetCheckRegister = (payload) => ({
  type: types.RESET_CHECK_REGISTER,
  payload,
});

export const requestOTPEmailRegister = (payload) => ({
  type: types.REQUEST_OTP_EMAIL_REGISTER,
  payload,
});
export const requestOTPPhoneNumberRegister = (payload) => ({
  type: types.REQUEST_OTP_PHONE_NUMBER_REGISTER,
  payload,
});
export const requestOTPRegisterSuccess = (payload) => ({
  type: types.REQUEST_OTP_REGISTER_SUCCESS,
  payload,
});
export const setLoadingRequestOTPRegister = (payload) => ({
  type: types.LOADING_REQUEST_OTP_REGISTER,
  payload,
});
export const resetRequestRegister = (payload) => ({
  type: types.RESET_REQUEST_REGISTER,
  payload,
});

export const verifyOTPRegister = (payload) => ({
  type: types.VERIFY_OTP_REGISTER,
  payload,
});
export const verifyOTPRegisterSuccess = (payload) => ({
  type: types.VERIFY_OTP_REGISTER_SUCCESS,
  payload,
});
export const setLoadingVerifyOTPRegister = (payload) => ({
  type: types.LOADING_VERIFY_OTP_REGISTER,
  payload,
});
export const resetVerifyOTPRegister = (payload) => ({
  type: types.RESET_VERIFY_OTP_REGISTER,
  payload,
});

export const registerUser = (payload) => ({
  type: types.REGISTER,
  payload,
});
export const registerSuccess = (payload) => ({
  type: types.REGISTER_SUCCESS,
  payload,
});
export const isLoadingRegister = (payload) => ({
  type: types.IS_REGISTER_LOADING,
  payload,
});
export const setMessageRegister = (payload) => ({
  type: types.SET_REGISTER_MESSAGE,
  payload,
});
export const clearMessageRegister = () => ({
  type: types.CLEAR_REGISTER_MESSAGE,
});
