import { put, call, takeLatest } from "redux-saga/effects";
import Api from "../../services";
import types from "./types";
import * as actions from "./reducer";

function* watchCheckTracking(value) {
  yield put(actions.setIsLoadingCheckTracking(true));
  const { payload } = value;
  try {
    const response = yield call(Api.shipping.tracking.order, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.checkTrackingSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.setIsLoadingCheckTracking(false));
  } finally {
    yield put(actions.setIsLoadingCheckTracking(false));
  }
}
function* watchCheckCosts(value) {
  yield put(actions.setIsLoadingCheckCosts(true));
  const { payload } = value;
  try {
    const response = yield call(Api.checkcost.multiple, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.checkCostsSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.setIsLoadingCheckCosts(false));
  } finally {
    yield put(actions.setIsLoadingCheckCosts(false));
  }
}
const sagas = [
  takeLatest(types.CHECK_TRACKING, watchCheckTracking),
  takeLatest(types.CHECK_COSTS, watchCheckCosts),
];

export default sagas;
