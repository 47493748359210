export default {
  SET_ORIGIN: 'SET_ORIGIN_BUY_DIRECT',
  SET_DESTINATION: 'SET_DESTINATION_BUY_DIRECT',

  GET_WAREHOUSE: 'GET_WAREHOUSE_BUY_DIRECT',
  GET_WAREHOUSE_SUCCESS: 'GET_WAREHOUSE_SUCCESS_BUY_DIRECT',
  IS_LOADING_GET_WAREHOUSE: 'IS_LOADING_GET_WAREHOUSE_BUY_DIRECT',

  SET_ORDER: 'SET_ORDER_BUY_DIRECT',
  SET_QUANTITY: 'SET_ORDER_QTY_BUY_DIRECT',
  SET_DESCRIPTION: 'SET_ORDER_DESCRIPTION_BUY_DIRECT',

  CHECK_SHIPPING_COST: 'CHECK_SHIPPING_COST_BUY_DIRECT',
  CHECK_SHIPPING_COST_SUCCESS: 'CHECK_SHIPPING_COST_BUY_DIRECT_SUCCESS',
  IS_LOADING_CHECK_SHIPPING_COST: 'IS_LOADING_CHECK_SHIPPING_COST_BUY_DIRECT',

  SET_COURIER: 'SET_COURIER_BUY_DIRECT',
  SET_INSURANCE: 'SET_INSURANCE_BUY_DIRECT',
  SET_COD: 'SET_COD_BUY_DIRECT',

  CREATE_ORDERS: 'CREATE_ORDERS_BUY_DIRECT',
  CREATE_ORDERS_SUCCESS: 'CREATE_ORDERS_SUCCESS_BUY_DIRECT',
  IS_LOADING_CREATE_ORDERS: 'IS_LOADING_CREATE_ORDERS_BUY_DIRECT',

  CHANGE_ORDERS: 'CHANGE_ORDERS_BUY_DIRECT',
  SELECT_PAYMENT_METHOD: 'SELECT_PAYMENT_METHOD_BUY_DIRECT',
  SET_PAYMENT: 'SET_PAYMENT_BUY_DIRECT',

  RESET_COD: 'RESET_COD_BUY_DIRECT',
  RESET_INSURANCE: 'RESET_INSURANCE_BUY_DIRECT',
  RESET_COURIER: 'RESET_COURIER_BUY_DIRECT',
  RESET_ORDERS: 'RESET_ORDERS_BUY_DIRECT',
  RESET_PAYMENT: 'RESET_PAYMENT_BUY_DIRECT',
  RESET_BUY: 'RESET_BUY_DIRECT',
}
