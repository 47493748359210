import types from './constant';

const initialState = {
  banners: [],
  isLoadingBanners: true,
  categories: [],
  isLoadingCategories: false,
  rfq: [],
  isLoadingGetRFQ: false,
  newStores: [],
  isLoadingNewStores: false,
  newProducts: [],
  isLoadingNewProducts: false
};

export default (state = initialState, action)=>{
  switch(action.type){
    case types.GET_ALL_BANNER_SUCCESS:
      return { ...state, banners:action.payload}
    case types.IS_LOADING_GET_BANNER:
      return { ...state, isLoadingBanners:action.payload}
    case types.GET_CATEGORY_SUCCESS:
      return { ...state, categories:action.payload}
    case types.IS_LOADING_GET_CATEGORY:
      return { ...state, isLoadingCategories:action.payload}
    case types.GET_RFQ_SUCCESS:
      return { ...state, rfq:action.payload}
    case types.IS_LOADING_GET_RFQ:
      return { ...state, isLoadingGetRFQ:action.payload}
    case types.GET_NEW_STORES_SUCCESS:
      return { ...state, newStores:action.payload}
    case types.IS_LOADING_GET_NEW_STORES:
      return { ...state, isLoadingNewStores:action.payload}
    case types.GET_NEW_PRODUCTS_SUCCESS:
      return { ...state, newProducts:action.payload}
    case types.IS_LOADING_GET_NEW_PRODUCTS:
      return { ...state, isLoadingNewProducts:action.payload}
    default:
      return state;
  }
}

export const getAllBanner = payload => ({
  type: types.GET_ALL_BANNER,
  payload
});

export const getAllBannerSuccess = payload => ({
  type: types.GET_ALL_BANNER_SUCCESS,
  payload
})

export const isLoadingGetBanner = payload => ({
  type: types.IS_LOADING_GET_BANNER,
  payload
})

//categories
export const getCategories = payload => ({
  type: types.GET_CATEGORY,
  payload
});

export const getCategoriesSuccess = payload => ({
  type: types.GET_CATEGORY_SUCCESS,
  payload
})

export const isLoadingGetCategories = payload => ({
  type: types.IS_LOADING_GET_CATEGORY,
  payload
})

//rfq
export const getRFQ = payload => ({
  type: types.GET_RFQ,
  payload
})
export const getRFQSuccess = payload => ({
  type: types.GET_RFQ_SUCCESS,
  payload
})
export const isLoadingGetRFQ = payload => ({
  type: types.IS_LOADING_GET_RFQ,
  payload
})

//new stores
export const getNewStores = payload => ({
  type: types.GET_NEW_STORES,
  payload
})
export const getNewStoresSuccess = payload => ({
  type: types.GET_NEW_STORES_SUCCESS,
  payload
})
export const isLoadingNewStores = payload => ({
  type: types.IS_LOADING_GET_NEW_STORES,
  payload
})

//new products
export const getNewProducts = payload => ({
  type: types.GET_NEW_PRODUCTS,
  payload
})
export const getNewProductsSuccess = payload => ({
  type: types.GET_NEW_PRODUCTS_SUCCESS,
  payload
})
export const isLoadingNewProducts = payload => ({
  type: types.IS_LOADING_GET_NEW_PRODUCTS,
  payload
})
