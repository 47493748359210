import types from './constant';

const initialState = {
  sent: false,
  loading: false
}

export default (state = initialState, action) => {
  switch(action.type){
    case types.SEND_CONTACT_US_SUCCESS:
      return { ...state, sent: action.payload }
    case types.IS_LOADING_SEND_CONTACT_US:
      return { ...state, loading: action.payload }
    default:
      return state;
  }
}

export const sendContactUs = payload => ({
  type: types.SEND_CONTACT_US,
  payload
})
export const sendContactUsSuccess = payload => ({
  type: types.SEND_CONTACT_US_SUCCESS,
  payload
})
export const setIsLoadingSendContactUs = payload => ({
  type: types.IS_LOADING_SEND_CONTACT_US,
  payload
})
