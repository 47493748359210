export default {
  //get address
  GET_ALL_ADDRESS: "GET_ALL_ADDRESS",
  GET_ALL_ADDRESS_SUCCESS: "GET_ALL_ADDRESS_SUCCESS",
  IS_LOADING_GET_ADDRESS: "IS_LOADING_GET_ADDRESS",
  //create address
  CREATE_ADDRESS: "CREATE_ADDRESS",
  CREATE_ADDRESS_SUCCESS: "CREATE_ADDRESS_SUCCESS",
  IS_LOADING_CREATE_ADDRESS: "IS_LOADING_CREATE_ADDRESS",
  //edit address
  EDIT_ADDRESS: "EDIT_ADDRESS",
  EDIT_ADDRESS_SUCCESS: "EDIT_ADDRESS_SUCCESS",
  IS_LOADING_EDIT_ADDRESS: "IS_LOADING_EDIT_ADDRESS",
  //set default address
  SET_DEFAULT_ADDRESS: "SET_DEFAULT_ADDRESS",
  SET_DEFAULT_ADDRESS_SUCCESS: "SET_DEFAULT_ADDRESS_SUCCESS",
  IS_LOADING_SET_DEFAULT_ADDRESS: "IS_LOADING_SET_DEFAULT_ADDRESS",
  //delete address
  DELETE_ADDRESS: "DELETE_ADDRESS",
  DELETE_ADDRESS_SUCCESS: "DELETE_ADDRESS_SUCCESS",
  IS_LOADING_DELETE_ADDRESS: "IS_LOADING_DELETE_ADDRESS",
  //GET_SUBDISTRICT
  GET_SUBDISTRICT: "GET_SUBDISTRICT",
  GET_SUBDISTRICT_SUCCESS: "GET_SUBDISTRICT_SUCCESS",
  IS_LOADING_GET_SUBDISTRICT: "IS_LOADING_GET_SUBDISTRICT",
  //GET_CITY
  GET_CITY: "GET_CITY",
  GET_CITY_SUCCESS: "GET_CITY_SUCCESS",
  IS_LOADING_GET_CITY: "IS_LOADING_GET_CITY",
  //RESPONSE_SUCCESS
  RESPONSE_SUCCESS: "RESPONSE_SUCCESS_ADDRESS",
  SET_ERROR: "set-error-address",
  SET_MESSAGE: "set-message-address",
};
