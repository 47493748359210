export default {
  CREATE_COMPLAINT : 'create-complaint',
  CREATE_COMPLAINT_SUCCESS : 'create-complaint-success',
  IS_LOADING_CREATE_COMPLAINT: 'is-loading-create-complaint',

  GET_COMPLAINT_BY_BUYER: 'GET_COMPLAINT_BY_BUYER',
  GET_COMPLAINT_BY_SELLER: 'GET_COMPLAINT_BY_SELLER',
  GET_COMPLAINT_SUCCESS: 'GET_COMPLAINT_SUCCESS',

  GET_DETAIL_COMPLAINT: 'GET_DETAIL_COMPLAINT',
  GET_DETAIL_COMPLAINT_BY_ORDER_ID: 'GET_DETAIL_COMPLAINT_BY_ORDER_ID',
  GET_DETAIL_COMPLAINT_SUCCESS: 'GET_DETAIL_COMPLAINT_SUCCESS',
  RESET_GET_COMPLAINT: 'RESET_GET_COMPLAINT',
  IS_LOADING_GET_COMPLAINT: 'IS_LOADING_GET_COMPLAINT',

  GET_HISTORY_COMPLAINT: 'GET_HISTORY_COMPLAINT',
  GET_HISTORY_COMPLAINT_SUCCESS: 'GET_HISTORY_COMPLAINT_SUCCESS',
  RESET_GET_HISTORY_COMPLAINT: 'RESET_GET_HISTORY_COMPLAINT',
  IS_LOADING_GET_HISTORY_COMPLAINT: 'IS_LOADING_GET_HISTORY_COMPLAINT',

  GET_DISCUSSION_COMPLAINT: 'GET_DISCUSSION_COMPLAINT',
  GET_DISCUSSION_COMPLAINT_SUCCESS: 'GET_DISCUSSION_COMPLAINT_SUCCESS',
  RESET_GET_DISCUSSION_COMPLAINT: 'RESET_GET_DISCUSSION_COMPLAINT',
  IS_LOADING_GET_DISCUSSION_COMPLAINT: 'IS_LOADING_GET_DISCUSSION_COMPLAINT',

  GET_SERVICES_COMPLAINT: 'GET_SERVICES_COMPLAINT',
  GET_SERVICES_COMPLAINT_SUCCESS: 'GET_SERVICES_COMPLAINT_SUCCESS',
  IS_LOADING_GET_SERVICES_COMPLAINT: 'IS_LOADING_GET_SERVICES_COMPLAINT',

  CREATE_DISCUSSION_COMPLAINT: 'CREATE_DISCUSSION_COMPLAINT',
  CREATE_DISCUSSION_COMPLAINT_SUCCESS: 'CREATE_DISCUSSION_COMPLAINT_SUCCESS',
  IS_LOADING_CREATE_DISCUSSION_COMPLAINT: 'IS_LOADING_CREATE_DISCUSSION_COMPLAINT',

  CREATE_BID_COMPLAINT: 'CREATE_BID_COMPLAINT',
  CREATE_BID_COMPLAINT_SUCCESS: 'CREATE_BID_COMPLAINT_SUCCESS',
  BID_SUCCESS: 'BID_SUCCESS',
  IS_LOADING_CREATE_BID_COMPLAINT: 'IS_LOADING_CREATE_BID_COMPLAINT',

  CREATE_RETURN_RECEIPT_FROM_BUYER: 'CREATE_RETURN_RECEIPT_FROM_BUYER',
  CREATE_DELIVERY_RECEIPT_FROM_SELLER: 'CREATE_DELIVERY_RECEIPT_FROM_SELLER',
  CREATE_RECEIPT_SUCCESS: 'CREATE_RECEIPT_SUCCESS',
  IS_LOADING_CREATE_RECEIPT: 'IS_LOADING_CREATE_RECEIPT',
  SET_RETURN_RECEIPT: 'set-return-receipt',

  CONFIRM_APPROVE_BY_SELLER: 'CONFIRM_APPROVE_BY_SELLER',
  CONFIRM_APPROVE_BY_SELLER_SUCCESS: 'CONFIRM_APPROVE_BY_SELLER_SUCCESS',
  SET_SUCCESS_APPROVE_BY_SELLER:'SET_SUCCESS_APPROVE_BY_SELLER',
  IS_LOADING_CONFIRM_APPROVE_BY_SELLER: 'IS_LOADING_CONFIRM_APPROVE_BY_SELLER',

  CONFIRM_ABORTED_BY_BUYER: 'CONFIRM_ABORTED_BY_BUYER',
  CONFIRM_ABORTED_BY_BUYER_SUCCESS: 'CONFIRM_ABORTED_BY_BUYER_SUCCESS',
  SET_SUCCESS_ABORTED_BY_BUYER:'SET_SUCCESS_ABORTED_BY_BUYER',
  IS_LOADING_CONFIRM_ABORTED_BY_BUYER: 'IS_LOADING_CONFIRM_ABORTED_BY_BUYER',

  CONFIRM_APPROVE_BY_BUYER: 'CONFIRM_APPROVE_BY_BUYER',
  CONFIRM_APPROVE_BY_BUYER_SUCCESS: 'CONFIRM_APPROVE_BY_BUYER_SUCCESS',
  SET_SUCCESS_APPROVE_BY_BUYER:'SET_SUCCESS_APPROVE_BY_BUYER',
  IS_LOADING_CONFIRM_APPROVE_BY_BUYER: 'IS_LOADING_CONFIRM_APPROVE_BY_BUYER',

  ASK_FOR_HELP: 'ASK_FOR_HELP',
  ASK_FOR_HELP_SUCCESS: 'ASK_FOR_HELP_SUCCESS',
  SET_SUCCESS_ASK_FOR_HELP:'SET_SUCCESS_ASK_FOR_HELP',
  IS_LOADING_ASK_FOR_HELP: 'IS_LOADING_ASK_FOR_HELP',

  SET_IS_READ_SELLER: 'SET_IS_READ_SELLER',
  SET_IS_READ_SELLER_SUCCESS: 'SET_IS_READ_SELLER_SUCCESS'
}
