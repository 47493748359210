import types from './constants';

const initialState = {
  last_login: {},
  loading_last_login: false,
  notification:{
    chat:0,
    l_chat:false,
    review:0,
    l_review:false,
    payment:0,
    l_payment:false,
    order:{
      waiting: 0,
      processed: 0,
      shipped: 0,
      arrived: 0,
      complaint: 0
    },
    l_order:false,
    help: 0,
    l_help: false
  }
};

export default (state = initialState, action) => {
  switch(action.type){
    case types.GET_LAST_LOGIN_SUCCESS:
      return { ...state, last_login: action.payload}
    case types.IS_LOADING_LAST_LOGIN:
      return { ...state, loading_last_login: action.payload}
    case types.GET_ORDER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: {
          ...state.notification,
          payment: action.payload.payment,
          order:{
            waiting: action.payload.waiting,
            processed: action.payload.processed,
            shipped: action.payload.shipped,
            arrived: action.payload.arrived,
            complaint: action.payload.complaint
          }
        }
      }
    case types.IS_LOADING_ORDER_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...state.notification,
          l_order: action.payload
        }
      }
    case types.GET_CHAT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification:{
          ...state.notification,
          chat:action.payload
        }
      }
    case types.IS_LOADING_CHAT_NOTIFICATION:
      return {
        ...state,
        notification:{
          ...state.notification,
          l_chat:action.payload
        }
      }
    case types.GET_REVIEW_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification:{
          ...state.notification,
          review:action.payload
        }
      }
    case types.IS_LOADING_REVIEW_NOTIFICATION:
      return {
        ...state,
        notification:{
          ...state.notification,
          l_review:action.payload
        }
      }
    case types.SET_CHAT_NOTIFICATION:
      return {
        ...state,
        notification:{
          ...state.notification,
          chat: action.payload
        }
      }
    case types.GET_HELP_CHAT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification:{
          ...state.notification,
          help:action.payload
        }
      }
    case types.IS_LOADING_HELP_CHAT_NOTIFICATION:
      return {
        ...state,
        notification:{
          ...state.notification,
          l_help:action.payload
        }
      }
    default:
      return state
  }
}

export const getLastLogin = payload => ({
  type: types.GET_LAST_LOGIN,
  payload
})
export const getLastLoginSuccess = payload => ({
  type: types.GET_LAST_LOGIN_SUCCESS,
  payload
})
export const setIsLastLogin = payload => ({
  type: types.IS_LOADING_LAST_LOGIN,
  payload
})

export const getOrderNotification = payload => ({
  type: types.GET_ORDER_NOTIFICATION,
  payload
})
export const getOrderNotificationSuccess = payload => ({
  type: types.GET_ORDER_NOTIFICATION_SUCCESS,
  payload
})
export const setIsLoadingOrderNotification = payload => ({
  type: types.IS_LOADING_ORDER_NOTIFICATION,
  payload
})

export const getChatNotification = payload => ({
  type: types.GET_CHAT_NOTIFICATION,
  payload
})
export const getChatNotificationSuccess = payload => ({
  type: types.GET_CHAT_NOTIFICATION_SUCCESS,
  payload
})
export const setIsLoadingChatNotification = payload => ({
  type: types.IS_LOADING_CHAT_NOTIFICATION,
  payload
})

export const getReviewNotification = payload => ({
  type: types.GET_REVIEW_NOTIFICATION,
  payload
})
export const getReviewNotificationSuccess = payload => ({
  type: types.GET_REVIEW_NOTIFICATION_SUCCESS,
  payload
})
export const setIsLoadingReviewNotification = payload => ({
  type: types.IS_LOADING_REVIEW_NOTIFICATION,
  payload
})

export const setChatNotification = payload => ({
  type: types.SET_CHAT_NOTIFICATION,
  payload
})

export const getHelpChatNotification = payload => ({
  type: types.GET_HELP_CHAT_NOTIFICATION,
  payload
})
export const getHelpChatNotificationSuccess = payload => ({
  type: types.GET_HELP_CHAT_NOTIFICATION_SUCCESS,
  payload
})
export const setIsLoadingHelpChatNotification = payload => ({
  type: types.IS_LOADING_HELP_CHAT_NOTIFICATION,
  payload
})
