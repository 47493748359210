import { put, call, takeLatest, select } from "redux-saga/effects";
import Api from "../../../../services";
import types from "./constants";
import * as actions from "./reducer";
import * as actionsLogin from "../../../loginpage/reducer";

function* watchGetDetailProfile(values) {
  yield put(actions.setIsLoadingGetDetailProfile(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.getDetailProfile, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getDetailProfileSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getDetailProfileSuccess(state.loginsecurity.detail));
  } finally {
    yield put(actions.setIsLoadingGetDetailProfile(false));
  }
}
function* watchGetDetailByChangeEmailToken(values) {
  yield put(actions.setIsLoadingGetDetailProfile(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.getDetailByChangeEmailToken, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getDetailProfileSuccess(data));
    }
  } catch (e) {
    yield put(actions.getDetailProfileSuccess(state.loginsecurity.detail));
  } finally {
    yield put(actions.setIsLoadingGetDetailProfile(false));
  }
}

function* watchRequestChangeMail(values) {
  yield put(actions.setLoadingRequestChangeEmail(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.requestChangeEmail, payload);
    const { data } = response;
    if (data.success) {
      let result = {
        email: data.email,
        success: data.success,
        message: data.message,
      };
      yield put(actions.requestChangeEmailSuccess(result));
    }
  } catch (e) {
    yield put(actions.getDetailProfileSuccess(state.loginsecurity.detail));
  } finally {
    yield put(actions.setLoadingRequestChangeEmail(false));
  }
}

function* watchCheckPassword(values) {
  yield put(actions.setIsLoadingCheckPassword(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.checkPassword, payload);
    const { data } = response;
    yield put(actions.checkPasswordSuccess(data));
  } catch (e) {
    yield put(actions.checkPasswordSuccess(state.loginsecurity.check_password));
  } finally {
    yield put(actions.setIsLoadingCheckPassword(false));
  }
}

function* watchGetLinkChangeEmail(values) {
  yield put(actions.setIsLoadingGetLinkEmailChangeEmail(true));
  const { payload } = values;
  try {
    const response = yield call(Api.user.requestLinkChangeEmail, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.selectVerificationChangeEmail(payload));
      yield put(actions.getLinkEmailChangeEmailSuccess(true));
    }
  } catch (e) {
    yield put(actions.getLinkEmailChangeEmailSuccess(false));
  } finally {
    yield put(actions.setIsLoadingGetLinkEmailChangeEmail(false));
  }
}

function* watchGetSMSOTPChangeEmail(values) {
  yield put(actions.setIsLoadingGetSMSOTPChangeEmail(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.checkLastPhoneNumberBySMS, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.selectVerificationChangeEmail(payload));
      yield put(actions.getSMSOTPChangeEmailSuccess(data));
    }
  } catch (e) {
    yield put(
      actions.getSMSOTPChangeEmailSuccess(
        state.loginsecurity.sms_otp_change_email
      )
    );
  } finally {
    yield put(actions.setIsLoadingGetSMSOTPChangeEmail(false));
  }
}

function* watchSendSMSOTPChangeEmail(values) {
  yield put(actions.setIsLoadingSendSMSOTPChangeEmail(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.confirmLastPhoneNumber, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.sendSMSOTPChangeEmailSuccess(data));
    }
  } catch (e) {
    yield put(
      actions.sendSMSOTPChangeEmailSuccess(
        state.loginsecurity.sms_otp_change_email
      )
    );
  } finally {
    yield put(actions.setIsLoadingSendSMSOTPChangeEmail(false));
  }
}

function* watchSubmitEmailOTPChangeEmail(values) {
  yield put(actions.setIsLoadingSubmitEmailOTPChangeEmail(true));
  const { payload } = values;
  try {
    const response = yield call(Api.user.requestLinkChangeEmail, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.submitEmailOTPChangeEmailSuccess(true));
    }
  } catch (e) {
    yield put(actions.submitEmailOTPChangeEmailSuccess(false));
  } finally {
    yield put(actions.setIsLoadingSubmitEmailOTPChangeEmail(false));
  }
}

function* watchCheckChangeNewEmail(values) {
  yield put(actions.setLoadingCheckChangeEmail(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.checkNewEmail, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.selectVerificationChangeEmail(payload));
      yield put(actions.checkChangeEmailSuccess(data));
    }
  } catch (e) {
    yield put(
      actions.checkChangeEmailSuccess(state.loginsecurity.check_change_email)
    );
  } finally {
    yield put(actions.setLoadingCheckChangeEmail(false));
  }
}

function* watchUpdateEmail(values) {
  yield put(actions.setLoadingSendOTPChangeEmail(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.updateNewEmailOTP, payload);
    const { data } = response;
    if (data.success) {
      let result = {
        user: data,
        token: data.token,
        logged_in: true,
      };
      yield localStorage.setItem("key_x-log", data.token);
      yield localStorage.setItem(
        "lstlgn",
        JSON.stringify({ nm: data.name, pic: data.avatar_img })
      );
      yield put(actionsLogin.getLoginSuccess(result));
      yield put(actions.sendOTPChangeEmailSuccess(data));
    }
  } catch (e) {
    yield put(
      actions.sendOTPChangeEmailSuccess(state.loginsecurity.update_email)
    );
  } finally {
    yield put(actions.setLoadingSendOTPChangeEmail(false));
  }
}

function* watchCheckChangeNewPhoneNumber(values) {
  yield put(actions.setLoadingCheckChangePhoneNumber(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.checkNewPhoneNumber, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.selectVerificationChangeEmail(payload));
      yield put(actions.checkChangePhoneNumberSuccess(data));
    }
  } catch (e) {
    yield put(
      actions.checkChangePhoneNumberSuccess(
        state.loginsecurity.check_phone_number
      )
    );
  } finally {
    yield put(actions.setLoadingCheckChangePhoneNumber(false));
  }
}

function* watchCheckLastPhoneNumberByEmail(values) {
  yield put(actions.setLoadingCheckLastPhoneNumber(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.checkLastPhoneNumberByEmail, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.selectVerificationChangeEmail(payload));
      yield put(actions.checkLastPhoneNumberSuccess(data));
    }
  } catch (e) {
    yield put(
      actions.checkLastPhoneNumberSuccess(
        state.loginsecurity.check_last_phone_number
      )
    );
  } finally {
    yield put(actions.setLoadingCheckLastPhoneNumber(false));
  }
}

function* watchCheckLastPhoneNumberBySMS(values) {
  yield put(actions.setLoadingCheckLastPhoneNumber(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.checkLastPhoneNumberBySMS, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.selectVerificationChangeEmail(payload));
      yield put(actions.checkLastPhoneNumberSuccess(data));
    }
  } catch (e) {
    yield put(
      actions.checkLastPhoneNumberSuccess(
        state.loginsecurity.check_last_phone_number
      )
    );
  } finally {
    yield put(actions.setLoadingCheckLastPhoneNumber(false));
  }
}

function* watchConfirmOTPLastPhoneNumber(values) {
  yield put(actions.setLoadingCheckLastPhoneNumber(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.confirmLastPhoneNumber, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.confirmOtpLastPhoneNumberSuccess(data));
    }
  } catch (e) {
    yield put(
      actions.confirmOtpLastPhoneNumberSuccess(
        state.loginsecurity.check_last_phone_number
      )
    );
  } finally {
    yield put(actions.setLoadingCheckLastPhoneNumber(false));
  }
}

function* watchConfirmOTPNewPhoneNumber(values) {
  yield put(actions.setLoadingConfirmOTPPhoneNumber(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.user.changePhoneNumberOtp, payload);
    const { data } = response;
    if (data.success) {
      let result = {
        user: data,
        token: data.token,
        logged_in: true,
      };
      yield localStorage.setItem("key_x-log", data.token);
      yield localStorage.setItem(
        "lstlgn",
        JSON.stringify({ nm: data.name, pic: data.avatar_img })
      );
      yield put(actionsLogin.getLoginSuccess(result));
      yield put(actions.confirmOTPPhoneNumberSuccess(data));
    }
  } catch (e) {
    yield put(
      actions.confirmOTPPhoneNumberSuccess(
        state.loginsecurity.check_last_phone_number
      )
    );
  } finally {
    yield put(actions.setLoadingConfirmOTPPhoneNumber(false));
  }
}
function* watchChangeEmailRequestOTPCheck(values) {
  yield put(actions.setIsLoadingGetSMSOTPChangeEmail(true));
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(
      Api.user.change_email.request_otp.check.email,
      payload
    );
    const { data } = response;
    if (data.success) {
      yield put(actions.selectVerificationChangeEmail(payload));
      yield put(actions.getSMSOTPChangeEmailSuccess(data));
    }
  } catch (e) {
    yield put(
      actions.getSMSOTPChangeEmailSuccess(
        state.loginsecurity.sms_otp_change_email
      )
    );
  } finally {
    yield put(actions.setIsLoadingGetSMSOTPChangeEmail(false));
  }
}

const profileSagas = [
  takeLatest(types.GET_DETAIL_PROFILE, watchGetDetailProfile),
  takeLatest(
    types.GET_DETAIL_BY_CHANGE_EMAIL_TOKEN,
    watchGetDetailByChangeEmailToken
  ),
  takeLatest(types.REQUEST_CHANGE_EMAIL, watchRequestChangeMail),
  takeLatest(types.CHECK_PASSWORD, watchCheckPassword),
  takeLatest(types.CHECK_CHANGE_EMAIL, watchCheckChangeNewEmail),
  takeLatest(types.GET_LINK_EMAIL_CHANGE_EMAIL, watchGetLinkChangeEmail),
  takeLatest(types.SEND_OTP_UPDATE_EMAIL, watchUpdateEmail),
  takeLatest(types.CHECK_CHANGE_PHONE_NUMBER, watchCheckChangeNewPhoneNumber),
  takeLatest(
    types.CHECK_LAST_PHONE_NUMBER_EMAIL,
    watchCheckLastPhoneNumberByEmail
  ),
  takeLatest(
    types.CHECK_LAST_PHONE_NUMBER_PHONE,
    watchCheckLastPhoneNumberBySMS
  ),
  takeLatest(
    types.CONFIRM_OTP_LAST_PHONE_NUMBER,
    watchConfirmOTPLastPhoneNumber
  ),
  takeLatest(types.CHANGE_PHONE_NUMBER_OTP, watchConfirmOTPNewPhoneNumber),
  takeLatest(types.CHANGE_EMAIL_OTP, watchSubmitEmailOTPChangeEmail),
  takeLatest(types.GET_SMS_OTP_CHANGE_EMAIL, watchGetSMSOTPChangeEmail),
  takeLatest(types.SEND_SMS_OTP_CHANGE_EMAIL, watchSendSMSOTPChangeEmail),
  takeLatest(
    types.GET_CHANGE_EMAIL_REQUEST_OTP_CHECK_EMAIL,
    watchChangeEmailRequestOTPCheck
  ),
];

export default profileSagas;
