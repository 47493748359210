import types from './constants';

const initialState = {
  checkDomain:{},
  check: {
    subdomain:{},
    name:{},
    loading_name: false,
    loading_subdomain: false,
  },
  created: {
    data: {},
    loading: false
  },
  data:null,
  isLoadingCreate:false,
  isShopCreatedSuccess:false,
  isLoadingCheckDomain:false
}

export default (state=initialState, action) => {
  switch(action.type){
    //GET ALL
    case types.CREATE_SHOP_SUCCESS:
      return {
        ...state,
        data:action.payload,
        created: {
          ...state.created,
          data: action.payload
        }
      }
    case types.IS_LOADING_CREATE_SHOP:
      return {
        ...state,
        isLoadingCreate:action.payload,
        created: {
          ...state.created,
          loading: action.payload
        }
      }
    case types.SET_IS_SHOP_CREATED_SUCCESS:
      return {...state, isShopCreatedSuccess:action.payload}
    case types.CHECK_SUBDOMAIN_SHOP_SUCCESS:
      return {
        ...state,
        checkDomain:action.payload,
        check:{
          ...state.check,
          subdomain: action.payload
        }
      }
    case types.IS_LOADING_CHECK_SUBDOMAIN_SHOP:
      return {
        ...state,
        isLoadingCheckDomain:action.payload,
        check:{
          ...state.check,
          loading_subdomain: action.payload
        }
      }
    case types.CHECK_NAME_SHOP_SUCCESS:
      return {
        ...state,
        check:{
          ...state.check,
          name: action.payload
        }
      }
    case types.IS_LOADING_CHECK_NAME_SHOP:
      return {
        ...state,
        check:{
          ...state.check,
          loading_name: action.payload
        }
      }
    default:
      return state;
  }
}

export const checkNameShop = payload => ({
  type:types.CHECK_NAME_SHOP,
  payload
})
export const checkNameShopSuccess = payload => ({
  type:types.CHECK_NAME_SHOP_SUCCESS,
  payload
})
export const setIsLoadingCheckNameShop = payload => ({
  type:types.IS_LOADING_CHECK_NAME_SHOP,
  payload
})

export const checkDomainShop = payload => ({
  type:types.CHECK_SUBDOMAIN_SHOP,
  payload
})
export const checkDomainShopSuccess = payload => ({
  type:types.CHECK_SUBDOMAIN_SHOP_SUCCESS,
  payload
})
export const setIsLoadingCheckDomainShop = payload => ({
  type:types.IS_LOADING_CHECK_SUBDOMAIN_SHOP,
  payload
})

export const createShop = payload => ({
  type:types.CREATE_SHOP,
  payload
})
export const createShopSuccess = payload => ({
  type:types.CREATE_SHOP_SUCCESS,
  payload
})
export const setIsLoadingGetShop = payload => ({
  type:types.IS_LOADING_CREATE_SHOP,
  payload
})
export const setIsShopCreatedSuccess = payload => ({
  type: types.SET_IS_SHOP_CREATED_SUCCESS,
  payload
})
