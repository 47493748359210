export default {
  GET_ALL_BANNER: 'GET_ALL_BANNER',
  GET_ALL_BANNER_SUCCESS: 'GET_ALL_BANNER_SUCCESS',
  IS_LOADING_GET_BANNER: 'IS_LOADING_GET_BANNER',
  //category
  GET_CATEGORY : 'GET_CATEGORY',
  GET_CATEGORY_SUCCESS : 'GET_CATEGORY_SUCCESS',
  IS_LOADING_GET_CATEGORY : 'IS_LOADING_GET_CATEGORY',
  //rfq
  GET_RFQ : 'GET_RFQ',
  GET_RFQ_SUCCESS : 'GET_RFQ_SUCCESS',
  IS_LOADING_GET_RFQ : 'IS_LOADING_GET_RFQ',
  //new stores
  GET_NEW_STORES : 'GET_NEW_STORES',
  GET_NEW_STORES_SUCCESS : 'GET_NEW_STORES_SUCCESS',
  IS_LOADING_GET_NEW_STORES : 'IS_LOADING_GET_NEW_STORES',
  //new products
  GET_NEW_PRODUCTS : 'GET_NEW_PRODUCTS',
  GET_NEW_PRODUCTS_SUCCESS : 'GET_NEW_PRODUCTS_SUCCESS',
  IS_LOADING_GET_NEW_PRODUCTS : 'IS_LOADING_GET_NEW_PRODUCTS',
}
