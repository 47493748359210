import { put, call, takeLatest } from 'redux-saga/effects';

import Api from '../../services';
import types from './constants';
import * as actions from './reducer';

function* watchLastLoginUser(values) {
  const { payload } = values;
  yield put(actions.setIsLastLogin(true));
  try {
    const response = yield call(Api.authentication.login.last_in, payload);
    const { data } = response;
    if(data.success){
      yield put(actions.getLastLoginSuccess(data.data));
    }
  } catch (error) {
    yield put(actions.getLastLoginSuccess({}));
  } finally {
    yield put(actions.setIsLastLogin(false));
  }
}

function* watchGetOrderNotification(values) {
  const { payload } = values;
  yield put(actions.setIsLoadingOrderNotification(true));
  try {
    const responseOrder = yield call(Api.buyertransaction.getNotification, payload);
    const responsePayment = yield call(Api.notification.getPayment, payload);
    const { data } = responseOrder;
    let result = {
      ...data.data,
      payment: responsePayment.data.count,
    }
    yield put(actions.getOrderNotificationSuccess(result));
  } catch (error) {
    yield put(actions.setIsLoadingOrderNotification(false));
  } finally {
    yield put(actions.setIsLoadingOrderNotification(false));
  }
}

function* watchGetReviewNotification(values) {
  const { payload } = values;
  yield put(actions.setIsLoadingReviewNotification(true));
  try {
    const response = yield call(Api.notification.getReview, payload);
    const { data } = response;
    if(data.success){
      yield put(actions.getReviewNotificationSuccess(data.data));
    }
  } catch (error) {
    yield put(actions.setIsLoadingReviewNotification(false));
  } finally {
    yield put(actions.setIsLoadingReviewNotification(false));
  }
}

function* watchGetChatNotification(values) {
  const { payload } = values;
  yield put(actions.setIsLoadingChatNotification(true));
  try {
    const response = yield call(Api.notification.getChatUser, payload);
    const { data } = response;
    if(data.success){
      yield put(actions.getChatNotificationSuccess(data.data));
    }
  } catch (error) {
    yield put(actions.setIsLoadingChatNotification(false));
  } finally {
    yield put(actions.setIsLoadingChatNotification(false));
  }
}

function* watchGetHelpNotification(values) {
  const { payload } = values;
  yield put(actions.setIsLoadingHelpChatNotification(true));
  try {
    const response = yield call(Api.notification.getHelpChat, payload);
    const { data } = response;
    if(data.success){
      yield put(actions.getHelpChatNotificationSuccess(data.data));
    }
  } catch (error) {
    yield put(actions.setIsLoadingHelpChatNotification(false));
  } finally {
    yield put(actions.setIsLoadingHelpChatNotification(false));
  }
}



const accountSagas = [
  takeLatest(types.GET_LAST_LOGIN, watchLastLoginUser),
  takeLatest(types.GET_ORDER_NOTIFICATION, watchGetOrderNotification),
  takeLatest(types.GET_CHAT_NOTIFICATION, watchGetChatNotification),
  takeLatest(types.GET_REVIEW_NOTIFICATION, watchGetReviewNotification),
  takeLatest(types.GET_HELP_CHAT_NOTIFICATION, watchGetHelpNotification)
];

export default accountSagas;
