export default {
  GET_REQUEST : 'GET_REQUEST',
  GET_REQUEST_SUCCESS : 'GET_REQUEST_SUCCESS',
  IS_LOADING_GET_REQUEST : 'IS_LOADING_GET_REQUEST',
  GET_COUNT_REQUEST: 'GET_COUNT_REQUEST',
  RESET_REQUEST : 'RESET_REQUEST',

  GET_OFFER : 'GET_OFFER',
  GET_OFFER_SUCCESS : 'GET_OFFER_SUCCESS',
  IS_LOADING_GET_OFFER : 'IS_LOADING_GET_OFFER',
  GET_COUNT_OFFER: 'GET_COUNT_OFFER',
  RESET_OFFER : 'RESET_OFFER',

  GET_RFQ : 'GET_RFQ',
  GET_RFQ_SUCCESS : 'GET_RFQ_SUCCESS',
  IS_LOADING_GET_RFQ : 'IS_LOADING_GET_RFQ',
  GET_COUNT_RFQ: 'GET_COUNT_RFQ',
  RESET_RFQ : 'RESET_RFQ',

  GET_DETAIL_REQUEST : 'GET_DETAIL_REQUEST',
  GET_DETAIL_REQUEST_SUCCESS : 'GET_DETAIL_REQUEST_SUCCESS',
  IS_LOADING_GET_DETAIL_REQUEST : 'IS_LOADING_GET_DETAIL_REQUEST',

  CREATE_REQUEST : 'CREATE_REQUEST',
  CREATE_REQUEST_SUCCESS : 'CREATE_REQUEST_SUCCESS',
  IS_LOADING_CREATE_REQUEST : 'IS_LOADING_CREATE_REQUEST',
  STATUS_CREATE_REQUEST_SUCCESS : 'STATUS_CREATE_REQUEST_SUCCESS',

  CREATE_OFFER : 'CREATE_OFFER',
  CREATE_OFFER_SUCCESS : 'CREATE_OFFER_SUCCESS',
  IS_LOADING_CREATE_OFFER : 'IS_LOADING_CREATE_OFFER',
  STATUS_CREATE_OFFER_SUCCESS : 'STATUS_CREATE_OFFER_SUCCESS',
  RESET_CREATE_OFFER : 'RESET_CREATE_OFFER',

  CREATE_COMMENT_OFFER : 'CREATE_COMMENT_OFFER',
  CREATE_COMMENT_OFFER_SUCCESS : 'CREATE_COMMENT_OFFER_SUCCESS',
  IS_LOADING_CREATE_COMMENT : 'IS_LOADING_CREATE_COMMENT',
  IS_COMMENT_SUCCESS : 'IS_COMMENT_SUCCESS',
  RESET_COMMENT_OFFER : 'RESET_COMMENT_OFFER',
}
