const formatPrice = price => {
  const priceSeparatorRegex = /\B(?=(\d{3})+(?!\d))/g;
  const format = price.toString().replace(priceSeparatorRegex, '.');
  return format;
};

const showingPrice = detail => {
  const price = detail.price;
  const minGrocier = Math.min.apply(null, detail.GrocierPrices.map(dt=>dt.price))
  return detail.GrocierPrices.length > 0 ? minGrocier : price;
}

const wholesaleStatus = detail => detail.GrocierPrices.length > 0 ? true : false;
export {
  formatPrice,
  showingPrice,
  wholesaleStatus,
};
