import { put, call, takeLatest, delay } from "redux-saga/effects";
import Api from "../../services";
import types from "./types";
import * as actions from "./reducer";
// import * as actionsAccount from '../../reducer';

function* watchGetHelpDetailTopic(values) {
  yield put(actions.setIsLoadingGetHelpDetailTopic(true));
  const { payload } = values;
  try {
    const response = yield call(Api.help.get.detail_topic, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getHelpDetailTopicSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.setIsLoadingGetHelpDetailTopic(false));
  } finally {
    yield put(actions.setIsLoadingGetHelpDetailTopic(false));
  }
}
function* watchGetHelpTopics(values) {
  yield put(actions.setIsLoadingGetHelpTopics(true));
  const { payload } = values;
  try {
    const response = yield call(Api.help.get.topics, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getHelpTopicsSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.setIsLoadingGetHelpTopics(false));
  } finally {
    yield put(actions.setIsLoadingGetHelpTopics(false));
  }
}
function* watchGetHelpChatTalk(values) {
  yield put(actions.setIsLoadingGetHelpChatTalk(true));
  const { payload } = values;
  try {
    const response = yield call(Api.help.get.chat, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getHelpChatTalkSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.setIsLoadingGetHelpChatTalk(false));
  } finally {
    yield put(actions.setIsLoadingGetHelpChatTalk(false));
  }
}

function* watchCreateNewHelpChatTalk(values) {
  yield put(actions.setIsLoadingCreateNewHelpChatTalk(true));
  const { payload } = values;
  try {
    const response = yield call(Api.help.createNewChatTalk, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.createNewHelpChatTalkSuccess(data.data));
      yield put(actions.setCreatedSuccess(true));
    }
  } catch (e) {
    yield put(actions.setIsLoadingCreateNewHelpChatTalk(false));
  } finally {
    yield delay(100);
    yield put(actions.setCreatedSuccess(false));
    yield put(actions.setIsLoadingCreateNewHelpChatTalk(false));
  }
}

function* watchReplyHelpChatTalk(values) {
  yield put(actions.setIsLoadingReplyHelpChatTalk(true));
  const { payload } = values;
  try {
    const response = yield call(Api.help.create.reply_chat, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.replyHelpChatTalkSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.setIsLoadingReplyHelpChatTalk(false));
  } finally {
    yield put(actions.setIsLoadingReplyHelpChatTalk(false));
  }
}

function* watchSetIsReadChat(values) {
  yield put(actions.setIsLoadingSetIsReadChatHelp(true));
  const { payload } = values;
  try {
    const response = yield call(Api.help.update.read, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.setIsReadChatHelpSuccess(payload));
      // yield put(actionsAccount.getHelpChatNotificationSuccess(0));
    }
  } catch (e) {
    yield put(actions.setIsLoadingSetIsReadChatHelp(false));
  } finally {
    yield put(actions.setIsLoadingSetIsReadChatHelp(false));
  }
}

function* watchSendContactUs(values) {
  const { payload } = values;
  yield put(actions.setIsLoadingSendContactUs(true));
  try {
    const response = yield call(Api.help.contactUs, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.sendContactUsSuccess(true));
    }
  } catch (error) {
    yield put(actions.sendContactUsSuccess(false));
  } finally {
    yield put(actions.sendContactUsSuccess(false));
    yield put(actions.setIsLoadingSendContactUs(false));
  }
}

const sagas = [
  takeLatest(types.GET_HELP_DETAIL_TOPIC, watchGetHelpDetailTopic),
  takeLatest(types.GET_HELP_TOPICS, watchGetHelpTopics),
  takeLatest(types.GET_HELP_CHAT_TALK, watchGetHelpChatTalk),
  takeLatest(types.CREATE_NEW_HELP_CHAT_TALK, watchCreateNewHelpChatTalk),
  takeLatest(types.REPLY_HELP_CHAT_TALK, watchReplyHelpChatTalk),
  takeLatest(types.SET_IS_READ_CHAT_HELP, watchSetIsReadChat),
  takeLatest(types.SEND_CONTACT_US, watchSendContactUs),
];

export default sagas;
