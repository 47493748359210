import { Api, apiUrl } from "./api";

export default {
  checkSubdomain: (payload) =>
    Api.post(
      `/v2/stores/check/subdomain`,
      {
        subdomain_store: payload.subdomain_store,
      },
      {
        headers: {
          token: payload.token,
        },
      }
    ),
  checkName: (payload) =>
    Api.post(
      `/v2/stores/check/name`,
      {
        name: payload.name,
      },
      {
        headers: {
          token: payload.token,
        },
      }
    ),
  createShop: (payload) =>
    Api.post("/v2/stores/create", payload.data, {
      headers: {
        token: payload.token,
      },
    }),
  getBySearchName: (payload) =>
    Api.post("/v2/stores/search", {
      search: payload.search,
      limit: payload.limit,
      page: payload.page,
      sort: payload.sort,
      services: payload.services,
      location: payload.location,
      premium: payload.premium,
    }),
  getMyShop: (payload) =>
    Api.get("/v2/stores/seller/info", {
      headers: { token: payload.token },
    }),
  getShopById: (payload) => Api.get(`/v2/stores/detail/${payload.id}`),
  getDecoration: (payload) =>
    Api.get(`/v2/store-decorations/web/${payload.id}`),
  getShopBySubdomain: (payload) =>
    Api.post(`/v2/stores/subdomain`, {
      subdomain: payload.subdomain_shop,
    }),
  getBanner: (payload) =>
    Api.get(`/v1/stores/slide-banner/${payload.store_id}`),
  createNewShop: (payload) =>
    Api.post("/v1/stores", payload.data, {
      headers: { token: payload.token },
    }),
  editShopInfo: (payload) =>
    Api.put(`/v1/stores/${payload.storeId}`, payload.data, {
      headers: { token: payload.token },
    }),
  changeShopAvatar: (payload) => {
    const formData = new FormData();
    formData.append("store_avatar", payload.data);
    return fetch(`${apiUrl}/v1/stores/change_avatar/${payload.storeId}`, {
      method: "PUT",
      body: formData,
      headers: {
        token: payload.token,
      },
    })
      .then((response) => response.json())
      .then((data) => ({ data }))
      .catch((error) => {
        throw Error(error);
      });
  },
  changeShopBanner: (payload) => {
    const formData = new FormData();
    formData.append("store_banner", payload.data);
    return fetch(`${apiUrl}/v1/stores/change_banner/${payload.storeId}`, {
      method: "PUT",
      body: formData,
      headers: {
        token: payload.token,
      },
    })
      .then((response) => response.json())
      .then((data) => ({ data }))
      .catch((error) => {
        throw Error(error);
      });
  },
  getShippingService: (payload) =>
    Api.get(`/v1/stores/shipping_services/${payload.storeId}`, {
      headers: { token: payload.token },
    }),
  updateShippingService: (payload) =>
    Api.post(
      `/v1/stores/shipping_services/${payload.storeId}`,
      {
        services: payload.data,
      },
      {
        headers: { token: payload.token },
      }
    ),
  getCountFavorites: (payload) =>
    Api.get(`/v1/favorite/store/${payload.storeId}`),
  getCountProductsSold: (payload) =>
    Api.get(`/v1/orders/product-sold/${payload.storeId}`),
  getRating: (payload) => Api.get(`/v2/reviews/ratings/store/${payload.id}`),
};
