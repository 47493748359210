import types from "./constants";

const initialState = {
  detail: {},
  isLoadingDetailProduct: true,
  courier: [],
  isLoadingCourier: false,
  discussions: [],
  countDiscussions: 0,
  isLoadingDiscussion: false,
  isLoadingCreateDiscussionByProductId: false,
  reviews: [],
  countReviews: 0,
  isLoadingReview: false,
  otherProducts: {
    data: [],
    count: 0,
    loading: false,
  },
  discussionDetail: null,
  isLoadingDiscussionDetail: false,
  isLoadingCreateComment: false,
  viewed: 0,
  isLoadingViewed: false,
  sold: 0,
  isLoadingSold: false,
  chat: {
    show: false,
    store: null,
    product: null,
  },
  costs: [],
  isLoadingCosts: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_VIEWED_PRODUCT_SUCCESS:
      return { ...state, viewed: action.payload };
    case types.IS_LOADING_GET_VIEWED_PRODUCT:
      return { ...state, isLoadingViewed: action.payload };
    case types.GET_SOLD_PRODUCT_SUCCESS:
      return { ...state, sold: action.payload };
    case types.IS_LOADING_GET_SOLD_PRODUCT:
      return { ...state, isLoadingSold: action.payload };
    case types.GET_DETAIL_PRODUCT_SUCCESS:
      return { ...state, detail: action.payload };
    case types.IS_LOADING_GET_DETAIL_PRODUCT:
      return { ...state, isLoadingDetailProduct: action.payload };
    case types.RESET_DETAIL_PRODUCT:
      return { ...state, detail: initialState.detail };
    case types.GET_COURIER_BY_STORE_ID_SUCCESS:
      return { ...state, courier: action.payload };
    case types.IS_LOADING_GET_COURIER_BY_STORE_ID:
      return { ...state, isLoadingCourier: action.payload };
    case types.RESET_COURIER_BY_STORE_ID:
      return { ...state, courier: initialState.courier };
    case types.SET_COUNT_DISCUSSION_BY_PRODUCT_ID:
      return { ...state, countDiscussions: action.payload };
    case types.GET_DISCUSSION_BY_PRODUCT_ID_SUCCESS:
      return { ...state, discussions: action.payload };
    case types.IS_LOADING_GET_DISCUSSION_BY_PRODUCT_ID:
      return { ...state, isLoadingDiscussion: action.payload };
    case types.RESET_DISCUSSION_BY_PRODUCT_ID:
      return { ...state, discussions: initialState.discussion };
    case types.SET_COUNT_REVIEW_BY_PRODUCT_ID:
      return { ...state, countReviews: action.payload };
    case types.GET_REVIEW_BY_PRODUCT_ID_SUCCESS:
      return { ...state, reviews: action.payload };
    case types.IS_LOADING_GET_REVIEW_BY_PRODUCT_ID:
      return { ...state, isLoadingReview: action.payload };
    case types.RESET_REVIEW_BY_PRODUCT_ID:
      return { ...state, reviews: initialState.reviews };
    case types.GET_DISCUSSION_BY_ID_SUCCESS:
      return { ...state, discussionDetail: action.payload };
    case types.IS_LOADING_GET_DISCUSSION_BY_ID:
      return { ...state, isLoadingDiscussionDetail: action.payload };
    case types.RESET_GET_DISCUSSION_BY_ID:
      return { ...state, discussionDetail: null };
    case types.CREATE_DISCUSSION_BY_PRODUCT_ID_SUCCESS:
      return Object.assign({}, state, {
        discussions: [action.payload, ...state.discussions],
      });
    case types.IS_LOADING_CREATE_DISCUSSION_BY_PRODUCT_ID:
      return { ...state, isLoadingCreateDiscussionByProductId: action.payload };
    case types.CREATE_COMMENT_BY_DISCUSSION_ID_SUCCESS:
      return Object.assign({}, state, {
        discussions: state.discussions.map((item) =>
          item.id === action.payload.discussion_id
            ? {
                ...item,
                Comments: [...item.Comments.concat(action.payload)],
              }
            : item
        ),
      });
    case types.IS_LOADING_CREATE_COMMENT:
      return { ...state, isLoadingCreateComment: action.payload };
    case types.SET_SHOW_CHATS_FROM_PRODUCT:
      return {
        ...state,
        chat: {
          show: action.payload.show,
          store: action.payload.store,
          product: action.payload.product,
        },
      };
    case types.CHECK_COST_SHIPPING_MULTIPLE_SUCCESS:
      return { ...state, costs: action.payload };
    case types.IS_LOADING_CHECK_COST_SHIPPING_MULTIPLE:
      return { ...state, isLoadingCosts: action.payload };
    case types.GET_PRODUCTS_BY_STORE_ID_ON_DETAIL_PRODUCT_SUCCESS:
      return {
        ...state,
        otherProducts: {
          ...state.otherProducts,
          data: action.payload,
        },
      };
    case types.SET_COUNT_PRODUCTS_BY_STORE_ID_ON_DETAIL_PRODUCT:
      return {
        ...state,
        otherProducts: {
          ...state.otherProducts,
          count: action.payload,
        },
      };
    case types.IS_LOADING_GET_PRODUCTS_BY_STORE_ID_ON_DETAIL_PRODUCT:
      return {
        ...state,
        otherProducts: {
          ...state.otherProducts,
          loading: action.payload,
        },
      };
    default:
      return state;
  }
};

export const getOtherProducts = (payload) => ({
  type: types.GET_PRODUCTS_BY_STORE_ID_ON_DETAIL_PRODUCT,
  payload,
});
export const getOtherProductsSuccess = (payload) => ({
  type: types.GET_PRODUCTS_BY_STORE_ID_ON_DETAIL_PRODUCT_SUCCESS,
  payload,
});
export const setIsLoadingGetOtherProducts = (payload) => ({
  type: types.IS_LOADING_GET_PRODUCTS_BY_STORE_ID_ON_DETAIL_PRODUCT,
  payload,
});
export const setCountOtherProducts = (payload) => ({
  type: types.SET_COUNT_PRODUCTS_BY_STORE_ID_ON_DETAIL_PRODUCT,
  payload,
});

export const updateViewedProduct = (payload) => ({
  type: types.UPDATE_VIEWED_PRODUCT,
  payload,
});
export const getViewedProduct = (payload) => ({
  type: types.GET_VIEWED_PRODUCT,
  payload,
});
export const getViewedProductSuccess = (payload) => ({
  type: types.GET_VIEWED_PRODUCT_SUCCESS,
  payload,
});
export const isLoadingGetViewedProduct = (payload) => ({
  type: types.IS_LOADING_GET_VIEWED_PRODUCT,
  payload,
});

export const getSoldProduct = (payload) => ({
  type: types.GET_SOLD_PRODUCT,
  payload,
});
export const getSoldProductSuccess = (payload) => ({
  type: types.GET_SOLD_PRODUCT_SUCCESS,
  payload,
});
export const isLoadingGetSoldProduct = (payload) => ({
  type: types.IS_LOADING_GET_SOLD_PRODUCT,
  payload,
});

//Get Product {id}
export const getDetailProduct = (payload) => ({
  type: types.GET_DETAIL_PRODUCT,
  payload,
});
export const getDetailProductSuccess = (payload) => ({
  type: types.GET_DETAIL_PRODUCT_SUCCESS,
  payload,
});
export const isLoadingGetDetailProduct = (payload) => ({
  type: types.IS_LOADING_GET_DETAIL_PRODUCT,
  payload,
});
export const resetDetailProduct = () => ({
  type: types.RESET_DETAIL_PRODUCT,
});

//Get Discussion {product_id, length, page}
export const setCountDiscussions = (payload) => ({
  type: types.SET_COUNT_DISCUSSION_BY_PRODUCT_ID,
  payload,
});
export const getDiscussion = (payload) => ({
  type: types.GET_DISCUSSION_BY_PRODUCT_ID,
  payload,
});
export const getDiscussionSuccess = (payload) => ({
  type: types.GET_DISCUSSION_BY_PRODUCT_ID_SUCCESS,
  payload,
});
export const isLoadingGetDiscussion = (payload) => ({
  type: types.IS_LOADING_GET_DISCUSSION_BY_PRODUCT_ID,
  payload,
});
export const resetDiscussion = () => ({
  type: types.RESET_DISCUSSION_BY_PRODUCT_ID,
});
//Create Discussion
export const createDiscussion = (payload) => ({
  type: types.CREATE_DISCUSSION_BY_PRODUCT_ID,
  payload,
});
export const createDiscussionSuccess = (payload) => ({
  type: types.CREATE_DISCUSSION_BY_PRODUCT_ID_SUCCESS,
  payload,
});
export const isLoadingCreateDiscussion = (payload) => ({
  type: types.IS_LOADING_CREATE_DISCUSSION_BY_PRODUCT_ID,
  payload,
});

//Get Review {product_id, length, page}
export const setCountReviews = (payload) => ({
  type: types.SET_COUNT_REVIEW_BY_PRODUCT_ID,
  payload,
});
export const getReview = (payload) => ({
  type: types.GET_REVIEW_BY_PRODUCT_ID,
  payload,
});
export const getReviewSuccess = (payload) => ({
  type: types.GET_REVIEW_BY_PRODUCT_ID_SUCCESS,
  payload,
});
export const isLoadingGetReview = (payload) => ({
  type: types.IS_LOADING_GET_REVIEW_BY_PRODUCT_ID,
  payload,
});
export const resetReview = () => ({
  type: types.RESET_REVIEW_BY_PRODUCT_ID,
});

//Get Courier {store_id}
export const getCourier = (payload) => ({
  type: types.GET_COURIER_BY_STORE_ID,
  payload,
});
export const getCourierSuccess = (payload) => ({
  type: types.GET_COURIER_BY_STORE_ID_SUCCESS,
  payload,
});
export const isLoadingGetCourier = (payload) => ({
  type: types.IS_LOADING_GET_COURIER_BY_STORE_ID,
  payload,
});
export const resetCourier = () => ({
  type: types.RESET_COURIER_BY_STORE_ID,
});

//Create Comments
export const createComment = (payload) => ({
  type: types.CREATE_COMMENT_BY_DISCUSSION_ID,
  payload,
});
export const createCommentSuccess = (payload) => ({
  type: types.CREATE_COMMENT_BY_DISCUSSION_ID_SUCCESS,
  payload,
});
export const isLoadingCreateComment = (payload) => ({
  type: types.IS_LOADING_CREATE_COMMENT,
  payload,
});

export const setShowChat = (payload) => ({
  type: types.SET_SHOW_CHATS_FROM_PRODUCT,
  payload,
});

export const getCheckCostShipping = (payload) => ({
  type: types.CHECK_COST_SHIPPING_MULTIPLE,
  payload,
});
export const getCheckCostShippingSuccess = (payload) => ({
  type: types.CHECK_COST_SHIPPING_MULTIPLE_SUCCESS,
  payload,
});
export const isLoadingGetCheckCostShipping = (payload) => ({
  type: types.IS_LOADING_CHECK_COST_SHIPPING_MULTIPLE,
  payload,
});
