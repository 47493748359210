import types from './constants';

const initialState = {
  detail:{},
  password_updated:false,
  isLoadingGetDetail:false,
  isLoadingUpdatePassword:false
}

export default (state=initialState, action) => {
  switch(action.type){
    case types.GET_DETAIL_PROFILE_FORGOT_PASSWORD_SUCCESS:
      return {...state, detail:action.payload}
    case types.IS_LOADING_GET_DETAIL_PROFILE_FORGOT_PASSWORD:
      return {...state, isLoadingGetDetail:action.payload}
    case types.CHANGE_PASSWORD_FROM_FORGOT_PASSWORD_SUCCESS:
      return {...state, password_updated:action.payload}
    case types.IS_LOADING_CHANGE_PASSWORD_FROM_FORGOT_PASSWORD:
      return {...state, isLoadingUpdatePassword:action.payload}
    case types.DEFAULT_FORGOT_PASSWORD:
      return {...state, ...initialState};
    default:
      return state;
  }
}

export const defaultForgotPassword = payload => ({
  type: types.DEFAULT_FORGOT_PASSWORD
})
//GET DETAIL
export const getDetail = payload => ({
  type: types.GET_DETAIL_PROFILE_FORGOT_PASSWORD,
  payload
})
export const getDetailSuccess = payload => ({
  type: types.GET_DETAIL_PROFILE_FORGOT_PASSWORD_SUCCESS,
  payload
})
export const setIsLoadingGetDetail = payload => ({
  type: types.IS_LOADING_GET_DETAIL_PROFILE_FORGOT_PASSWORD,
  payload
})

export const updatePassword = payload => ({
  type: types.CHANGE_PASSWORD_FROM_FORGOT_PASSWORD,
  payload
})
export const updatePasswordSuccess = payload => ({
  type: types.CHANGE_PASSWORD_FROM_FORGOT_PASSWORD_SUCCESS,
  payload
})
export const setIsLoadingUpdatePassword = payload => ({
  type: types.IS_LOADING_CHANGE_PASSWORD_FROM_FORGOT_PASSWORD,
  payload
})
