import { put, call, takeLatest, select } from 'redux-saga/effects';
import Api from '../../../../services';
import types from './constants';
import * as actions from './reducer';
import { notification } from '../../../../helpers/notification';

function* watchGetWishlist(values){
  yield put(actions.isLoadingGetWishlist(true))
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.wishlist.get, payload);
    const {data} = response;
    if(data.success){
      yield put(actions.getWishlistSuccess(data.data));
      yield put(actions.setCountWishlist(data.count));
    }
  } catch (e) {
    yield put(actions.getWishlistSuccess(state.list.wishlist));
  } finally {
    yield put(actions.isLoadingGetWishlist(false))
  }
}
function* watchCheckWishlist(values){
  yield put(actions.isLoadingCheckWishlist(true))
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.wishlist.check, payload);
    const {data} = response;
    if(data.success){
      yield put(actions.checkWishlistSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getWishlistSuccess(state.list.wishlist));
  } finally {
    yield put(actions.isLoadingCheckWishlist(false))
  }
}
function* watchCheckProductsOnWishlist(values){
  yield put(actions.setIsLoadingCheckAllProductsOnWishlist(true))
  const { payload } = values;
  try {
    const response = yield call(Api.wishlist.checkProducts, payload);
    const { data } = response;
    if(data.success){
      yield put(actions.checkAllProductsOnWishlistSuccess(data.data))
    }
  } catch (e) {
    yield put(actions.setIsLoadingCheckAllProductsOnWishlist(false))
  } finally {
    yield put(actions.setIsLoadingCheckAllProductsOnWishlist(false))
  }
}

function* watchCreateWishlist(values){
  yield put(actions.setIsLoadingCreateWishlist(true))
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.wishlist.add, payload);
    const {data} = response;
    if(data.success){
      let dt = {
        ...data,
        Product:payload.product
      }
      yield put(actions.createWishlistSuccess(dt));
      yield put(actions.checkAllProductsOnWishlistSuccess([...state.list.check_products, payload.product_id]))
      yield notification.default('Produk berhasil disimpan di Wishlist')
    }
  } catch (e) {
    yield put(actions.getWishlistSuccess(state.list.wishlist));
  } finally {
    yield put(actions.setIsLoadingCreateWishlist(false))
  }
}
function* watchDeleteWishlist(values){
  yield put(actions.setIsLoadingDeleteWishlist(true))
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.wishlist.delete, payload);
    const { data } = response;
    if(data.success){
      yield put(actions.deleteWishlistSuccess(data))
      yield notification.default('Produk telah dihapus dari Wishlist')
    }
  } catch (e) {
    yield put(actions.getWishlistSuccess(state.list.wishlist));
  } finally {
    yield put(actions.setIsLoadingDeleteWishlist(false))
  }
}
function* watchDeleteWishlistByProductId(values){
  yield put(actions.setIsLoadingDeleteWishlistByProductId(true))
  const { payload } = values;
  try {
    const response = yield call(Api.wishlist.removeWithProductId, payload);
    const { data } = response;
    if(data.success){
      yield put(actions.deleteWishlistByProductIdSuccess(payload.product_id))
      yield notification.default('Produk telah dihapus dari Wishlist')
    }
  } catch (e) {
    yield put(actions.setIsLoadingDeleteWishlistByProductId(false))
  } finally {
    yield put(actions.setIsLoadingDeleteWishlistByProductId(false))
  }
}

function* watchGetFavorite(values){
  yield put(actions.setIsLoadingGetFavorite(true))
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.favorite.get, payload);
    const {data} = response;
    if(data.success){
      yield put(actions.getFavoriteSuccess(data.data));
      yield put(actions.setCountFavorite(data.count));
    }
  } catch (e) {
    yield put(actions.getFavoriteSuccess(state.list.favorite));
  } finally {
    yield put(actions.setIsLoadingGetFavorite(false))
  }
}
function* watchCheckFavorite(values){
  yield put(actions.isLoadingCheckFavorite(true))
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.favorite.check, payload);
    const {data} = response;
    if(data.success){
      yield put(actions.checkFavoriteSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.getFavoriteSuccess(state.list.favorite));
  } finally {
    yield put(actions.isLoadingCheckFavorite(false))
  }
}
function* watchCreateFavorite(values){
  yield put(actions.setIsLoadingCreateFavorite(true))
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.favorite.add, payload);
    const {data} = response;
    if(data.success){
      let dt = {
        ...data,
        Store:payload.store
      }
      yield put(actions.createFavoriteSuccess(dt));
    }
  } catch (e) {
    yield put(actions.getFavoriteSuccess(state.list.favorite));
  } finally {
    yield put(actions.setIsLoadingCreateFavorite(false))
  }
}
function* watchDeleteFavorite(values){
  yield put(actions.setIsLoadingDeleteFavorite(true))
  const state = yield select();
  const { payload } = values;
  try {
    const response = yield call(Api.favorite.delete, payload);
    const { data } = response;
    if(data.success){
      yield put(actions.deleteFavoriteSuccess(data))
    }
  } catch (e) {
    yield put(actions.getFavoriteSuccess(state.list.favorite));
  } finally {
    yield put(actions.setIsLoadingDeleteFavorite(false))
  }
}

const sagas = [
  takeLatest(types.CHECK_ALL_PRODUCTS_ON_WISHLIST, watchCheckProductsOnWishlist),
  takeLatest(types.GET_WISHLIST, watchGetWishlist),
  takeLatest(types.CHECK_WISHLIST, watchCheckWishlist),
  takeLatest(types.CREATE_WISHLIST, watchCreateWishlist),
  takeLatest(types.DELETE_WISHLIST, watchDeleteWishlist),
  takeLatest(types.DELETE_WISHLIST_BY_PRODUCT_ID, watchDeleteWishlistByProductId),

  takeLatest(types.GET_FAVORITE, watchGetFavorite),
  takeLatest(types.CHECK_FAVORITE, watchCheckFavorite),
  takeLatest(types.CREATE_FAVORITE, watchCreateFavorite),
  takeLatest(types.DELETE_FAVORITE, watchDeleteFavorite),
]

export default sagas;
