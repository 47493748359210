import { put, call, takeLatest } from 'redux-saga/effects';
import Api from '../../services';
import types from './constants';
import * as actions from './reducer';
import { notification } from '../../helpers/notification';

function* watchCheckShippingCost(values){
  const {payload} = values;
  yield put(actions.setIsLoadingCheckSippingCost(true))
  try{
    const response = yield call(Api.checkcost.multiple, payload)
    const {data} = response;
    yield put(actions.checkSippingCostSuccess(data.data));
  }catch(e){
    yield put(actions.setIsLoadingCheckSippingCost(false))
  }finally{
    yield put(actions.setIsLoadingCheckSippingCost(false))
  }
}

function* watchRequestOrder(values){
  const {payload} = values;
  yield put(actions.setIsLoadingCreateOrders(true));
  try{
    const response = yield call(Api.buyertransaction.requestOrder, payload)
    const {data} = response;
    if(response.status === 200){
      yield put(actions.createOrdersSuccess(data));
      yield put(actions.setPayment(data.payment))
    }
  }catch(e){
    notification.error('Gagal mengajukan pesanan');
    yield put(actions.setIsLoadingCreateOrders(false))
  }finally{
    yield put(actions.setIsLoadingCreateOrders(false))
  }
}
function* watchGetWarehouse(values){
  const {payload} = values;
  yield put(actions.setIsLoadingGetWarehouse(true));
  try{
    const response = yield call(Api.warehouse.getByStoreId, payload)
    const {data} = response;
    if(data.success){
      yield put(actions.getWarehouseSuccess(data.data));
    }
  }catch(e){
    yield put(actions.setIsLoadingGetWarehouse(false))
  }finally{
    yield put(actions.setIsLoadingGetWarehouse(false))
  }
}

const sagas = [
  takeLatest(types.CHECK_SHIPPING_COST, watchCheckShippingCost),
  takeLatest(types.CREATE_ORDERS, watchRequestOrder),
  takeLatest(types.GET_WAREHOUSE, watchGetWarehouse)
]

export default sagas;
