import { Api } from "./api";

export default {
  getBannerHomepages: () => Api.get("/slider-homepages"),
  getCategories: () => Api.get("/v1/categories"),
  getAllRFQ: (payload) =>
    Api.get("/v1/rfq", {
      params: {
        limit: payload.limit,
      },
    }),
  getNewStore: (payload) =>
    Api.get("/v1/stores", {
      params: {
        length: payload.limit,
      },
    }),
  getNewProduct: (payload) =>
    Api.get("/v2/products", {
      params: {
        limit: payload.limit,
        page: payload.page,
      },
    }),
};
