import { put, call, takeLatest } from "redux-saga/effects";
import Api from "../../services";
import types from "./constants";
import * as actions from "./reducer";
import * as actionsLogin from "../loginpage/reducer";

function* watchCheckDomainShop(values) {
  yield put(actions.setIsLoadingCheckDomainShop(true));
  const { payload } = values;
  try {
    const response = yield call(Api.store.checkSubdomain, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.checkDomainShopSuccess(data));
    }
  } catch (e) {
    yield put(
      actions.checkDomainShopSuccess({
        success: false,
        message: e.response.data?.message,
      })
    );
  } finally {
    yield put(actions.setIsLoadingCheckDomainShop(false));
  }
}
function* watchCheckNameShop(values) {
  yield put(actions.setIsLoadingCheckNameShop(true));
  const { payload } = values;
  try {
    const response = yield call(Api.store.checkName, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.checkNameShopSuccess(data));
    }
  } catch (e) {
    yield put(
      actions.checkNameShopSuccess({
        success: false,
        message: e.response.data?.message,
      })
    );
  } finally {
    yield put(actions.setIsLoadingCheckNameShop(false));
  }
}

function* watchCreatedShop(values) {
  yield put(actions.setIsLoadingGetShop(true));
  const { payload } = values;
  try {
    const response = yield call(Api.store.createShop, payload);
    const { data } = response;
    if (data.success) {
      let resultLogin = {
        user: data.data.user,
        token: data.data.token,
        logged_in: true,
      };
      yield put(actions.createShopSuccess(data));
      yield put(actionsLogin.getLoginSuccess(resultLogin));
      yield put(actions.setIsShopCreatedSuccess(true));
    }
  } catch (e) {
  } finally {
    yield put(actions.setIsLoadingGetShop(false));
  }
}

const shopSagas = [
  takeLatest(types.CHECK_NAME_SHOP, watchCheckNameShop),
  takeLatest(types.CHECK_SUBDOMAIN_SHOP, watchCheckDomainShop),
  takeLatest(types.CREATE_SHOP, watchCreatedShop),
];

export default shopSagas;
